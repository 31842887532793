import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Helpers } from 'src/app/helper/helper';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-tpl-success',
  templateUrl: './tpl-success.component.html',
  styleUrls: ['./tpl-success.component.scss']
})
export class TplSuccessComponent implements OnInit {

  downloadLink: string;

  constructor(private activatedRoute: ActivatedRoute,
    private helper: Helpers) { }

  ngOnInit() {
    this.activatedRoute.queryParamMap.subscribe( queryParam => {
      let token = queryParam.get("token");
      
      if ( !token ) {
        token = this.helper.getToken();
      }
      
      if ( token ) {
        this.downloadLink = environment.dynamicLink + "?link=https://saiyarti.com/set-password?token=" + token + "&apn=com.rc.saiyarti&ibi=com.saiyarti&isi=1459650287&efr=1&ofl=" + environment.dynamicLinkOfl + "set-password?token=" + token;
      }
    }, error => {});
  }

}
