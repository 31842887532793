<div class="page-content">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="card col-12 col-md-8">
                <div class="row pb-4">
                    <form class="col-12" #updatePolicyForm="ngForm" (ngSubmit)="updateDetails(updatePolicyForm)">
                        <div class="row">
                            <div class="col-lg-12" style="height: 30px;"></div>
                            <h2 class="col-md-12" style="color: cadetblue;">{{ 'Congratulations! Your request for a
                                third-party motor liability policy has been received successfully with order number' |
                                translate}} {{paymentDataBaseId}}</h2>
                            <p class="col-md-12" style="color: cadetblue;"> {{ 'Please make a note of this for your
                                records as well.' | translate}} </p>
                            <p class="col-md-12"> {{ 'We will process your request and get back to you with your
                                Insurance Policy at earliest. We look forward to delivering your policy within the next
                                48-72 hours.' | translate }} </p>
                            <p class="col-md-12" *ngIf="showComprehensiveText === 'true'"> {{ "The quote generated for
                                your comprehensive insurance is valid till "  | translate}} {{ quoteExpiryDate }} {{ ", and is subject to Insurance Company's approval." | translate }} </p>
                            <p class="col-md-12" *ngIf="showComprehensiveText === 'true'"> {{ 'Based on the vehicle data
                                and photographs shared by you, the Insurance company will confirm the final pricing,
                                terms and conditions for your comprehensive insurance. We will revert to you with their
                                approval confirmation on your quote and the payment link for your comprehensive
                                insurance at earliest.' | translate }} </p>
                            <p class="col-md-12"> {{ 'In case you need any further information or assistance, we are
                                available on' | translate }} </p>
                            <div class="my-3 col-md-12">
                                <a href="https://web.whatsapp.com/send?phone=96567734711&text=&source=&data=&app_absent="
                                    target="_blank"><img src="../../../assets/images/WhatsApp.svg" alt="" width="18"
                                        height="18"><span class="ml-2 font-size-16"
                                        style="color: #222B4A;vertical-align: middle;"> {{'wp-number'|translate}}</span></a>
                            </div>
                            <div class="my-3 col-md-12">
                                <a href="tel:+965 22207335"><img src="../../../assets/images/Phone.svg" alt=""
                                        width="18" height="18"><span class="ml-2 font-size-16"
                                        style="color: #222B4A;vertical-align: middle;"> {{'call-number'|translate}}</span></a>
                            </div>
                            <div class="my-3 col-md-12">
                                <a href="mailto:info@saiyarti.com"><img src="../../../assets/images/Email.svg" alt=""
                                        width="18" height="18"><span class="ml-2 font-size-16"
                                        style="color: #222B4A;vertical-align: middle;"> info@saiyarti.com</span></a>
                            </div>
                            <div class="my-3 col-md-12">
                                <button type="submit" class="btn px-4" style="background-color: #222B4A;color: white;"
                                    routerLink="/vehicles">{{'Go Home' | translate}}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div> <!-- container-fluid -->
</div>
<!-- End Page-content -->
