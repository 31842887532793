<div class="page-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-6" style="max-width: 55%;">
                <div class="row">
                    <!-- vehicle details card -->
                    <div class="col-lg-12">
                        <div class="card">
                            <div class="card-body" style="padding: 1rem;">
                                <div class="d-flex justify-content-between">
                                    <div>
                                        <h4 class="heading-text mb-4"> {{ 'Vehicle Details' | translate }}</h4>
                                    </div>
                                </div>
                                <div class="d-flex">
                                    <div class="table-responsive" style="overflow-x:unset">
                                        <table class="table table-centered table-nowrap mb-0 col-6">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <h5 class="data-text m-0">{{ 'Plate Number' | translate }} </h5>
                                                    </td>
                                                    <td class="data-text">{{ rateCard.vehicle.plateNumber}}</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <h5 class="data-text m-0">{{ 'Purpose of License' | translate }}
                                                        </h5>
                                                    </td>
                                                    <td class="data-text">{{ rateCard.vehicle.purposeOfLicense }}</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <h5 class="data-text m-0">{{ 'Car Make' | translate }}</h5>
                                                    </td>
                                                    <td class="data-text">{{ rateCard.vehicle.carMake }}</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <h5 class="data-text m-0">{{ 'Car Model' | translate }} </h5>
                                                    </td>
                                                    <td class="data-text">{{ rateCard.vehicle.carModel }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="table-responsive" style="overflow-x:unset">
                                        <table class="table table-centered table-nowrap mb-0 col-6">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <h5 class="data-text m-0">{{ 'Variant' | translate }}</h5>
                                                    </td>
                                                    <td class="data-text">{{ rateCard.vehicle.variant }}</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <h5 class="data-text m-0">{{ 'Model Year' | translate }} </h5>
                                                    </td>
                                                    <td class="data-text">{{ rateCard.vehicle.modelYear }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <h5 class="data-text m-0">{{ 'Number of Passengers' | translate
                                                            }}</h5>
                                                    </td>
                                                    <td class="data-text">{{rateCard.vehicle.noOfPassenger}}</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <h5 class="data-text m-0">{{ 'Market Value of Car' | translate
                                                            }}</h5>
                                                    </td>
                                                    <td class="data-text">{{'KWD'| translate}} {{ rateCard.vehicle.marketValue |
                                                        number : '1.3-3'
                                                        }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end col -->
                    <!-- Insurance coverages -->
                    <div class="col-lg-12" *ngIf="rateCardSelections">
                        <div class="card">
                            <div class="card-body">
                                <div class="d-flex justify-content-between">
                                    <div>
                                        <h4 class="heading-text mb-4">{{ 'Insurance Coverages' | translate }}</h4>
                                    </div>
                                </div>
                                <div class="table-responsive">
                                    <table class="table table-centered table-nowrap mb-0">
                                        <tbody class="insuranceDetails">
                                            <tr *ngIf="rateCardSelections.limitedCoverage"
                                                class="d-flex justify-content-between">
                                                <th>
                                                    <h5 class="data-text m-0">{{'Limited Comprehensive Cover' |
                                                        translate}} </h5>
                                                </th>
                                                <td class="dark-data-text">
                                                    <span *ngIf="rateCardSelections.limitedCoverage">{{'Included' |
                                                        translate}}</span>
                                                </td>
                                            </tr>
                                            <tr *ngIf="rateCardSelections.comprehensive"
                                                class="d-flex justify-content-between">
                                                <th>
                                                    <h5 class="data-text m-0">{{'Full Comprehensive Cover' |
                                                        translate}}
                                                    </h5>
                                                </th>
                                                <td></td>
                                            </tr>
                                            <div *ngIf="rateCardSelections.comprehensive && rateCard.parametersMap">
                                                <ng-container
                                                    *ngFor="let parameterKeyValue of rateCard.parametersMap | keyvalue : sort">
                                                    <tr *ngIf="parameterKeyValue.value.isSelected"
                                                        class="d-flex justify-content-between ml-5">
                                                        <th>
                                                            <h5 class="light-data-text m-0"
                                                                style="max-width: 310px;word-break: break-word;white-space: normal;">
                                                                {{parameterKeyValue.value.translatedName(language)}}
                                                            </h5>
                                                        </th>
                                                        <ng-container
                                                            *ngIf="Object.keys(parameterKeyValue.value.subParametersMap).length === 0;">
                                                            <td *ngIf="parameterKeyValue.value.isSelected"
                                                                class="dark-data-text">{{'Included' | translate}}
                                                            </td>
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="Object.keys(parameterKeyValue.value.subParametersMap).length > 0;">
                                                            <ng-container
                                                                *ngFor="let subParameterKeyValue of parameterKeyValue.value.subParametersMap | keyvalue : sort">
                                                                <td *ngIf="subParameterKeyValue.value.isSelected"
                                                                    class="dark-data-text">
                                                                    {{subParameterKeyValue.value.translatedName(language)
                                                                    |
                                                                    translate}}
                                                                </td>
                                                            </ng-container>
                                                        </ng-container>
                                                    </tr>
                                                </ng-container>
                                            </div>
                                            <tr *ngIf="rateCardSelections.addOns">
                                                <th>
                                                    <h5 class="data-text m-0">{{'Saiyarti Add-Ons' | translate}}</h5>
                                                </th>
                                                <td></td>
                                            </tr>
                                            <div *ngIf="rateCardSelections.addOns">

                                                <tr *ngFor="let addOnKeyValue of rateCard.addOnsMap | keyvalue : sort"
                                                    class="d-flex justify-content-between ml-5">
                                                    <ng-container *ngIf="addOnKeyValue.key ==='Replacement Car'">
                                                        <th>
                                                            <h5 class="light-data-text m-0">
                                                                {{addOnKeyValue.value.translatedName(language)}}
                                                                <ng-container
                                                                    *ngFor="let subParameter of rateCard.addOnsMap[addOnKeyValue.key].subParametersMap| keyvalue : sort">
                                                                    <ng-container *ngIf="subParameter.value.isSelected">
                                                                        ({{subParameter.value.translatedName(language)}})
                                                                    </ng-container>
                                                                </ng-container>
                                                            </h5>
                                                        </th>
                                                        <ng-container
                                                            *ngFor="let subParameter of rateCard.addOnsMap[addOnKeyValue.key].subParametersMap| keyvalue : sort">
                                                            <ng-container *ngIf="subParameter.value.isSelected">
                                                                <ng-container
                                                                    *ngFor="let carSizeKeyValue of subParameter.value.subParametersMap| keyvalue : sort">
                                                                    <ng-container
                                                                        *ngIf="carSizeKeyValue.value.isSelected">
                                                                        <td class="dark-data-text">
                                                                            {{carSizeKeyValue.value.translatedName(language)}}
                                                                        </td>
                                                                    </ng-container>
                                                                </ng-container>
                                                            </ng-container>
                                                        </ng-container>
                                                    </ng-container>

                                                    <ng-container *ngIf="addOnKeyValue.key !=='Replacement Car'">
                                                        <th>
                                                            <h5 class="light-data-text m-0">
                                                                {{addOnKeyValue.value.translatedName(language)}}</h5>
                                                        </th>
                                                        <td class="dark-data-text">{{'Included' | translate}}</td>
                                                    </ng-container>
                                                </tr>

                                            </div>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end col -->
                </div>
            </div>
            <div class="col-6" style="max-width: 45%;">
                <!-- Policy details -->
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-body" style="padding: 1rem;">
                            <h4 class="heading-text mb-4">{{ 'Submitted Quote' | translate }}</h4>
                            <div class="d-flex justify-content-between">
                                <div>
                                    <p class="data-text" style="font-weight: 500;font-size: 20px;">{{'Supporting
                                        Documents' |
                                        translate}}</p>
                                </div>
                                <div>
                                  <span *ngIf="((language == 'en'))">
                                    <button class="btn data-text uploadedImages"
                                        (click)="showViewImages = !showViewImages"> {{
                                            uploadedImageCounter }} / 10 <span class="material-icons dropdownbtn"
                                            [ngClass]="showViewImages ? 'down-arrow' : '' ">arrow_forward_ios</span></button>
                                  </span>
                                  <span *ngIf="((language == 'ar'))">
                                    <button class="btn data-text uploadedImages"
                                        (click)="showViewImages = !showViewImages"> {{
                                            uploadedImageCounter }} / 10 <span class="material-icons dropdownbtn"
                                            [ngClass]="showViewImages ? 'up-arrow' : '' ">arrow_back_ios</span></button>
                                  </span>
                                </div>
                            </div>
                            <div class="row quoteSummary" *ngIf="showViewImages">
                                <div class="choose-file-section col-12 py-3 ml-3" style="max-width: 100% !important;">
                                    <ol class="images-list" style="border-bottom: 1px solid #98bcd5;">
                                        <div class="d-flex justify-content-between mb-2"
                                            *ngFor="let imageIndex of imageList; let index = index">
                                            <div>
                                                <li class="registraton-card images-fields">
                                                    <label class="font-size-16">{{imageIndex |translate}}</label>
                                                </li>
                                            </div>
                                            <div class="">
                                                <label for="file-upload" class="custom-file-upload">
                                                    <button class="btn"
                                                        style="text-decoration:none; padding:0.47rem 1.75rem;"
                                                        (click)="openRcCivilImage(index)">{{'View' |
                                                        translate}}</button>
                                                </label>
                                            </div>
                                        </div>
                                        <div>
                                            <li class="car-images images-fields"
                                                [ngStyle]="{'text-align': ((language == 'en') ? 'left' : 'right')}">
                                                <label class="font-size-16">{{'Car Images (with Date, Time & Location)' | translate}}</label>
                                                <ol type="a">
                                                    <div class="d-flex justify-content-between mb-2"
                                                        *ngFor="let document of carImagesfields;  let paramIndex = index">
                                                        <div>
                                                            <li class="front-image images-fields">
                                                                <label class="font-size-16">{{ document |translate}}</label>
                                                            </li>
                                                        </div>
                                                        <div class="">
                                                            <label for="file-upload" class="custom-file-upload">
                                                                <button class="btn px-3"
                                                                    style="text-decoration:none; padding:0.47rem 1.75rem !important;"
                                                                    (click)="open(paramIndex)">{{'View' | translate}}</button>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </ol>
                                            </li>
                                        </div>
                                        <div class="d-flex justify-content-between mb-2"
                                            *ngFor="let imageIndex of videoImg; let Videoindex = index">
                                            <div>
                                                <li class="registraton-card images-fields">
                                                    <label class="font-size-16">{{imageIndex |translate}}</label>
                                                </li>
                                            </div>
                                            <div class="">
                                                <label for="file-upload" class="custom-file-upload">
                                                    <button class="btn"
                                                        style="text-decoration:none; padding:0.47rem 1.75rem;"
                                                        (click)="openVideo(Videoindex)">{{'View' | translate}}</button>
                                                </label>
                                            </div>
                                        </div>
                                    </ol>
                                </div>
                            </div>
                            <div class="table-responsive mb-3">
                                <table class="table table-centered table-nowrap mb-0">
                                    <tbody>
                                        <tr>
                                            <th>
                                                <h2 class="data-text">{{'Total Amount' | translate}}</h2>
                                            </th>
                                        </tr>

                                        <ng-container *ngFor="let fee of rateCard?.fees">
                                            <tr *ngIf="fee.amount > 0">
                                                <td>
                                                    <h5 class="light-data-text ml-3">{{ ((language === 'en') ? fee.name
                                                        :
                                                        fee.arabicName) |translate}}
                                                    </h5>
                                                </td>
                                                <td><small class="light-data-text"> {{fee.amount |
                                                        number:
                                                        '1.3-3'}} {{'KWD'|translate}}</small> </td>
                                            </tr>
                                        </ng-container>

                                        <tr *ngIf="rateCard.saiyartiAssistAmount > 0">
                                            <td>
                                                <h5 class="light-data-text ml-3">{{ 'Saiyarti Assist' |
                                                    translate }}
                                                </h5>
                                            </td>
                                            <td><small class="light-data-text"> {{rateCard.saiyartiAssistAmount |
                                                    number:
                                                    '1.3-3'}} {{'KWD'|translate}}</small> </td>
                                        </tr>

                                        <tr
                                            *ngIf="rateCard.comprehensiveAmount > 0 || rateCard.limitedComprehensiveAmount > 0">
                                            <td>
                                                <h5 class="light-data-text ml-3">{{ 'Total Comprehensive Premium' |
                                                    translate }}
                                                </h5>
                                            </td>
                                            <td *ngIf="rateCard.comprehensiveAmount > 0"><small class="light-data-text">
                                                    {{comprehensiveAmount |
                                                    number:
                                                    '1.3-3'}} {{'KWD'|translate}}</small> </td>

                                            <td *ngIf="rateCard.limitedComprehensiveAmount > 0"><small
                                                    class="light-data-text">
                                                    {{rateCard.limitedComprehensiveAmount |
                                                    number:
                                                    '1.3-3'}} {{'KWD'|translate}}</small> </td>
                                        </tr>
                                        <tr *ngIf="rateCard.addOnAmount != 0">
                                            <td>
                                                <h5 class="light-data-text ml-3">{{ 'Total Add-Ons Premium' | translate
                                                    }} </h5>
                                            </td>
                                            <td><small class="light-data-text"> {{rateCard.addOnAmount | number:
                                                    '1.3-3'}}
                                                    {{'KWD'|translate}}</small> </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <h5 class="heading-orange-text m-0" style="color: #DA512D;">{{ 'Total
                                                    Payable' |
                                                    translate }}</h5>
                                            </td>
                                            <td><small class="dark-data-text"> {{rateCard.amount | number: '1.3-3'}}
                                                    {{'KWD'|translate}}</small> </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div *ngIf="showPayNowForComprehensive">
                                <div class="mb-3 row">
                                    <div class="col-12 d-flex">
                                        <mat-checkbox
                                            *ngIf="rateCardSelections.tpl || rateCardSelections.limitedCoverage || rateCardSelections.comprehensive"
                                            class="mr-2 mt-1" [checked]="canMakePaymentForComprehensive"
                                            (change)="canMakePaymentForComprehensive = !canMakePaymentForComprehensive">
                                        </mat-checkbox>
                                        <p class="light-data-text"
                                            *ngIf="!rateCardSelections.tpl && (rateCardSelections.limitedCoverage || rateCardSelections.comprehensive)">
                                            {{'I understand and accept the terms and conditions of my purchase. I agree to make the payment.' | translate}} </p>
                                    </div>
                                </div>
                                <!-- <div class="text-center d-flex justify-content-between" *ngIf="!isQuoteSummary"> -->
                                <div class="text-center d-flex justify-content-between">
                                    <button type="button" class="btn back-btn px-4 dark-data-text" (click)="back()">{{
                                        'Back' | translate }}</button>
                                    <button type="button" class="btn  px-4"
                                        style="background-color: #DA512D;color: white;font: normal normal bold 16px/5px Roboto;"
                                        data-toggle="modal" data-target="#deliveryAddress"
                                        [disabled]="!canMakePaymentForComprehensive">
                                        <i *ngIf="showPayNowLoader"
                                            class="bx bx-loader bx-spin pay-now-loader font-size-16 align-middle mr-2"></i>{{'Make
                                        Payment' | translate }} </button>

                                </div>
                            </div>

                            <div *ngIf="showAcceptRejectButtons">
                                <div class="mb-3 row">
                                    <div class="col-12 d-flex">
                                        <mat-checkbox
                                            *ngIf="rateCardSelections.tpl || rateCardSelections.limitedCoverage || rateCardSelections.comprehensive"
                                            class="mr-2 mt-1" [checked]="canMakePaymentForComprehensive"
                                            (change)="canMakePaymentForComprehensive = !canMakePaymentForComprehensive">
                                        </mat-checkbox>
                                        <p class="light-data-text"
                                            *ngIf="!rateCardSelections.tpl && (rateCardSelections.limitedCoverage|| rateCardSelections.comprehensive)">
                                            {{'Your request for a comprehensive insurance has been modified by the insurance company.' |translate}}
                                            {{'I understand and accept the terms and conditions of my purchase.' |
                                            translate}} </p>
                                    </div>
                                </div>
                                <!-- <div class="text-center d-flex justify-content-between" *ngIf="!isQuoteSummary"> -->
                                <div class="text-center d-flex justify-content-between">
                                    <button type="button" class="btn back-btn px-4 dark-data-text"
                                        (click)="rejectRateCard()">{{
                                        'I Reject' | translate }}</button>
                                    <button type="button" class="btn  px-4"
                                        style="background-color: #DA512D;color: white;font: normal normal bold 16px/5px Roboto;"
                                        data-toggle="modal" data-target="#deliveryAddress"
                                        [disabled]="!canMakePaymentForComprehensive">
                                        <i *ngIf="showPayNowLoader"
                                            class="bx bx-loader bx-spin pay-now-loader font-size-16 align-middle mr-2"></i>{{'I
                                        Accept' | translate }} </button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end col -->
            </div>
        </div>
        <div class="modal fade" tabindex="-1" role="dialog" id="deliveryAddress" aria-labelledby="Delivery Address"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0">{{ 'Enter contact details' | translate }}</h5>
                        <button #closeModel type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form #submitQuoteForm="ngForm" (ngSubmit)="makePayment(); closeModel.click();">
                            <div class="form-group text-left">
                                <label class="pl-1 mb-1" for="deliveryAddress"
                                    [ngStyle]="{'text-align': ((language == 'en') ? 'left' : 'right')}">{{'Delivery
                                    Address' | translate }}</label>
                                <textarea class="form-control resize-none" id="deliveryAddressText" rows="3"
                                    placeholder="Enter delivery address" [(ngModel)]="deliveryAddress"
                                    name="deliveryAddress" #tDeliveryAddress="ngModel" required></textarea>
                                <div class="position-absolute pl-1 lh-1"
                                    *ngIf="tDeliveryAddress.invalid && tDeliveryAddress.touched">
                                    <small class="error" *ngIf="tDeliveryAddress.hasError('required')">{{ 'Delivery
                                        Address is required' | translate }}</small>
                                </div>
                            </div>
                            <div class="form-group">
                                <button type="submit" [disabled]="submitQuoteForm.invalid"
                                    class="btn btn-primary waves-effect waves-light" #tSubmitQuote> {{ 'Make Payment' |
                                    translate }} </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
