<footer class="footer">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6"> {{getCurrentYear()}} © Insure & Secure Insurance Brokerage Company</div>
            <div class="col-sm-6">
                <div class="text-sm-right d-none d-sm-block"> Design & Developed by <a
                        href="https://www.royalecheese.com/">Royale Cheese</a>
                </div>
            </div>
        </div>
    </div>
</footer>