<div class="card border border-success" *ngIf="!isTPLSuccess">
    <div class="card-body">
        <h5 class="mt-0 mb-3 text-success"><i class="mdi mdi-check-all mx-2"></i>{{ 'Thank you for purchasing a policy
            from Saiyarti!' |
            translate }}</h5>
        <p class="card-text mx-2">{{ 'We have received your request for Comprehensive Insurance with Order Number'  | translate }}  {{ orderId }}. {{ 'Our team will process this request and revert to you at the earliest. We aim to deliver your policy to you within the next 2 working days.' | translate }}</p>

        <p class="col-md-12 px-0 mx-2"> {{ 'In case you need any further information or assistance, we are
            available on' | translate }} </p>

        <div class="my-3 col-md-12 px-0 mx-2">
            <a href="https://web.whatsapp.com/send?phone=96567734711&text=&source=&data=&app_absent="
                target="_blank"><img src="../../../assets/images/WhatsApp.svg" alt="" width="18" height="18"><span
                    class="mx-2 font-size-16" style="color: #222B4A;vertical-align: middle;"> <span dir="ltr">+965 67734711</span></span></a>
        </div>
        <div class="my-3 col-md-12 px-0 mx-2">
            <a href="tel:+965 22207335"><img src="../../../assets/images/Phone.svg" alt="" width="18" height="18"><span
                    class="mx-2 font-size-16" style="color: #222B4A;vertical-align: middle;"> <span dir="ltr">+965 22207335</span></span></a>
        </div>
        <div class="my-3 col-md-12 px-0 mx-2">
            <a href="mailto:info@saiyarti.com"><img src="../../../assets/images/Email.svg" alt="" width="18"
                    height="18"><span class="mx-2 font-size-16" style="color: #222B4A;vertical-align: middle;">
                    info@saiyarti.com</span></a>
        </div>
        <div class="my-3 col-md-12 px-0 mx-2">
            <button type="submit" class="btn px-4" style="background-color: #222B4A;color: white;"
                routerLink="/vehicles">{{'Go Home' | translate}}</button>
        </div>
    </div>
</div>