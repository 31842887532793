import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CarDetails } from 'src/app/models/carModel';
import { RateCard } from 'src/app/models/rate-card';
import { VehicleService } from 'src/app/services/vehicle.service';

@Component({
  selector: 'app-vehicle-detail-page',
  templateUrl: './vehicle-detail-page.component.html',
  styleUrls: ['./vehicle-detail-page.component.scss']
})
export class VehicleDetailPageComponent implements OnInit {
  carDetails = new CarDetails({})
  carId: number;
  isCarDetailsAvail: boolean;
  constructor(private _activatedRoute: ActivatedRoute,
    private vehicleService: VehicleService) { }

  ngOnInit(): void {
    this._activatedRoute.paramMap.subscribe(paramMap => {
      const id = paramMap.get('id')
      if (id) {
        this.carId = +id
        this.vehicleService.getCarById(this.carId.toString()).subscribe((carDetail: CarDetails) => {
          this.isCarDetailsAvail = true;
          this.carDetails = new CarDetails(carDetail);
        }
        )
      }
   })

  }

}
