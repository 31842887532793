import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit {

  @Input() successMsg: string;
  @Input() redirectLink: any;
  @Input() autoRedirect: boolean = true;
  @Input() isTPLSuccess: boolean = false;
  @Output() redirected: EventEmitter<any> = new EventEmitter<any>();
  @Input() orderId: any;

  constructor(private router: Router) { }

  ngOnInit(): void {
    if ( this.redirectLink == "self" ) {

    }
  }

  redirect(): void {
    if ( this.redirectLink ) {
      if ( this.redirectLink == "self" ) {
        this.redirectedToSelf();
      } else {
        this.router.navigate(this.redirectLink);
      }
    } else {
      this.router.navigate(['/vehicles']);
    }
  }

  redirectedToSelf(): void {
    this.redirected.emit("success");
  }

}
