import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Helpers } from 'src/app/helper/helper';
import { UserType } from 'src/app/models/userModel';

@Component({
  selector: 'app-redirect-user',
  template: `
    <p>
      404 not Found
    </p>
  `,
  styles: []
})
export class RedirectUserComponent implements OnInit {

  constructor(private router: Router, private helper: Helpers) { }

  ngOnInit(): void {
    if (this.helper.isAuthenticated()) {
      const userToken = this.helper.getDecodedAccessToken(this.helper.getToken());
      if (userToken.type == UserType.CUSTOMER) {
        this.router.navigate(['/vehicles']);
      } else {
        this.router.navigate(['/customer-login']);
      }
    } else {
      // this.router.navigate(['/home']);
      window.location.href = 'https://saiyarti-website-dev.azurewebsites.net/comprehensive';
    }
  }

}
