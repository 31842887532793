import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AddQuoteService } from 'src/app/add-quote.service';
@Component({
  selector: 'app-comprehensive-success',
  templateUrl: './comprehensive-success.component.html',
  styleUrls: ['./comprehensive-success.component.scss']
})
export class ComprehensiveSuccessComponent implements OnInit{
  quoteExpiryDate: any;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private _addQuoteService: AddQuoteService) {
    console.log("hi");
  }

  ngOnInit(): void {
    this.quoteExpiryDate = this._addQuoteService.quoteExpiryDate;
  }
}
