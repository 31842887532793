<div class="page-content">
    <div class="container-fluid">
        <!-- start page title -->
        <div class="row">
            <div class="col-12">
                <div class="page-title-box d-flex align-items-center justify-content-between">
                    <h4 class="mb-0 font-size-18">{{ 'Request Details' | translate }}</h4>
                    <div class="page-title-right">
                        <ol class="breadcrumb m-0">
                            <li class="breadcrumb-item"><a [routerLink]="['/dashboard-customer']">{{ 'Requests' |
                                    translate }}</a></li>
                            <li class="breadcrumb-item active">{{ 'Request Details' | translate }}</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
        <!-- end page title -->
        <div class="row" *ngIf="requestDetails">
            <!-- Car details -->
            <div class="col-lg-6">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xl-12 mb-3">
                                <div>
                                    <h4 class="card-title mt-1 mb-3">{{ 'Car Details' | translate }}</h4>
                                    <div class="table-responsive">
                                        <table class="table table-centered table-nowrap mb-0">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <h5 class="font-size-14 text-dark m-0">{{ 'Model Year' |
                                                            translate }}</h5>
                                                    </td>
                                                    <td>{{ requestDetails.modelYear ? requestDetails.modelYear : '-' }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <h5 class="font-size-14 text-dark m-0">{{ 'Plate Number' |
                                                            translate }}</h5>
                                                    </td>
                                                    <td>{{ requestDetails.plateNumber ? requestDetails.plateNumber : '-'
                                                        }}</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <h5 class="font-size-14 text-dark m-0">{{ 'Car Make' | translate
                                                            }}</h5>
                                                    </td>
                                                    <td>{{ requestDetails.carMake ? requestDetails.carMake : '-' }}</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <h5 class="font-size-14 text-dark m-0">{{ 'Car Model' |
                                                            translate }}</h5>
                                                    </td>
                                                    <td>{{ requestDetails.carModel ? requestDetails.carModel : '-' }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <h5 class="font-size-14 text-dark m-0">{{ 'Trim' | translate }}
                                                        </h5>
                                                    </td>
                                                    <td>{{ requestDetails.trim ? requestDetails.trim : '-' }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-12 text-center">
                                <p class="text-muted">{{ 'RC Image' | translate }}</p>
                                <div class="row justify-content-center">
                                    <div class="col-md-5 img-thumbnail">
                                        <a href="{{ SASTokenForRCImageRead ? getRCImageURL() : '' }}"
                                            target="_blank"><img
                                                src="{{ SASTokenForRCImageRead ? getRCImageURL() : 'assets/images/companies/img-6.png' }}"
                                                onerror="this.src='assets/images/companies/img-6.png'" alt=""
                                                class="img-fluid mx-auto d-block"></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end col -->
            <!-- Quotation details -->
            <div class="col-lg-6">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title mb-4">{{ 'Quotation Details' | translate }}</h4>
                        <div class="table-responsive">
                            <table class="table table-centered table-nowrap mb-0">
                                <tbody>
                                    <tr>
                                        <td>
                                            <h5 class="font-size-14 text-dark m-0">{{ 'Insurance Company' | translate }}
                                            </h5>
                                        </td>
                                        <td>{{ requestDetails.insurer ? requestDetails.insurer : '-' }}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <h5 class="font-size-14 text-dark m-0">{{ 'Amount' | translate }}</h5>
                                        </td>
                                        <td><small *ngIf="requestDetails.amount" class="text-muted">{{'KWD'|translate}}</small> {{
                                            requestDetails.amount ? requestDetails.amount : '-' }}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <h5 class="font-size-14 text-dark m-0">{{ 'Quote Submission Date' |
                                                translate }}</h5>
                                        </td>
                                        <td>{{ requestDetails.requestDate ? ( requestDetails.requestDate | date:'dd MM
                                            yyyy':'locale' ) : '-' }}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <h5 class="font-size-14 text-dark m-0">{{ 'Quote Request Status' | translate
                                                }}</h5>
                                        </td>
                                        <td>
                                            <span class="status-badge badge badge-soft-primary"
                                                [class.badge-soft-success]="requestDetails.status == QuoteStatus.APPROVED"
                                                [class.badge-soft-danger]="requestDetails.status == QuoteStatus.REJECTED"
                                                [class.badge-soft-info]="requestDetails.status == QuoteStatus.DELIVERED || requestDetails.status == QuoteStatus.POLICY_PRINTED || requestDetails.status == QuoteStatus.PAYMENT_DONE || requestDetails.status == QuoteStatus.DISPATCHED">
                                                {{ requestDetails.status ? (requestDetails.status | translate) : '-' }}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <h5 class="font-size-14 text-dark m-0">{{ 'Expiry Date' | translate }}</h5>
                                        </td>
                                        <td>{{ requestDetails.expiryDate ? ( requestDetails.expiryDate | date:'dd MM
                                            yyyy':'locale' ) : '-' }}</td>
                                    </tr>
                                    <!-- <tr>
                                        <td>
                                            <h5 class="font-size-14 mb-1"><a href="javascript::void()" class="text-dark">{{ 'orderDetails.documents.declarationPDF' | translate }}</a></h5>

                                        </td>
                                        <td>
                                            <a href="javascript::void()" class="btn btn-outline-info waves-effect waves-light">
                                                <i class="bx bx-download font-size-16 align-middle mr-2"></i> {{ 'orderDetails.documents.sizeText' | translate }} 3.25 MB
                                            </a>
                                        </td>
                                    </tr> -->
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end col -->
        </div>
        <!-- end row -->
    </div> <!-- container-fluid -->
</div>
<!-- End Page-content -->
