<div class="account-pages my-5 pt-sm-5">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-8 col-lg-6 col-xl-5">
                <div class="card overflow-hidden">
                    <div class="bg-soft-primary">
                        <div class="row">
                            <div class="col-7">
                                <div class="text-primary p-4">
                                    <h5 class="text-primary">{{ 'Reset Password' | translate }}</h5>
                                    <p>{{ 'Re-Password with Saiyarti!' | translate }}</p>
                                </div>
                            </div>
                            <div class="col-5 align-self-end">
                                <img src="assets/images/profile-img.png" alt="" class="img-fluid">
                            </div>
                        </div>
                    </div>
                    <div class="card-body pt-0">
                        <div>
                            <a [routerLink]="['/partner-login']">
                                <div class="avatar-md profile-user-wid mb-4">
                                    <span class="avatar-title rounded-circle bg-light">
                                        <img src="assets/images/logo.svg" alt="" class="rounded-circle" height="34">
                                    </span>
                                </div>
                            </a>
                        </div>
                        <div class="p-2">
                            <div class="alert alert-success text-center mb-4" role="alert"> {{'Enter your Email and
                                instructions will be sent to you!' | translate}} </div>
                            <form #resetPasswordForm="ngForm" (ngSubmit)="resetPassword(resetPasswordForm)">
                                <div class="form-group">
                                    <input class="form-control" type="email" id="email" name="email"
                                        [(ngModel)]="userEmail" placeholder="{{'Email Address' | translate }}"
                                        #tEmail="ngModel" required
                                        pattern="^(?=[^@]{2,}@)([\w\.-]*[a-zA-Z0-9_]@(?=.{2,}\.[^.]*$)[\w\.-]*[a-zA-Z0-9]\.[a-zA-Z][a-zA-Z\.]*[a-zA-Z])$">
                                    <div class="pl-1" *ngIf="tEmail.invalid && tEmail.touched">
                                        <small class="error" *ngIf="tEmail.hasError('required')">{{ 'Email is required'
                                            | translate }}</small>
                                        <small class="error" *ngIf="tEmail.hasError('pattern')">{{ 'Email is not valid'
                                            | translate }}</small>
                                    </div>
                                </div>
                                <div class="form-group row mb-0">
                                    <div class="col-12 text-right">
                                        <button [disabled]="resetPasswordForm.invalid"
                                            class="btn btn-primary w-md waves-effect waves-light" type="submit"><i
                                                *ngIf="showResetLoader"
                                                class="bx bx-loader bx-spin reset-loader font-size-16 align-middle mr-2"></i>{{
                                            'Reset' | translate }}</button>
                                    </div>
                                    <div class="col-12 text-right">
                                        <span class="text-danger" *ngIf="isInvalid"> {{ 'User with the given Email was
                                            not found' | translate }} </span>
                                        <span class="text-success" *ngIf="isSuccess"> {{ 'Password reset link is send to
                                            your email' | translate }} </span>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="mt-5 text-center">
                    <p>{{'Remember It?' | translate}} <a [routerLink]="['/partner-login']"
                            class="font-weight-medium text-primary"> {{'Sign In here' | translate}}</a> </p>
                    <p>© 2022 Insure & Secure Insurance Brokerage Company
                </div>
            </div>
        </div>
    </div>
</div>