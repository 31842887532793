import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { RateCard, RateCardSelections } from 'src/app/models/rate-card';
import { PaymentService } from 'src/app/services/payment.service';
import { QuoteService } from 'src/app/services/quote.service';
import { TranslateService } from 'src/app/services/translate.service';
import { environment } from 'src/environments/environment';
import * as HesabeCrypt from 'hesabe-crypt';
import * as aesjs from 'aes-js';
import { InitiateTransactionRequestBody } from 'src/app/models/paymentModel';
import { MatSnackBar as MatSnackBar } from '@angular/material/snack-bar';
import { QuoteStatus } from 'src/app/models/quoteModel';
import { Helpers } from 'src/app/helper/helper';
import { CarDetails } from 'src/app/models/carModel';
import { Lightbox } from 'ngx-lightbox';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-ratecard-summary',
  templateUrl: './ratecard-summary.component.html',
  styleUrls: ['./ratecard-summary.component.scss']
})
export class RatecardSummaryComponent implements OnInit {

  rateCardSelections?: RateCardSelections = {
    comprehensive: true,
    addOns: true,
    limitedCoverage: true,
    tpl: false,
  }
  showPayNowForComprehensive = false;
  canMakePaymentForComprehensive = false;
  rateCard?: RateCard
  Object = Object;
  payment: HesabeCrypt;
  showPayNowLoader = false;
  token?: string
  deliveryAddress?: string
  showViewImages: boolean = false
  @Input() uploadedimages: number = 0;
  vehicle: CarDetails
  imageList: any[] = ["Registration Card", "Civil ID Front Image", "Civil ID Back Image"];
  videoImg: any[] = ['Video of the Car'];
  carImagesfields: string[] = ['Front Image', 'Back Image', 'Left Side Image', 'Right Side Image', 'Front Windshield Image', 'Chassis Number under the Front Windshield'];
  @Input() uploadedRc: number = 0;
  @Input() uploadedCivilFront: number = 0;
  @Input() uploadedCivilBack: number = 0;
  @Input() uploadedCarImage_1: number = 0;
  @Input() uploadedCarImage_2: number = 0;
  @Input() uploadedCarImage_3: number = 0;
  @Input() uploadedCarImage_4: number = 0;
  @Input() uploadedCarImage_5: number = 0;
  @Input() uploadedCarImage_6: number = 0;
  @Input() uploadedCarVideo: number = 0;
  @Input() uploadedImageCounter: number;
  rcCivilImages: any;
  @Input() selectedQuote: RateCard;
  get comprehensiveAmount(): number {
    if (this.rateCard?.comprehensiveAmount === 0) {
      return 0;
    }
    var fees = 0;
    for (let index = 0; index < this.rateCard?.fees.length; index++) {
      const fee = this.rateCard?.fees[index];
      fees = fee.amount
    }
    return this.rateCard?.comprehensiveAmount - fees
  }
  get showAcceptRejectButtons(): boolean {
    return this.rateCard.status === 'ADMIN_UPDATED'
  }


  //To get language
  get language(): string {
    return this._translateService.lang;
  }

  constructor(private _translateService: TranslateService,
    private _activatedRoute: ActivatedRoute,
    private _location: Location,
    private _paymentService: PaymentService,
    private _snackBar: MatSnackBar,
    private _helper: Helpers,
    private _quoteService: QuoteService,
    private lightbox: Lightbox,
    private snackBar: MatSnackBar,
    private storageService: StorageService) {

    const secret = environment.paymetGateway.secret;
    const ivKey = environment.paymetGateway.ivKey;

    const key = aesjs.utils.utf8.toBytes(secret);
    const iv = aesjs.utils.utf8.toBytes(ivKey);
    this.payment = new HesabeCrypt(key, iv);
  }

  ngOnInit(): void {

    this._activatedRoute.queryParamMap.subscribe(queryParamMap => {

      const language = queryParamMap.get('lang')
      const token = queryParamMap.get('token')
      const pdfLink = queryParamMap.get('pdflink')
      if (language) {
        this._translateService.lang = language
      }
      if (token) {

        this._helper.setToken({ token });
        this.token = token
      }
      if (language && token && pdfLink) {
        const pdfUrl = pdfLink + '?lang=' + language + '&token=' + token
        window.location.href = pdfUrl
      }
    });

    this._activatedRoute.paramMap.subscribe(paramMap => {
      const id = paramMap.get('id')

      this._quoteService.getQuoteById(+id).subscribe(x => {
        this.rateCard = new RateCard(x.rateCardId, x);
        this.rateCardSelections.comprehensive = this.rateCard.comprehensiveAmount > 0;
        this.rateCardSelections.limitedCoverage = this.rateCard.limitedComprehensiveAmount > 0;
        this.showPayNowForComprehensive = this.rateCard.status === QuoteStatus.ADMIN_APPROVED

        this._activatedRoute.queryParamMap.subscribe(queryParamMap => {

          const shouldAccept = queryParamMap.get('customerstatus')

          if (shouldAccept !== undefined && shouldAccept !== null) {
            if (shouldAccept === 'true') {
              this._quoteService.acceptRateCard(id).pipe(take(1)).subscribe(x => {
                this.showPayNowForComprehensive = true
              })
            } else {
              this._quoteService.rejectRateCard(id).pipe(take(1)).subscribe(x => {
                console.log('Rejected Rate Card');
              })
            }
          }
        });
      })
    })
    this.loadImages();
  }
  loadImages() {
    this._activatedRoute.paramMap.subscribe(paramMap => {
      const id = paramMap.get('id')
      this._quoteService.getQuoteById(+id).subscribe(x => {
        const vehicleid = x.vehicleId
        this.storageService.userDocumentPresent(vehicleid).subscribe(presentImages => {
          let rcImage, civil_front, civil_back, frontimg, backimg, leftimg, rightimg, frontWSimg, chassisNum, video;
          this.rcCivilImages = presentImages;
          if (Object.keys(presentImages).length !== 0) {
            if (presentImages.rCbookUrl) {
              rcImage = presentImages.rCbookUrl;
              this.uploadedRc = 1;
            }
            if (presentImages.civilCardFrontUrl) {
              civil_front = presentImages.civilCardFrontUrl;
              this.uploadedCivilFront = 1;
            }
            if (presentImages.civilCardbackUrl) {
              civil_back = presentImages.civilCardbackUrl;
              this.uploadedCivilBack = 1;
            }
            if (presentImages.carFrontUrl) {
              frontimg = presentImages.carFrontUrl;
              this.uploadedCarImage_1 = 1;
            }
            if (presentImages.carBackUrl) {
              backimg = presentImages.carFrontUrl;
              this.uploadedCarImage_2 = 1;
            }
            if (presentImages.carLeftUrl) {
              leftimg = presentImages.carBackUrl;
              this.uploadedCarImage_3 = 1;
            }
            if (presentImages.carRightUrl) {
              rightimg = presentImages.carRightUrl;
              this.uploadedCarImage_4 = 1;
            }
            if (presentImages.carFrontWSUrl) {
              frontWSimg = presentImages.carFrontWSUrl;
              this.uploadedCarImage_5 = 1;
            }
            if (presentImages.carFrontChassisUrl) {
              chassisNum = presentImages.carFrontChassisUrl;
              this.uploadedCarImage_6 = 1;
            }
            if (presentImages.carVideoUrl) {
              video = presentImages.carVideoUrl;
              this.uploadedCarVideo = 1;
            }
            this.uploadedImageCounter = this.uploadedRc + this.uploadedCivilFront + this.uploadedCivilBack + this.uploadedCarImage_1 + this.uploadedCarImage_2 + this.uploadedCarImage_3 + this.uploadedCarImage_4 + this.uploadedCarImage_5 + this.uploadedCarImage_6 + this.uploadedCarVideo;
          }
        });
      })
    })
  }
  sort(x: any, y: any) {
    const aStepId = x.value.order ?? 0
    const bStepId = y.value.order ?? 0
    return aStepId > bStepId ? 1 : (bStepId > aStepId ? -1 : 0);
  }


  openRcCivilImage(imageIndex): void {
    const src = this.getImageURL(imageIndex);
    const caption = this.imageList[imageIndex];
    const thumb = src
    const album = {
      src,
      caption,
      thumb
    }

    if (imageIndex == 0 && this.uploadedRc == 1) {
      this.lightbox.open([album], 0);
    } else if (imageIndex == 1 && this.uploadedCivilFront == 1) {
      this.lightbox.open([album], 0);
    } else if (imageIndex == 2 && this.uploadedCivilBack == 1) {
      this.lightbox.open([album], 0);
    } else {
      this.snackBar.open('Image is not uploaded.', 'CANCEL');
    }

  }
  getImageURL(imageIndex): string {
    return imageIndex == 0 ? this.rcCivilImages.rCbookUrl : imageIndex == 1 ? this.rcCivilImages.civilCardFrontUrl : this.rcCivilImages.civilCardbackUrl;
  }

  open(index): void {
    const src = this.getCarImageURL(index);
    const caption = this.carImagesfields[index];
    const thumb = src
    const album = {
      src,
      caption,
      thumb
    }
    if (index == 0 && this.uploadedCarImage_1 == 1) {
      this.lightbox.open([album], 0);
    } else if (index == 1 && this.uploadedCarImage_2 == 1) {
      this.lightbox.open([album], 0);
    } else if (index == 2 && this.uploadedCarImage_3 == 1) {
      this.lightbox.open([album], 0);
    } else if (index == 3 && this.uploadedCarImage_4 == 1) {
      this.lightbox.open([album], 0);
    } else if (index == 4 && this.uploadedCarImage_5 == 1) {
      this.lightbox.open([album], 0);
    } else if (index == 5 && this.uploadedCarImage_6 == 1) {
      this.lightbox.open([album], 0);
    } else {
      this.snackBar.open('Image is not uploaded.', 'CANCEL');
    }
  }
  getCarImageURL(index): string {
    return index == 0 ? this.rcCivilImages.carFrontUrl : index == 1 ? this.rcCivilImages.carBackUrl : index == 2 ? this.rcCivilImages.carLeftUrl : index == 3 ? this.rcCivilImages.carRightUrl : index == 4 ? this.rcCivilImages.carFrontWSUrl : this.rcCivilImages.carFrontChassisUrl
  }
  openVideo(index): void {
    const src = this.getVideoURL(index);
    const caption = this.videoImg[index];
    const thumb = src
    const album = {
      src,
      caption,
      thumb
    }
    if (index == 0 && this.uploadedCarVideo == 1) {
      this.lightbox.open([album], 0);
    } else {
      this.snackBar.open('Video is not uploaded.', 'CANCEL');
    }
  }
  getVideoURL(index): string {
    return index == 0 ? this.rcCivilImages.carVideoUrl : 'Video not uploaded..'
  }
  back() {
    this._location.back();
  }

  makePayment() {
    //For Comprehensive Payment
    this.showPayNowLoader = true;

    this._paymentService.initiateTransaction(this.rateCard.rateCardId, new InitiateTransactionRequestBody(this.rateCard.amount, this.deliveryAddress), this.token).subscribe((initiateTransactionResponse: any) => {
      if (initiateTransactionResponse.paymentId) {
        const requestBody = {
          merchantCode: environment.paymetGateway.merchantCode,
          amount: this.rateCard.amount,
          paymentType: 1,
          responseUrl: window.location.origin + '/payment-success' + (this.token ? ('?token=' + this.token) : ''),
          failureUrl: window.location.origin + '/payment-failure' + (this.token ? ('?token=' + this.token) : ''),
          version: '2.0',
          variable1: this.rateCard.vehicleId,
          variable2: initiateTransactionResponse.rateCardId,
          variable3: initiateTransactionResponse.paymentId,
          variable4: this.language,
          variable5: false
        };
        const encryptedRequestBody = this.payment.encryptAes(JSON.stringify(requestBody)); // Ecnryption
        this._paymentService.getPaymentGatewayLink(encryptedRequestBody).subscribe(paymentRes => {
          const decrypted = JSON.parse(this.payment.decryptAes(paymentRes));
          window.location.href = environment.paymetGateway.url + '/payment?data=' + decrypted.response.data;
        }, err => {
          this.showPayNowLoader = false;
          this._snackBar.open('Error occur while Initiating Transaction. Please try again.', 'CANCEL');
        });
      }
    }, error => {
      this.showPayNowLoader = false;
      this._snackBar.open('Error occur while Initiating Transaction. Please try again.', 'CANCEL');

    });
  }

  acceptRateCard() {
    this._quoteService.acceptRateCard(this.rateCard.rateCardId).pipe(take(1)).subscribe(x => {
      this.showPayNowForComprehensive = true
    })
  }

  rejectRateCard() {
    this._quoteService.rejectRateCard(this.rateCard.rateCardId).pipe(take(1)).subscribe(x => {
      console.log('Rejected Rate Card');
      this._location.back()
    })
  }
}
