import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CarImages } from 'src/app/models/carModel';
import { QuoteStatus } from 'src/app/models/quoteModel';
import { RateCard } from 'src/app/models/rate-card';
import { QuoteService } from 'src/app/services/quote.service';
import { StorageService } from 'src/app/services/storage.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent implements OnInit {
  ImageType = ImageType;
  QuoteStatus = QuoteStatus;
  orderDetails: RateCard;

  //#region Storage API

  SASTokenForCarImageRead: string;
  SASTokenForRCImageRead: string;
  SASTokenForCivilIdFrontImageRead: string;
  SASTokenForCivilIdBackImageRead: string;
  SASTokenForPolicyDocument: string;
  SASTokenForMoroorDocument: string;
  SASTokenForInvoiceDocument: string;

  //#endregion

  constructor(private activatedRoute: ActivatedRoute,
    private quoteService: QuoteService,
    private storageService: StorageService) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(paramMap => {
      let rateCardId = paramMap.get("id");

      if (rateCardId == null && this.activatedRoute.snapshot.url.length == 2) {
        rateCardId = this.activatedRoute.snapshot.url[1].path;
      }

      this.quoteService.getQuoteById(+rateCardId).subscribe(response => {
        this.orderDetails = new RateCard(response.rateCardId, response);

        //#region Token to read image files

        this.storageService.getReadTokenForCarImage().subscribe(readTokenResponse => {
          this.SASTokenForCarImageRead = readTokenResponse.sasToken;
        }, error => { });

        this.storageService.getReadTokenForRCImage(this.orderDetails.vehicleId.toString()).subscribe(readTokenResposne => {
          this.SASTokenForRCImageRead = readTokenResposne.sasToken;
        }, error => { });

        this.storageService.getReadTokenForCivilImage(this.orderDetails.vehicleId + this.storageService.civilCardFront).subscribe(readTokenResposne => {
          this.SASTokenForCivilIdFrontImageRead = readTokenResposne.sasToken;
        }, error => { });

        this.storageService.getReadTokenForCivilImage(this.orderDetails.vehicleId + this.storageService.civilCardBack).subscribe(readTokenResposne => {
          this.SASTokenForCivilIdBackImageRead = readTokenResposne.sasToken;
        }, error => { });

        this.storageService.getSASToken(rateCardId + "_policy", environment.policyImageContainer).subscribe(readTokenResposne => {
          this.SASTokenForPolicyDocument = readTokenResposne.sasToken;
        }, error => { });

        this.storageService.getSASToken(rateCardId + "_moroor", environment.policyImageContainer).subscribe(readTokenResposne => {
          this.SASTokenForMoroorDocument = readTokenResposne.sasToken;
        }, error => { });

        this.storageService.getSASToken(rateCardId + "_invoice", environment.policyImageContainer).subscribe(readTokenResposne => {
          this.SASTokenForInvoiceDocument = readTokenResposne.sasToken;
        }, error => { });

        //#endregion
      }, error => { });
    }, error => { });
  }

  downloadImage(imageType: ImageType) {
    if (imageType == ImageType.RC_IMAGE) {
      this.storageService.downloadImageByURL(this.getRCImageURL(), this.orderDetails.vehicleId + "_RC-Image.jpg");
    } else if (imageType == ImageType.CIVILID) {
      this.storageService.downloadImageByURL(this.getCivilIdFrontImageURL(), this.orderDetails.vehicleId + "_CivilID-front-image.jpg");
      this.storageService.downloadImageByURL(this.getCivilIdBackImageURL(), this.orderDetails.vehicleId + "_CivilID-end-image.jpg");
    } else if (imageType == ImageType.POLICY_DOC) {
      this.storageService.downloadImageByURL(this.getPolicyDocURL(), this.orderDetails.rateCardId + "_policy.pdf");
    } else if (imageType == ImageType.MOROOR_DOC) {
      this.storageService.downloadImageByURL(this.getMoroorDocURL(), this.orderDetails.rateCardId + "_moroor.pdf");
    } else if (imageType == ImageType.INVOICE_DOC) {
      this.storageService.downloadImageByURL(this.getInvoiceDocURL(), this.orderDetails.rateCardId + "_invoice.pdf");
    }
  }

  getRCImageURL(): string | null {
    if (this.orderDetails) {
      return environment.cdnUrl + "/" + environment.rcImageContainer + "/" + this.orderDetails.vehicleId + this.SASTokenForRCImageRead;
    }
    return null;
  }

  getCivilIdFrontImageURL(): string | null {
    if (this.orderDetails) {
      return environment.cdnUrl + "/" + environment.civilCardImageContainer + "/" + this.orderDetails.vehicleId + this.storageService.civilCardFront + this.SASTokenForCivilIdFrontImageRead;
    }
    return null;
  }

  getCivilIdBackImageURL(): string | null {
    if (this.orderDetails) {
      return environment.cdnUrl + "/" + environment.civilCardImageContainer + "/" + this.orderDetails.vehicleId + this.storageService.civilCardBack + this.SASTokenForCivilIdBackImageRead;
    }
    return null
  }

  getCarImageURL(carImage: CarImages): string | null {
    if (carImage) {
      return environment.cdnUrl + "/" + environment.carImageContainer + "/" + carImage.name + this.SASTokenForCarImageRead;
    }
    return null;
  }

  getPolicyDocURL(): string | null {
    if (this.orderDetails) {
      return environment.cdnUrl + "/" + environment.policyImageContainer + "/" + this.orderDetails.rateCardId + "_policy" + this.SASTokenForPolicyDocument;
    }
    return null;
  }

  getMoroorDocURL(): string | null {
    if (this.orderDetails) {
      return environment.cdnUrl + "/" + environment.policyImageContainer + "/" + this.orderDetails.rateCardId + "_moroor" + this.SASTokenForMoroorDocument;
    }
    return null;
  }

  getInvoiceDocURL(): string | null {
    if (this.orderDetails) {
      return environment.cdnUrl + "/" + environment.policyImageContainer + "/" + this.orderDetails.rateCardId + "_invoice" + this.SASTokenForInvoiceDocument;
    }
    return null;
  }
}

enum ImageType {
  RC_IMAGE = "rc_image",
  CIVILID = 'civilId',
  POLICY_DOC = 'policy_doc',
  MOROOR_DOC = 'moroor_doc',
  INVOICE_DOC = 'invoice_doc',
  CAR_IMAGE = 'car_image'
}