import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder } from '@angular/forms';
import { MatSnackBar as MatSnackBar, MatSnackBarRef as MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
// import { BlobService, UploadConfig, UploadParams } from 'angular-azure-blob-service';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { CarDetails } from 'src/app/models/carModel';
import { PlanStatus, PlanType, RateCardType, RCValueType, Steps } from 'src/app/models/quoteModel';
import { RegistrationPurpose } from 'src/app/models/userModel';
import { QuoteService } from 'src/app/services/quote.service';
import { StorageService } from 'src/app/services/storage.service';
import { Fee, PurposeOfLicense } from 'src/app/models/tpl.model';
import { TplService } from 'src/app/services/tpl.service';
import { VehicleService } from 'src/app/services/vehicle.service';
import { environment } from 'src/environments/environment';
import * as HesabeCrypt from 'hesabe-crypt';
import * as aesjs from 'aes-js';
import { TranslateService } from 'src/app/services/translate.service';
import { Helpers } from 'src/app/helper/helper';
import { InsurerService } from 'src/app/services/insurer.service';
import { AddQuoteService } from 'src/app/add-quote.service';
import { RateCard, RateCardSelections } from 'src/app/models/rate-card';

@Component({
  selector: 'app-add-quote',
  templateUrl: './add-quote.component.html',
  styleUrls: ['./add-quote.component.scss']
})
export class AddQuoteComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('tMarketValue') marketValueElement: any;
  Object = Object;
  Steps = Steps;
  PlanType = PlanType;
  PlanStatus = PlanStatus;
  RegistrationPurpose = RegistrationPurpose;
  RateCardType = RateCardType;
  ValueType = RCValueType;
  rcImageFiles: File;
  civilIdFrontImageFiles: File;
  civilIdBackImageFiles: File;
  frontImageFiles: File;
  backImageFiles: File;
  leftSideImageFiles: File;
  rightSideImageFiles: File;
  frontWindshieldImageFiles: File;
  chassisNumberImageFiles: File;
  videoOfCarFiles: File;
  currentStep: number;
  showCivilIDFrontImageErrorMsg: boolean;
  showCivilIDBackImageErrorMsg: boolean;
  showRCImageErrorMsg: boolean;
  currentPlanType: string;
  showEditCarLoader: boolean;
  disabledCarEdit: boolean;
  tplPayment: any;
  showAddOns: boolean;
  carUploadToken: string;
  carImagesList: any;
  uploadedImageCounter: number;
  AzureParamForCar: any;

  get selectedLicense(): PurposeOfLicense {
    return this._addQuoteService.purposeOfLicense
  };
  get selectedLicenseAr(): PurposeOfLicense {
    return this._addQuoteService.purposeOfLicenseAr
  };
  longitude: any;
  latitude: any;
  location: any;
  noImageModal: boolean = false;
  mandatoryTooltip = `Get the minimum basic cover for your car
  1. Water Damage
  2. Total Loss Due to an Accident
  3. Fire or Storm Damage
  4. Theft`

  assistTooltip = `Get assistance, in every motor emergency!
  1. Car breakdown
  2. Fuel provision
  3. Battery replacement
  4. Tire replacement
  5. Crane services`
  //#region Payment Variable

  payment: HesabeCrypt;
  userToken: string;

  // updated:boolean
  //#endregion


  //#region Storage API

  // AzureParamForRC: UploadParams = {
  //   sas: '',
  //   storageAccount: environment.storageAccount,
  //   containerName: environment.rcImageContainer
  // };
  // AzureParamForCivilId: UploadParams = {
  //   sas: '',
  //   storageAccount: environment.storageAccount,
  //   containerName: environment.civilCardImageContainer
  // };
  // AzureParamForCar: UploadParams = {
  //   sas: '',
  //   storageAccount: environment.storageAccount,
  //   containerName: environment.carImageContainer
  // };


  // uploadConfig: UploadConfig;
  showUploadLoader: boolean;
  totalImageUploaded: number;
  imageProcessed: number;
  imageUploaded: UploadType[];
  imageUploadError: UploadType[];
  uploadStatusBehaviour$: BehaviorSubject<number>;
  uploadStatusSubscription: Subscription;
  SASTokenForCarImageRead: any;
  SASTokenForRCImageRead: any;
  SASTokenForCivilIdFrontImageRead: any;
  SASTokenForCivilIdBackImageRead: any;
  carRcImageUrl: string;
  carCivilFrontImageUrl: string;
  carCivilBackImageUrl: string;
  downloadLink: string;
  shortURL: string;
  quoteIndex: any;
  periodForTPL: any = [];
  timestamp: any;
  currentPage: string = "quotePage";
  locationOnImage: any;
  uploadedRc: number = 0;
  uploadedCivilFront: number = 0;
  uploadedCivilBack: number = 0;
  uploadedCarVideo: number = 0;

  uploadedCarImage_1: number = 0;
  uploadedCarImage_2: number = 0;
  uploadedCarImage_3: number = 0;
  uploadedCarImage_4: number = 0;
  uploadedCarImage_5: number = 0;
  uploadedCarImage_6: number = 0;
  uploadedCarImages: number[] = [this.uploadedCarImage_1, this.uploadedCarImage_2, this.uploadedCarImage_3, this.uploadedCarImage_4, this.uploadedCarImage_5, this.uploadedCarImage_6];
  tplPaymentPending: boolean;
  //#endregion


  numSequence(i: number): Array<number> {
    return Array(i);
  }

  carImagesfields: string[] = ['Front Image', 'Back Image', 'Left Side Image', 'Right Side Image', 'Front Windshield Image', 'Chassis Number under the Front Windshield']

  carSize: any = [];

  fields: string[] = ["Third Party Liability", "Limited Comprehensive Cover", "Comrehensive Add-On Coverage", "Saiyarti Add-Ons"]

  baseInfoTop = {
    aInsuranceCompanyName: "Insurance Company Name",
    dQuoteValidity: "Quote Validity",
  }

  baseInfoBottom = {
    ttotalCover: "Total Cover",
    // utotalAddOn: "Total Add On",
    vtotalFee: "Total Fee",
    winsuranceAmount: "Insurance Amount",
    xActionButton: "Proceed"
  }

  selectedRateCard?: RateCard
  rateCardSelection?: RateCardSelections
  selectedFee?: Fee
  fetchedCarDetails?: CarDetails;
  todayYear: number;
  hasQuote = false;
  getQuoteRef: MatSnackBarRef<SimpleSnackBar>;
  uploadCarSAS?: string;
  uploadVideoSAS?: string
  protected _onDestroy = new Subject<void>();

  constructor(private vehicleService: VehicleService,
    private fb: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private quoteService: QuoteService,
    // private blobService: BlobService,
    private storageService: StorageService,
    private snackBar: MatSnackBar,
    private tplService: TplService,
    private translateService: TranslateService,
    private helper: Helpers,
    private insurerService: InsurerService,
    private _addQuoteService: AddQuoteService) {
    const secret = environment.paymetGateway.secret;
    const ivKey = environment.paymetGateway.ivKey;

    const key = aesjs.utils.utf8.toBytes(secret);
    const iv = aesjs.utils.utf8.toBytes(ivKey);
    this.payment = new HesabeCrypt(key, iv);

    // Add class to body to manipulated style of select dropdown. 
    document.body.classList.add("ngx-select");
    document.body.classList.add("add-quote");
  }

  ngOnInit(): void {
    this.showCivilIDFrontImageErrorMsg = this.showCivilIDBackImageErrorMsg = false;
    this.showRCImageErrorMsg = false;
    this.showEditCarLoader = false;
    this.disabledCarEdit = false;
    this.currentStep = Steps.QUOTES;
    this.uploadedImageCounter = 0;
    this.carImagesList = [];
    let quoteState = window.history.state;
    if (quoteState.summary) {
      this.currentStep = Steps.CONFIRMATION;
      this._addQuoteService.carDetails = new CarDetails(quoteState.summary)
    }
    else if (quoteState.type) {
      this.currentPlanType = quoteState.type;
    }

    this.activatedRoute.paramMap.subscribe(
      paramMap => {
        let vehicleId = paramMap.get("id");
        if (vehicleId) {
          this.vehicleService.getCarById(vehicleId).subscribe((vehicle: CarDetails) => {
            if (vehicle) {
              this.fetchedCarDetails = vehicle;
              this.getLocation();
              this.loadImages();
              this.carImages();
            }
          }, error => {
            // Car Details are not available.
          });
        }
      }, error => {
        // Car id is not present in paramMap.
      }
    );

    //QR Link Generate From Here
    this.activatedRoute.queryParamMap.subscribe(queryParam => {
      let token = queryParam.get("token");
      let hasPassword = this.helper.getHasPassword();
      let isLogin;
      if (!token) {
        token = this.helper.getToken();
      }
      if (hasPassword == "true")
        isLogin = "login?token=";
      else
        isLogin = "set-password?token=";


      if (token && hasPassword) {
        this.downloadLink = environment.dynamicLink + "?link=https://saiyarti.com/" + isLogin + token + "&apn=com.rc.saiyarti&ibi=com.saiyarti&isi=1459650287&efr=1&ofl=" + environment.dynamicLinkOfl + "set-password?token=" + token;

        let http = new XMLHttpRequest();
        let url = "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=" + environment.firebase.apiKey;
        let body = {
          "longDynamicLink": this.downloadLink,
          "suffix": {
            "option": "SHORT"
          }
        }
        let JSONString = JSON.stringify(body);
        let response;
        http.open("POST", url, true);
        http.setRequestHeader('Content-Type', 'application/json');
        http.onreadystatechange = () => {
          if (http.readyState == 4 && http.status == 200) {
            response = JSON.parse(http.responseText);
            this.shortURL = response.shortLink;
          }
        }
        http.send(JSONString);
      }
    }, error => { });

    // this.staticDataProviderService.getCarStaticDetails().subscribe(staticDetails => {
    //   this.handleCarStaticDetails(staticDetails);
    // }, error => {
    //   // handle error.
    // });
  }

  ngOnDestroy() {
    document.body.classList.remove("ngx-select");
    document.body.classList.remove("add-quote");

    this._onDestroy.next();
    this._onDestroy.complete();
  }

  ngAfterViewInit() { }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        let today = new Date();
        let date = today.getDate() + '-' + (today.getMonth() + 1) + '-' + today.getFullYear();
        let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        this.timestamp = date + ' ' + time;
        if (this.latitude && this.longitude) {
          this.getLocationName(this.latitude, this.longitude);
        }
      });
    }
  }
  getLocationName(lat, long) {
    let responseAddress;
    let url = "https://maps.googleapis.com/maps/api/geocode/json?";
    const latlng = {
      lat: parseFloat(lat),
      lng: parseFloat(long),
    };

    url += "latlng=" + this.latitude + "," + this.longitude + "&result_type=sublocality&key=AIzaSyA0CQAJMeKenEeQLnO3SLzqtMu_ZzLN6z8";
    var http = new XMLHttpRequest();
    http.open("GET", url);
    http.send();
    http.onreadystatechange = (response) => {
      if (http.status == 200) {
        try {
          responseAddress = JSON.parse(http.responseText);
          this.locationOnImage = responseAddress.results[0].address_components[0].long_name;
        } catch (e) { }
      }
    }
  }
  async imageExist(url) {
    var http = new XMLHttpRequest();
    http.open('HEAD', url, false);
    http.send();
    return http.status != 404;
  }

  getImages() {
    //Checking if Images for RC Book, Car Images and Civil ID have been already uploaded or not

    //Get SAS Token to upload images on Azure Portal For Car Images
    this.storageService.getUploadOnlyTokenForContainer("comprehensive-car-book").subscribe(Token => {
      this.uploadCarSAS = Token.sasToken;
    });
    this.storageService.getUploadOnlyTokenForContainer("comprehensive-rc-book").subscribe(Token => {
      this.uploadVideoSAS = Token.sasToken;
    });

    //Get Car Images While Page is loading 
    this.storageService.getReadTokenForCarImage().subscribe(carReadToken => {
      this.SASTokenForCarImageRead = carReadToken.sasToken;
      this.storageService.getCarImagesByVehicleId(String(this.fetchedCarDetails.id)).subscribe(carImagesList => {
        if (carImagesList.length != 0) {
          this.uploadedImageCounter = this.uploadedImageCounter + carImagesList.length;
          this.setCarImages(carImagesList);
        }
      });
    });
  }

  loadImages() {

    this.storageService.userDocumentPresent(this.fetchedCarDetails.id).subscribe(presentImages => {
      if (Object.keys(presentImages).length !== 0) {
        if (presentImages.rCbookUrl) {
          this.uploadedRc = 1;
        }
        if (presentImages.civilCardFrontUrl) {
          this.uploadedCivilFront = 1;
        }
        if (presentImages.civilCardbackUrl) {
          this.uploadedCivilBack = 1;
        }
        if (presentImages.carFrontUrl) {
          this.uploadedCarImage_1 = 1;
        }
        if (presentImages.carBackUrl) {
          this.uploadedCarImage_2 = 1;
        }
        if (presentImages.carLeftUrl) {
          this.uploadedCarImage_3 = 1;
        }
        if (presentImages.carRightUrl) {
          this.uploadedCarImage_4 = 1;
        }
        if (presentImages.carFrontWSUrl) {
          this.uploadedCarImage_5 = 1;
        }
        if (presentImages.carFrontChassisUrl) {
          this.uploadedCarImage_6 = 1;
        }
        if (presentImages.carVideoUrl) {
          this.uploadedCarVideo = 1;
        }
        this.uploadedImageCounter = this.uploadedRc + this.uploadedCivilFront + this.uploadedCivilBack + this.uploadedCarImage_1 + this.uploadedCarImage_2 + this.uploadedCarImage_3 + this.uploadedCarImage_4 + this.uploadedCarImage_5 + this.uploadedCarImage_6 + this.uploadedCarVideo;
      }
    });
  }

  carImages() {
    this.storageService.getUploadOnlyTokenForContainer("comprehensive-car-book").subscribe(Token => {
      this.uploadCarSAS = Token.sasToken;
    });
    this.storageService.getUploadOnlyTokenForContainer("comprehensive-rc-book").subscribe(Token => {
      this.uploadVideoSAS = Token.sasToken;
    });
    this.storageService.getReadTokenForCarImage().subscribe(carReadToken => {
      this.SASTokenForCarImageRead = carReadToken.sasToken;
      this.storageService.getCarImagesByVehicleId(String(this.fetchedCarDetails.id)).subscribe(carImagesList => {
        if (carImagesList.length != 0) {
          this.setCarImages(carImagesList);
          for (let i = 0; i < carImagesList.length; i++) {
            if (carImagesList[i] != "") {
              if (i + 1 == 1) {
                this.uploadedCarImage_1 = 1;
                this.uploadedCarImages[i] = 1;
                this.uploadedImageCounter++;
              } else if (i + 1 == 2) {
                this.uploadedCarImage_2 = 1;
                this.uploadedCarImages[i] = 1;
                this.uploadedImageCounter++;
              } else if (i + 1 == 3) {
                this.uploadedCarImage_3 = 1;
                this.uploadedCarImages[i] = 1;
                this.uploadedImageCounter++;
              } else if (i + 1 == 4) {
                this.uploadedCarImage_4 = 1;
                this.uploadedCarImages[i] = 1;
                this.uploadedImageCounter++;
              } else if (i + 1 == 5) {
                this.uploadedCarImage_5 = 1;
                this.uploadedCarImages[i] = 1;
                this.uploadedImageCounter++;
              } else if (i + 1 == 6) {
                this.uploadedCarImage_6 = 1;
                this.uploadedCarImages[i] = 1;
                this.uploadedImageCounter++;
              }
            }
          }
        }
      });
    });
  }

  // Activate step to perform
  activate(step: Steps): void {
    this.currentStep = step;
    if (this.currentStep == Steps.QUOTES) {
      this.currentPage = "quotePage";
    } else if (this.currentStep == Steps.USER_INFO) {
      this.currentPage = "imagePage";
    } else if (this.currentStep == Steps.CONFIRMATION) {
      this.currentPage = "summaryPage";
    }
    // this.getImages();
  }

  // Back one step
  back(): void {
    if (this.currentStep != Steps.QUOTES) {
      this.currentStep--;
      if (this.currentStep == Steps.QUOTES) {
        this.currentPage = "quotePage";
      } else if (this.currentStep == Steps.USER_INFO) {
        this.currentPage = "imagePage";
      } else if (this.currentStep == Steps.CONFIRMATION) {
        this.currentPage = "summaryPage";
      }
    } else {
      this.router.navigate(['/vehicle', 'add', this.fetchedCarDetails.id]);
      this.currentPage == "addVehiclePage";
    }

    this.scrollToTop();
  }
  edit(page) {
    if (page == "quotes") {
      this.activate(Steps.QUOTES);
      this.currentPage = "quotePage";
    }
    else if (page == "editVehicle") {
      this.router.navigate(['/vehicle', 'add', this.fetchedCarDetails.id]);
      this.currentPage = "addVehiclePage";
    }
    this.scrollToTop();
  }
  backEvent(): void {
    this.currentStep--;
    if (this.currentStep == Steps.QUOTES) {
      this.currentPage = "quotePage";
    } else if (this.currentStep == Steps.USER_INFO) {
      this.currentPage = "imagePage";
    } else if (this.currentStep == Steps.CONFIRMATION) {
      this.currentPage = "summaryPage";
    }
    this.scrollToTop();
  }

  generateCheckboxFromArray(arrayToConvert: any[]): UntypedFormArray {
    let formArray: UntypedFormArray = new UntypedFormArray([]);
    arrayToConvert.forEach((element, index) => {
      if (element) {
        let formGroup = this.fb.group({
          has: element.isSelected,
          type: element.type
        });
        if (element.subLimit) {
          let selectedSubLimit = 0
          element.subLimit.forEach((sub, index) => {
            if (sub.isSelected) {
              selectedSubLimit = index;
              sub.isSelected = false;
            }
          });
          formGroup.addControl(element.name, this.fb.control(index + "_" + selectedSubLimit));
        }
        formArray.push(formGroup);
      }
    });
    return formArray;
  }

  //New Final Quote Submission
  showUploadImages(event: { selectedFee: Fee, rateCard: RateCard, rateCardSelection: RateCardSelections }) {
    this.selectedRateCard = event.rateCard
    this.rateCardSelection = event.rateCardSelection
    this.selectedFee = event.selectedFee
    this.scrollToTop();
    this.activate(Steps.USER_INFO);
    this.quoteService.sendNotificationToApp(this.fetchedCarDetails.id).subscribe(response => { }, error => { });
  }

  IsValidCarDetails(): boolean {
    if (this.fetchedCarDetails.modelYear != undefined && this.fetchedCarDetails.modelYear != "" &&
      this.fetchedCarDetails.carMake != undefined && this.fetchedCarDetails.carMake != "" &&
      this.fetchedCarDetails.carModel != undefined && this.fetchedCarDetails.carModel != "" &&
      this.fetchedCarDetails.variant != undefined && this.fetchedCarDetails.variant != "" &&
      this.fetchedCarDetails.marketValue != undefined && this.fetchedCarDetails.marketValue > 0 && this.fetchedCarDetails.id) {
      return true;
    }
    return false;
  }

  setCarImages(carImagesList): void {
    let tempCarImages: any = [];
    for (var carImage of carImagesList) {
      let image: any = {};
      let carImageIndex = +(carImage.name.split("_")[1]);
      let carImageUrl = environment.cdnUrl + '/comprehensive-car-book/' + carImage.name + this.SASTokenForCarImageRead;
      image.src = carImageUrl;
      image.caption = carImage.name;
      image.carIndex = carImageIndex;

      tempCarImages.push(image);
    }
    this.carImagesList = Object.assign([], tempCarImages);
  }

  onSelect(event) {
    if (event.target.files[0]) {
      if (event.target.id == "register-card") {
        if (this.carImagesList.includes(event.target.id)) {
          this.snackBar.open("Image has been already uploaded successfully", "CANCEL");
        } else {
          this.rcImageFiles = event.target.files[0];
          let rcImageName = this.fetchedCarDetails.id.toString();
          this.storageService.getUploadTokenForRCImage(rcImageName).subscribe(token => {
            this.storageService.uploadFileToAzure(`/${environment.rcImageContainer}/${rcImageName}${token.sasToken}`, this.rcImageFiles, 'image/jpg').subscribe();
            this.carImagesList.push(event.target.id);
            this.snackBar.open("Image uploaded successfully", "CANCEL");
            if (this.uploadedRc != 1) {
              this.uploadedRc = 1;
              this.uploadedImageCounter++;
            }
          }, error => { });
        }
        this.showRCImageErrorMsg = false;
      } else if (event.target.id == "civil-ID-front") {
        this.civilIdFrontImageFiles = event.target.files[0];
        let civilIdFrontImageName = this.fetchedCarDetails.id.toString() + this.storageService.civilCardFront;
        this.storageService.getUploadTokenForCivilImage(civilIdFrontImageName).subscribe(token => {
          this.storageService.uploadFileToAzure(`/${environment.civilCardImageContainer}/${civilIdFrontImageName}${token.sasToken}`, this.civilIdFrontImageFiles, 'image/jpg').subscribe();
          if (!this.carImagesList.includes(event.target.id)) {
            this.carImagesList.push(event.target.id);
            this.snackBar.open("Image uploaded successfully", "CANCEL");
            if (this.uploadedCivilFront != 1) {
              this.uploadedCivilFront = 1;
              this.uploadedImageCounter++;
            }
          }
        }, error => { });
        this.showCivilIDFrontImageErrorMsg = false;
      } else if (event.target.id == "civil-ID-back") {
        this.civilIdBackImageFiles = event.target.files[0];
        let civilIdBackImageName = this.fetchedCarDetails.id.toString() + this.storageService.civilCardBack;
        this.storageService.getUploadTokenForCivilImage(civilIdBackImageName).subscribe(token => {
          this.storageService.uploadFileToAzure(`/${environment.civilCardImageContainer}/${civilIdBackImageName}${token.sasToken}`, this.civilIdBackImageFiles, 'image/jpg').subscribe();
          if (!this.carImagesList.includes(event.target.id)) {
            this.carImagesList.push(event.target.id);
            this.snackBar.open("Image uploaded successfully", "CANCEL");
            if (this.uploadedCivilBack != 1) {
              this.uploadedCivilBack = 1;
              this.uploadedImageCounter++;
            }
          }
        }, error => { });
        this.showCivilIDBackImageErrorMsg = false;
      } else if (event.target.id == "front-img") {
        if (this.carImagesList.includes(event.target.id)) {
          this.snackBar.open("Image has been already uploaded successfully", "CANCEL");
        } else {
          this.frontImageFiles = event.target.files[0];
          let frontImageName = this.fetchedCarDetails.id.toString() + this.storageService.carImageFront;
          this.storageService.uploadFileToAzure(`/${environment.carImageContainer}/${frontImageName}${this.uploadCarSAS}`, this.frontImageFiles, 'image/jpg').subscribe();
          this.carImagesList.push(event.target.id);
          this.snackBar.open("Image uploaded successfully", "CANCEL");
          if (this.uploadedCarImage_1 != 1) {
            this.uploadedCarImage_1 = 1;
            this.uploadedImageCounter++;
          }
        }
        this.showRCImageErrorMsg = false;
      } else if (event.target.id == "back-img") {
        if (this.carImagesList.includes(event.target.id)) {
          this.snackBar.open("Image has been already uploaded successfully", "CANCEL");
        } else {
          this.backImageFiles = event.target.files[0];
          let backImageName = this.fetchedCarDetails.id.toString() + this.storageService.carImageBack;
          this.storageService.uploadFileToAzure(`/${environment.carImageContainer}/${backImageName}${this.uploadCarSAS}`, this.backImageFiles, 'image/jpg').subscribe();
          this.carImagesList.push(event.target.id);
          this.snackBar.open("Image uploaded successfully", "CANCEL");
          if (this.uploadedCarImage_2 != 1) {
            this.uploadedCarImage_2 = 1;
            this.uploadedImageCounter++;
          }

        }
        this.showRCImageErrorMsg = false;
      } else if (event.target.id == "ls-img") {
        if (this.carImagesList.includes(event.target.id)) {
          this.snackBar.open("Image has been already uploaded successfully", "CANCEL");
        } else {
          this.leftSideImageFiles = event.target.files[0];
          let leftSideImageName = this.fetchedCarDetails.id.toString() + this.storageService.carImageLeftSide;
          this.storageService.uploadFileToAzure(`/${environment.carImageContainer}/${leftSideImageName}${this.uploadCarSAS}`, this.leftSideImageFiles, 'image/jpg').subscribe();
          this.carImagesList.push(event.target.id);
          this.snackBar.open("Image uploaded successfully", "CANCEL");
          if (this.uploadedCarImage_3 != 1) {
            this.uploadedCarImage_3 = 1;
            this.uploadedImageCounter++;
          }

        }
        this.showRCImageErrorMsg = false;
      } else if (event.target.id == "rs-img") {
        if (this.carImagesList.includes(event.target.id)) {
          this.snackBar.open("Image has been already uploaded successfully", "CANCEL");
        } else {
          this.rightSideImageFiles = event.target.files[0];
          let rightSideImageName = this.fetchedCarDetails.id.toString() + this.storageService.carImageRightSide;
          this.storageService.uploadFileToAzure(`/${environment.carImageContainer}/${rightSideImageName}${this.uploadCarSAS}`, this.rightSideImageFiles, 'image/jpg').subscribe();
          this.carImagesList.push(event.target.id);
          this.snackBar.open("Image uploaded successfully", "CANCEL");
          if (this.uploadedCarImage_4 != 1) {
            this.uploadedCarImage_4 = 1;
            this.uploadedImageCounter++;
          }

        }
        this.showRCImageErrorMsg = false;
      } else if (event.target.id == "fw-img") {
        if (this.carImagesList.includes(event.target.id)) {
          this.snackBar.open("Image has been already uploaded successfully", "CANCEL");
        } else {
          this.frontWindshieldImageFiles = event.target.files[0];
          let frontWindshieldImageName = this.fetchedCarDetails.id.toString() + this.storageService.carImageFrontWSImg;
          this.storageService.uploadFileToAzure(`/${environment.carImageContainer}/${frontWindshieldImageName}${this.uploadCarSAS}`, this.frontWindshieldImageFiles, 'image/jpg').subscribe();
          this.carImagesList.push(event.target.id);
          this.snackBar.open("Image uploaded successfully", "CANCEL");
          if (this.uploadedCarImage_5 != 1) {
            this.uploadedCarImage_5 = 1;
            this.uploadedImageCounter++;
          }

        }
        this.showRCImageErrorMsg = false;
      } else if (event.target.id == "chassis-num") {
        if (this.carImagesList.includes(event.target.id)) {
          this.snackBar.open("Image has been already uploaded successfully", "CANCEL");
        } else {
          this.chassisNumberImageFiles = event.target.files[0];
          let chassisNumberImageName = this.fetchedCarDetails.id.toString() + this.storageService.carImageChassisNum;
          this.storageService.uploadFileToAzure(`/${environment.carImageContainer}/${chassisNumberImageName}${this.uploadCarSAS}`, this.chassisNumberImageFiles, 'image/jpg').subscribe();
          this.carImagesList.push(event.target.id);
          this.snackBar.open("Image uploaded successfully", "CANCEL");
          if (this.uploadedCarImage_6 != 1) {
            this.uploadedCarImage_6 = 1;
            this.uploadedImageCounter++;
          }

        }
        this.showRCImageErrorMsg = false;
      }

      else if (event.target.id == "video-car") {
        // if (this.carImagesList.includes(event.target.id)) {
        //   this.snackBar.open("File has been already uploaded successfully", "CANCEL");
        // } else {
        //   let fileName = this.fetchedCarDetails.id.toString() + "_video";
        //   // TODO:
        //   // this.AzureParamForCar.containerName = 'comprehensive-car-book';
        //   this.uploadFileCarImages(event.target.files[0], fileName, (x) => { });
        // }
        if (this.carImagesList.includes(event.target.id)) {
          this.snackBar.open("Video has been already uploaded successfully", "CANCEL");
        } else {
          this.videoOfCarFiles = event.target.files[0];
          let videoOfCarFilesName = this.fetchedCarDetails.id.toString() + this.storageService.carVideo;
          this.storageService.uploadFileToAzure(`/${environment.rcImageContainer}/${videoOfCarFilesName}${this.uploadVideoSAS}`, this.videoOfCarFiles, 'video/MP4').subscribe();
          this.carImagesList.push(event.target.id);
          this.snackBar.open("Video uploaded successfully", "CANCEL");
          if (this.uploadedCarVideo != 1) {
            this.uploadedCarVideo = 1;
            this.uploadedImageCounter++;
          }
        }
        this.showRCImageErrorMsg = false;
      }
    }
  }

  uploadCarImage(event: any, index: any) {
    const file = event.target.files[0];
    let fileName = this.fetchedCarDetails.id.toString() + "_" + index;

    // TODO:
    // this.AzureParamForCar.containerName = 'comprehensive-car-book';

    if (file !== undefined) {
      const reader = new FileReader();
      const image = new Image();
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      const watermark = () => {
        let width = image.width;
        let height = image.height;
        let watermarkText = this.timestamp + " , " + this.locationOnImage;
        let maxSize = 1200;
        if (width > height) {
          if (width > maxSize) {
            height *= maxSize / width;
            width = maxSize;
          }
        } else {
          if (height > maxSize) {
            width *= maxSize / height;
            height = maxSize;
          }
        }
        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(image, 0, 0, width, height);
        ctx.fillStyle = "red";
        ctx.textBaseline = "bottom";
        ctx.font = "bold 20px serif";
        let textwidth = ctx.measureText(watermarkText).width;
        ctx.globalAlpha = .50;
        ctx.fillText(watermarkText, width - textwidth - 20, height - 20);
        let dataUrl = canvas.toDataURL(event.target.files[0].type);
        const file = new File([this.convertDataUrlToBlob(dataUrl)], this.fetchedCarDetails.id.toString() + "_" + index, { type: event.target.files[0].type });
        this.uploadFileCarImages(file, fileName, (x) => { });
      };

      return new Promise((ok, no) => {
        if (!file.type.match(/image.*/)) {
          no(new Error("Not an image"));
          return;
        }

        reader.onload = (readerEvent: any) => {
          image.onload = () => ok(watermark());
          image.src = readerEvent.target.result;
        };
        reader.readAsDataURL(file);
      })
    }
  }

  convertDataUrlToBlob(dataUrl): Blob {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new Blob([u8arr], { type: mime });
  }

  uploadFileCarImages(yourfile: any, fileName: string, cb: (error: Error) => void) {
    // TODO:
    // if (yourfile !== null) {
    this.storageService.uploadFileToAzure(`/${environment.carImageContainer}/${fileName}${this.AzureParamForCar.sas}`, yourfile, 'image/jpg').subscribe(token => {
      //   let baseUrl = this.blobService.generateBlobUrl(this.AzureParamForCar, fileName);
      let fileNumber = fileName.split("_")[1];
      this.snackBar.open('File uploaded successfully', 'CANCEL');
      if (fileNumber == '1' && this.uploadedCarImage_1 != 1) {
        this.uploadedCarImage_1 = 1;
        this.uploadedCarImages[0] = 1;
        this.uploadedImageCounter++;
      } else if (fileNumber == '2' && this.uploadedCarImage_2 != 1) {
        this.uploadedCarImage_2 = 1;
        this.uploadedCarImages[1] = 1;
        this.uploadedImageCounter++;
      } else if (fileNumber == '3' && this.uploadedCarImage_3 != 1) {
        this.uploadedCarImage_3 = 1;
        this.uploadedCarImages[2] = 1;
        this.uploadedImageCounter++;
      } else if (fileNumber == '4' && this.uploadedCarImage_4 != 1) {
        this.uploadedCarImage_4 = 1;
        this.uploadedCarImages[3] = 1;
        this.uploadedImageCounter++;
      } else if (fileNumber == '5' && this.uploadedCarImage_5 != 1) {
        this.uploadedCarImage_5 = 1;
        this.uploadedCarImages[4] = 1;
        this.uploadedImageCounter++;
      } else if (fileNumber == '6' && this.uploadedCarImage_6 != 1) {
        this.uploadedCarImage_6 = 1;
        this.uploadedCarImages[5] = 1;
        this.uploadedImageCounter++;
      } else if (fileNumber == 'video' && this.uploadedCarVideo != 1) {
        this.uploadedCarVideo = 1;
        this.uploadedImageCounter++;
      }
      this.storeCarImageName(fileName);
    }, error => {
      this.snackBar.open('There was an error uploading your document: ' + fileName, 'CANCEL');
    });

    if (!this.carImagesList.includes(fileName)) {
      this.carImagesList.push(fileName);
    }

  }

  storeCarImageName(car): void {
    let uploadCarBody = [
      car
    ]
    this.storageService.uploadCarImages(this.fetchedCarDetails.id.toString(), uploadCarBody).subscribe(() => { }, () => { });
  }


  proceedToNextPage() {
    // if (this.rcImageFiles == undefined && this.civilIdFrontImageFiles == undefined && this.civilIdBackImageFiles == undefined && this.showTpl) {
    //   this.activate(Steps.CONFIRMATION);
    //   this.scrollToTop();
    // } else {
    //   this.activate(Steps.CONFIRMATION);
    //   this.scrollToTop();
    //   this.snackBar.open("Register Card Image, Civil ID front and Civil ID back images are required to proceed further.", "CANCEL");
    // }
    // if(this.carImagesList.length == 0){
    //   this.noImageModal = true;
    // } else{
    //   this.activate(Steps.CONFIRMATION);
    //   this.scrollToTop();
    // }
  }

  scrollToTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  showQuotePage() {
    this.activate(Steps.QUOTES);
  }
  onRemove(id) {
    if (id == "vehicleRCImage") {
      this.rcImageFiles = null;
      this.showRCImageErrorMsg = true;
    } else if (id == "civilIdImage_front") {
      this.civilIdFrontImageFiles = null;
      this.showCivilIDFrontImageErrorMsg = true;
    } else if (id == "civilIdImage_back") {
      this.civilIdBackImageFiles = null;
      this.showCivilIDBackImageErrorMsg = true;
    }
  }

  viewSummary(formData): void {
    if (this.rcImageFiles == undefined && this.civilIdFrontImageFiles == undefined && this.civilIdBackImageFiles == undefined) {
      this.showCivilIDFrontImageErrorMsg = this.civilIdFrontImageFiles == undefined;
      this.showCivilIDBackImageErrorMsg = this.civilIdBackImageFiles == undefined;
      this.showRCImageErrorMsg = this.rcImageFiles == undefined;
    } else {
      this.showRCImageErrorMsg = false;
      this.showCivilIDFrontImageErrorMsg = false;
      this.showCivilIDBackImageErrorMsg = false;
      this.showUploadLoader = true;
      this.totalImageUploaded = 3, this.imageProcessed = 0;
      this.imageUploaded = [], this.imageUploadError = [];
      this.uploadStatusBehaviour$ = new BehaviorSubject<number>(this.imageProcessed);

      this.uploadStatusSubscription = this.uploadStatusBehaviour$.asObservable().subscribe((status: number) => {
        if (status == this.totalImageUploaded) {
          if (this.imageUploaded.length == this.totalImageUploaded) {
            this.snackBar.open("Image uploaded successfully", "CANCEL");
            this.fetchedCarDetails.insuranceType = this.currentPlanType as PlanType;
            this.activate(Steps.CONFIRMATION);
          } else if (this.imageUploadError.length == this.totalImageUploaded) {
            this.snackBar.open("Error occur while uploading image. Please try again.", "CANCEL");
          } else if (this.imageUploaded.length > 0) {
            let fileUploadedName = "";

            this.imageUploaded.forEach((fileType: UploadType) => {
              if (fileType == UploadType.RC_IMAGE) {
                fileUploadedName += this.rcImageFiles.name + ", ";
              } else if (fileType == UploadType.CIVILID_FRONT_IMAGE) {
                fileUploadedName += this.civilIdFrontImageFiles.name + ", ";
              } else if (fileType == UploadType.CIVILID_BACK_IMAGE) {
                fileUploadedName += this.civilIdBackImageFiles.name + ", ";
              }
            });
            fileUploadedName = fileUploadedName.substring(0, fileUploadedName.length - 2);

            this.snackBar.open("Image " + fileUploadedName + " uploaded successfully", "CANCEL");
          }

          this.showUploadLoader = false;
          this.uploadStatusSubscription.unsubscribe();
        }
      }, error => {
        this.uploadStatusSubscription.unsubscribe();
        this.snackBar.open("Error occur while uploading image. Please try again.", "CANCEL");
      });

      // upload user vehicle RC Image and Civil ID Image.
      let rcImageName = this.fetchedCarDetails.id.toString();
      this.storageService.getUploadTokenForRCImage(rcImageName).subscribe(token => {
      }, error => { });

      let civilIdFrontImageName = this.fetchedCarDetails.id.toString() + this.storageService.civilCardFront;
      this.storageService.getUploadTokenForCivilImage(civilIdFrontImageName).subscribe(token => {
      }, error => { });

      let civilIdBackImageName = this.fetchedCarDetails.id.toString() + this.storageService.civilCardBack;
      this.storageService.getUploadTokenForCivilImage(civilIdBackImageName).subscribe(token => {
      }, error => { });
    }
  }

  uploadFile(yourfile: any, fileName: string, uploadType: UploadType, uploadParams: any, cb: (error: Error) => void) {

  }

  //#region Car Details Method
  getLanguage(): string {

    if (this.translateService.lang !== 'en') {
      this.mandatoryTooltip = `احصل على الحد الأدنى من التغطية الأساسية لسيارتك
      أضرار المياه
      الخسارة الكلية نتيجة حادث
      أضرار الحريق أو العاصفة
    السرقة`

      this.assistTooltip = `احصل على المساعدة ، في كل حالة طوارئ للسيارات!
    اعطال السيارة
      توفير الوقود
      استبدال البطارية
    استبدال الإطارات
    خدمات الرافعات`
    }

    return this.translateService.lang;
  }

  getCarDetailsJson(): any {
    if (this.fetchedCarDetails) {
      return {
        "carMake": this.fetchedCarDetails.carMake,
        "carModel": this.fetchedCarDetails.carModel,
        "modelYear": this.fetchedCarDetails.modelYear,
        "variant": this.fetchedCarDetails.variant,
        "marketValue": this.fetchedCarDetails.marketValue
      }
    }
  }

  //#endregion Car Details Method

  // TODO: Check where to use this.
  // getSupervisionFeeForPeriod(period: number): number {
  //   return (period == 1 ? this.fee.supervisionFee : (period == 2 ? (this.fee.supervisionFee2 ? this.fee.supervisionFee2 : this.fee.supervisionFee) : (period == 3 ? (this.fee.supervisionFee3 ? this.fee.supervisionFee3 : this.fee.supervisionFee) : this.fee.supervisionFee)));
  // }

}

enum UploadType {
  RC_IMAGE = "rc_image",
  CIVILID_FRONT_IMAGE = "civilId_front_image",
  CIVILID_BACK_IMAGE = "civilId_back_image"
}