import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PlanStatus } from 'src/app/models/quoteModel';
import { RequestModel } from 'src/app/models/requestModel';
import { QuoteService } from 'src/app/services/quote.service';

@Component({
  selector: 'app-dashboard-customer',
  templateUrl: './dashboard-customer.component.html',
  styleUrls: ['./dashboard-customer.component.scss']
})
export class DashboardCustomerComponent implements OnInit {

  allRequestedQuote: RequestModel[];
  displayedColumn: string[] = ["Req No.", "Owner Name", "Plate No.", "Car Make", "Quotation Amount", "Request Status"];

  constructor(private quoteService: QuoteService, private router: Router) { }

  ngOnInit(): void {
    this.quoteService.getAllQuoteRequest().subscribe( ( quoteResponse: any[] ) => {
        if ( quoteResponse && quoteResponse.length == 0 ) {
          this.router.navigate(["/vehicle/add"]);
        } else {
          this.router.navigate(["/quotes"]);
        }
      },
      error => {
        this.router.navigate(["/vehicle/add"]);
      }
    )
  }

  requestDetails(request: RequestModel) {
    if ( request ) {
      this.router.navigate(['/quotes', request.id])
    }
  }
}
