import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Helpers } from 'src/app/helper/helper';
import { CustomerUserModel, UserType } from 'src/app/models/userModel';
import { LoginService } from 'src/app/services/login.service';
import { TranslateService } from 'src/app/services/translate.service';
import { Directionality } from '@angular/cdk/bidi';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-login-customer',
  templateUrl: './login-customer.component.html',
  styleUrls: ['./login-customer.component.scss']
})
export class LoginCustomerComponent implements OnInit {
  customerUserModel: CustomerUserModel = new CustomerUserModel();
  enableVerifyOtpView: boolean;
  isIncorrectOTP: boolean;
  showLoginLoader: boolean;
  enableEmailView: boolean = false;
  enableNameView: boolean = false;
  enablePhoneView: boolean;
  enableCountryCodeView: boolean;
  curLang: string = "en";
  /** Whether the widget is in RTL mode or not. */
  isRtl: boolean;
  @Output() onChangeLanguage: EventEmitter<string> = new EventEmitter<string>();

  /** Subscription to the Directionality change EventEmitter. */
  private _dirChangeSubscription = Subscription.EMPTY;
  constructor(private router: Router,
    private loginService: LoginService,
    private helpers: Helpers,
    private readonly translate: TranslateService,
    dir: Directionality,
    private activatedRoute: ActivatedRoute) {
    this.isRtl = dir.value === 'rtl';

    this._dirChangeSubscription = dir.change.subscribe((dir) => {
      console.log(dir);
    });
  }

  ngOnInit(): void {

    const userToken = this.helpers.getDecodedAccessToken(this.helpers.getToken());
    if (userToken) {
      this.router.navigate(['/vehicles']);
    }


    this.customerUserModel = new CustomerUserModel();
    this.enablePhoneView = true;
    this.enableCountryCodeView = true;
    this.enableVerifyOtpView = false;
    this.isIncorrectOTP = false;
    this.showLoginLoader = false;


    this.activatedRoute.paramMap.subscribe(param => {
      if (param.get("id")) {
        this.customerUserModel.referrerId = decodeURIComponent(param.get("id"));
        this.customerUserModel.referrer = decodeURIComponent(param.get("userName"));
      }
    });

    let url = window.location.search;
    let queryParameter = new URLSearchParams(url);
    if (queryParameter.get('language')) {
      this.setLang(queryParameter.get('language'));
    }
  }
  ngOnDestroy() {
    this._dirChangeSubscription.unsubscribe();
  }
  logInCustomer(formData): void {
    if (this.enableVerifyOtpView) {
      let authValues = {
        phoneNumber: this.customerUserModel.phoneNumber,
        countryCode: +this.customerUserModel.countryCode,
        email: this.customerUserModel.email,
        code: this.customerUserModel.otp
      };

      this.showLoginLoader = true;
      this.loginService.verifyOtp(authValues).subscribe(token => {
        this.showLoginLoader = false;
        this.isIncorrectOTP = false;
        this.helpers.setToken(token);
        this.helpers.setHasPassword(token.hasPassword);
        const userToken = this.helpers.getDecodedAccessToken(this.helpers.getToken());
        if (userToken.type == UserType.CUSTOMER) {
          this.router.navigate(['/vehicles']);
        }
      },
        error => {
          this.showLoginLoader = false;
          if (error == "Invalid Otp") {
            this.isIncorrectOTP = true;
          }
        });
    } else {
      this.sendOTP(true);
    }
  }

  removeFocus(maxlength) {
    let otpField = document.getElementById("otpField");
    if (maxlength.length == 6) {
      otpField.blur();
    }
  }
  checkUser(phone) {
    if (phone.length == 8 || phone.length == 10) {
      let authValues = {
        phoneNumber: this.customerUserModel.phoneNumber,
        countryCode: +this.customerUserModel.countryCode
      };
      this.showLoginLoader = true;
      this.loginService.getOtp(authValues).subscribe((response: any) => {
        this.showLoginLoader = false;
        this.enableEmailView = false;
        this.enableCountryCodeView = false;
        this.enablePhoneView = false;
        this.helpers.setNewCustomer(false);
        this.enableVerifyOtpView = true;
      },
        error => {
          //Error handling
          this.enableEmailView = true;
          this.enableNameView = true;
          this.helpers.setNewCustomer(true);
          this.showLoginLoader = false;
        });
    }
  }

  setLang(lang) {
    this.translate.use(lang);
    this.curLang = this.translate.lang;
    if (lang == 'en') {
      this.isRtl = false;
    } else {
      this.isRtl = true;
    }
    this.onChangeLanguage.emit(lang);
  }

  sendOTP(showLoader): void {
    if (this.customerUserModel.phoneNumber != undefined && this.customerUserModel.phoneNumber != "") {
      const authValues = {
        phoneNumber: this.customerUserModel.phoneNumber,
        countryCode: +this.customerUserModel.countryCode,
        referrer: this.customerUserModel.referrerId ? this.customerUserModel.referrerId : null,
        emailAddress: this.customerUserModel.email,
        firstName: this.customerUserModel.name
      };

      this.showLoginLoader = showLoader && true;
      this.loginService.registerCustomer(authValues).subscribe((response: any) => {
        this.showLoginLoader = false;
        this.enableVerifyOtpView = true;
        this.enableEmailView = false;
        this.enableNameView = false;
        this.enableCountryCodeView = false;
        this.enablePhoneView = false;
      },
        error => {
          //Error handling
          if (this.customerUserModel.email != "" && this.customerUserModel.email != undefined) {
            this.showModal(true);
          }
          this.showLoginLoader = false;
        });
    }
  }

  showModal(show: boolean) {
    let popUpModal = document.getElementById("popUpModalLogin");
    if (show) {
      if (this.customerUserModel.email != "" && this.customerUserModel.email != undefined) {
        popUpModal.classList.add("d-block");
        popUpModal.classList.remove("d-none");
      }
    } else if (!show) {
      popUpModal.classList.remove("d-block");
      popUpModal.classList.add("d-add");
    }
  }
}
