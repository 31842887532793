import { Component, OnInit } from '@angular/core';
import { MatSnackBar as MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { QuoteStatus } from 'src/app/models/quoteModel';
import { RateCard } from 'src/app/models/rate-card';
import { QuoteService } from 'src/app/services/quote.service';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {

  QuoteStatus = QuoteStatus;
  allOrders: RateCard[];
  showGetOrdersLoader: boolean;
  //orderDetail: RateCard;
  displayedColumn: string[] = [
    "Order No.",
    "Order Date",
    "Owner Name",
    "Plate Number",
    "Insurer",
    "Policy Type",
    "Policy No.",
    "Premium Amount",
    "Order Status"];

  constructor(private quoteService: QuoteService,
    private router: Router,
    private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.showGetOrdersLoader = true;
    this.quoteService.getAllOrders().subscribe((orderResponse: RateCard[]) => {
      if (orderResponse.length > 0) {
        this.allOrders = orderResponse;
      }
      this.showGetOrdersLoader = false;
    }, error => {
      this.showGetOrdersLoader = false;
    }
    );
  }

  orderDetails(order: RateCard) {
    if (order && order.type != 'TPL') {
      this.router.navigate(['/orders', order.rateCardId])
    } else {
      this.snackBar.open("Please use saiyarti app to view Order Details.", "OK", {
        horizontalPosition: 'right',
        duration: 2000
      })
    }
  }

}
