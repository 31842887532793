import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Helpers } from '../helper/helper';
import { InsurerModel } from '../models/insurerModel';
import { BaseService } from './base.service';
import { TranslateService } from 'src/app/services/translate.service';

@Injectable({
  providedIn: 'root'
})
export class InsurerService extends BaseService {

  constructor( helper: Helpers,
    private http: HttpClient , translate: TranslateService) {
    super(helper , translate);
  }

  getInsuranceCompanies(): Observable<InsurerModel[] | any> {
    return this.http.get( this.pathAPI + "Insurer/InsuranceCompanies", super.header()).pipe(
      catchError(super.handleError)
    );
  }

  getInsurerById(id: string): Observable<InsurerModel | any> {
    return this.http.get( this.pathAPI + "Insurer/Insurer/" + id, super.header()).pipe(
      catchError(super.handleError)
    );
  }

  addInsurer(body: any): Observable<any> {
    return this.http.post( this.pathAPI + "Insurer/InsurerAdd", body, super.header()).pipe(
      catchError(super.handleError)
    );
  }

  editInsurer(body: any, id: string): Observable<any> {
    return this.http.put( this.pathAPI + "Insurer/EditInsurer/" + id, body, super.header()).pipe(
      catchError(super.handleError)
    );
  }
}
