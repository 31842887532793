// export const environment = {
//   production: true,
//   apiUrl: 'https://comprehensive-backend-prod.azurewebsites.net/api/',
//   apiUrlForTPL: 'https://api.saiyarti.com/api/',
//   apiUrlForRateCard: 'https://api.saiyarti.com/api/',
//   apiKey: 'bY4Nya4TQwkSRQcL',
//   apiPassword: 'KC7d9g48vfvpsxuxrrjxT2RTV6StUcamJYevgtCC',
//   storageAccount: 'saiyartiproduction',
//   cdnUrl: 'https://saiyartiproduction.blob.core.windows.net',
//   tplRCImageContainer: "rc-book",
//   tplCivilCardImageContainer: "civil-card",
//   rcImageContainer: "comprehensive-rc-book",
//   companyImageContainer: "company",
//   civilCardImageContainer: "comprehensive-civilcard-book",
//   carImageContainer: "comprehensive-car-book",
//   policyImageContainer: "comprehensive-policy-book",
//   tradeLicenseImageContainer: "comprehensive-inurancecompany-book",
//   companyLogoContainer: "comprehensive-inurancecompany-logo",
//   amplitudekey:'baccea13df734af11fc9ef6ee53108fd',
//   dynamicLink: 'https://saiyarti.page.link',
//   dynamicLinkOfl: 'https://portal.saiyarti.com/',
//   firebase: {
//     apiKey: 'AIzaSyA0CQAJMeKenEeQLnO3SLzqtMu_ZzLN6z8',
//     authDomain: 'neural-sunup-231810.f  irebaseapp.com',
//     databaseURL: 'https:neural-sunup-231810.firebaseio.com',
//     projectId: 'neural-sunup-231810',
//     storageBucket: 'neural-sunup-231810.appspot.com',
//     messagingSenderId: '6675864888',
//     appId: '1:6675864888:web:d1b7e67be85d8d317675cd',
//     measurementId: 'G-CN8N5CLX5E'
//   },
//   paymetGateway: {
//     url: 'https://api.hesabe.com',
//     accessCode: '6055648a-ebfe-4d76-85e7-567a5ab0f920',
//     secret: 'v60zByOVZEnwaW0pb3moYgXrNb1jp23L',
//     ivKey: 'ZEnwaW0pb3moYgXr',
//     merchantCode: '12560819'
//   },
// };

export const environment = {
  production: true,
  apiUrl: 'https://comprehensive-backed-dev.azurewebsites.net/api/',
  apiUrlForTPL: 'https://qaapi.saiyarti.com/api/',
  apiUrlForRateCard: 'https://qaapi.saiyarti.com/api/',
  apiKey: 'a',
  apiPassword: 'b',
  storageAccount: 'saiyartidev',
  cdnUrl: 'https://saiyartidev.blob.core.windows.net',
  tplRCImageContainer: "rc-book",
  tplCivilCardImageContainer: "civil-card",
  rcImageContainer: "comprehensive-rc-book",
  companyImageContainer: "company",
  civilCardImageContainer: "comprehensive-civilcard-book",
  carImageContainer: "comprehensive-car-book",
  policyImageContainer: "comprehensive-policy-book",
  tradeLicenseImageContainer: "comprehensive-inurancecompany-book",
  companyLogoContainer: "comprehensive-inurancecompany-logo",
  amplitudekey:'6e7ffcaeede5834057d865f39f2dff68',
  dynamicLink: 'https://saiyartidev.page.link',
  dynamicLinkOfl: 'https://qaportal.saiyarti.com/',
  firebase: {
    apiKey: 'AIzaSyCwTM4nDDcnToYmHTtNnf3I16zfr8PoSs4',
    authDomain: 'saiyarti-dev.firebaseapp.com',
    databaseURL: 'https://saiyarti-dev.firebaseio.com',
    projectId: 'saiyarti-dev',
    storageBucket: 'saiyarti-dev.appspot.com',
    messagingSenderId: '73985020764',
    appId: '1:73985020764:web:e9f6eb1123d8ffe30da799',
    measurementId: 'G-YYQELWJ86D'
  },
  paymetGateway: {
    url: 'https://sandbox.hesabe.com',
    accessCode: 'c333729b-d060-4b74-a49d-7686a8353481',
    secret: 'PkW64zMe5NVdrlPVNnjo2Jy9nOb7v1Xg',
    ivKey: '5NVdrlPVNnjo2Jy9',
    merchantCode: '842217'
  },
};
