<div class="page-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <div class="text-center mb-5">
                    <h4 class="text-uppercase">{{ 'Oops! Something went wrong! Please contact us on +965 67734711 if
                        your card / bank has a debit charge for this transaction. Do retry your payment by' | translate
                        }}<a class="link cursor-pointer" (click)="retry()"> {{'clicking here.' | translate}} </a></h4>
                </div>
            </div>
        </div>
    </div>
    <!-- container-fluid -->
</div>
<!-- End Page-content -->
