import { Component, OnDestroy, OnInit } from '@angular/core';
import { CarDetails } from 'src/app/models/carModel';
import { VehicleService } from 'src/app/services/vehicle.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PlanType } from 'src/app/models/quoteModel';
import { StaticDataProviderService } from 'src/app/services/static-data-provider.service';
import { RegistrationPurpose } from 'src/app/models/userModel';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { CatalogService } from 'src/app/services/catalog.service';
import { map, startWith, take, takeUntil } from 'rxjs/operators';
import { MatSnackBar as MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from 'src/app/services/translate.service';
import { PurposeOfLicense } from 'src/app/models/tpl.model';
import { AddQuoteService } from 'src/app/add-quote.service';
import * as amplitude from '@amplitude/analytics-browser';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-add-vehicle',
  templateUrl: './add-vehicle.component.html',
  styleUrls: ['./add-vehicle.component.scss']
})
export class AddVehicleComponent implements OnInit, OnDestroy {

  Object = Object;
  PlanType = PlanType;
  RegistrationPurpose = RegistrationPurpose;
  carDetails = new CarDetails({})
  showErrorMsgForAddCar: boolean = false;
  editData: boolean = false;

  carId?: number;
  showAddCarLoader: boolean = false;
  carStaticDetails: any;
  purposeOfLicense: string = "";
  isCarDetailsAvail: boolean = false;
  currentPage: string = '';
  editCar: boolean = false;

  showModalBox: boolean = false;
  errorMsg: any = '';

  myControl = new FormControl('');
  options: string[] = ['one', 'two'];
  //options=this.carDetails.carMake;
  filteredOptions: Observable<string[]>;



  get ModelYear() {
    return this.carDetails != null ? this.carDetails.modelYear || "" : "";
  }
  set ModelYear(value) {
    this.carDetails.modelYear = value;
    this.setCarMarketValue();
  }

  // Car Make
  get CarMake() {
    return this.carDetails.carMake;
  }
  set CarMake(value) {
    this.carDetails.carMake = value;
    this.getCarModels();
    this.carDetails.carMakeAr = '';
    this.carDetails.trim = '';
  }

  get CarMakeAr() {
    return this.carDetails.carMakeAr;
  }
  set CarMakeAr(value) {
    this.carDetails.carMakeAr = value;
    this.getCarModels();
    this.carDetails.carMake = '';
    this.carDetails.carModelAr = '';
    this.carDetails.trimAr = '';
  }

  // Car Model
  get CarModel() {
    return this.carDetails.carModel;
  }
  set CarModel(value) {
    this.carDetails.carModel = value;
    this.getCarTrim();
    this.carDetails.carModelAr = '';
    this.carDetails.trim = '';
  }

  get CarModelAr() {
    return this.carDetails.carModelAr;
  }
  set CarModelAr(value) {
    this.carDetails.carModelAr = value;
    this.getCarTrim();
    this.carDetails.carModel = '';
    this.carDetails.trimAr = '';
  }

  // Car Trim
  get CarTrim() {
    return this.carDetails.trim;
  }
  set CarTrim(value) {
    this.carDetails.variant = value;
    this.carDetails.trim = value;
    this.getPassengerCount();
    this.setCarMarketValue();
    this.carDetails.trimAr = '';
  }

  get CarTrimAr() {
    return this.carDetails.trimAr;
  }
  set CarTrimAr(value) {
    this.carDetails.variant = value;
    this.carDetails.trimAr = value;
    this.getPassengerCount();
    this.carDetails.trim = '';
  }

  get selectedLicense(): PurposeOfLicense {
    return this._addQuoteService.purposeOfLicense
  }
  set selectedLicense(value: PurposeOfLicense) {
    this._addQuoteService.purposeOfLicense = value
  }


  carMakeList: any;
  carModelList: any;
  carTrimList: any;

  carMakeListAr: any;
  carModelListAr: any;
  carTrimListAr: any;
  get passengers(): number {
    return this._addQuoteService.noOfPassenger
  }

  set passengers(value: number) {
    this._addQuoteService.noOfPassenger = value
  }

  purposeOfLicenses =
    [
      new PurposeOfLicense(0, 'Private'),
      new PurposeOfLicense(1, 'Taxi'),
      new PurposeOfLicense(2, 'Japanese Pick Up'),
      new PurposeOfLicense(3, 'American Pick Up'),
      new PurposeOfLicense(4, 'Water Tank'),
      new PurposeOfLicense(5, 'Ambulance'),
      new PurposeOfLicense(6, 'Motorcycle'),
      new PurposeOfLicense(7, 'Crane'),
      new PurposeOfLicense(8, 'Bus'),
      new PurposeOfLicense(12, 'Mixer'),
      new PurposeOfLicense(13, 'Fork Cliff'),
      new PurposeOfLicense(15, 'Tipper'),
      new PurposeOfLicense(18, 'Half Lorry'),
    ];

  purposeOfLicensesAr =
    [
      new PurposeOfLicense(0, 'خاصة'),
      new PurposeOfLicense(0, 'تاكسي'),
      new PurposeOfLicense(0, 'ونيت ياباني'),
      new PurposeOfLicense(0, 'ونيت أمريكي'),
      new PurposeOfLicense(0, 'خزان مياه'),
      new PurposeOfLicense(0, 'إسعاف'),
      new PurposeOfLicense(0, ' دراجة بخارية'),
      new PurposeOfLicense(0, 'رافعة (كرين)'),
      new PurposeOfLicense(0, 'باص'),
    ];

  /** control for the selected carMake */
  public carMakeCtrl: UntypedFormControl = new UntypedFormControl();
  public carMakeCtrlAr: UntypedFormControl = new UntypedFormControl();
  /** list of car make filtered by search keyword */
  public filteredCarMakeList: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);
  public filteredCarMakeListAr: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);

  public carTrimCtrl: UntypedFormControl = new UntypedFormControl();
  public carTrimCtrlAr: UntypedFormControl = new UntypedFormControl();

  public filteredCarTrimList: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);
  public filteredCarTrimListAr: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);

  /** control for the selected carModel */
  public carModelCtrl: UntypedFormControl = new UntypedFormControl();
  public carModelCtrlAr: UntypedFormControl = new UntypedFormControl();
  /** control for the MatSelect filter keyword */
  public carModelFilterCtrl: UntypedFormControl = new UntypedFormControl();
  public carModelFilterCtrlAr: UntypedFormControl = new UntypedFormControl();
  /** list of car make filtered by search keyword */
  public filteredCarModelList: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);
  public filteredCarModelListAr: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);
  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  constructor(private vehicleService: VehicleService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private staticDataProviderService: StaticDataProviderService,
    private catalogService: CatalogService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private _addQuoteService: AddQuoteService) {
    // Add class to body to manipulated style of select dropdown.
    document.body.classList.add("ngx-select");
    amplitude.init(environment.amplitudekey, undefined, { defaultTracking: { sessions: true, pageViews: true, formInteractions: true, fileDownloads: true } });

    // Get Car Makes
    this.catalogService.getCarMakes().subscribe(carMakesResponse => {
      this.carMakeList = carMakesResponse;
      this.createCarMakeFilter();
      if (this.isCarDetailsAvail) {
        this.carMakeCtrl.patchValue(this.carDetails.carMake);

      }
    }, error => {
      this.snackBar.open("Error occur while fetching Car Makes", 'Cancel', {
        duration: 2000,
        horizontalPosition: 'right'
      });

    });

    this.catalogService.getCarMakes('ar').subscribe(carMakesArResponse => {
      if (carMakesArResponse) {
        this.carMakeListAr = carMakesArResponse.filter((carMake: any) => { if (carMake) return true });
        this.createCarMakeFilterAr();

      }
    }, error => { });

    this._addQuoteService.purposeOfLicense = this.purposeOfLicenses[0];
    this._addQuoteService.purposeOfLicenseAr = this.purposeOfLicensesAr[0];
  }

  ngOnInit(): void {
    this.showErrorMsgForAddCar = false;
    this.carId = undefined;
    this.showAddCarLoader = false;
    this.currentPage = "addVehiclePage";
    this.activatedRoute.paramMap.subscribe(paramMap => {
      const id = paramMap.get('id')
      if (id) {
        this.carId = +id
        this.vehicleService.getCarById(this.carId.toString()).subscribe((carDetail: CarDetails) => {
          this.isCarDetailsAvail = true;
          this.carDetails = new CarDetails(carDetail);
          this._addQuoteService.carDetails = this.carDetails
          this.carDetails.trim = carDetail.variant;
          this.carMakeCtrl.patchValue(this.carDetails.carMake);
          for (let purposeOfLicense of this.purposeOfLicenses) {
            if (purposeOfLicense.name == this.carDetails.purposeOfLicense) {
              this._addQuoteService.purposeOfLicense = purposeOfLicense;
            }
          }
          for (let purposeOfLicenseAr of this.purposeOfLicensesAr) {
            if (purposeOfLicenseAr.name == this.carDetails.purposeOfLicense) {
              this._addQuoteService.purposeOfLicenseAr = purposeOfLicenseAr;
            }
          }
        }, error => { });
      } else {
        let navState = window.history.state;
        if (!navState.insuranceType) {
          this.router.navigate(['/vehicle/add']);
        } else {
          this.carDetails.insuranceType = navState.insuranceType;
        }
      }
    }, error => { })

    this.staticDataProviderService.getCarStaticDetails().subscribe(staticDetails => {
      this.handleCarStaticDetails(staticDetails);
    }, error => {
      // handle error.
    });

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );

  }
  private _filter(value: string): string[] {
    //throw new Error('Method not implemented.');
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }

  ngOnDestroy() {
    document.body.classList.remove("ngx-select");

    this._onDestroy.next();
    this._onDestroy.complete();
  }

  getLanguage(): string {
    return this.translateService.lang;
  }

  //#region Car Make filter

  createCarMakeFilter(): void {
    // load the initial car make list
    this.filteredCarMakeList.next(this.carMakeList.slice());

    // listen for search field value changes
    this.carMakeCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(
      () => {

        this.CarMake = this.carMakeCtrl.value;
        //this.hideVariant = this.carMakeList.slice().includes(this.CarMake)
        this.filterCarMake()
      }, error => { }
    )
  }

  createCarMakeFilterAr(): void {
    // load the initial car make list
    this.filteredCarMakeListAr.next(this.carMakeListAr.slice());

    // listen for search field value changes
    this.carMakeCtrlAr.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(
      () => {
        this.CarMakeAr = this.carMakeCtrlAr.value;
        //this.hideVariant = this.carMakeListAr.slice().includes(this.CarMakeAr)
        this.filterCarMakeAr()
      }, error => { }
    )
  }

  protected filterCarMake() {
    if (!this.carMakeList) {
      return;
    }
    // get the search keyword
    let search = this.carMakeCtrl.value;
    if (!search) {
      this.filteredCarMakeList.next(this.carMakeList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the CarMake
    this.filteredCarMakeList.next(
      this.carMakeList.filter(carMake => carMake.toLowerCase().indexOf(search) > -1)
    );
  }

  protected filterCarMakeAr() {
    if (!this.carMakeListAr) {
      return;
    }
    // get the search keyword
    let search = this.carMakeCtrlAr.value;
    if (!search) {
      this.filteredCarMakeListAr.next(this.carMakeListAr.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the CarMake
    this.filteredCarMakeListAr.next(
      this.carMakeListAr.filter(carMake => carMake.toLowerCase().indexOf(search) > -1)
    );
  }

  //#endregion

  //#region  Car model filter

  createCarModelFilter(): void {
    // load the initial car model list
    this.filteredCarModelList.next(this.carModelList.slice());

    // listen for search field value changes
    this.carModelCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(
      () => {
        this.CarModel = this.carModelCtrl.value;
        this.filterCarModel()
      }, error => { }
    )
  }



  createCarModelFilterAr(): void {
    // load the initial car model list
    this.filteredCarModelListAr.next(this.carModelListAr.slice());

    // listen for search field value changes
    this.carModelCtrlAr.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(
      () => {
        this.CarModelAr = this.carModelCtrlAr.value;
        this.filterCarModelAr()
      }, error => { }
    )
  }

  protected filterCarModel() {
    if (!this.carModelList) {
      return;
    }
    // get the search keyword
    let search = this.carModelCtrl.value;

    if (!search) {
      this.filteredCarModelList.next(this.carModelList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the CarModel
    this.filteredCarModelList.next(
      this.carModelList.filter(carModel => carModel.toLowerCase().indexOf(search) > -1)
    );
  }

  protected filterCarModelAr() {
    if (!this.carModelListAr) {
      return;
    }
    // get the search keyword
    let search = this.carModelFilterCtrlAr.value;
    if (!search) {
      this.filteredCarModelListAr.next(this.carModelListAr.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the CarModel
    this.filteredCarModelListAr.next(
      this.carModelListAr.filter(carModel => carModel.toLowerCase().indexOf(search) > -1)
    );
  }

  //#endregion car model filter

  // Get Car Models
  getCarModels() {
    let carModelSnackBarRef;
    if (this.carDetails.carMake && this.translateService.lang == 'en') {
      this.catalogService.getCarModels(this.carDetails.carMake).subscribe(carModelsResponse => {
        this.carModelList = carModelsResponse;
        this.createCarModelFilter();
        if (this.isCarDetailsAvail) {
          this.carModelCtrl.patchValue(this.carDetails.carModel);
        }
      }, error => {
        carModelSnackBarRef = this.snackBar.open("Error occur while fetching Car Models", 'Try Again', {
          duration: 2000,
          horizontalPosition: 'right'
        });
      });
    }

    if (this.carDetails.carMakeAr && this.translateService.lang == 'ar') {
      this.catalogService.getCarModels(this.carDetails.carMakeAr, 'ar').subscribe(carModelsArResponse => {
        this.carModelListAr = carModelsArResponse;
        this.createCarModelFilterAr();
      }, error => {
        carModelSnackBarRef = this.snackBar.open("Error occur while fetching Car Models", 'Try Again', {
          duration: 2000,
          horizontalPosition: 'right'
        });
      });
    }

    if (carModelSnackBarRef) {
      carModelSnackBarRef.onAction().pipe(take(1)).subscribe(
        action => {
          carModelSnackBarRef.dismiss();
          this.getCarModels();
        }, error => { });
    }
  }

  // Get Car trim
  getCarTrim() {
    let carTrimSnackBarRef;
    if (this.carDetails.carMake && this.carDetails.carModel && this.translateService.lang == 'en') {
      this.catalogService.getCarTrim(this.carDetails.carMake, this.carDetails.carModel).subscribe(carTrimResponse => {
        this.carTrimList = carTrimResponse;
        this.createCarTrimFilter();
        if (this.isCarDetailsAvail) {
          //this.CarTrim = this.carDetails.variant;
          this.carTrimCtrl.patchValue(this.carDetails.variant);
        }
      }, error => {
        carTrimSnackBarRef = this.snackBar.open("Error occur while fetching Car Trim", 'Try Again', {
          duration: 2000,
          horizontalPosition: 'right'
        });
      });
    }

    if (this.carDetails.carMakeAr && this.carDetails.carModelAr && this.translateService.lang == 'ar') {
      this.catalogService.getCarTrim(this.carDetails.carMakeAr, this.carDetails.carModelAr, 'ar').subscribe(carTrimArResponse => {
        this.carTrimListAr = carTrimArResponse;
        this.createCarTrimFilterAr();

      }, error => {
        carTrimSnackBarRef = this.snackBar.open("Error occur while fetching Car Trim", 'Try Again', {
          duration: 2000,
          horizontalPosition: 'right'
        });
      });
    }

    if (carTrimSnackBarRef) {
      carTrimSnackBarRef.onAction().pipe(take(1)).subscribe(
        action => {
          carTrimSnackBarRef.dismiss();
          this.getCarTrim();
        }, error => { });
    }
  }

  createCarTrimFilter() {
    this.filteredCarTrimList.next(this.carTrimList.slice());
    this.carTrimCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(
      () => {
        this.CarTrim = this.carTrimCtrl.value;
        this.filterCarTrim()
      }, error => { }
    )
  }
  protected filterCarTrim() {
    if (!this.carTrimList) {
      return;
    }
    // get the search keyword
    let search = this.carTrimCtrl.value;
    if (!search) {
      this.filteredCarTrimList.next(this.carTrimList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredCarTrimList.next(
      this.carTrimList.filter(carTrim => carTrim.toLowerCase().indexOf(search) > -1)
    );
  }

  createCarTrimFilterAr() {
    this.filteredCarTrimListAr.next(this.carTrimListAr.slice());
    this.carTrimCtrlAr.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(
      () => {
        this.CarTrimAr = this.carTrimCtrlAr.value;
        this.filterCarTrimAr()
      }, error => { }
    )
  }
  protected filterCarTrimAr() {
    if (!this.carTrimListAr) {
      return;
    }
    // get the search keyword
    let search = this.carTrimCtrlAr.value;
    if (!search) {
      this.filteredCarTrimListAr.next(this.carTrimList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredCarTrimListAr.next(
      this.carTrimListAr.filter(carTrimAr => carTrimAr.toLowerCase().indexOf(search) > -1)
    );
  }
  editRestriction() {
    this.editData = true;
  }

  // Get PassengerCount
  getPassengerCount() {
    let passengerSnackBarRef;
    if (this.carDetails.carMake && this.carDetails.carModel && this.carDetails.trim && this.translateService.lang == 'en') {
      this.catalogService.getPassengerCount(this.carDetails.carMake, this.carDetails.carModel, this.carDetails.trim).subscribe(passengerCountResponse => {
        this.passengers = passengerCountResponse.passengers;
      }, error => {
        passengerSnackBarRef = this.snackBar.open("Error occur while fetching passenger count", 'Try Again', {
          duration: 2000,
          horizontalPosition: 'right'
        });
      });
    }

    if (this.carDetails.carMakeAr && this.carDetails.carModelAr && this.carDetails.trimAr && this.translateService.lang == 'ar') {
      this.catalogService.getPassengerCount(this.carDetails.carMakeAr, this.carDetails.carModelAr, this.carDetails.trimAr).subscribe(passengerCountResponse => {
        this.passengers = passengerCountResponse.passengers;
      }, error => {
        passengerSnackBarRef = this.snackBar.open("Error occur while fetching passenger count", 'Try Again', {
          duration: 2000,
          horizontalPosition: 'right'
        });
      });
    }

    if (passengerSnackBarRef) {
      passengerSnackBarRef.onAction().pipe(take(1)).subscribe(
        action => {
          passengerSnackBarRef.dismiss();
          this.getPassengerCount();
        }, error => { });
    }
  }

  addCarDetails(formData): void {
    amplitude.track('New User!')
    this.showErrorMsgForAddCar = false;
    if (this.carId == null) {
      this.showAddCarLoader = true;
      this.vehicleService.addCar(this.getCarDetailsJson()).subscribe(
        (response: any) => {
          this.showAddCarLoader = false;
          this.carDetails.id = response ? (response.id || null) : null;
          if (this.carDetails.id) {
            this.router.navigate(['/vehicle', this.carDetails.id, 'quote'], { state: { noOfPassenger: this.passengers, type: this.carDetails.insuranceType } });
          } else {
            this.router.navigate(['/vehicles']);
          }
          amplitude.track('Car Added!',{ payload: JSON.stringify(this.carDetails.id) })
        },
        error => {
          this.showAddCarLoader = false;
          this.showErrorMsgForAddCar = true;
          this.errorMsg = error;
        }
      );
    } else {
      this.showAddCarLoader = true;
      this.editCar = true;
      this.vehicleService.editCar(this.carId.toString(), this.getCarDetailsJson()).subscribe(response => {
        this.showAddCarLoader = false;
        this.router.navigate(['/vehicle', this.carDetails.id, 'quote'], { state: { noOfPassenger: this.passengers, type: this.carDetails.insuranceType } });
        amplitude.track('Car Edited!',{ payload: JSON.stringify(this.carDetails.id) })
      }, error => {
        this.showAddCarLoader = false;
        this.showErrorMsgForAddCar = true;
        this.errorMsg = error;
      });
    }
  }

  getCarDetailsJson(): any {
    if (this.carDetails) {
      return {
        "carMake": this.translateService.lang == 'en' ? this.carDetails.carMake : this.carDetails.carMakeAr,
        "carModel": this.translateService.lang == 'en' ? this.carDetails.carModel : this.carDetails.carModelAr,
        "modelYear": this.carDetails.modelYear,
        "variant": this.translateService.lang == 'en' ? this.carDetails.trim : this.carDetails.trimAr,
        "marketValue": this.carDetails.marketValue,
        "ownerName": this.carDetails.ownerName,
        "plateNumber": this.carDetails.plateNumber,
        "noOfPassenger": this.passengers,
        "purposeOfLicense": this.translateService.lang == 'en' ? this._addQuoteService.purposeOfLicense.name : this._addQuoteService.purposeOfLicenseAr.name
      }
    }
  }

  handleCarStaticDetails(staticDetails): void {
    if (staticDetails) {
      this.carStaticDetails = staticDetails;
    }
  }

  getCarMakeList() {
    if (this.carDetails.modelYear && this.carStaticDetails) {
      let carMakeList = this.carStaticDetails.find(carList => {
        return Object.keys(carList)[0] == this.carDetails.modelYear
      });

      if (carMakeList)
        return carMakeList[this.carDetails.modelYear];
    }
    return null;
  }

  getCarModelList() {
    if (this.carDetails.carMake) {
      let carMakeList = this.getCarMakeList();
      if (carMakeList) {
        let carMake = carMakeList.find(carMake => {
          return carMake["make"] == this.carDetails.carMake
        });

        if (carMake)
          return carMake["models"];
      }
    }
    return null;
  }

  getCarVariantList() {
    if (this.carDetails.carModel) {
      let carModelList = this.getCarModelList();

      if (carModelList) {
        let carModel = carModelList.find(carModel => {
          return carModel["name"] == this.carDetails.carModel;
        });

        if (carModel)
          return carModel["variants"];
      }
    }
    return null;
  }

  setCarMarketValue() {
    if (this.isCarDetailsAvail) {
      this.carDetails.marketValue = this.carDetails.marketValue;
      this.isCarDetailsAvail = false;
      return;
    }
    if (this.carDetails.variant) {
      let carVariantList = this.getCarVariantList();

      if (carVariantList) {
        let carVariant = carVariantList.find(carVariant => {
          return carVariant["name"] == this.carDetails.variant;
        });

        if (carVariant) {
          this.carDetails.marketValue = (carVariant.estimate.maximum + carVariant.estimate.minimum) / 2;
        } else {
          this.carDetails.marketValue = 0;
        }
      } else {
        this.carDetails.marketValue = 0;
      }
    } else {
      this.carDetails.marketValue = 0;
    }
  }

  showModal(show: boolean) {
    let popUpModal = document.getElementById("popUpModal");
    if (show) {
      if (this.CarMake == "Other" || this.CarTrim == "Other" || this.CarModel == "Other") {
        popUpModal.classList.add("d-block");
        popUpModal.classList.remove("d-none");
      }
    } else if (!show) {
      popUpModal.classList.remove("d-block");
      popUpModal.classList.add("d-add");
    }
  }

  sendNotificationToAdmin() {
    let popUpModal = document.getElementById("popUpModal");
    let body = {
      "ownerName": this.carDetails.ownerName,
      "help": false
    }
    this.vehicleService.getVehicleHelp(body).subscribe(response => {
      popUpModal.classList.remove("d-block");
      popUpModal.classList.add("d-add");
    });
  }


}
