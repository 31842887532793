import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges, ElementRef, ViewChild } from '@angular/core';
import { MatSnackBar as MatSnackBar } from '@angular/material/snack-bar';
import { CarDetails } from 'src/app/models/carModel';
import { InitiateTransactionRequestBody, InitiateTransactionForTPLRequestBody } from 'src/app/models/paymentModel';
import { PlanType, RateCardType, RCValueType, Steps } from 'src/app/models/quoteModel';
import { MatDialogRef as MatDialogRef } from '@angular/material/dialog';
import { LoaderDialogComponent } from 'src/app/dialogs/loader-dialog/loader-dialog.component';
import { PaymentService } from 'src/app/services/payment.service';
import { VehicleService } from 'src/app/services/vehicle.service';
import { environment } from 'src/environments/environment';
import { Lightbox } from 'ngx-lightbox';
import * as HesabeCrypt from 'hesabe-crypt';
import * as aesjs from 'aes-js';
import { TranslateService } from 'src/app/services/translate.service';
import { UserService } from 'src/app/services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from 'src/app/services/storage.service';
import { QuoteService } from 'src/app/services/quote.service';
import { AddQuoteService } from 'src/app/add-quote.service';
import { RateCard, RateCardSelections } from 'src/app/models/rate-card';
import { Helpers } from 'src/app/helper/helper';
import * as amplitude from '@amplitude/analytics-browser';
import { Fee } from 'src/app/models/tpl.model';
import { TplService } from 'src/app/services/tpl.service';


@Component({
  selector: 'app-quote-summary',
  templateUrl: './quote-summary.component.html',
  styleUrls: ['./quote-summary.component.scss']
})
export class QuoteSummaryComponent implements OnInit, OnChanges {

  @Input() carDetails: CarDetails;
  quoteExpiryDate: string = "";
  @Input() uploadedCarImage_1: number = 0;
  @Input() uploadedCarImage_2: number = 0;
  @Input() uploadedCarImage_3: number = 0;
  @Input() uploadedCarImage_4: number = 0;
  @Input() uploadedCarImage_5: number = 0;
  @Input() uploadedCarImage_6: number = 0;
  @Input() uploadedCarVideo: number = 0;
  rcImageFiles: File;
  SASTokenForRCImageUpload: string;
  showRCImageErrorMsg: boolean;
  civilIdFrontImageFiles: any;
  SASTokenForCivilImageUpload: any;
  showCivilIDFrontImageErrorMsg: boolean;
  civilIdBackImageFiles: any;
  showCivilIDBackImageErrorMsg: boolean;
  frontImageFiles: any;
  backImageFiles: any;
  leftSideImageFiles: any;
  rightSideImageFiles: any;
  frontWindshieldImageFiles: any;
  chassisNumberImageFiles: any;
  videoOfCarFiles: any;
  @Input() get passengerCount(): number {
    return this._addQuoteService.noOfPassenger
  };

  Object = Object;

  @Input() uploadedimages: number;
  @Input() rateCardSelections: RateCardSelections;
  @Input() selectedFee: any;
  @Input() selectedLicense: any;
  @Input() selectedLicenseAr: any;
  @Input() fetchedCarDetails: any;
  tplFee:Fee
  Steps = Steps;
  carReadToken: any;
  carImagesList: any;
  albumsCarImage: any;
  currentStep: number;
  uploadedRc: number = 0;
  uploadedCivilFront: number = 0;
  uploadedCivilBack: number = 0;
  uploadedImageCounter: number;
  rcCivilImages: any;
  @Input() policyDetails: any;
  @Input() isSubmitted: boolean;
  @Input() isPaymentDone: boolean;
  @Input() isPaymentShowForTpl: boolean;
  @Input() isPaymentShowForComprehensive: boolean;
  @Input() showPayNow: boolean;
  @Input() isQuoteSummary: boolean;
  @Output() quoteSubmitted: EventEmitter<any>;
  @Output() payNow: EventEmitter<any>;
  @ViewChild('tSubmitQuote') tSubmitQuote: ElementRef;
  @Output() editEvent = new EventEmitter<any>();
  @Output() backEvent = new EventEmitter<any>();
  @Input() selectedQuote: RateCard;
  RateCardType = RateCardType;
  RCValueType = RCValueType;
  PlanType = PlanType;
  emailId: string;
  deliveryAddress: string;
  emailAddress?: string;
  comment: string;
  showSubmitQuoteLoader: boolean;
  showPayNowLoader: boolean;
  showAddOns: boolean = false;
  activeImage: string = "0";

  get comprehensiveAmount(): number {
    if (this.selectedQuote?.comprehensiveAmount === 0) {
      return 0;
    }
    var fees = 0;
    for (let index = 0; index < this.selectedQuote?.fees.length; index++) {
      const fee = this.selectedQuote?.fees[index];
      fees = fees + fee.amount
    }
    return this.selectedQuote?.comprehensiveAmount - fees
  }

  get limitedComprehensiveAmount(): number {
    if (this.selectedQuote?.limitedComprehensiveAmount === 0) {
      return 0;
    }
    var fees = 0;
    for (let index = 0; index < this.selectedQuote?.fees.length; index++) {
      const fee = this.selectedQuote?.fees[index];
      fees = fees + fee.amount
    }
    return this.selectedQuote?.limitedComprehensiveAmount - fees
  }

  //#region Payment Variable
  payment: HesabeCrypt;
  token: string;
  showViewImages: boolean = false;
  imageList: any[] = ["Registration Card", "Civil ID Front Image", "Civil ID Back Image"];
  videoImg: any[] = ['Video of the Car'];
  carImagesfields: string[] = ['Front Image', 'Back Image', 'Left Side Image', 'Right Side Image', 'Front Windshield Image', 'Chassis Number under the Front Windshield'];
  albums: any = [];
  showReviewSubmit: boolean = true;
  selectCivilCard: number;
  userCivilCardList: any;
  lang: string = 'en';
  uploadCarSAS?: string;
  uploadVideoSAS?: string;
  //#endregion

  //#region Storage API

  SASTokenForRCImageRead: string;
  SASTokenForCivilIdFrontImageRead: string;
  SASTokenForCivilIdBackImageRead: string;

  //#endregion
  loaderDialogRef: MatDialogRef<LoaderDialogComponent>;

  constructor(private vehicleService: VehicleService,
    private snackBar: MatSnackBar,
    private lightbox: Lightbox,
    private paymentService: PaymentService,
    private translateService: TranslateService,
    private storageService: StorageService,
    private quoteService: QuoteService,
    private userService: UserService,
    private tplService: TplService,
    private router: Router,
    private helpers: Helpers,
    private activatedRoute: ActivatedRoute,
    private _addQuoteService: AddQuoteService) {

    const userToken = this.helpers.getDecodedAccessToken(this.helpers.getToken());
    this.emailAddress = userToken['sub'];
    this.quoteSubmitted = new EventEmitter<any>();
    this.payNow = new EventEmitter<any>();

    const secret = environment.paymetGateway.secret;
    const ivKey = environment.paymetGateway.ivKey;

    const key = aesjs.utils.utf8.toBytes(secret);
    const iv = aesjs.utils.utf8.toBytes(ivKey);
    this.payment = new HesabeCrypt(key, iv);

    amplitude.init(environment.amplitudekey, undefined, { defaultTracking: { sessions: true, pageViews: true, formInteractions: true, fileDownloads: true } });

    // Get User Civil Card details.
    this.userService.getCustomerCivilCardDetail().subscribe(result => {
      if (result && result.length > 0) {
        this.userCivilCardList = Object.assign([], result);
        this.selectCivilCard = this.userCivilCardList[0].id;
      } else {
        this.selectCivilCard = null;
      }
      this.hideLoaderDialog();
    }, error => {
      this.hideLoaderDialog();
    });
  }
  carImages() {
    this.storageService.getUploadOnlyTokenForContainer("comprehensive-car-book").subscribe(Token => {
      this.uploadCarSAS = Token.sasToken;
    });
    this.storageService.getUploadOnlyTokenForContainer("comprehensive-rc-book").subscribe(Token => {
      this.uploadVideoSAS = Token.sasToken;
    });
  }

  onSelect(event) {
    if (event.target.files[0]) {
      if (event.target.id == "register-card") {
        if (this.carImagesList.includes(event.target.id)) {
          this.snackBar.open("Image has been already uploaded successfully", "CANCEL");
        } else {
          this.rcImageFiles = event.target.files[0];
          let rcImageName = this.fetchedCarDetails.id.toString();
          this.storageService.getUploadTokenForRCImage(rcImageName).subscribe(token => {
            this.storageService.uploadFileToAzure(`/${environment.rcImageContainer}/${rcImageName}${token.sasToken}`, this.rcImageFiles, 'image/jpg').subscribe();
            this.carImagesList.push(event.target.id);
            this.snackBar.open("Image uploaded successfully", "CANCEL");
            if (this.uploadedRc != 1) {
              this.uploadedRc = 1;
              this.uploadedImageCounter++;
            }
          }, error => { });
        }
        this.showRCImageErrorMsg = false;
      } else if (event.target.id == "civil-ID-front") {
        this.civilIdFrontImageFiles = event.target.files[0];
        let civilIdFrontImageName = this.fetchedCarDetails.id.toString() + this.storageService.civilCardFront;
        this.storageService.getUploadTokenForCivilImage(civilIdFrontImageName).subscribe(token => {
          this.storageService.uploadFileToAzure(`/${environment.civilCardImageContainer}/${civilIdFrontImageName}${token.sasToken}`, this.civilIdFrontImageFiles, 'image/jpg').subscribe();
          if (!this.carImagesList.includes(event.target.id)) {
            this.carImagesList.push(event.target.id);
            this.snackBar.open("Image uploaded successfully", "CANCEL");
            if (this.uploadedCivilFront != 1) {
              this.uploadedCivilFront = 1;
              this.uploadedImageCounter++;
            }
          }
        }, error => { });
        this.showCivilIDFrontImageErrorMsg = false;
      } else if (event.target.id == "civil-ID-back") {
        this.civilIdBackImageFiles = event.target.files[0];
        let civilIdBackImageName = this.fetchedCarDetails.id.toString() + this.storageService.civilCardBack;
        this.storageService.getUploadTokenForCivilImage(civilIdBackImageName).subscribe(token => {
          this.storageService.uploadFileToAzure(`/${environment.civilCardImageContainer}/${civilIdBackImageName}${token.sasToken}`, this.civilIdBackImageFiles, 'image/jpg').subscribe();
          if (!this.carImagesList.includes(event.target.id)) {
            this.carImagesList.push(event.target.id);
            this.snackBar.open("Image uploaded successfully", "CANCEL");
            if (this.uploadedCivilBack != 1) {
              this.uploadedCivilBack = 1;
              this.uploadedImageCounter++;
            }
          }
        }, error => { });
        this.showCivilIDBackImageErrorMsg = false;
      } else if (event.target.id == "front-img") {
        if (this.carImagesList.includes(event.target.id)) {
          this.snackBar.open("Image has been already uploaded successfully", "CANCEL");
        } else {
          this.frontImageFiles = event.target.files[0];
          let frontImageName = this.fetchedCarDetails.id.toString() + this.storageService.carImageFront;
          this.storageService.uploadFileToAzure(`/${environment.carImageContainer}/${frontImageName}${this.uploadCarSAS}`, this.frontImageFiles, 'image/jpg').subscribe();
          this.carImagesList.push(event.target.id);
          this.snackBar.open("Image uploaded successfully", "CANCEL");
          if (this.uploadedCarImage_1 != 1) {
            this.uploadedCarImage_1 = 1;
            this.uploadedImageCounter++;
          }

        }
        this.showRCImageErrorMsg = false;
      } else if (event.target.id == "back-img") {
        if (this.carImagesList.includes(event.target.id)) {
          this.snackBar.open("Image has been already uploaded successfully", "CANCEL");
        } else {
          this.backImageFiles = event.target.files[0];
          let backImageName = this.fetchedCarDetails.id.toString() + this.storageService.carImageBack;
          this.storageService.uploadFileToAzure(`/${environment.carImageContainer}/${backImageName}${this.uploadCarSAS}`, this.backImageFiles, 'image/jpg').subscribe();
          this.carImagesList.push(event.target.id);
          this.snackBar.open("Image uploaded successfully", "CANCEL");
          if (this.uploadedCarImage_2 != 1) {
            this.uploadedCarImage_2 = 1;
            this.uploadedImageCounter++;
          }
        }
        this.showRCImageErrorMsg = false;
      } else if (event.target.id == "ls-img") {
        if (this.carImagesList.includes(event.target.id)) {
          this.snackBar.open("Image has been already uploaded successfully", "CANCEL");
        } else {
          this.leftSideImageFiles = event.target.files[0];
          let leftSideImageName = this.fetchedCarDetails.id.toString() + this.storageService.carImageLeftSide;
          this.storageService.uploadFileToAzure(`/${environment.carImageContainer}/${leftSideImageName}${this.uploadCarSAS}`, this.leftSideImageFiles, 'image/jpg').subscribe();
          this.carImagesList.push(event.target.id);
          this.snackBar.open("Image uploaded successfully", "CANCEL");
          if (this.uploadedCarImage_3 != 1) {
            this.uploadedCarImage_3 = 1;
            this.uploadedImageCounter++;
          }
        }
        this.showRCImageErrorMsg = false;
      } else if (event.target.id == "rs-img") {
        if (this.carImagesList.includes(event.target.id)) {
          this.snackBar.open("Image has been already uploaded successfully", "CANCEL");
        } else {
          this.rightSideImageFiles = event.target.files[0];
          let rightSideImageName = this.fetchedCarDetails.id.toString() + this.storageService.carImageRightSide;
          this.storageService.uploadFileToAzure(`/${environment.carImageContainer}/${rightSideImageName}${this.uploadCarSAS}`, this.rightSideImageFiles, 'image/jpg').subscribe();
          this.carImagesList.push(event.target.id);
          this.snackBar.open("Image uploaded successfully", "CANCEL");
          if (this.uploadedCarImage_4 != 1) {
            this.uploadedCarImage_4 = 1;
            this.uploadedImageCounter++;
          }
        }
        this.showRCImageErrorMsg = false;
      } else if (event.target.id == "fw-img") {
        if (this.carImagesList.includes(event.target.id)) {
          this.snackBar.open("Image has been already uploaded successfully", "CANCEL");
        } else {
          this.frontWindshieldImageFiles = event.target.files[0];
          let frontWindshieldImageName = this.fetchedCarDetails.id.toString() + this.storageService.carImageFrontWSImg;
          this.storageService.uploadFileToAzure(`/${environment.carImageContainer}/${frontWindshieldImageName}${this.uploadCarSAS}`, this.frontWindshieldImageFiles, 'image/jpg').subscribe();
          this.carImagesList.push(event.target.id);
          this.snackBar.open("Image uploaded successfully", "CANCEL");
          if (this.uploadedCarImage_5 != 1) {
            this.uploadedCarImage_5 = 1;
            this.uploadedImageCounter++;
          }
        }
        this.showRCImageErrorMsg = false;
      } else if (event.target.id == "chassis-num") {
        if (this.carImagesList.includes(event.target.id)) {
          this.snackBar.open("Image has been already uploaded successfully", "CANCEL");
        } else {
          this.chassisNumberImageFiles = event.target.files[0];
          let chassisNumberImageName = this.fetchedCarDetails.id.toString() + + this.storageService.carImageChassisNum;
          this.storageService.uploadFileToAzure(`/${environment.carImageContainer}/${chassisNumberImageName}${this.uploadCarSAS}`, this.chassisNumberImageFiles, 'image/jpg').subscribe();
          this.carImagesList.push(event.target.id);
          this.snackBar.open("Image uploaded successfully", "CANCEL");
          if (this.uploadedCarImage_6 != 1) {
            this.uploadedCarImage_6 = 1;
            this.uploadedImageCounter++;
          }
        }
        this.showRCImageErrorMsg = false;
      }

      else if (event.target.id == "video-car") {
        if (this.carImagesList.includes(event.target.id)) {
          this.snackBar.open("Video has been already uploaded successfully", "CANCEL");
        } else {
          this.videoOfCarFiles = event.target.files[0];
          let videoOfCarFilesName = this.fetchedCarDetails.id.toString() + this.storageService.carVideo;
          this.storageService.uploadFileToAzure(`/${environment.rcImageContainer}/${videoOfCarFilesName}${this.uploadVideoSAS}`, this.videoOfCarFiles, 'video/MP4').subscribe();
          this.carImagesList.push(event.target.id);
          this.snackBar.open("Video uploaded successfully", "CANCEL");
          if (this.uploadedCarVideo != 1) {
            this.uploadedCarVideo = 1;
            this.uploadedImageCounter++;
          }
        }
        this.showRCImageErrorMsg = false;
      }

    }
  }

  addOnsSelected() {
    this.showAddOns = Object.values(this.selectedQuote.addOnsMap).filter(x => x.isSelected === true).length !== 0
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.addOnsSelected()
    //#region Token to read image files
    if (changes.carDetails.currentValue) {
      this.storageService.getReadTokenForRCImage(this.selectedQuote.vehicleId.toString()).subscribe(readTokenResposne => {
        this.SASTokenForRCImageRead = readTokenResposne.sasToken;
      }, error => { });

      this.storageService.getReadTokenForCivilImage(this.selectedQuote.vehicleId.toString() + this.storageService.civilCardFront).subscribe(readTokenResposne => {
        this.SASTokenForCivilIdFrontImageRead = readTokenResposne.sasToken;
      }, error => { });

      this.storageService.getReadTokenForCivilImage(this.selectedQuote.vehicleId.toString() + this.storageService.civilCardBack).subscribe(readTokenResposne => {
        this.SASTokenForCivilIdBackImageRead = readTokenResposne.sasToken;
      }, error => { });
    }

    this.lang = this.translateService.lang;

    //#endregion
  }

  ngOnInit(): void {
    this.scrollToTop();
    this.showSubmitQuoteLoader = false;
    this.showPayNowLoader = false;
    this.currentStep = Steps.QUOTES;
    this.carImagesList = [];

    this.tplService.getFee().subscribe(fee=>{
      this.tplFee=fee
    })
    if (this.showPayNow) {
      this.activatedRoute.queryParamMap.subscribe(queryParam => {
        if (queryParam.get('token')) {
          this.token = queryParam.get('token');
        }
      }, error => { });
      this.activatedRoute.paramMap.subscribe(paramMap => {
        if (paramMap.get('token')) {
          this.token = paramMap.get('token');
        }
      }, error => { });
    }

    if (this.rateCardSelections.limitedCoverage || this.rateCardSelections.comprehensive) {
      let expiryDate = new Date();
      expiryDate.setDate(expiryDate.getDate() + this.selectedQuote.validityPeriod);
      let dd = expiryDate.getDate();
      let mm = expiryDate.getMonth() + 1;
      let yyyy = expiryDate.getFullYear();

      this.quoteExpiryDate = dd + "/" + mm + "/" + yyyy;
      this._addQuoteService.quoteExpiryDate = this.quoteExpiryDate;
    }

    this.storageService.getReadTokenForCarImage().subscribe(carReadToken => {
      this.carReadToken = carReadToken.sasToken;
      this.storageService.getCarImagesByVehicleId(this.selectedQuote.vehicleId.toString()).subscribe(carImagesList => {
        this.setCarImages(carImagesList);
      });
    });
    this.loadImages();
  }
  scrollToTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  hideLoaderDialog(): void {
    if (this.loaderDialogRef) {
      this.loaderDialogRef.close();
    }
  }

  submitQuote(submitQuoteForm): void {
    if (submitQuoteForm.value.email && submitQuoteForm.value.email != "") {
      let quoteBody: {
        email: string,
        address: string,
        comment?: string
      } = {
        email: submitQuoteForm.value.email,
        address: submitQuoteForm.value.deliveryAddress
      };

      if (submitQuoteForm.value.comment) {
        quoteBody.comment = submitQuoteForm.value.comment;
      }

      this.showSubmitQuoteLoader = true;
      this.vehicleService.addEmailOrAddress(quoteBody, this.selectedQuote.vehicleId.toString()).subscribe(addEmailResponse => {
        this.quoteSubmitted.emit(submitQuoteForm);
      }, error => {
        this.showSubmitQuoteLoader = false;
        this.snackBar.open('Error occur while adding email. Please try again.', 'CANCEL');
      });
    }
  }

  showPayment() {
    this.showReviewSubmit = false;
  }
  makeChanges(page) {
    this.editEvent.emit(page);
  }

  makeBackEvent() {
    this.backEvent.emit();
  }

  back(): void {
    // this.activate(Steps.USER_INFO);
    this.currentStep--;
  }
  activate(step: Steps): void {
    this.currentStep = step;
  }
  makePayment() {
    //For TPL Payment
    amplitude.track('Quote Submitted!')
    if (this.selectedQuote.tplAmount > 0) {
      const tplRequestBody = new InitiateTransactionForTPLRequestBody(
        this.passengerCount,
        this.selectedFee.price,
        this.tplFee.issueFee,
        this.getSupervisionFeeForPeriod(this.selectedFee.period),
        this.selectedFee.price,
        this.translateService.lang == 'en' ? this.selectedLicense.name : this.selectedLicenseAr.name,
        this.carDetails.plateNumber,
        this.deliveryAddress,
        this.tplFee.deliveryFee,
        this.translateService.lang == 'en' ? this.carDetails.carMake : this.carDetails.carMake,
        this.tplFee.convenienceFee,
        this.selectedFee.price,
        +this.carDetails.modelYear,
        this.translateService.lang == 'en' ? this.carDetails.carModel : this.carDetails.carModel,
        this.selectedFee ? this.selectedFee.id : 0,
        this.carDetails.rcBookId ? this.carDetails.rcBookId : null,
        this.selectCivilCard,
        this.translateService.lang == 'en' ? this.carDetails.trim : this.carDetails.trimAr,
        this.selectedQuote.vehicleId);

      tplRequestBody.ownerName = this.carDetails.ownerName;
      this.paymentService.initiateTransactionForTPL(tplRequestBody).subscribe((initiateTransactionResponse: any) => {
        if (initiateTransactionResponse.policyId) {
          let paymentObj = {
            policyId: initiateTransactionResponse.policyId,
            civilId: initiateTransactionResponse.civilId,
            rcBookId: initiateTransactionResponse.rcBookId
          }
          const requestBody = {
            merchantCode: environment.paymetGateway.merchantCode,
            amount: this.selectedFee.price,
            paymentType: 1,
            responseUrl: window.location.origin + '/tpl-details?isComprehensive=' + ((this.rateCardSelections.tpl && this.rateCardSelections.comprehensive) ? true : false) + (this.token ? ('&token=' + this.token) : '') + (this.quoteExpiryDate != '' ? ('&quoteExpiryDate=' + this.quoteExpiryDate) : ''),
            failureUrl: window.location.origin + '/payment-failure' + (this.token ? ('?token=' + this.token) : ''),
            version: '2.0',
            variable1: this.selectedFee ? this.selectedFee.id : 0,
            variable2: paymentObj.policyId,
            variable3: paymentObj.civilId + "," + paymentObj.rcBookId + "," + (this.fetchedCarDetails ? this.fetchedCarDetails.id : ""),
            variable4: this.translateService.lang,
            variable5: true,
          };

          const encryptedRequestBody = this.payment.encryptAes(JSON.stringify(requestBody)); // Ecnryption
          this.paymentService.getPaymentGatewayLink(encryptedRequestBody).subscribe(paymentRes => {
            const decrypted = JSON.parse(this.payment.decryptAes(paymentRes));
            window.location.href = environment.paymetGateway.url + '/payment?data=' + decrypted.response.data;
          }, err => {
            this.showPayNowLoader = false;
            this.snackBar.open('Error occur while Initiating Transaction. Please try again.', 'CANCEL');
          });
        }
      }, error => {
        this.showPayNowLoader = false;
        this.snackBar.open('Error occur while Initiating Transaction. Please try again.', 'CANCEL');
      });
      if (this.rateCardSelections.limitedCoverage || this.rateCardSelections.comprehensive) {
        this.quoteService.addConfirmedQuote(this.selectedQuote.vehicleId, this.selectedQuote).subscribe(response => {
          // After successfully quote submission
          this.showSuccessMsg();
          amplitude.track('TPL Policy Purchased!',{ payload: JSON.stringify(this.selectedQuote.vehicleId) })
        }, error => {
          //handle error.
          this.snackBar.open('Your Quote has not been submitted. Please try again.', 'CANCEL');
        });
      }
    }

    //For Comprehensive Payment
    else if (this.isPaymentShowForComprehensive && (this.selectedQuote.comprehensiveAmount > 0 || this.selectedQuote.limitedComprehensiveAmount > 0)) {
      if (this.deliveryAddress && this.deliveryAddress.trim() != "") {
        const addressBody = {
          address: this.deliveryAddress
        };
        this.showPayNowLoader = true;
        this.vehicleService.addEmailOrAddress(addressBody, this.selectedQuote.vehicleId.toString()).subscribe(addAddressResponse => {
          this.paymentService.initiateTransaction(this.selectedQuote.rateCardId, new InitiateTransactionRequestBody(this.selectedQuote.amount), this.token).subscribe((initiateTransactionResponse: any) => {
            if (initiateTransactionResponse.paymentId) {
              const requestBody = {
                merchantCode: environment.paymetGateway.merchantCode,
                amount: this.selectedQuote.limitedComprehensiveAmount + this.selectedQuote.comprehensiveAmount + this.selectedQuote.addOnAmount,
                paymentType: 1,
                responseUrl: window.location.origin + '/payment-success?' + ((this.rateCardSelections.limitedCoverage) ? true : false) + '&' + (this.token ? ('?token=' + this.token) : '' +this.lang),
                failureUrl: window.location.origin + '/payment-failure' + (this.token ? ('?token=' + this.token) : ''),
                version: '2.0',
                variable1: this.selectedQuote.vehicleId,
                variable2: initiateTransactionResponse.rateCardId,
                variable3: initiateTransactionResponse.paymentId,
                variable4: this.translateService.lang,
                variable5: false
              };
              const encryptedRequestBody = this.payment.encryptAes(JSON.stringify(requestBody)); // Ecnryption
              this.paymentService.getPaymentGatewayLink(encryptedRequestBody).subscribe(paymentRes => {
                const decrypted = JSON.parse(this.payment.decryptAes(paymentRes));
                window.location.href = environment.paymetGateway.url + '/payment?data=' + decrypted.response.data;
              }, err => {
                this.showPayNowLoader = false;
                this.snackBar.open('Error occur while Initiating Transaction. Please try again.', 'CANCEL');
              });
            }
          }, error => {
            this.showPayNowLoader = false;
            this.snackBar.open('Error occur while Initiating Transaction. Please try again.', 'CANCEL');
          });
        }, error => {
          this.showPayNowLoader = false;
          this.snackBar.open('Error occur while adding address. Please try again.', 'CANCEL');
        });
      }
    }

    //Submit the Comprehensive Quote
    else if (this.selectedQuote.tplAmount == 0 && !this.isPaymentShowForComprehensive) {
      if (this.rateCardSelections.limitedCoverage || this.rateCardSelections.comprehensive) {
        this.selectedQuote.user = { email: this.emailAddress }
        this.quoteService.addConfirmedQuote(this.selectedQuote.vehicleId, this.selectedQuote).subscribe(response => {
          // console.log('Hello World');
          // After successfully quote submission
          this.router.navigate(['/comprehensive-success']);
          amplitude.track('Comprehensive Policy Purchased!',{ payload: JSON.stringify(this.selectedQuote.vehicleId) })
        }, error => {
          this.snackBar.open('Your Quote has not been submitted. Please try again.', 'CANCEL');
        });
      }
    }

  }
  sort(x: any, y: any) {
    const aStepId = x.value.order ?? 0
    const bStepId = y.value.order ?? 0
    return aStepId > bStepId ? 1 : (bStepId > aStepId ? -1 : 0);
  }

  showSuccessMsg(): void {
    this.showPayNowLoader = true;
    // this.snackBar.open('Your Quote has been successfully submitted.', 'CANCEL');
  }

  getSupervisionFeeForPeriod(period: number): number {
    return (period == 1 ? this.tplFee.supervisionFee : (period == 2 ? (this.tplFee.supervisionFee2 ? this.tplFee.supervisionFee2 : this.tplFee.supervisionFee) : (period == 3 ? (this.tplFee.supervisionFee3 ? this.tplFee.supervisionFee3 : this.tplFee.supervisionFee) : this.tplFee.supervisionFee)));
  }
  //#region Image handling

  openRcCivilImage(imageIndex): void {
    const src = this.getImageURL(imageIndex);
    const caption = this.imageList[imageIndex];
    const thumb = src;
    const album = {
      src,
      caption,
      thumb
    }

    if (imageIndex == 0 && this.uploadedRc == 1) {
      this.lightbox.open([album], 0);
    } else if (imageIndex == 1 && this.uploadedCivilFront == 1) {
      this.lightbox.open([album], 0);
    } else if (imageIndex == 2 && this.uploadedCivilBack == 1) {
      this.lightbox.open([album], 0);
    } else {
      this.snackBar.open('Image is not uploaded.', 'CANCEL');
    }

  }

  loadImages() {

    this.storageService.userDocumentPresent(this.selectedQuote.vehicleId).subscribe(presentImages => {
      let rcImage, civil_front, civil_back, frontimg, backimg, leftimg, rightimg, frontWSimg, chassisNum, video;
      this.rcCivilImages = presentImages;
      if (Object.keys(presentImages).length !== 0) {
        if (presentImages.rCbookUrl) {
          rcImage = presentImages.rCbookUrl;
          this.uploadedRc = 1;
        }
        if (presentImages.civilCardFrontUrl) {
          civil_front = presentImages.civilCardFrontUrl;
          this.uploadedCivilFront = 1;
        }
        if (presentImages.civilCardbackUrl) {
          civil_back = presentImages.civilCardbackUrl;
          this.uploadedCivilBack = 1;
        }
        if (presentImages.carFrontUrl) {
          frontimg = presentImages.carFrontUrl;
          this.uploadedCarImage_1 = 1;
        }
        if (presentImages.carBackUrl) {
          backimg = presentImages.carFrontUrl;
          this.uploadedCarImage_2 = 1;
        }
        if (presentImages.carLeftUrl) {
          leftimg = presentImages.carBackUrl;
          this.uploadedCarImage_3 = 1;
        }
        if (presentImages.carRightUrl) {
          rightimg = presentImages.carRightUrl;
          this.uploadedCarImage_4 = 1;
        }
        if (presentImages.carFrontWSUrl) {
          frontWSimg = presentImages.carFrontWSUrl;
          this.uploadedCarImage_5 = 1;
        }
        if (presentImages.carFrontChassisUrl) {
          chassisNum = presentImages.carFrontChassisUrl;
          this.uploadedCarImage_6 = 1;
        }
        if (presentImages.carVideoUrl) {
          video = presentImages.carVideoUrl;
          this.uploadedCarVideo = 1;
        }
        this.uploadedImageCounter = this.uploadedRc + this.uploadedCivilFront + this.uploadedCivilBack + this.uploadedCarImage_1 + this.uploadedCarImage_2 + this.uploadedCarImage_3 + this.uploadedCarImage_4 + this.uploadedCarImage_5 + this.uploadedCarImage_6 + this.uploadedCarVideo;

      }
    });
  }

  open(index): void {
    const src = this.getCarImageURL(index);
    const caption = this.carImagesfields[index];
    const thumb = src
    const album = {
      src,
      caption,
      thumb
    }
    if (index == 0 && this.uploadedCarImage_1 == 1) {
      this.lightbox.open([album], 0);
    } else if (index == 1 && this.uploadedCarImage_2 == 1) {
      this.lightbox.open([album], 0);
    } else if (index == 2 && this.uploadedCarImage_3 == 1) {
      this.lightbox.open([album], 0);
    } else if (index == 3 && this.uploadedCarImage_4 == 1) {
      this.lightbox.open([album], 0);
    } else if (index == 4 && this.uploadedCarImage_5 == 1) {
      this.lightbox.open([album], 0);
    } else if (index == 5 && this.uploadedCarImage_6 == 1) {
      this.lightbox.open([album], 0);
    } else {
      this.snackBar.open('Image is not uploaded.', 'CANCEL');
    }
  }
  openVideo(index): void {
    const src = this.getVideoURL(index);
    const caption = this.videoImg[index];
    const thumb = src
    const album = {
      src,
      caption,
      thumb
    }
    if (index == 0 && this.uploadedCarVideo == 1) {
      this.lightbox.open([album], 0);
    } else {
      this.snackBar.open('Video is not uploaded.', 'CANCEL');
    }
  }

  setCarImages(carImagesList): void {
    let tempCarImages: any = [];
    let index = 0;
    for (var carImage of carImagesList) {
      let image: any = {};
      let carImageName = this.carImagesfields[index];
      let carImageUrl = environment.cdnUrl + '/comprehensive-car-book/' + carImage.name + this.carReadToken;
      image.src = carImageUrl;
      image.caption = carImageName;
      // image.carIndex = carImageIndex;
      image.thumb = carImageUrl;

      tempCarImages.push(image);
      index++;
    }
    // if ( carImagesList.length < 6 ) {
    //   let image: any = {};
    //   image.src = null;
    //   image.caption = 'Add car image';
    //   image.carIndex = carImagesList.length + 1;

    //   tempCarImages.push(image);
    // }

    this.albumsCarImage = tempCarImages;
  }

  getImageURL(imageIndex): string {
    return imageIndex == 0 ? this.rcCivilImages.rCbookUrl : imageIndex == 1 ? this.rcCivilImages.civilCardFrontUrl : this.rcCivilImages.civilCardbackUrl;
  }

  getCarImageURL(index): string {
    return index == 0 ? this.rcCivilImages.carFrontUrl : index == 1 ? this.rcCivilImages.carBackUrl : index == 2 ? this.rcCivilImages.carLeftUrl : index == 3 ? this.rcCivilImages.carRightUrl : index == 4 ? this.rcCivilImages.carFrontWSUrl : this.rcCivilImages.carFrontChassisUrl
  }
  getVideoURL(index): string {
    return index == 0 ? this.rcCivilImages.carVideoUrl : 'Video not uploaded..'
  }
  getRCImageURL(): string {
    if (this.carDetails) {
      // let src =  environment.cdnUrl + "/" + environment.rcImageContainer + "/" + this.selectedQuote.vehicleId.toString() + this.SASTokenForRCImageRead;
      const src = environment.cdnUrl + "/" + environment.rcImageContainer + "/" + this.selectedQuote.vehicleId.toString() + this.SASTokenForRCImageRead;
      const caption = 'RC Book';
      const thumb = src;
      const album = {
        src,
        caption,
        thumb
      };
      this.albums.push = album;

      return src;
    }
    return '';
  }

  getCivilIdFrontImageURL(): string {
    if (this.carDetails) {
      // return environment.cdnUrl + "/" + environment.civilCardImageContainer + "/" + this.selectedQuote.vehicleId.toString() + this.storageService.civilCardFront + this.SASTokenForCivilIdFrontImageRead;

      const src = environment.cdnUrl + "/" + environment.civilCardImageContainer + "/" + this.selectedQuote.vehicleId.toString() + this.storageService.civilCardFront + this.SASTokenForCivilIdFrontImageRead;
      const caption = 'RC Book';
      const thumb = src;
      const album = {
        src,
        caption,
        thumb
      };
      this.albums.push = album;

      return src;
    }
    return '';
  }

  getCivilIdBackImageURL(): string {
    if (this.carDetails) {
      // return environment.cdnUrl + "/" + environment.civilCardImageContainer + "/" + this.selectedQuote.vehicleId.toString() + this.storageService.civilCardBack + this.SASTokenForCivilIdBackImageRead;

      const src = environment.cdnUrl + "/" + environment.civilCardImageContainer + "/" + this.selectedQuote.vehicleId.toString() + this.storageService.civilCardBack + this.SASTokenForCivilIdBackImageRead;
      const caption = 'RC Book';
      const thumb = src;
      const album = {
        src,
        caption,
        thumb
      };
      this.albums.push = album;

      return src;
    }
    return '';
  }

  //#endregion Image handling


  //To get language
  getLanguage(): string {
    return this.translateService.lang;
  }

}
