import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { TranslateService } from 'src/app/services/translate.service';
import { environment } from '../../environments/environment';
import { Helpers } from '../helper/helper';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  pathAPI = environment.apiUrl;
  constructor(protected helper: Helpers , private readonly translate: TranslateService) { }

  protected header() {

    let header = new HttpHeaders({ 'Content-Type': 'application/json' });
    header = header.append('apiKey', environment.apiKey);
    header = header.append('apiPassword', environment.apiPassword);
    header = header.append('accept-language', this.translate.lang);

    if (this.helper.isAuthenticated()) {
      header = header.append('Authorization', 'Bearer ' + this.helper.getToken());
    }

    return { headers: header};
  }

  public handleError(error: HttpErrorResponse | any) {
    let errMsg: any;
    if (error instanceof HttpErrorResponse) {
      const body = error.error || '';
      const err = body || JSON.stringify(body);
      errMsg = err.text;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    return throwError(errMsg);
  }
}
