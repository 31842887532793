<div class="page-content">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-6" *ngIf="isPaymentSuccessfully">
                <app-success [autoRedirect]="true" [orderId] = "orderId"></app-success>
            </div>
            <div *ngIf="!isPaymentSuccessfully" class="spinner-border text-primary m-1" role="status">
                <span class="sr-only">{{'Loading...' | translate}}</span>
            </div>
        </div>
    </div>
    <!-- container-fluid -->
</div>
<!-- End Page-content -->