import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { QuoteStatus } from 'src/app/models/quoteModel';
import { CustomerRequestDetailsModel } from 'src/app/models/requestModel';
import { QuoteService } from 'src/app/services/quote.service';
import { StorageService } from 'src/app/services/storage.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-quote-details',
  templateUrl: './quote-details.component.html',
  styleUrls: ['./quote-details.component.scss']
})
export class QuoteDetailsComponent implements OnInit {

  requestDetails: CustomerRequestDetailsModel;
  QuoteStatus = QuoteStatus;

  //#region Storage API

  SASTokenForRCImageRead: string;

  //#endregion

  constructor(private activatedRoute: ActivatedRoute,
    private quoteService: QuoteService,
    private storageService: StorageService) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(paramMap => {
      let vehicleId = paramMap.get("id");
      let requestId = paramMap.get("requestId");

      this.quoteService.getQuoteRequestByVehicleId(requestId).subscribe(summaryResponse => {
        if (summaryResponse) {
          this.requestDetails = Object.assign({}, summaryResponse);
        }
      }, error => { });

      this.storageService.getReadTokenForRCImage(vehicleId).subscribe(readTokenResponse => {
        this.SASTokenForRCImageRead = readTokenResponse.sasToken;
      }, error => { });
    }, error => { });
  }

  getRCImageURL(): string {
    if (this.SASTokenForRCImageRead) {
      return environment.cdnUrl + "/" + environment.rcImageContainer + "/" + this.requestDetails.vehicleId + this.SASTokenForRCImageRead;
    }
    return '';
  }

}
