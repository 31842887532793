import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-comprehensive-stepper',
  templateUrl: './comprehensive-stepper.component.html',
  styleUrls: ['./comprehensive-stepper.component.scss']
})
export class ComprehensiveStepperComponent implements OnInit {
  @Input() comprehensiveRequestStatus : number;
  @Input() currentPage : string;
  constructor() { }

  ngOnInit(): void {
  }

}
