<h3 mat-dialog-title>{{ 'Add Civil Card' | translate }}</h3>

<div class="row">
    <div class="col-12">
        <!-- Civil Card Number -->
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>{{'Civil Card Number' | translate}}</mat-label>
            <input type="text" matInput numeric numericType="number" minlength="12" maxlength="12" name="civilCardNumber" [(ngModel)]="cc.civilCardNumber" placeholder="{{'Civil Card Number' | translate }}" required>
        </mat-form-field>
    </div>
    <div class="col-6">
        <!-- Nationality -->
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>{{'Nationality' | translate}}</mat-label>
            <input type="text" matInput name="nationality" [(ngModel)]="cc.nationality" placeholder="{{'Nationality' | translate }}" required>
        </mat-form-field>
    </div>
    <div class="col-6">
        <!-- Expiry Date -->
        <mat-form-field color="accent" class="w-100" appearance="outline">
            <mat-label>{{'Expiry Date' | translate}}</mat-label>
            <input matInput [matDatepicker]="expiry" [(ngModel)]="cc.expiry">
            <mat-datepicker-toggle matSuffix [for]="expiry"></mat-datepicker-toggle>
            <mat-datepicker #expiry name="expiry"></mat-datepicker>
        </mat-form-field>
    </div>
    <div class="col-12 d-flex justify-content-end">
        <button class="btn btn-light mr-4 px-3" mat-dialog-close mat-dialog-close="false">{{'Cancel' | translate}}</button>
        <button type="submit" class="btn btn-primary waves-effect waves-light px-3" (click)="addCivilCard()" [disabled]="isValid()">{{'Add' | translate}}</button>
    </div>
</div>