import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Helpers } from '../helper/helper';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(private http: HttpClient,
    private helper: Helpers) { }

  public handleError(error: HttpErrorResponse | any) {
    let errMsg: any;
    console.log(error);

    if (error instanceof HttpErrorResponse) {
      const body = error.error || '';
      const err = body || JSON.stringify(body);
      errMsg = err.text;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }

    return throwError(errMsg);
  }


  public header(token?: string) {

    let header = new HttpHeaders({ 'Content-Type': 'application/json' });
    header = header.append('apiKey', environment.apiKey);
    header = header.append('apiPassword', environment.apiPassword);
    if ( token ) {
      header = header.append('Authorization', 'Bearer ' + token);
    } else {
      header = header.append('Authorization', 'Bearer ' + this.helper.getToken());
    }
    return { headers: header };
  }

  getSASTokenForContainer(tokenBody: any, token?: string): any {
    return this.http.post<any>(environment.apiUrl + 'Storage/GetReadOnlyToken', tokenBody, this.header(token)).pipe(catchError(this.handleError));
  }

  getUploadOnlyTokenForContainer(containerName: string, token?: string): any {
    return this.http.post<any>(environment.apiUrl + 'Storage/GetUploadOnlyTokenForContainer', { containerName }, this.header(token)).pipe(catchError(this.handleError));
  }

  updateDetails(data: any, token: string): Observable<any> {
    return this.http.post<any>(environment.apiUrl + 'Account/UpdateEmailTPLDetails', data, this.header(token)).pipe(catchError(this.handleError));
  }

  getInTouch(data: any): any {
    return this.http.post(environment.apiUrl + 'Account/GetInTouch', data, this.header()).pipe(catchError(this.handleError));
  }
}
