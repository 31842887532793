import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Helpers } from 'src/app/helper/helper';
import { UserModel, UserType } from 'src/app/models/userModel';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  userModel: UserModel;
  isInvalidCredentials: boolean;
  showLoginLoader: boolean;

  constructor(private router: Router,
    private loginService: LoginService,
    private helpers: Helpers) { }

  ngOnInit(): void {
    this.userModel = new UserModel();
    this.isInvalidCredentials = false;
    this.showLoginLoader = false;
    const userToken = this.helpers.getDecodedAccessToken(this.helpers.getToken());

    if (userToken) {
      this.router.navigate(['/dashboard-customer']);
    }
  }

  logInUser(formData): void {
    if ((this.userModel.email && this.userModel.email != "")
      && (this.userModel.password && this.userModel.password != "")) {
      const authValues = { email: this.userModel.email, password: this.userModel.password };

      this.showLoginLoader = true;
      this.loginService.verifyUser(authValues).subscribe(token => {
        this.showLoginLoader = false;
        this.isInvalidCredentials = false;
        this.helpers.setToken(token);
        this.helpers.setEmail(this.userModel.email);
        const userToken = this.helpers.getDecodedAccessToken(this.helpers.getToken());
        this.helpers.setId(userToken["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"]);
        if (userToken.type == UserType.CUSTOMER) {
          this.router.navigate(['/dashboard-customer']);
        } else if (userToken.type == UserType.ADMIN || userToken.type == UserType.INSURER) {
          this.router.navigate(['/dashboard']);
        } else {
          this.router.navigate(['/dashboard']);
        }
      },
        error => {
          this.showLoginLoader = false;
          this.isInvalidCredentials = true;
        });
    }
  }

}
