import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Helpers } from '../helper/helper';
import { TranslateService } from '../services/translate.service';

@Injectable({
  providedIn: 'root'
})
export class CatalogService {

  constructor(private http: HttpClient,
    private translateService: TranslateService,
    private helper: Helpers) { }

  public handleError(error: HttpErrorResponse | any) {
    let errMsg: any;

    if (error instanceof HttpErrorResponse) {
      const body = error.error || '';
      const err = body || JSON.stringify(body);
      errMsg = err.text;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    return throwError(errMsg);
  }

  public header(lang?: string, token?: string) {

    let header = new HttpHeaders({ 'Content-Type': 'application/json' });
    header = header.append('apiKey', environment.apiKey);
    header = header.append('apiPassword', environment.apiPassword);
    header = header.set('Accept-Language', lang ? lang : 'en')
    if (token) {
      header = header.append('Authorization', 'Bearer ' + token);
    } else {
      header = header.append('Authorization', 'Bearer ' + this.helper.getToken());
    }
    return { headers: header };
  }

  getCarMakes(lang?: string): Observable<any> {
    return this.http.get<any>(environment.apiUrl + `Catalog/GetMake?Lang=${lang ?? 'en'}`, this.header(lang)).pipe(
      catchError(this.handleError)
    );
  }

  getCarModels(carMake: string, lang?: string): Observable<any> {
    return this.http.get<any>(environment.apiUrl + `Catalog/GetModels?Make=${carMake}&Lang=${lang ?? 'en'}`, this.header(lang)).pipe(
      catchError(this.handleError)
    );
  }

  getCarTrim(carMake: string, carModel: string, lang?: string): Observable<any> {
    return this.http.get<any>(environment.apiUrl + `Catalog/GetTrim?Make=${carMake}&Model=${carModel}&Lang=${lang ?? 'en'}`, this.header(lang)).pipe(
      catchError(this.handleError)
    );
  }

  getPassengerCount(carMake: string, carModel: string, carTrim: string): Observable<any> {
    return this.http.get<any>(environment.apiUrl + 'Catalog/GetPassengerCount?Make=' + carMake + '&Model=' + carModel + '&Trim=' + carTrim, this.header(null)).pipe(
      catchError(this.handleError)
    );
  }
}
