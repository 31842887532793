<div class="page-content">
  <div class="container-fluid">
    <app-comprehensive-stepper [currentPage]="currentPage"></app-comprehensive-stepper>
    <app-quote-comparision *ngIf="currentStep == Steps.QUOTES" [carDetails]="fetchedCarDetails"
      (onProceed)="showUploadImages($event)"></app-quote-comparision>

    <!-- New User information Design -->
    <!-- Upload Images -->
    <div class="col-12 upload-image-container p-5 mb-5" *ngIf="currentStep == Steps.USER_INFO">
      <div class="d-flex justify-content-between py-3">
        <div class="upload-images text-left">
          <!-- {{'Upload Images' | translate}} --> {{'Upload images here or download the app to upload from
          your phone' | translate}}
        </div>
        <div class="uploaded-count text-right"> {{'Uploaded' | translate}}: {{ uploadedImageCounter }} / 10
        </div>
      </div>
      <form #userDetailsForm2="ngForm" (ngSubmit)="proceedToNextPage()">
        <div class="row">
          <div class="choose-file-section col-5 py-3 ml-3" style="max-width: 50% !important;">
            <ol class="images-list">
              <div class="d-flex justify-content-between mb-2 align-items-center">
                <div>
                  <li class="registraton-card images-fields">
                    <label>{{'Registration Card' | translate}}</label>
                  </li>
                </div>
                <div class="">
                  <label for="register-card" class="custom-file-upload">
                    <input type="file" name="register-card" id="register-card" (change)="onSelect($event)"
                      accept="image/*" [ngClass]="uploadedRc == 1 ? 'custom-input-upload' : 'custom-input' ">
                  </label>
                </div>
              </div>
              <div class="d-flex justify-content-between mb-2">
                <div>
                  <li class="civil-ID-front images-fields">
                    <label>{{'Civil ID Front Image' | translate}}</label>
                  </li>
                </div>
                <div class="">
                  <label for="civil-ID-front" class="custom-file-upload">
                    <input type="file" name="civil-ID-front" id="civil-ID-front" accept="image/*"
                      [ngClass]="uploadedCivilFront == 1 ? 'custom-input-upload' : 'custom-input' "
                      (change)="onSelect($event)">
                  </label>
                </div>
              </div>
              <div class="d-flex justify-content-between mb-2">
                <div>
                  <li class="civil-ID-black images-fields">
                    <label>{{'Civil ID Back Image' | translate}}</label>
                  </li>
                </div>
                <div class="">
                  <label for="civil-ID-back" class="custom-file-upload">
                    <input type="file" name="civil-ID-back" id="civil-ID-back" accept="image/*"
                      [ngClass]="uploadedCivilBack == 1 ? 'custom-input-upload' : 'custom-input' "
                      (change)="onSelect($event)">
                  </label>
                </div>
              </div>
              <div>
                <li class="car-images images-fields"
                  [ngStyle]="{'text-align': ((getLanguage() == 'en') ? 'left' : 'right')}">
                  <label>{{'Car Images (with Date, Time & Location)' | translate}}</label>
                  <!-- <ol type="a" style="padding-left: 10px;">
                    <div class="d-flex justify-content-between mb-2 align-items-center"
                      *ngFor="let document of carImagesfields; let paramIndex = index">
                      <div>
                        <li class="front-image images-fields">
                          <label>{{ document | translate }}</label>
                        </li>
                      </div>
                      <div class="">
                        <label for="{{ 'file' + document.replace(' ','') }}" class="custom-file-upload">
                          <input
                            type="file" name="{{'document'}}" accept="image/*" id="{{ 'file' +document.replace(' ','') }}"
                            [ngClass]="uploadedCarImages[paramIndex] == 1 ? 'custom-input-upload' : 'custom-input' "
                            (change)="uploadCarImage($event, paramIndex + 1)">
                        </label>
                      </div>
                    </div>
                  </ol> -->
                </li>
              </div>
              <ol type="a">
                <div class="d-flex justify-content-between mb-2">
                  <div>
                    <li class="front-img images-fields">
                      <label>{{'Front Image' | translate}}</label>
                    </li>
                  </div>
                  <div class="">
                    <label for="front-img" class="custom-file-upload">
                      <input type="file" name="front-img" id="front-img" accept="image/*"
                        [ngClass]="uploadedCarImage_1 == 1 ? 'custom-input-upload' : 'custom-input' "
                        (change)="onSelect($event)">
                    </label>
                  </div>
                </div>
                <div class="d-flex justify-content-between mb-2">
                  <div>
                    <li class="back-img images-fields">
                      <label>{{'Back Image' | translate}}</label>
                    </li>
                  </div>
                  <div class="">
                    <label for="back-img" class="custom-file-upload">
                      <input type="file" name="back-img" id="back-img" accept="image/*"
                        [ngClass]="uploadedCarImage_2 == 1 ? 'custom-input-upload' : 'custom-input' "
                        (change)="onSelect($event)">
                    </label>
                  </div>
                </div>
                <div class="d-flex justify-content-between mb-2">
                  <div>
                    <li class="ls-img images-fields">
                      <label>{{'Left Side Image' | translate}}</label>
                    </li>
                  </div>
                  <div class="">
                    <label for="ls-img" class="custom-file-upload">
                      <input type="file" name="ls-img" id="ls-img" accept="image/*"
                        [ngClass]="uploadedCarImage_3 == 1 ? 'custom-input-upload' : 'custom-input' "
                        (change)="onSelect($event)">
                    </label>
                  </div>
                </div>
                <div class="d-flex justify-content-between mb-2">
                  <div>
                    <li class="rs-img images-fields">
                      <label>{{'Right Side Image' | translate}}</label>
                    </li>
                  </div>
                  <div class="">
                    <label for="rs-img" class="custom-file-upload">
                      <input type="file" name="rs-img" id="rs-img" accept="image/*"
                        [ngClass]="uploadedCarImage_4 == 1 ? 'custom-input-upload' : 'custom-input' "
                        (change)="onSelect($event)">
                    </label>
                  </div>
                </div>
                <div class="d-flex justify-content-between mb-2">
                  <div>
                    <li class="fw-img images-fields">
                      <label>{{'Front Windshield Image' | translate}}</label>
                    </li>
                  </div>
                  <div class="">
                    <label for="fw-img" class="custom-file-upload">
                      <input type="file" name="fw-img" id="fw-img" accept="image/*"
                        [ngClass]="uploadedCarImage_5 == 1 ? 'custom-input-upload' : 'custom-input' "
                        (change)="onSelect($event)">
                    </label>
                  </div>
                </div>
                <div class="d-flex justify-content-between mb-2">
                  <div>
                    <li class="chassis-num images-fields">
                      <label>{{'Chassis Number under the Front Windshield' | translate}}</label>
                    </li>
                  </div>
                  <div class="">
                    <label for="chassis-num" class="custom-file-upload">
                      <input type="file" name="chassis-num" id="chassis-num" accept="image/*"
                        [ngClass]="uploadedCarImage_6 == 1 ? 'custom-input-upload' : 'custom-input' "
                        (change)="onSelect($event)">
                    </label>
                  </div>
                </div>
              </ol>




              <div class="d-flex justify-content-between mb-2">
                <div>
                  <li class="video-car images-fields"
                    [ngStyle]="{'text-align': ((getLanguage() == 'en') ? 'left' : 'right')}">
                    <label>{{'Video of the Car' | translate}}</label>
                  </li>
                </div>
                <div class="">
                  <label for="video-car" class="custom-file-upload">
                    <input type="file" name="video-car" id="video-car" accept="video/*"
                      [ngClass]="uploadedCarVideo == 1 ? 'custom-input-upload' : 'custom-input' "
                      (change)="onSelect($event)">
                  </label>
                </div>
              </div>
            </ol>
          </div>
          <div class="OR-part col-2 d-flex align-self-center justify-content-around" style="max-width: 9% !important;">
            <div>
              <label class="or-label"> {{'-OR-' | translate}} </label>
            </div>
          </div>
          <div class="QR-section col-5 pt-0 mr-3" style="max-width: 41% !important;">
            <div class="my-4 text-center download-text" style="height: 24px;">
              <!-- {{'Download the App to upload car images' | translate}} -->
            </div>
            <div class="my-auto">
              <div class="users-text" style="font-size: 20px;margin-top: 10px;"> {{'Scan QR code below and download
                Saiyarti
                app'|translate}}<br> {{'to take a picture of
                your car'|translate}}<br> {{'with Time, Date and Location' | translate}}
              </div>
              <div class="row">
                <div class="col-12 mt-3" style="text-align: center;">
                  <!-- <ngx-qrcode [elementType]="elementType" [errorCorrectionLevel]="correctionLevel"
                                        [value]="shortURL" cssClass="bshadow qrCss">
                                    </ngx-qrcode> -->
                  <qr-code [value]="shortURL" size="300" errorCorrectionLevel="M"></qr-code>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="back-proceed d-flex justify-content-between mt-5">
          <div class="back-btn">
            <button type="button" class="btn" (click)="back()">{{'Back' | translate}}</button>
          </div>
          <div class="image-uploaded">
            <!-- <span class="mr-3">
                            {{ uploadedImageCounter }} / 9 Images Uploaded
                        </span> -->
            <button type="submit" class="btn" data-toggle="modal" data-target="#imageUploadModal">{{'Proceed
              to Submit Quote' | translate}}</button>
          </div>
        </div>
      </form>
    </div>

    <!-- view summary and confirmation -->
    <div class="col-12" *ngIf="currentStep == Steps.CONFIRMATION">
      <app-quote-summary [carDetails]="fetchedCarDetails" [uploadedimages]="uploadedImageCounter"
        [rateCardSelections]="rateCardSelection" [selectedQuote]="selectedRateCard" [selectedFee]="selectedFee"
        [selectedLicense]="selectedLicense" [selectedLicenseAr]="selectedLicenseAr"
        [fetchedCarDetails]="fetchedCarDetails" [isPaymentShowForTpl]=true [isPaymentShowForComprehensive]=false
        [isSubmitted]=false [isPaymentDone]=false [isQuoteSummary]=false (editEvent)="edit($event)"
        (backEvent)="backEvent()">
      </app-quote-summary>
    </div>
    <!-- end view summary and confirmation -->
    <!-- Success -->
    <div class="col-6" *ngIf="currentStep == Steps.SUCCESS">
      <app-success></app-success>
    </div>
    <!-- end Success-->
  </div> <!-- container-fluid -->
</div>
<!-- End Page-content -->
<div class="modal fade" id="imageUploadModal" tabindex="-1" role="dialog" aria-labelledby="imageUploadModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body" *ngIf="uploadedImageCounter == 0"> {{ 'You have not uploaded any image, are you sure
        you want to continue?' | translate }} </div>
      <div class="modal-body" *ngIf="uploadedImageCounter != 0"> {{'You have uploaded' | translate}} {{
        uploadedImageCounter }} {{'/ 10 images, are you sure you want to continue?' | translate }} </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ 'Close' | translate }}</button>
        <button type="button" class="btn btn-primary" (click)="activate(Steps.CONFIRMATION)" data-dismiss="modal">{{
          'Yes' | translate }}</button>
      </div>
    </div>
  </div>
</div>
