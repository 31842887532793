import { Component, OnInit } from '@angular/core';
import { MatDialogRef as MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar as MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-add-civil-card-dialog',
  templateUrl: './add-civil-card-dialog.component.html',
  styleUrls: ['./add-civil-card-dialog.component.scss']
})
export class AddCivilCardDialogComponent implements OnInit {

  cc: {
    civilCardNumber?: number,
    nationality?: string,
    expiry?: Date
  } = {};
  constructor(private dialogRef: MatDialogRef<AddCivilCardDialogComponent>,
    private snackBar: MatSnackBar) { }

  ngOnInit(): void {
  }

  addCivilCard(): void {
    if (this.cc.civilCardNumber && this.cc.nationality && this.cc.nationality != '' && this.cc.expiry) {
      this.dialogRef.close({ civilCardNumber: this.cc.civilCardNumber, nationality: this.cc.nationality, expiry: this.cc.expiry });
    }
  }

  isValid(): boolean {
    if (this.cc.civilCardNumber && this.cc.nationality && this.cc.nationality != '' && this.cc.expiry) {
      return false;
    }
    return true;
  }

}
