import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Helpers } from 'src/app/helper/helper';
import { CarDetails, VehicleSummary } from 'src/app/models/carModel';
import { QuoteStatus } from 'src/app/models/quoteModel';
import { CustomerRequestModel } from 'src/app/models/requestModel';
import { QuoteService } from 'src/app/services/quote.service';
import { VehicleService } from 'src/app/services/vehicle.service';

@Component({
  selector: 'app-quotes',
  templateUrl: './quotes.component.html',
  styleUrls: ['./quotes.component.scss']
})
export class QuotesComponent implements OnInit {

  QuoteStatus = QuoteStatus;
  allRequest: CustomerRequestModel[];
  carDetails: CarDetails;
  showGetRequestLoader: boolean = false;
  displayedColumn: string[] = [
    "Submission Date",
    "Expiry Date",
    "Car Make",
    "Insurer",
    "Quote Amount",
    "Status",
    ""
  ];

  submittedQuote: any;
  isQuotePaymentDone: boolean;
  isPaymentSuccessfully: boolean;

  constructor(private quoteService: QuoteService,
    private vehicleService: VehicleService,
    private router: Router,
    private helper: Helpers) { }

  ngOnInit(): void {
    this.isPaymentSuccessfully = false;
    this.showGetRequestLoader = true;
    this.quoteService.getAllQuoteRequest().subscribe((requestResponse: CustomerRequestModel[]) => {
      if (requestResponse.length > 0) {
        this.allRequest = requestResponse;
      }
      this.showGetRequestLoader = false;
    }, error => {
      this.showGetRequestLoader = false;
    });
  }

  proceedForPayment(request: CustomerRequestModel): void {
    if (request) {
      this.router.navigate(['/make-payment', request.vehicleId, { token: this.helper.getToken() }]);
    }
  }

  waitingForApproval(request: CustomerRequestModel): void {
    if (request) {
      this.vehicleService.getSummaryByVehicleId(request.vehicleId).subscribe((summaryResponse: VehicleSummary) => {
        this.submittedQuote = summaryResponse.rateCard;
        this.submittedQuote.insurerName = summaryResponse.insuranceCompanyName;
        this.carDetails = new CarDetails(summaryResponse);
        this.isQuotePaymentDone = true;
      }, error => { });
    }
  }

  proceedToAddQuote(request: CustomerRequestModel): void {
    this.router.navigate(['/vehicle', request.vehicleId, 'quote']);
  }

  redirected($event): void {
    this.isPaymentSuccessfully = false;
  }

  back(): void {
    this.isQuotePaymentDone = false;
  }

  orderDetails(request: CustomerRequestModel) {
    if (request && request.vehicleId) {
      this.router.navigate(['/orders', request.vehicleId])
    }
  }

  quoteDetails(request: CustomerRequestModel) {
    if (request && request.vehicleId) {
      this.router.navigate(['/quotes', request.vehicleId, { requestId: request.id }]);
    }
  }

}
