import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Helpers } from '../helper/helper';
import { BaseService } from './base.service';
import { TranslateService } from 'src/app/services/translate.service';
import { CarDetails } from '../models/carModel';

@Injectable({
  providedIn: 'root'
})
export class VehicleService extends BaseService {

  constructor(helper: Helpers, private http: HttpClient, translate: TranslateService) {
    super(helper, translate);
  }

  addCar(body: any): Observable<any> {
    return this.http.post(this.pathAPI + 'Vehicle/AddCar', body, super.header()).pipe(
      catchError(super.handleError)
    );
  }

  getCars(): Observable<any> {
    return this.http.get(this.pathAPI + 'Vehicle/GetCars', super.header()).pipe(
      catchError(super.handleError)
    );
  }

  editCar(id: string, body: any): Observable<any> {
    return this.http.put(this.pathAPI + 'Vehicle/EditCar/' + id, body, super.header()).pipe(
      catchError(super.handleError)
    );
  }

  getCarById(id: string): Observable<CarDetails> {
    return this.http.get<CarDetails>(this.pathAPI + 'Vehicle/GetCar/' + id, super.header()).pipe(
      catchError(super.handleError)
    );
  }

  getIDVValue(body: any): Observable<any> {
    return this.http.post(this.pathAPI + 'Vehicle/IDVValue', body, super.header()).pipe(
      catchError(super.handleError)
    );
  }

  getSummaryByVehicleId(vehicleId: string): Observable<any> {
    return this.http.get(this.pathAPI + "Vehicle/Summary/" + vehicleId, super.header()).pipe(
      catchError(super.handleError)
    );
  }

  addEmailOrAddress(body: any, carId: string): Observable<any> {
    return this.http.post(this.pathAPI + "Vehicle/Confirmation/" + carId, body, super.header()).pipe(
      catchError(super.handleError)
    );
  }

  getAllOrders(): Observable<any> {
    return this.http.get(this.pathAPI + "Vehicle/AllOrders", super.header()).pipe(
      catchError(super.handleError)
    );
  }

  getVehicleHelp(body: any): Observable<any> {
    return this.http.post(this.pathAPI + "Vehicle/Help", body, super.header()).pipe(
      catchError(super.handleError)
    );
  }

}
