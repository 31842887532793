import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CarDetails, CarImages, QuoteSummary } from 'src/app/models/carModel';
import { PlanStatus, PolicyDetailsModel, QuoteStatus } from 'src/app/models/quoteModel';
import { QuoteService } from 'src/app/services/quote.service';
import { StorageService } from 'src/app/services/storage.service';
import { environment } from 'src/environments/environment';
import { VehicleService } from 'src/app/services/vehicle.service';
import { RateCard } from 'src/app/models/rate-card';

@Component({
  selector: 'app-vehicle-details',
  templateUrl: './vehicle-details.component.html',
  styleUrls: ['./vehicle-details.component.scss']
})
export class VehicleDetailsComponent implements OnInit {

  QuoteStatus = QuoteStatus;
  vehicleDetails: CarDetails;
  // policyDetails: PolicyDetailsModel;
  UnSubmittedQuotes: Array<UnSubmittedQuoteModel>;
  carImages: CarImages[];
  activeCarImage: string;
  requestDetails: any;

  currentQuote: RateCard;

  //#region Storage API

  SASTokenForCarImageRead: string;
  SASTokenForRCImageRead: string;
  SASTokenForCivilIdFrontImageRead: string;
  SASTokenForCivilIdBackImageRead: string;
  vehicleId = '';

  //#endregion

  constructor(private activatedRoute: ActivatedRoute,
    private quoteService: QuoteService,
    private _vehicleService: VehicleService,
    private router: Router,
    private storageService: StorageService) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(paramMap => {
      this.vehicleId = paramMap.get("id");
      this.getQuoteDetails();

      // this.quoteService.getQuoteSummaryForVehicle(this.vehicleId).subscribe((summaryResponse: QuoteSummary) => {
      //   console.log(summaryResponse);
      //   if (summaryResponse) {
      //     this.handleVehicleDetailsResponse(summaryResponse);

      //     if (summaryResponse.rateCardSummary.length > 0) {
      //       this.handlePolicyDetailsResponse(summaryResponse.rateCardSummary)
      //     } else {
      //       this.handlePolicyDetailsResponse(null);
      //     }
      //   }
      // }, error => {
      //   //handle error
      // });

      this.quoteService.getCarImagesByVehicleId(this.vehicleId).subscribe((carImageResponse: CarImages[]) => {
        if (carImageResponse) {
          this.carImages = carImageResponse
          if (this.carImages.length > 0) {
            this.activeCarImage = this.carImages[0].name;
          }
        }
      }, error => { });

      //#region Token to read image files

      this.storageService.getReadTokenForCarImage().subscribe(readTokenResponse => {
        this.SASTokenForCarImageRead = readTokenResponse.sasToken;
      }, error => { });

      this.storageService.getReadTokenForRCImage(this.vehicleId).subscribe(readTokenResposne => {
        this.SASTokenForRCImageRead = readTokenResposne.sasToken;
      }, error => { });

      this.storageService.getReadTokenForCivilImage(this.vehicleId + this.storageService.civilCardFront).subscribe(readTokenResposne => {
        this.SASTokenForCivilIdFrontImageRead = readTokenResposne.sasToken;
      }, error => { });

      this.storageService.getReadTokenForCivilImage(this.vehicleId + this.storageService.civilCardBack).subscribe(readTokenResposne => {
        this.SASTokenForCivilIdBackImageRead = readTokenResposne.sasToken;
      }, error => { });

      //#endregion
    }, error => { });
  }

  // handleVehicleDetailsResponse(summaryResponse: QuoteSummary): void {
  //   if (summaryResponse) {
  //     this.vehicleDetails = new CarDetails(summaryResponse);
  //   }
  // }

  // handlePolicyDetailsResponse(comprehensiveQuote: RateCard[]): void {
  //   if (comprehensiveQuote) {
  //     this.UnSubmittedQuotes = new Array<UnSubmittedQuoteModel>();
  //     comprehensiveQuote.forEach(quote => {
  //       if (quote.isConfirmed) {
  //         this.vehicleDetails.status = quote.status;
  //         this.policyDetails = new PolicyDetailsModel(quote.insurer.name, 1, quote.amount, quote.createdAt, quote.validity, 1, quote.status);
  //       } else {
  //         this.UnSubmittedQuotes.push(new UnSubmittedQuoteModel(quote.insurer.name, quote.amount, quote.validity));
  //       }
  //     });
  //   }
  // }

  proceedToSubmit(): void {
    this.router.navigate(["/vehicle", this.vehicleDetails.id, 'quote']);
  }

  getRCImageURL(): string {
    return environment.cdnUrl + "/" + environment.rcImageContainer + "/" + this.vehicleDetails.id + this.SASTokenForRCImageRead;
  }

  getCivilIdFrontImageURL(): string {
    return environment.cdnUrl + "/" + environment.civilCardImageContainer + "/" + this.vehicleDetails.id + this.storageService.civilCardFront + this.SASTokenForCivilIdFrontImageRead;
  }

  getCivilIdBackImageURL(): string {
    return environment.cdnUrl + "/" + environment.civilCardImageContainer + "/" + this.vehicleDetails.id + this.storageService.civilCardBack + this.SASTokenForCivilIdBackImageRead;
  }

  getCarImageURL(carImage: CarImages): string {
    return environment.cdnUrl + "/" + environment.carImageContainer + "/" + carImage.name + this.SASTokenForCarImageRead;
  }

  downloadQuotePDF(): void {
    // let pdfData = document.getElementById('quotePDF');
    // html2canvas(pdfData).then(canvas => {

    // let fileWidth = 110;
    // let fileHeight = canvas.height * fileWidth / canvas.width;

    // const fileURI = canvas.toDataURL('image/png')
    // let PDF = new jsPDF('p', 'mm', 'a4');
    // let position = 0;
    // PDF.addImage(fileURI, 'PNG', 0, position, fileWidth, fileHeight)

    // PDF.save('quote_summary.pdf');
    // });
  }


  private getQuoteDetails() {
    this._vehicleService.getSummaryByVehicleId(this.vehicleId).subscribe((requestDetails: any) => {
      this.vehicleDetails = requestDetails;
      // Get Quote
      this.quoteService.getQuoteById(this.vehicleDetails.rateCardId).subscribe(quote => {
        this.currentQuote = quote;
      }, error => { });
    })
  }

  getRateCardValue(rateCard: RateCard): any {
    let bRetVal = "";

    // TODO: Check what is needed

    // if ( rateCard && rateCard.subLimit ) {
    //   let selectedSubLimit = rateCard.subLimit.find((sublimit) => sublimit.isSelected );
    //   if ( selectedSubLimit ) {

    //     bRetVal = selectedSubLimit.name ;
    //   }
    // }
    return bRetVal;
  }

}

class UnSubmittedQuoteModel {
  insuranceCompanyName: string;
  expiryDate: Date;
  premiumAmount: number;
  quoteStatus: PlanStatus;

  constructor(insuranceCompanyName?: string, premiumAmount?: number, expiryDate?: Date, quoteStatus?: PlanStatus) {
    this.insuranceCompanyName = insuranceCompanyName,
      this.expiryDate = expiryDate,
      this.premiumAmount = premiumAmount,
      this.quoteStatus = quoteStatus;
  }
}
