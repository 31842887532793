import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import jwt_decode from 'jwt-decode';
import { UserType } from '../models/userModel';

@Injectable({
    providedIn: 'root'
})

export class Helpers {
    private authenticationChanged = new Subject<boolean>();

    constructor() {
    }

    getDecodedAccessToken(token: string): any {
        try {
            return jwt_decode(token);
        } catch (Error) {
            return null;
        }
    }

    public isAuthenticated(): boolean {
        return (!(window.localStorage.token === undefined ||
            window.localStorage.token === null ||
            window.localStorage.token === 'null' ||
            window.localStorage.token === 'undefined' ||
            window.localStorage.token === ''));
    }

    public isAuthenticationChanged(): any {
        return this.authenticationChanged.asObservable();
    }

    public getToken(): any {
        if (window.localStorage.token === undefined ||
            window.localStorage.token === null ||
            window.localStorage.token === 'null' ||
            window.localStorage.token === 'undefined' ||
            window.localStorage.token === '') {
            return '';
        }

        const obj = JSON.parse(window.localStorage.token);
        return obj.token;
    }

    public getHasPassword(): any {
        if (window.localStorage.hasPassword === undefined ||
            window.localStorage.hasPassword === null ||
            window.localStorage.hasPassword === 'null' ||
            window.localStorage.hasPassword === 'undefined' ||
            window.localStorage.hasPassword === '') {
            return '';
        }

        return window.localStorage.hasPassword;
    }

    public setToken(data: any): void {
        this.setStorageToken(JSON.stringify(data));
    }

    public setHasPassword(data: any): void {
        this.setStorageHasPassword(data);
    }

    public setStorageHasPassword(value: any): void {
        window.localStorage.hasPassword = value;
    }

    public setNewCustomer(data: boolean): void {
        this.setStorageNewCustomer(data);
    }

    public setStorageNewCustomer(value: boolean): void {
        window.localStorage.newCustomer = value;
    }

    public failToken(): void {
        this.setStorageToken(undefined);
    }

    public logout(): void {
        window.localStorage.email = undefined;
        this.setStorageToken(undefined);
    }

    private setStorageToken(value: any): void {
        window.localStorage.token = value;
        this.authenticationChanged.next(this.isAuthenticated());
    }

    public setEmail(value: any): void {
        window.localStorage.email = value;
    }

    public getEmail(): string {
        return window.localStorage.email;
    }

    public setName(value: any): void {
        window.localStorage.name = value;
    }

    public getName(): string {
        return window.localStorage.name;
    }

    public setId(value: any): void {
        window.localStorage.id = value;
    }

    public getId(): string {
        return window.localStorage.id;
    }

    public isCustomer(): boolean {
        let userObject = this.getDecodedAccessToken(this.getToken());
        return userObject && userObject.type == UserType.CUSTOMER;
    }

    public isAdmin(): boolean {
        let userObject = this.getDecodedAccessToken(this.getToken());
        return userObject && userObject.type == UserType.ADMIN;
    }

    public isInsurer(): boolean {
        let userObject = this.getDecodedAccessToken(this.getToken());
        return userObject && userObject.type == UserType.INSURER;
    }
}