<!-- Start Heder Area --> 
<header class="header-area fixed-top">
    <div class="top-header-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="header-content-left">
                        <p>Welcome To Surety Insurance!</p>
                    </div>
                </div>

                <div class="col-lg-6">
                    <ul class="header-content-right">
                        <li>
                            <a href="tel:+822456974">
                                <i class="bx bx-phone-call"></i>
                                Call Us For Inquiry: +822456974
                            </a>
                        </li>

                        <li>
                            <a href="mailto:hello@surety.com">
                                <i class="bx bx-envelope"></i>
                                Email: hello@surety.com
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <!-- Start Navbar Area -->
    <div class="nav-area">
        <div class="navbar-area">
            <!-- Menu For Mobile Device -->
            <div class="mobile-nav">
                <a routerLink="/home" class="logo">
                    <img src="assets/img/logo.png" alt="Logo">
                </a>
            </div>

            <!-- Menu For Desktop Device -->
            <div class="main-nav">
                <nav class="navbar navbar-expand-md navbar-light">
                    <div class="container">
                        <a class="navbar-brand" routerLink="/home">
                            <img src="assets/img/home-one-logo.png" alt="Logo">
                        </a>
                        
                        <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                            <ul class="navbar-nav m-auto">
                                <li class="nav-item">
                                    <a routerLink="/home" class="nav-link active">Home</a>
                                </li>

                                <li class="nav-item">
                                    <a href="about.html" class="nav-link">About</a>
                                </li>

                                <li class="nav-item">
                                    <a href="#" class="nav-link dropdown-toggle">
                                        Pages
                                        <i class="bx bx-plus"></i>
                                    </a>

                                    <ul class="dropdown-menu">
                                        <li class="nav-item">
                                            <a href="testimonials.html" class="nav-link">Testimonials</a>
                                        </li>

                                        <li class="nav-item">
                                            <a href="search-result.html" class="nav-link">Search Result</a>
                                        </li>

                                        <li class="nav-item">
                                            <a href="team.html" class="nav-link">Team</a>
                                        </li>

                                        <li class="nav-item">
                                            <a href="faq.html" class="nav-link">FAQ</a>
                                        </li>

                                        <li class="nav-item">
                                            <a href="terms-conditions.html" class="nav-link">Terms & Conditions</a>
                                        </li>

                                        <li class="nav-item">
                                            <a href="privacy-policy.html" class="nav-link">Privacy Policy</a>
                                        </li>

                                        <li class="nav-item">
                                            <a href="#" class="nav-link dropdown-toggle">
                                                User
                                                <i class="bx bx-plus"></i>
                                            </a>

                                            <ul class="dropdown-menu">
                                                <li>
                                                    <a [routerLink]="['/partner-login']">Log In as Admin</a>
                                                </li>
                                                <li>
                                                    <a [routerLink]="['/customer-login']">Log In as Customer</a>
                                                </li>
                                                <li class="nav-item">
                                                    <a href="sign-in.html" class="nav-link">Sign In</a>
                                                </li>
                                                <li class="nav-item">
                                                    <a href="recover-password.html" class="nav-link">Recover Password</a>
                                                </li>
                                            </ul>
                                        </li>

                                        <li class="nav-item">
                                            <a href="coming-soon.html" class="nav-link">Coming Soon</a>
                                        </li>

                                        <li class="nav-item">
                                            <a href="404.html" class="nav-link">404 Error Page</a>
                                        </li>
                                    </ul>
                                </li>

                                <li class="nav-item">
                                    <a href="#" class="nav-link dropdown-toggle">
                                        Insurance
                                        <i class="bx bx-plus"></i>
                                    </a>

                                    <ul class="dropdown-menu">
                                        <li class="nav-item">
                                            <a href="travel-insurance.html" class="nav-link">Travel Insurance</a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="business-insurance.html" class="nav-link">Business Insurance</a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="home-insurance.html" class="nav-link">Health Insurance</a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="car-insurance.html" class="nav-link">Car Insurance</a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="insurance-details.html" class="nav-link">Insurance Details</a>
                                        </li>
                                    </ul>
                                </li>

                                <li class="nav-item">
                                    <a href="#" class="nav-link dropdown-toggle">
                                        Portfolio
                                        <i class="bx bx-plus"></i>
                                    </a>

                                    <ul class="dropdown-menu">
                                        <li class="nav-item">
                                            <a href="portfolio-columns-two.html" class="nav-link">Portfolio Columns Two</a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="portfolio-columns-three.html" class="nav-link">Portfolio Columns Three</a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="portfolio-columns-four.html" class="nav-link">Portfolio Columns Four</a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="portfolio-light-box.html" class="nav-link">Portfolio Light Box</a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="portfolio-masonry.html" class="nav-link">Portfolio Masonry</a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="portfolio-details.html" class="nav-link">Portfolio Details</a>
                                        </li>
                                    </ul>
                                </li>

                                <li class="nav-item">
                                    <a href="#" class="nav-link dropdown-toggle">
                                        Blog
                                        <i class="bx bx-plus"></i>
                                    </a>

                                    <ul class="dropdown-menu">
                                        <li class="nav-item">
                                            <a href="blog-grid.html" class="nav-link">Blog Grid</a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="blog-columns-two.html" class="nav-link">Blog Columns Two</a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="blog-left-sidebar.html" class="nav-link">Blog Left Sidebar</a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="blog-right-sidebar.html" class="nav-link">Blog Right Sidebar</a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="blog-details.html" class="nav-link">Blog Details</a>
                                        </li>
                                    </ul>
                                </li>

                                <li class="nav-item">
                                    <a href="contact.html" class="nav-link">Contact</a>
                                </li>
                            </ul>
                            
                            <!-- Start Other Option -->
                            <div class="others-option">
                                <div class="option-item">
                                    <i class="search-btn bx bx-search"></i>
                                    <i class="close-btn bx bx-x"></i>
                                    
                                    <div class="search-overlay search-popup">
                                        <div class='search-box'>
                                            <form class="search-form">
                                                <input class="search-input" name="search" placeholder="Search" type="text">

                                                <button class="search-button" type="submit"><i class="bx bx-search"></i></button>
                                            </form>
                                        </div>
                                    </div>
                                </div>

                                <div class="subscribe">
                                    <a href="#" class="default-btn">
                                        Get A Quote
                                    </a>
                                </div>

                                <div class="sidebar-menu">
                                    <a href="#" data-toggle="modal" data-target="#myModal2">
                                        <i class="bx bx-grid-alt"></i>
                                    </a>
                                </div>
                            </div>
                            <!-- End Other Option -->
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    </div>
    <!-- End Navbar Area -->
</header>
<!-- End Heder Area -->

<!-- Start Sidebar Modal -->
<div class="sidebar-modal">  
    <div class="modal right fade" id="myModal2">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button #tClose type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">
                            <i class="bx bx-x"></i>
                        </span>
                    </button>

                    <h2 class="modal-title">
                        <a routerLink="/home">
                            <img src="assets/img/logo.png" alt="Logo">
                        </a>
                    </h2>
                </div>

                <div class="modal-body">
                    <div class="sidebar-modal-widget">
                        <h3 class="title">Change Language</h3>
                        <ul>
                            <li>
                                <a href="javascript:void(0);" [class.text-muted]="curLang == 'en'" (click)="setLang('ar')" >AR</a>
                            </li>
                            <li>
                                <a href="javascript:void(0);" [class.text-muted]="curLang == 'ar'" (click)="setLang('en')">EN</a>
                            </li>
                        </ul>
                    </div>

                    <div class="sidebar-modal-widget">
                        <h3 class="title">Additional Links</h3>

                        <ul>
                            <li>
                                <a [routerLink]="['/partner-login']" (click)="tClose.click();">Log In as Admin</a>
                            </li>
                            <li>
                                <a [routerLink]="['/customer-login']" (click)="tClose.click();">Log In as Customer</a>
                            </li>
                            <li>
                                <a href="sign-in.html">Sign In</a>
                            </li>
                            <li>
                                <a href="#">Log Out</a>
                            </li>
                        </ul>
                    </div>

                    <div class="sidebar-modal-widget">
                        <h3 class="title">Contact Info</h3>

                        <ul class="contact-info">
                            <li>
                                <i class="bx bx-location-plus"></i>
                                Address
                                <span>123, Western Road, Melbourne Australia</span>
                            </li>
                            <li>
                                <i class="bx bx-envelope"></i>
                                Email
                                <a href="mailto:hello@surety.com">hello@surety.com</a>
                            </li>
                            <li>
                                <i class="bx bxs-phone-call"></i>
                                Phone
                                <a href="tel:+822456974">+822456974</a>
                            </li>
                        </ul>
                    </div>
                    
                    <div class="sidebar-modal-widget">
                        <h3 class="title">Connect With Us</h3>

                        <ul class="social-list">
                            <li>
                                <a href="#">
                                    <i class='bx bxl-twitter'></i>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class='bx bxl-facebook'></i>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class='bx bxl-instagram'></i>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class='bx bxl-linkedin'></i>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class='bx bxl-youtube'></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Sidebar Modal -->
