import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Helpers } from 'src/app/helper/helper';
import { UserType } from 'src/app/models/userModel';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  UserType = UserType;
  userType: UserType

  constructor(private helper: Helpers, private router: Router) { }

  ngOnInit(): void {
    this.userType = this.helper.isAdmin() ? UserType.ADMIN : UserType.INSURER;
  }

}
