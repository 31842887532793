import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Helpers } from '../helper/helper';
import { CompleteTransactionRequestBody, InitiateTransactionForTPLRequestBody, InitiateTransactionRequestBody } from '../models/paymentModel';
import { BaseService } from './base.service';
import { TranslateService } from 'src/app/services/translate.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentService extends BaseService {

  constructor(helper: Helpers, private http: HttpClient, translate: TranslateService) {
    super(helper , translate);
   }

   getHeader( token ) {
    let header = new HttpHeaders({ 'Content-Type': 'application/json' });
    header = header.append('apiKey', environment.apiKey);
    header = header.append('accept-language', window.localStorage['language']);
    header = header.append('apiPassword', environment.apiPassword);
    if ( token ) {
      header = header.append('Authorization', 'Bearer ' + token);
    } else {
      header = header.append('Authorization', 'Bearer ' + this.helper.getToken());
    }
    return { headers: header };
  }

  getPaymentGatewayLink(data: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Accept', '*/*');
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('accessCode', environment.paymetGateway.accessCode);
    return this.http.post( environment.paymetGateway.url + '/checkout', { data }, { headers, responseType: 'text' }).pipe(catchError(this.handleError));
  }

  initiateTransaction( rateCardId: number, body: InitiateTransactionRequestBody, token: string ): Observable<any> {
    return this.http.post( this.pathAPI + "Payment/InitiateTransaction/" + rateCardId, body, this.getHeader( token ) ).pipe(
      catchError(super.handleError)
    );
  }

  completeTransaction( paymentId: string, body: CompleteTransactionRequestBody, token: string ): Observable<any> {
    return this.http.post( this.pathAPI + "Payment/CompleteTransaction/" + paymentId, body, this.getHeader( token ) ).pipe(
      catchError(super.handleError)
    );
  }

  initiateTransactionForTPL( body: InitiateTransactionForTPLRequestBody, token?: string ): Observable<any> {
    return this.http.post( this.pathAPI + "Payment/InitiateTransactionForTpl", body, this.getHeader( token ) ).pipe(
      catchError(super.handleError)
    );
  }

  completeTransactionForTPL( policyId: string, body: CompleteTransactionRequestBody, token: string ): Observable<any> {
    return this.http.put( this.pathAPI + "Payment/CompleteTransactionForTpl/" +  +policyId, body, this.getHeader( token ) ).pipe(
      catchError(super.handleError)
    );
  }
}
