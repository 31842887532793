import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Helpers } from '../helper/helper';
import { BaseService } from './base.service';
import { TranslateService } from 'src/app/services/translate.service';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService {

  constructor( helper: Helpers, private http: HttpClient , translate: TranslateService) {
    super(helper , translate);
  }

  getAllUsers(): Observable<any> {
    return this.http.get( this.pathAPI + "User/Users", super.header()).pipe(
      catchError(super.handleError)
    );
  }

  getAllCustomers(): Observable<any> {
    return this.http.get( this.pathAPI + "User/Customers", super.header()).pipe(
      catchError(super.handleError)
    );
  }

  getUserById( userId: string ): Observable<any> {
    return this.http.get( this.pathAPI + "User/UserDetail/" + userId, super.header()).pipe(
      catchError(super.handleError)
    )
  }

  getCustomerByUserId( userId: string ): Observable<any> {
    return this.http.get( this.pathAPI + "User/CustomerDetail/" + userId, super.header()).pipe(
      catchError(super.handleError)
    )
  }

  getCustomerCivilCardDetail(): Observable<any> {
    return this.http.get( this.pathAPI + "User/CustomerCivilCardDetail", super.header()).pipe(
      catchError(super.handleError)
    )
  }

  addCustomerCivilCard(civilCardBody): Observable<any> {
    return this.http.post( this.pathAPI + "User/AddCustomerCivilCard", civilCardBody, super.header()).pipe(
      catchError(super.handleError)
    )
  }
}
