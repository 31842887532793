import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PlanType } from 'src/app/models/quoteModel';

@Component({
  selector: 'app-select-insurance',
  templateUrl: './select-insurance.component.html',
  styleUrls: ['./select-insurance.component.scss']
})
export class SelectInsuranceComponent implements OnInit {

  PlanType = PlanType;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  forPlan(type: PlanType): void {
    if ( type == PlanType.TPL ) {
      this.router.navigate(['/tpl'], { state: { insuranceType: type } } );
    } else {
      this.router.navigate(['/vehicle', 'add'], { state: { insuranceType: type } } );
    }
  }
}
