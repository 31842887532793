import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar as MatSnackBar } from '@angular/material/snack-bar';
import { Helpers } from 'src/app/helper/helper';
import { CarDetails } from 'src/app/models/carModel';
import { QuoteStatus } from 'src/app/models/quoteModel';
import { VehicleService } from 'src/app/services/vehicle.service';
import { CatalogService } from 'src/app/services/catalog.service';
import { TranslateService } from 'src/app/services/translate.service';
import { StorageService } from 'src/app/services/storage.service';
import { environment } from 'src/environments/environment';
import { AddQuoteService } from 'src/app/add-quote.service';
import { QuoteService } from 'src/app/services/quote.service';
import { RateCard, RateCardSelections } from 'src/app/models/rate-card';

@Component({
  selector: 'app-vehicles',
  templateUrl: './vehicles.component.html',
  styleUrls: ['./vehicles.component.scss']
})
export class VehiclesComponent implements OnInit {

  Object = Object;
  QuoteStatus = QuoteStatus;
  allVehicle: CarDetails[];
  displayedColumn: string[] = [
    "Car Make",
    "Car Model",
    "Variant",
    "Plate Number",
    "Model Year",
    "Quote",
    "Payment Status"
  ];
  // isQuoteSummary: boolean;
  submittedQuote: RateCard;
  isPaymentSuccessfully: boolean;
  showGetVehiclesLoader: boolean;
  selectedQuoteDetails?: RateCardSelections = {
    addOns: false,
    comprehensive: false,
    limitedCoverage: false,
    tpl: false
  }
  passengerCount: number;

  SASTokenForCarImageRead: any;
  uploadedImageCounter: number = 0;
  SASTokenForRCImageRead: any;
  carRcImageUrl: string;
  carImagesList: any = [];
  SASTokenForCivilIdFrontImageRead: any;
  carCivilFrontImageUrl: string;
  SASTokenForCivilIdBackImageRead: any;
  carCivilBackImageUrl: string;
  showPayment: boolean = false;
  uploadedRc: number = 0;
  uploadedCivilFront: number = 0;
  uploadedCivilBack: number = 0;
  uploadedCarImage_1: number = 0;
  uploadedCarImage_2: number = 0;
  uploadedCarImage_3: number = 0;
  uploadedCarImage_4: number = 0;
  uploadedCarImage_5: number = 0;
  uploadedCarImage_6: number = 0;
  uploadedCarImages: any = [this.uploadedCarImage_1, this.uploadedCarImage_2, this.uploadedCarImage_3, this.uploadedCarImage_4, this.uploadedCarImage_5, this.uploadedCarImage_6];
  uploadedCarVideo: number;


  constructor(private vehicleService: VehicleService,
    private activatedRoute: ActivatedRoute,
    private quoteService: QuoteService,
    private router: Router,
    private catalogService: CatalogService,
    private translateService: TranslateService,
    private storageService: StorageService,
    private snackBar: MatSnackBar,
    private helper: Helpers,
    private _addQuoteService: AddQuoteService) { }

  ngOnInit(): void {

    this.isPaymentSuccessfully = false;
    this.showGetVehiclesLoader = true;

    this.vehicleService.getCars().subscribe(
      (allCars: any[]) => {
        this.showGetVehiclesLoader = false;
        if (allCars.length > 0) {
          this.allVehicle = new Array<CarDetails>();
          allCars.forEach((car: CarDetails) => {
            var carDetails = new CarDetails(car);
            if (car.status === undefined) {
              carDetails.status = QuoteStatus.NOT_SUBMITTED
              carDetails.action = false
            }

            this.allVehicle.push(carDetails);
          })
        }
      },
      error => {
        this.showGetVehiclesLoader = false;
      }
    )
  }

  vehicleDetails(vehicle: CarDetails): void {

    if (vehicle) {
      this.router.navigate(['/vehicle', vehicle.id], { queryParams: { quoteId: vehicle.rateCardId } });
    }
  }

  redirected($event): void {
    this.isPaymentSuccessfully = false;
  }

  proceedForPayment(vehicle: CarDetails): void {
    if (vehicle && vehicle.id) {
      this.router.navigate(['/make-payment', vehicle.id, { token: this.helper.getToken() }]);
    }
  }

  // Get PassengerCount
  getPassengerCount() {
    if (this.submittedQuote.vehicle.carMake && this.submittedQuote.vehicle.carModel && this.submittedQuote.vehicle.variant && this.translateService.lang == 'en') {
      this.catalogService.getPassengerCount(this.submittedQuote.vehicle.carMake, this.submittedQuote.vehicle.carModel, this.submittedQuote.vehicle.variant).subscribe(passengerCountResponse => {
        this.passengerCount = passengerCountResponse.passengers;
        this._addQuoteService.noOfPassenger = this.passengerCount;
      }, error => {
        this.snackBar.open("Error occur while fetching passenger count", 'Try Again', {
          duration: 2000,
          horizontalPosition: 'right'
        });
      });
    }

    // if (this.submittedQuote.vehicle.carMakeAr && this.submittedQuote.vehicle.carModelAr && this.submittedQuote.vehicle.trimAr && this.translateService.lang == 'ar') {
    //   this.catalogService.getPassengerCount(this.submittedQuote.vehicle.carMakeAr, this.submittedQuote.vehicle.carModelAr, this.submittedQuote.vehicle.trimAr).subscribe(passengerCountResponse => {
    //     this.passengerCount = passengerCountResponse.passengers;
    //   }, error => {
    //     this.snackBar.open("Error occur while fetching passenger count", 'Try Again', {
    //       duration: 2000,
    //       horizontalPosition: 'right'
    //     });
    //   });
    // }
  }

  showQuoteSummary(vehicle: CarDetails, showPayment: boolean): void {
    this.router.navigate(['/ratecard-summary', vehicle.rateCardId]);
  }
  showVehicleSummary(vehicle: CarDetails): void {
    this.router.navigate(['/vehicle-detail-page', vehicle.id]);
  }

  showPolicyDetails(vehicle: CarDetails): void {
    this.router.navigate(['/orders', vehicle.rateCardId]);
  }
  scrollToTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  loadImages() {
    this.storageService.userDocumentPresent(this.submittedQuote.vehicleId).subscribe(presentImages => {
      let rcImage, civil_front, civil_back, frontimg, backimg, leftimg, rightimg, frontWSimg, chassisNum, video;
      if (Object.keys(presentImages).length !== 0) {
        if (presentImages.rCbookUrl) {
          rcImage = presentImages.rCbookUrl;
          this.uploadedRc = 1;
        }
        if (presentImages.civilCardFrontUrl) {
          civil_front = presentImages.civilCardFrontUrl;
          this.uploadedCivilFront = 1;
        }
        if (presentImages.civilCardbackUrl) {
          civil_back = presentImages.civilCardbackUrl;
          this.uploadedCivilBack = 1;
        }
        if (presentImages.carFrontUrl) {
          frontimg = presentImages.carFrontUrl;
          this.uploadedCarImage_1 = 1;
        }
        if (presentImages.carBackUrl) {
          backimg = presentImages.carFrontUrl;
          this.uploadedCarImage_2 = 1;
        }
        if (presentImages.carLeftUrl) {
          leftimg = presentImages.carBackUrl;
          this.uploadedCarImage_3 = 1;
        }
        if (presentImages.carRightUrl) {
          rightimg = presentImages.carRightUrl;
          this.uploadedCarImage_4 = 1;
        }
        if (presentImages.carFrontWSUrl) {
          frontWSimg = presentImages.carFrontWSUrl;
          this.uploadedCarImage_5 = 1;
        }
        if (presentImages.carFrontChassisUrl) {
          chassisNum = presentImages.carFrontChassisUrl;
          this.uploadedCarImage_6 = 1;
        }
        if (presentImages.carVideoUrl) {
          video = presentImages.carFrontChassisUrl;
          this.uploadedCarVideo = 1;
        }
        this.uploadedImageCounter = this.uploadedRc + this.uploadedCivilFront + this.uploadedCivilBack + this.uploadedCarImage_1 + this.uploadedCarImage_2 + this.uploadedCarImage_3 + this.uploadedCarImage_4 + this.uploadedCarImage_5 + this.uploadedCarImage_6 + this.uploadedCarVideo;

      }
    });
  }

  carImages() {
    this.storageService.getReadTokenForCarImage().subscribe(carReadToken => {
      this.SASTokenForCarImageRead = carReadToken.sasToken;
      this.storageService.getCarImagesByVehicleId(String(this.submittedQuote.vehicleId)).subscribe(carImagesList => {
        if (carImagesList.length != 0) {
          this.setCarImages(carImagesList);
          for (let i = 0; i < carImagesList.length; i++) {
            if (carImagesList[i] != "") {
              if (i + 1 == 1) {
                this.uploadedCarImage_1 = 1;
                this.uploadedCarImages[i] = 1;
                this.uploadedImageCounter++;
              } else if (i + 1 == 2) {
                this.uploadedCarImage_2 = 1;
                this.uploadedCarImages[i] = 1;
                this.uploadedImageCounter++;
              } else if (i + 1 == 3) {
                this.uploadedCarImage_3 = 1;
                this.uploadedCarImages[i] = 1;
                this.uploadedImageCounter++;
              } else if (i + 1 == 4) {
                this.uploadedCarImage_4 = 1;
                this.uploadedCarImages[i] = 1;
                this.uploadedImageCounter++;
              } else if (i + 1 == 5) {
                this.uploadedCarImage_5 = 1;
                this.uploadedCarImages[i] = 1;
                this.uploadedImageCounter++;
              } else if (i + 1 == 6) {
                this.uploadedCarImage_6 = 1;
                this.uploadedCarImages[i] = 1;
                this.uploadedImageCounter++;
              }
            }
          }
        }
      });
    });
  }

  setCarImages(carImagesList): void {
    let tempCarImages: any = [];
    for (var carImage of carImagesList) {
      let image: any = {};
      let carImageIndex = +(carImage.name.split("_")[1]);
      let carImageUrl = environment.cdnUrl + '/comprehensive-car-book/' + carImage.name + this.SASTokenForCarImageRead;
      image.src = carImageUrl;
      image.caption = carImage.name;
      image.carIndex = carImageIndex;

      tempCarImages.push(image);
    }
    this.carImagesList = Object.assign([], tempCarImages);
  }

  imageExist(url) {
    var http = new XMLHttpRequest();

    http.open('HEAD', url, false);
    http.send();

    return http.status;
  }
  handleSummaryResponse(rateCard: RateCard) {
    if (rateCard) {
      this.submittedQuote = new RateCard(rateCard.rateCardId, rateCard);
      this.selectedQuoteDetails.limitedCoverage = this.submittedQuote.limitedComprehensiveCover != 0
      this.selectedQuoteDetails.comprehensive = Object.keys(this.submittedQuote.parametersMap).length > 0
      this.selectedQuoteDetails.addOns = Object.keys(this.submittedQuote.addOnsMap).length > 0
      // if (rateCard.vehicle.CarTrim || summaryResponse.trimAr) {
      //   this.translateService.lang == 'en' ? this.submittedQuote.vehicle.trim = summaryResponse.trim : this.submittedQuote.vehicle.trimAr = summaryResponse.trimAr;
      // }

      this.getPassengerCount();
      this.loadImages();
      this.carImages();
    }
  }
}
