import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { take } from 'rxjs/operators';
import { AddQuoteService } from 'src/app/add-quote.service';
import { CarDetails } from 'src/app/models/carModel';
import { RateCard, RateCardSelections } from 'src/app/models/rate-card';
import { QuoteService } from 'src/app/services/quote.service';
import { TranslateService } from 'src/app/services/translate.service';
import { Fee, PurposeOfLicense } from 'src/app/models/tpl.model';
import { TplService } from 'src/app/services/tpl.service';
import { Parameter } from 'src/app/models/parameter';
import { AnimationOptions } from 'ngx-lottie';
import { KeyValue } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { VehicleService } from 'src/app/services/vehicle.service';
import * as amplitude from '@amplitude/analytics-browser';


@Component({
  selector: 'app-quote-comparision',
  templateUrl: './quote-comparision.component.html',
  styleUrls: ['./quote-comparision.component.scss']
})
export class QuoteComparisionComponent implements OnInit {


  options: AnimationOptions = {
    path: '/assets/images/arrow.json',
  };

  rateCards: RateCard[] = []
  allRateCards: RateCard[] = []
  restOfRateCards: RateCard[] = []

  rateCardSelection?: RateCardSelections = {
    tpl: true,
    addOns: false,
    comprehensive: true,
    limitedCoverage: false
  }

  checklist: boolean = true
  // Stores the names & descriptions of the parameters.
  // It's an aggregate of all the rate cards
  comprehensiveParametersAggregate: { [key: string]: Parameter } = {}

  // Stores the names & descriptions of the parameters.
  // It's an aggregate of all the rate cards
  limitedCoverageParameters: string[] = ['Total Loss due to Water Damage, Accident, Theft, Fire and or Storm Damage']

  // Stores the names & descriptions of the addOns.
  // It's an aggregate of all the rate cards
  saiyartiAddOnsAggregate: { [key: string]: Parameter } = {}

  showTPL = true
  showLimitedCoverage: boolean = false
  showComprehensive = true
  showAddOns = false
  Object = Object;

  addOnIntermediate = false

  tplFees: Fee[] = []
  selectedTPLFee: Fee;
  tplPaymentPending = false
  checkedList;
  list: any = ['Included']
  @Input() carDetails: CarDetails;
  @Output() onProceed: EventEmitter<{ selectedFee: Fee, rateCard: RateCard, rateCardSelection: RateCardSelections, Checklist: boolean }> = new EventEmitter<{ selectedFee: Fee, rateCard: RateCard, rateCardSelection: RateCardSelections, Checklist: boolean }>();
  diffOfYear:number;



  get selectedLicense(): PurposeOfLicense {
    return this._addQuoteService.purposeOfLicense
  };
  get selectedLicenseAr(): PurposeOfLicense {
    return this._addQuoteService.purposeOfLicenseAr
  };

  constructor(
    private _quoteService: QuoteService,
    private _tplService: TplService,
    private activatedRoute: ActivatedRoute,
    private vehicleService: VehicleService,
    private _addQuoteService: AddQuoteService,
    private _translateService: TranslateService,
    private router: Router,) {
      amplitude.init(environment.amplitudekey, undefined, { defaultTracking: { sessions: true, pageViews: true, formInteractions: true, fileDownloads: true } });
  }
  date=new Date()
  currentYear=this.date.getFullYear()
  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(paramMap => {
      const id = paramMap.get('id')
        this.vehicleService.getCarById((+id).toString()).subscribe((carDetail: CarDetails) => {
          this.carDetails = new CarDetails(carDetail);
          const carModelYear= (this.carDetails.modelYear)
          this.diffOfYear= (+this.currentYear) - Number(carModelYear)
        })
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.carDetails.currentValue) {
      this.getTPLFees();
      if (this.carDetails.tplStatus == undefined) {
        this.showTPL = true;
        this.tplPaymentPending = true;
      } else if (this.carDetails.tplStatus != "PAYMENT_PENDING") {
        this.tplPaymentPending = false
        this.rateCardSelection.tpl = false
        this.showTPL = false;
      } else {
        this.tplPaymentPending = true;
      }
    }
  }

  getTPLFees() {
    this._tplService.getTPL(+this.carDetails.modelYear, (this._translateService.lang == 'en' ? this.selectedLicense.id : this.selectedLicenseAr.id), this._addQuoteService.noOfPassenger).subscribe(fees => {
      this.tplFees = fees.sort((x, y) => (x.period < y.period) ? -1 : 1);
      this.selectedTPLFee = this.tplFees[0]
      this.getRateCards()
    }, _ => { });
  }

  getRateCards() {
    const body = {
      carMake: this.carDetails.carMake,
      carModel: this.carDetails.carModel,
      purposeOfLicense: this.carDetails.purposeOfLicense
    }
    this._quoteService.getQuotes(body).pipe(take(1)).subscribe((rateCards) => {
      this.allRateCards = rateCards

      this.restOfRateCards = rateCards.filter(x => x.insurerId !== this.allRateCards[0].insurerId
        && x.insurerId !== this.allRateCards[1].insurerId
        && x.insurerId !== this.allRateCards[2].insurerId)

      this.rateCards.push(this.allRateCards[0])
      this.rateCards.push(this.allRateCards[1])
      this.rateCards.push(this.allRateCards[2])

      // Allocate the comprehensive parameters
      this.aggregateComprehensiveParameters()
      this.recalculateQuote()
    })
  }

  aggregateComprehensiveParameters() {
    for (let i = 0; i < this.allRateCards.length; i++) {

      for (const key in this.allRateCards[i].parametersMap) {
        const value = this.allRateCards[i].parametersMap[key];
        this.comprehensiveParametersAggregate[key] = value
      }

      for (const key in this.allRateCards[i].addOnsMap) {
        const value = this.allRateCards[i].addOnsMap[key];
        this.saiyartiAddOnsAggregate[key] = value
      }
    }
  }

  getLanguage(): string {
    return this._translateService.lang;
  }


  /////////////////////////////////////////////////////// PROCEED /////////////////////////////////////////////

  proceed(rateCard: RateCard) {
    rateCard.vehicleId = this.carDetails.id
    rateCard.rateCardId = this.carDetails.rateCardId
    this.onProceed.emit({ selectedFee: this.selectedTPLFee, rateCard: rateCard, rateCardSelection: this.rateCardSelection, Checklist: this.checklist });
    amplitude.track('Proceed For Quote',{ payload: JSON.stringify(this.carDetails.rateCard) })
  }
  /////////////////////////////////////////////////////// END PROCEED /////////////////////////////////////////////

  /////////////////////////////////////////////////////// PARAMETER CHANGES /////////////////////////////////////////////

  changeTPLFee(fee: Fee) {
    this.selectedTPLFee = fee
    this.recalculateQuote()
    amplitude.track('TPL selected!')
  }



  selectComprehensiveSubParameter(parameter: Parameter, subParameter: Parameter) {
    for (let index = 0; index < this.rateCards.length; index++) {
      var rateCard = this.rateCards[index];
      var parameter = rateCard.parametersMap[parameter.name];
      for (const subParameterKey in parameter.subParametersMap) {
        var subP = parameter.subParametersMap[subParameterKey];
        subP.isSelected = subParameter.name == subP.name
      }
    }
    this.recalculateQuote()
  }

  selectAddOnSubParameter(subParameter: Parameter, addOn: Parameter) {
    for (let index = 0; index < this.rateCards.length; index++) {
      var rateCard = this.rateCards[index];
      var addOn = rateCard.addOnsMap[addOn.name];
      for (const subParameterKey in addOn.subParametersMap) {
        var subP = addOn.subParametersMap[subParameterKey];
        for (const durationKey in subP.subParametersMap) {
          var duration = subP.subParametersMap[durationKey];
          duration.isSelected = subParameter.name == duration.name
        }
      }
    }
    this.recalculateQuote()
  }


  selectSubParameterForAddOn(carSize: Parameter, addOn: Parameter) {
    for (let index = 0; index < this.rateCards.length; index++) {
      const rateCard = this.rateCards[index];
      const fetchedAddOn = rateCard.addOnsMap[addOn.name];
      if (fetchedAddOn) {
        for (const key in fetchedAddOn.subParametersMap) {
          fetchedAddOn.subParametersMap[key].isSelected = false
        }

        fetchedAddOn.subParametersMap[carSize.name].isSelected = true
      }
    }
    this.recalculateQuote()
  }


  /////////////////////////////////////////////////////// END PARAMETER CHANGES /////////////////////////////////////////////


  /////////////////////////////////////////////////////// CHECKBOX TOGGLES /////////////////////////////////////////////


  toggleTPL() {
    this.showTPL = !this.showTPL;
    this.rateCardSelection.tpl = this.showTPL
    this.recalculateQuote();
    if(this.showTPL==true){
      amplitude.track('TPL selected')
    }
  }

  toggleLimitedCoverage() {
    this.showLimitedCoverage = !this.showLimitedCoverage;
    this.showComprehensive = false
    this.rateCardSelection.limitedCoverage = this.showLimitedCoverage;
    this.rateCardSelection.comprehensive = false
    this.recalculateQuote();
    if(this.showLimitedCoverage==true){
      amplitude.track('Limited Comprehensive Selected!')
    }
  }

  toggleComprehensive() {
    this.showComprehensive = !this.showComprehensive;
    this.showLimitedCoverage = false
    this.rateCardSelection.comprehensive = this.showComprehensive
    this.rateCardSelection.limitedCoverage = false

    if(this.showComprehensive==true){
      amplitude.track('Comprehensive Selected !')
    }
    this.recalculateQuote();
  }

  toggleAddOns() {
    if (this.addOnIntermediate || this.showAddOns) {
      this.addOnIntermediate = false;
      this.showAddOns = false;
    } else {
      this.showAddOns = true;
    }

    this.rateCardSelection.addOns = this.showAddOns


    for (const key in this.saiyartiAddOnsAggregate) {
      const addOn = this.saiyartiAddOnsAggregate[key]
      addOn.isSelected = this.showAddOns
    }

    for (let index = 0; index < this.rateCards.length; index++) {
      const rateCard = this.rateCards[index];
      for (const key in rateCard.addOnsMap) {
        const addOn = rateCard.addOnsMap[key];
        addOn.isSelected = this.showAddOns
      }
    }

    this.recalculateQuote();
  }

  toggleAddOn(addOnKeyValue: KeyValue<string, Parameter>) {

    const toggledValued = addOnKeyValue.value.isSelected ? false : true
    addOnKeyValue.value.isSelected = toggledValued
    for (let index = 0; index < this.rateCards.length; index++) {
      const rateCard = this.rateCards[index];
      const addOn = rateCard.addOnsMap[addOnKeyValue.value.name];
      if (addOn) {
        addOn.isSelected = toggledValued
      }
    }

    var count = 0
    for (const key in this.saiyartiAddOnsAggregate) {
      if (this.saiyartiAddOnsAggregate[key].isSelected) {
        count++
      }
    }

    if (count > 0 && count < Object.keys(this.saiyartiAddOnsAggregate).length) {
      this.addOnIntermediate = true
    } else {
      this.addOnIntermediate = false
    }

    this.showAddOns = count > 0

    this.recalculateQuote();
  }

  /////////////////////////////////////////////////////// END CHECKBOX TOGGLES /////////////////////////////////////////////


  /////////////////////////////////////////////////////// CALCULATIONS FOR RATE CARD/////////////////////////////////////////////
  recalculateQuote(): void {
    // Reset all values
    for (let i = 0; i < this.rateCards.length; i++) {
      var rateCard = this.rateCards[i]
      // rateCard.amount = rateCard.saiyartiAssistAmount ?? 0
      rateCard.amount = 0
      rateCard.addOnAmount = 0
      rateCard.tplAmount = 0
      rateCard.comprehensiveAmount = 0
      rateCard.limitedComprehensiveAmount = 0
    }

    // TPL is SELECTED
    if (this.showTPL) {
      this.calculateTPL();
    }

    // Limited Comprehensive Coverage is selected
    if (this.showLimitedCoverage) {
      this.calculateLimitedCoverage();
    }

    // Comprehensive is Selected
    if (this.showComprehensive) {
      this.calculateComprehensive();
    }

    // Add Ons is Selected
    if (this.showAddOns) {
      this.calculateAddOns();
    }
  }

  private calculateTPL() {
    // Add the TPL Amount to final quote
    for (let index = 0; index < this.rateCards.length; index++) {
      for (let i = 0; i < this.tplFees.length; i++) {
        const fee = this.tplFees[i];
        if (fee.period == this.selectedTPLFee.period) {
          this.rateCards[index].amount += fee.price;
          this.rateCards[index].tplAmount += fee.price;
        }
      }
    }
  }

  private calculateLimitedCoverage() {
    if (this.showComprehensive === false) {
      for (let i = 0; i < this.rateCards.length; i++) {
        var rateCard = this.rateCards[i]
        const minimumPremiumAmount = this.rateCards[i].minimumPremiumAmount;
        var amountToAdd = 0;
        for (let index = 0; index < rateCard.fees.length; index++) {
          const fee = rateCard.fees[index];
          amountToAdd += fee.amount
        }

        rateCard.limitedComprehensiveAmount += ((this.carDetails.marketValue * rateCard.limitedComprehensiveCover) / 100);
        if (rateCard.limitedComprehensiveAmount < minimumPremiumAmount) {
          rateCard.limitedComprehensiveAmount = minimumPremiumAmount;
        }

        rateCard.limitedComprehensiveAmount += amountToAdd;
        rateCard.amount += rateCard.limitedComprehensiveAmount;
        rateCard.amount = +rateCard.amount.toFixed(3);
        rateCard.limitedComprehensiveAmount = +rateCard.limitedComprehensiveAmount.toFixed(3);

      }
    }
  }

  private calculateComprehensive() {
    var i = 0

    // Loop over parameters for each insurance company to calculate total comprehensive amount
    this.rateCards.forEach((rateCard) => {
      // Extract the variables needed for further calculations
      const saiyartiPercentage = rateCard.saiyartiPercentage;
      const insurerPercentage = rateCard.insurerPercentage;
      const minimumPremiumAmount = rateCard.minimumPremiumAmount;
      var fees = 0;
      for (let index = 0; index < rateCard.fees.length; index++) {
        const fee = rateCard.fees[index];
        fees += fee.amount
      }


      var premium = 0;
      var comprehensivePercentage = saiyartiPercentage + insurerPercentage;
      let isAnyParamIsEligible = false;
      // Calculate the premium based on the subLimit and KD values
      // For Waiver of Subrogation use KD values
      // For anything else with subLimit use their percentage values

      for (const key in rateCard.parametersMap) {
        const val = rateCard.parametersMap[key]
        if (val.isSelected) {
          if (val.name === 'Waiver of Subrogation') {
            for (const subParameterKey in val.subParametersMap) {
              const subLimit = val.subParametersMap[subParameterKey]
              if (subLimit && subLimit.isSelected) {
                premium += +subLimit.valueForInsurer;
                isAnyParamIsEligible = true;
              }
            }
          } else if (val.subParametersMap) {
            for (const subParameterKey in val.subParametersMap) {
              const subLimit = val.subParametersMap[subParameterKey]
              if (subLimit.isSelected) {
                comprehensivePercentage -= +subLimit.percentageValue;
                isAnyParamIsEligible = true;
              }
            };
          }
        }
      };

      if (!isAnyParamIsEligible) { // If all parameters is not eligible according to model year.
        premium += ((this.carDetails.marketValue * rateCard.limitedComprehensiveCover) / 100);
      } else {
        premium += ((+comprehensivePercentage * this.carDetails.marketValue) / 100.0);
      }
      // If the premium is less than minimum premium amount
      // for the insurance company use the minimumPremiumAmount
      if (premium < minimumPremiumAmount) {
        premium = minimumPremiumAmount;
      }
      // Add premium & fees to final amount
      const amountToAdd = premium + fees;
      rateCard.amount += amountToAdd;
      rateCard.comprehensiveAmount += amountToAdd;
      rateCard.amount = +rateCard.amount.toFixed(3);
      rateCard.comprehensiveAmount = +rateCard.comprehensiveAmount.toFixed(3);
      i = i + 1;
    });
  }

  private calculateAddOns() {
    for (let i = 0; i < this.rateCards.length; i++) {
      var rateCard = this.rateCards[i]
      for (const key in rateCard.addOnsMap) {
        const addOn = rateCard.addOnsMap[key]

        if (addOn.isSelected) {
          if (Object.keys(addOn.subParametersMap).length > 0) {
            for (const key in addOn.subParametersMap) {
              const limit = addOn.subParametersMap[key]
              if (limit.isSelected) {
                for (const key in limit.subParametersMap) {
                  const subLimit = limit.subParametersMap[key]
                  if (subLimit.isSelected) {
                    rateCard.amount += +subLimit.valueForInsurer;
                    rateCard.addOnAmount += +subLimit.valueForInsurer;
                    rateCard.amount = +rateCard.amount.toFixed(3);
                    rateCard.addOnAmount = +rateCard.addOnAmount.toFixed(3);
                  }
                }
              }
            }
          } else {
            rateCard.amount += +addOn.valueForInsurer;
            rateCard.addOnAmount += +addOn.valueForInsurer;
            rateCard.amount = +rateCard.amount.toFixed(3);
            rateCard.addOnAmount = +rateCard.addOnAmount.toFixed(3);
          }
        }
      }
    }
  }
  /////////////////////////////////////////////////////// END OF CALCULATIONS FOR RATE CARD/////////////////////////////////////////////

  back() {
    //throw new Error('Method not implemented.');
    this.router.navigate(['/vehicle/add', this.carDetails.id]);

  }

  sort(x: any, y: any) {
    const aStepId = x.value.order ?? 0
    const bStepId = y.value.order ?? 0
    return aStepId > bStepId ? 1 : (bStepId > aStepId ? -1 : 0);
  }

  /////////////////////////////////////////////////////// CHANGE OF INSURER /////////////////////////////////////////////

  changeInsurer(rateCard: RateCard, index: number) {
    const oldInsurerId = this.rateCards[index].insurerId;
    this.rateCards[index] = rateCard
    this.restOfRateCards = this.restOfRateCards.filter(x => x.insurerId !== rateCard.insurerId)
    this.restOfRateCards.push(...this.allRateCards.filter(x => x.insurerId == oldInsurerId))
    this.recalculateQuote()
  }

  /////////////////////////////////////////////////////// END OF CHANGE OF INSURER /////////////////////////////////////////////


  getCompanyImageURL(insurerId: string): string {
    return environment.cdnUrl + "/" + environment.companyImageContainer + "/" + insurerId + '.jpg';
  }
}
