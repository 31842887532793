export class Parameter {

    constructor(obj: any, onlySelectedParameters?: boolean) {

        if (obj) {
            Object.assign(this, obj)
            if (obj.subParametersMap) {
                this.subParametersMap = {}
                for (const key in obj.subParametersMap) {
                    const val = obj.subParametersMap[key]
                    if (val.isEnabled) {
                        if (onlySelectedParameters && val.isSelected) {
                            this.subParametersMap[key] = new Parameter(val, onlySelectedParameters)
                        } else if (!onlySelectedParameters) {
                            this.subParametersMap[key] = new Parameter(val, onlySelectedParameters)
                        }
                    }
                }
            }
        }
    }

    higherRange?: number
    lowerRange?: number
    isMandatory?: boolean
    name?: string
    arabicName?: string
    type?: string
    valueForInsurer?: number
    percentageValue?: number
    isSelected?: boolean
    isEnabled?: boolean
    description?: string
    arabicDescription?: string
    subParametersMap?: { [key: string]: Parameter } = {}

    translatedName(lang: string) {
        return lang === 'en' ? this.name : this.arabicName
    }

    translatedDescription(lang: string) {
        return lang === 'en' ? this.description : this.arabicDescription
    }
}