import { Router, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Helpers } from './helper';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserType } from '../models/userModel';

@Injectable({
    providedIn: 'root'
})

export class AuthGuard  {

    customerPageList: string[] = [
        "/dashboard-customer",
        "/vehicle",
        "/orders",
        "/quotes",
        "/select-insurance",
        "/tpl",
        "/tpl-success",
        "/comprehensive-success"
    ]

    customerDetailsPageList: string[] = [
        "/request",
        "/vehicle",
        "/orders",
        "/quotes",
        "make-payment",
        "/payment-success",
        "/payment-failure",
        "/tpl-details",
    ]

    adminInsurerPageList: string[] = [
        "/dashboard",
        "/request",
        "/policies",
        "/users",
        "/customers",
        "/orders"
    ]

    adminInsurerDetailsPageList: string[] = [
        "/request/",
        "/users/",
        "/customers/",
        "/orders/"
    ]

    adminPageList: string[] = [
        "/insurers",
        "/masterRateCard",
    ];

    adminDetailsPageList: string[] = [
        "/insurers/",
        "/rateCard/"
    ];

    constructor(private router: Router, private helper: Helpers) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): UrlTree | Observable<boolean> | boolean {
        if (!this.helper.isAuthenticated()) {
            this.router.navigate(['/customer-login']);
            return false;
        }
        
        const userToken = this.helper.getDecodedAccessToken(this.helper.getToken());
        if ( userToken.type == UserType.CUSTOMER) {
            if ( this.customerPageList.includes(state.url) ) {
                return true;
            }
            else if ( state.url.indexOf("dashboard") > -1 ) {
                return this.router.createUrlTree(['/dashboard-customer']);
            } else if ( this.customerDetailsPageList.findIndex( page => { return state.url.indexOf(page) > -1 } ) > -1 ) {
                return true;
            }
        } else if ( userToken.type == UserType.ADMIN || userToken.type == UserType.INSURER ){
            if ( this.adminInsurerPageList.includes(state.url) ) {
                return true;
            } else if ( userToken.type == UserType.ADMIN && this.adminPageList.includes(state.url) ) {
                return true;
            } else if ( state.url.indexOf("dashboard") > -1 ) {
                return this.router.createUrlTree(['/dashboard']);
            } else if ( this.adminInsurerDetailsPageList.findIndex( page => { return state.url.indexOf(page) > -1 } ) > -1 ) {
                return true;
            } else if ( userToken.type == UserType.ADMIN && 
                this.adminDetailsPageList.findIndex( page => { return state.url.indexOf(page) > -1 } ) > -1 ) {
                return true;
            }
        }
        return false;
    }
}