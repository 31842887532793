<div class="page-content">
    <div class="container-fluid">
        <div class="row justify-content-center mt-5 pt-5">
            <div class="col-12 text-center mb-4">
                <h4>{{ 'What Motor insurance are you looking for?' | translate }}</h4>
            </div>
            <div class="col-xl-4 col-sm-6">
                <div class="card py-4" role="button" (click)="forPlan(PlanType.TPL)">
                    <h5 class="text-truncate m-0 text-center">{{'Third Party Motor' | translate}}<br>{{'Insurance' | translate}}</h5>
                </div>
            </div>
            <div class="col-xl-4 col-sm-6">
                <div class="card py-4" role="button" (click)="forPlan(PlanType.COMPREHENSIVE)">
                    <h5 class="text-truncate m-0 text-center">{{'Comprehensive' | translate}}<br>{{'Insurance' | translate}}</h5>
                </div>
            </div>
            <div class="col-xl-4 col-sm-6">
                <div class="card py-4" role="button" (click)="forPlan(PlanType.TPLANDCOMPREHENSIVE)">
                    <h5 class="text-truncate m-0 text-center">{{'Comprehensive with Third Party' | translate}} <br>{{'Insurance' | translate}}</h5>
                </div>
            </div>
        </div>
        <!--  end row -->
</div>