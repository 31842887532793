<div class="page-content ">
    <div class="container-fluid w-100 d-flex justify-content-center">

        <div *ngIf="!isRejected && !isAccepted">
            Loading...
        </div>

        <div *ngIf="isRejected" class="card-title">
            <div style="line-height: 1.2em;">
                We are sorry to hear that you don't want to proceed with {{insuranceCompanyName}}.<br>
                Would you like to see other available options for your car?
            </div>

            <div class="d-flex justify-content-center mt-3">
                <button class="btn btn-primary waves-effect waves-light add-car-btn" (click)="showOptions()">Yes</button>

                <button class="btn btn-primary waves-effect waves-light ml-3" style="background-color: #222B4A;"
                    (click)="dontShowOptions = true">No</button>
            </div>
        </div>


        <div *ngIf="dontShowOptions">
            Thank you for visiting Saiyarti. We hope we can serve you in the future.
        </div>
    </div>
</div>