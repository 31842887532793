import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Helpers } from 'src/app/helper/helper';
import { UserType } from 'src/app/models/userModel';
import { TranslateService } from 'src/app/services/translate.service';
import { AccountService } from '../../services/account.service';
import { VehicleService } from 'src/app/services/vehicle.service';
import { AddQuoteService } from 'src/app/add-quote.service';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit {

  @Input() isVerticalHeader: boolean;
  newUser: string;
  UserType = UserType;
  userType: UserType;
  curLang: string;
  @Output() onChangeLanguage: EventEmitter<string> = new EventEmitter<string>();

  contactSubmitted: boolean;
  phoneNumber: string;
  name: string;


  constructor(private accountService: AccountService, private helper: Helpers, private router: Router, private readonly translate: TranslateService, private vehicleService: VehicleService,
    private _addQuoteService: AddQuoteService) { }

  ngOnInit(): void {
    this.curLang = this.translate.lang;
    this.newUser = window.localStorage.newCustomer;
    if (this.newUser == "true") {
      window.localStorage.newCustomer = "false";
    }
    const userToken = this.helper.getDecodedAccessToken(this.helper.getToken());
    this.userType = userToken?.type;
  }

  getLanguage(): string {
    return this.translate.lang;
  }

  changeImage(ele) {
    let imagePath = ele.src.split("/");
    let previousImage;
    if (this.curLang == "en") {
      previousImage = imagePath[imagePath.length - 1].slice(-6, -5);
    } else if (this.curLang == "ar") {
      previousImage = imagePath[imagePath.length - 1].slice(-8, -7);
    }
    let currentImage = parseInt(previousImage) + 1;
    if (this.curLang == 'en') {
      if (previousImage != 7) {
        ele.src = "../../../assets/images/Tutorial/Comprehensive_Tut" + currentImage + ".jpeg";
      } else {
        ele.src = "";
        ele.parentElement.classList.add("d-none");
      }
    } else if (this.curLang == 'ar') {
      if (previousImage != 6) {
        ele.src = "../../../assets/images/Tutorial/Comprehensive_Tut" + currentImage + "_AR.jpg";
      } else {
        ele.src = "";
        ele.parentElement.classList.add("d-none");
      }
    }
  }

  logoutUser() {
    this.helper.logout();
    if (this.userType == UserType.CUSTOMER) {
      this.router.navigate(['/customer-login']);
    } else {
      this.router.navigate(['/partner-login']);
    }
  }

  setLang(lang) {
    this.translate.use(lang);
    this.curLang = this.translate.lang;
    this.onChangeLanguage.emit(lang);
  }
  submitContact(form) {
    const data = {
      name: form.value.name,
      phoneNumber: '+965 ' + form.value.phoneNumber,
      email: '-',
      message: '-',
      source: 'Comprehensive Client Portal',
      type: 'Header'
    };

    // Regardless of success or error reset the form and show success
    this.contactSubmitted = true;
    form.reset();
    setTimeout(() => {
      this.contactSubmitted = false;
    }, 3000);

    this.accountService.getInTouch(data).subscribe(res => { }, err => { });
  }


  getHelp() {
    let body = {
      "help": true
    }
    this.vehicleService.getVehicleHelp(body).subscribe(response => {
      console.log("Hello");
    });
  }

showVehicles () {
    this._addQuoteService.onShowMyVehicles.emit()
  }
}
