import { Parameter } from "./parameter";
import { RateCardFee } from "./rate-card-fee";
import { RateCardInsurer } from "./rate-card-insurer";
import { RateCardVehicle } from "./rate-card-vehicle";
import { RateCardUser } from "./rate-card-user";
import { RateCardCivilCard } from "./rate-card-civil-card";
import { RateCardTransaction } from "./rate-card-transaction";

export class RateCard {

    constructor(id?: number, obj?: any, onlySelectedParameters?: boolean) {

        if (obj) {
            Object.assign(this, obj)

            if (obj.parametersMap) {
                this.parametersMap = {}
                for (const key in obj.parametersMap) {
                    const val = obj.parametersMap[key]
                    if (val.isEnabled) {
                        if (onlySelectedParameters && val.isSelected) {
                            this.parametersMap[key] = new Parameter(val, onlySelectedParameters)
                        } else if (!onlySelectedParameters) {
                            this.parametersMap[key] = new Parameter(val, onlySelectedParameters)
                        }
                    }
                }
            }


            if (obj.addOnsMap) {
                this.addOnsMap = {}
                for (const key in obj.addOnsMap) {
                    const val = obj.addOnsMap[key]
                    if (val.isEnabled) {
                        if (onlySelectedParameters && val.isSelected) {
                            this.addOnsMap[key] = new Parameter(val, onlySelectedParameters)
                        } else if (!onlySelectedParameters) {
                            this.addOnsMap[key] = new Parameter(val, onlySelectedParameters)
                        }
                    }
                }
            }
        }

        if (id === undefined || id === 0) {
            this.id = undefined
            this.rateCardId = undefined
        }
    }

    id?: number
    historyId?: number
    rateCardId?: number
    imageLink?: string
    parametersMap: { [key: string]: Parameter } = {}
    addOnsMap: { [key: string]: Parameter } = {}
    saiyartiRecommended?: boolean
    insurerId?: string
    vehicleId?: number
    isConfirmed: boolean = true
    userId?: string
    validity?: Date
    createdAt?: Date
    status?: string
    policyNumber?: string
    amount?: number
    premium?: number
    notes?: string

    policyOrderDate?: Date
    policyStartDate?: Date
    policyExpiryDate?: Date
    policyIssueDate?: Date
    policyDeliveryDate?: Date

    saiyartiPercentage?: number
    validityPeriod?: number
    isRejected: boolean = false
    insurerPercentage?: number
    type?: string
    minimumPremiumAmount?: number
    limitedComprehensiveCover?: number
    addOnAmount?: number
    saiyartiAssistAmount?: number
    tplAmount?: number
    comprehensiveAmount?: number
    limitedComprehensiveAmount?: number
    address?: string
    vehicle?: RateCardVehicle
    insurer: RateCardInsurer
    fees: RateCardFee[] = []
    user: RateCardUser
    transaction?: RateCardTransaction
    civilCard: RateCardCivilCard = {}
}

export interface RateCardSelections {
    tpl: boolean
    comprehensive: boolean
    addOns: boolean
    limitedCoverage: boolean
}