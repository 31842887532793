import { Component, DoCheck, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from './services/translate.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, DoCheck {

  lang: string = 'en';
  @ViewChild('mainBody') mainBody: ElementRef;


  defaultHeaderFooterList: string[] = [
    "/home"
  ]

  showDefaultHeaderFooter: boolean;

  constructor(private translate: TranslateService, private router: Router){}

  ngOnInit(): void {
    this.translate.use(this.translate.lang);
  }

  ngDoCheck(): void {
    this.showDefaultHeaderFooter = this.defaultHeaderFooterList.includes(this.router.url);
  }

  setLanganguage(): void {
    window.addEventListener('DOMContentLoaded', (event) => {
      this.mainBody.nativeElement.removeClass('en');
      this.mainBody.nativeElement.removeClass('ar');
     this.mainBody.nativeElement.addClass(this.translate.lang);
    });
  }

}