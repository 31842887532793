<div class="page-content">
    <div class="container-fluid">
        <div class="row" *ngIf="!isQuotePaymentDone && !isPaymentSuccessfully">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title mb-4">{{ 'All Requests' | translate }}</h4>
                        <div class="table-responsive all-request">
                            <table class="table table-centered table-nowrap mb-0">
                                <thead class="thead-light">
                                    <tr>
                                        <th *ngFor="let column of displayedColumn">{{ column | translate }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="cursor-pointer" *ngFor="let request of allRequest">
                                        <td (click)="quoteDetails(request)">{{ request.requestDate ? (
                                            request.requestDate | date:'dd MMM yyyy':'locale' ) : '-' }}</td>
                                        <td (click)="quoteDetails(request)">{{ request.expiryDate ? ( request.expiryDate
                                            | date:'dd MMM yyyy':'locale' ) : '-' }}</td>
                                        <td (click)="quoteDetails(request)">{{ request.carMake ? request.carMake : '-'
                                            }}</td>
                                        <td (click)="quoteDetails(request)">{{ request.insurer ? request.insurer : '-'
                                            }}</td>
                                        <td (click)="quoteDetails(request)"><small *ngIf="request.amount"
                                                class="text-muted">{{'KWD'|translate}}</small> {{ request.amount ? ( request.amount |
                                            number:'.0-2' ) : '-' }}</td>
                                        <td (click)="quoteDetails(request)">
                                            <span class="status-badge badge badge-soft-primary"
                                                [class.badge-soft-success]="request.status == QuoteStatus.APPROVED"
                                                [class.badge-soft-danger]="request.status == QuoteStatus.REJECTED"
                                                [class.badge-soft-info]="request.status == QuoteStatus.DELIVERED || request.status == QuoteStatus.POLICY_PRINTED || request.status == QuoteStatus.PAYMENT_DONE || request.status == QuoteStatus.DISPATCHED">
                                                {{ request.status ? (request.status | translate) : '-' }} </span>
                                        </td>
                                        <td class="quoteStatus" style="width: 120px; min-width: 120px;">
                                            <button
                                                *ngIf="request.status == QuoteStatus.DELIVERED || request.status == QuoteStatus.POLICY_PRINTED"
                                                (click)="orderDetails(request)"
                                                class="btn btn-primary btn-rounded waves-effect waves-light"> {{ 'View
                                                Order Details' | translate }} </button>
                                            <button
                                                *ngIf="request.status == QuoteStatus.WAITING_FOR_APPROVAL || request.status == QuoteStatus.PAYMENT_DONE"
                                                (click)="waitingForApproval( request )"
                                                class="btn btn-primary btn-rounded waves-effect waves-light"> {{ 'View
                                                Submitted Quotes' | translate }} </button>
                                            <button *ngIf="request.status == QuoteStatus.REJECTED"
                                                (click)="proceedToAddQuote(request)"
                                                class="btn btn-primary btn-rounded waves-effect waves-light"> {{ 'View
                                                Alternate Quotes' | translate }} </button>
                                            <button *ngIf="request.status == QuoteStatus.APPROVED"
                                                (click)="proceedForPayment( request )"
                                                class="btn btn-primary btn-rounded waves-effect waves-light px-3"> {{
                                                'Pay Now' | translate }} </button>
                                        </td>
                                    </tr>
                                    <tr
                                        *ngIf="!showGetRequestLoader && ( allRequest == null || allRequest.length == 0 )">
                                        <td colspan="7" class="text-center">{{'No quotes found' | translate}}</td>
                                    </tr>
                                    <tr *ngIf="showGetRequestLoader">
                                        <td colspan="7" class="text-center">
                                            <div class="spinner-border text-primary m-1" role="status">
                                                <span class="sr-only">{{'Loading...' | translate}}</span>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- end table-responsive -->
                    </div>
                </div>
            </div>
        </div>
        <!-- end row -->
        <app-quote-summary *ngIf="isQuotePaymentDone"
            [isSubmitted]="submittedQuote.status == QuoteStatus.WAITING_FOR_APPROVAL"
            [isPaymentDone]="submittedQuote.status == QuoteStatus.PAYMENT_DONE" [selectedQuote]="submittedQuote"
            [carDetails]="carDetails"></app-quote-summary>
        <div class="row justify-content-center" *ngIf="isPaymentSuccessfully">
            <div class="col-6">
                <app-success (redirected)="redirected($event)" [redirectLink]="'self'"></app-success>
            </div>
        </div>
        <!-- end row -->
        <div class="row mb-3" *ngIf="isQuotePaymentDone">
            <div class="col-12">
                <button type="button" class="btn btn-primary waves-effect waves-light" (click)="back()">{{ 'Back' |
                    translate }}</button>
            </div>
        </div>
    </div>
    <!-- container-fluid -->
</div>
<!-- End Page-content -->
