<div class="row" *ngIf="showReviewSubmit">
  <div class="col-6" style="max-width: 55%;">
    <div class="row">
      <!-- vehicle details card -->
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body" style="padding: 1rem;">
            <div class="d-flex justify-content-between">
              <div>
                <h4 class="heading-text mb-4"> {{ 'Vehicle Details' | translate }}</h4>
              </div>
              <div class="editBtn" [ngClass]="(getLanguage() == 'en') ? 'pl-2' : 'pl-1'" *ngIf="!isSubmitted">
                <a style="cursor: pointer;top: -2px; color: #222B4A;" class="position-relative data-text"
                  (click)="makeChanges('editVehicle')"> {{ 'Edit'
                  | translate }} <span class="material-icons position-relative"
                    style="top: 8px;color: #222B4A;">edit</span>
                </a>
              </div>
            </div>
            <div class="d-flex">
              <div class="table-responsive" style="overflow-x:unset">
                <table class="table table-centered table-nowrap mb-0 col-6">
                  <tbody>
                    <tr>
                      <td>
                        <h5 class="data-text m-0">{{ 'Plate Number' | translate }} </h5>
                      </td>
                      <td class="data-text">{{ carDetails ? carDetails.plateNumber : 'plateNumber'
                        }}</td>
                    </tr>
                    <tr>
                      <td>
                        <h5 class="data-text m-0">{{ 'Purpose of License' | translate }}</h5>
                      </td>
                      <td class="data-text">{{ carDetails ? carDetails.purposeOfLicense :
                        'purposeOfLicense' }}</td>
                    </tr>
                    <tr>
                      <td>
                        <h5 class="data-text m-0">{{ 'Car Make' | translate }}</h5>
                      </td>
                      <td class="data-text">{{ carDetails ? carDetails.carMake : 'carMake' }}</td>
                    </tr>
                    <tr>
                      <td>
                        <h5 class="data-text m-0">{{ 'Car Model' | translate }} </h5>
                      </td>
                      <td class="data-text">{{ carDetails ? carDetails.carModel : 'carModel' }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="table-responsive" style="overflow-x:unset">
                <table class="table table-centered table-nowrap mb-0 col-6">
                  <tbody>
                    <tr>
                      <td>
                        <h5 class="data-text m-0">{{ 'Variant' | translate }}</h5>
                      </td>
                      <td class="data-text">{{ carDetails ? carDetails.variant : 'variant' }}</td>
                    </tr>
                    <tr>
                      <td>
                        <h5 class="data-text m-0">{{ 'Model Year' | translate }} </h5>
                      </td>
                      <td class="data-text">{{ carDetails ? carDetails.modelYear : 'modelYear' }}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h5 class="data-text m-0">{{ 'Number of Passengers' | translate }}</h5>
                      </td>
                      <td class="data-text">{{passengerCount}}</td>
                    </tr>
                    <tr>
                      <td>
                        <h5 class="data-text m-0">{{ 'Market Value of Car' | translate }}</h5>
                      </td>
                      <td class="data-text">{{'KWD' |translate}} {{ (carDetails ? carDetails.marketValue : 0) |
                        number : '1.3-3'
                        }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end col -->
      <!-- Insurance coverages -->
      <div class="col-lg-12" *ngIf="rateCardSelections">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <div>
                <h4 class="heading-text mb-4">{{ 'Insurance Coverages' | translate }}</h4>
              </div>
              <div class="editBtn" [ngClass]="(getLanguage() == 'en') ? 'pl-2' : 'pl-1'" *ngIf="!isSubmitted">
                <a style="cursor: pointer;top: -2px;" class="position-relative data-text"
                  (click)="makeChanges('quotes')"> {{ 'Edit' | translate }} <span
                    class="material-icons position-relative" style="top: 8px; color: #222B4A;">edit</span>
                </a>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-centered table-nowrap mb-0">
                <tbody class="insuranceDetails">
                  <tr *ngIf="rateCardSelections.tpl" class="d-flex justify-content-between">
                    <th>
                      <h5 class="data-text m-0">{{'Third Party Liability' | translate}}</h5>
                    </th>
                    <td class="dark-data-text">{{selectedFee.period | translate}} {{'Year/s'|translate}}</td>
                  </tr>
                  <tr *ngIf="rateCardSelections.limitedCoverage" class="d-flex justify-content-between">
                    <th>
                      <h5 class="data-text m-0">{{'Limited Comprehensive Cover' | translate}}
                      </h5>
                    </th>
                    <td class="dark-data-text">
                      <span *ngIf="rateCardSelections.limitedCoverage">
                        {{'Included' | translate}}</span>
                    </td>
                  </tr>
                  <tr *ngIf="rateCardSelections.comprehensive" class="d-flex justify-content-between">
                    <th>
                      <h5 class="data-text m-0">{{'Full Comprehensive Cover' | translate}}
                      </h5>
                    </th>
                    <td></td>
                  </tr>
                  <div *ngIf="rateCardSelections.comprehensive && selectedQuote.parametersMap">
                    <ng-container *ngFor="let parameterKeyValue of selectedQuote.parametersMap | keyvalue : sort">
                      <tr *ngIf="parameterKeyValue.value.isSelected" class="d-flex justify-content-between ml-5">
                        <th>
                          <h5 class="light-data-text m-0" *ngIf="getLanguage() == 'en'"
                            style="max-width: 310px;word-break: break-word;white-space: normal;">
                            {{parameterKeyValue.value.translatedName(getLanguage())}}
                          </h5>
                          <h5 class="light-data-text m-0" *ngIf="getLanguage() == 'ar'"
                            style="max-width: 310px;word-break: break-word;white-space: normal;">
                            {{parameterKeyValue.value.translatedName(getLanguage())}}
                          </h5>
                        </th>
                        <ng-container *ngIf="Object.keys(parameterKeyValue.value.subParametersMap).length === 0;">
                          <td *ngIf="parameterKeyValue.value.isSelected" class="dark-data-text">{{'Included' |
                            translate}}
                          </td>
                        </ng-container>
                        <ng-container *ngIf="Object.keys(parameterKeyValue.value.subParametersMap).length > 0;">
                          <ng-container
                            *ngFor="let subParameterKeyValue of parameterKeyValue.value.subParametersMap | keyvalue : sort">
                            <td *ngIf="subParameterKeyValue.value.isSelected" class="dark-data-text">
                              {{subParameterKeyValue.value.translatedName(getLanguage()) |
                              translate}}
                            </td>
                          </ng-container>
                        </ng-container>
                      </tr>
                    </ng-container>
                  </div>
                  <tr *ngIf="showAddOns">
                    <th>
                      <h5 class="data-text m-0">{{'Saiyarti Add-Ons' | translate}}</h5>
                    </th>
                    <td></td>
                  </tr>
                  <div *ngIf="showAddOns">
                    <ng-container *ngFor="let addOnKeyValue of selectedQuote.addOnsMap | keyvalue : sort">
                      <tr *ngIf="selectedQuote.addOnsMap[addOnKeyValue.key].isSelected"
                        class="d-flex justify-content-between ml-5">
                        <ng-container *ngIf="addOnKeyValue.key ==='Replacement Car'">
                          <th>
                            <h5 class="light-data-text m-0" *ngIf="getLanguage() == 'en'">
                              {{addOnKeyValue.value.translatedName(getLanguage())}}
                              <ng-container
                                *ngFor="let subParameter of selectedQuote.addOnsMap[addOnKeyValue.key].subParametersMap| keyvalue : sort">
                                <ng-container *ngIf="subParameter.value.isSelected">
                                  ({{subParameter.value.translatedName(getLanguage())}})
                                </ng-container>
                              </ng-container>
                            </h5>
                          </th>
                          <ng-container
                            *ngFor="let subParameter of selectedQuote.addOnsMap[addOnKeyValue.key].subParametersMap| keyvalue : sort">
                            <ng-container *ngIf="subParameter.value.isSelected">
                              <ng-container
                                *ngFor="let carSizeKeyValue of subParameter.value.subParametersMap| keyvalue : sort">
                                <ng-container *ngIf="carSizeKeyValue.value.isSelected">
                                  <td class="dark-data-text">
                                    {{carSizeKeyValue.value.translatedName(getLanguage())}}
                                  </td>
                                </ng-container>
                              </ng-container>
                            </ng-container>
                          </ng-container>
                        </ng-container>

                        <ng-container *ngIf="addOnKeyValue.key !=='Replacement Car'">
                          <th>
                            <h5 class="light-data-text m-0" *ngIf="getLanguage() == 'en'">
                              {{addOnKeyValue.value.translatedName(getLanguage())}}</h5>
                          </th>
                          <td class="dark-data-text">{{'Included' | translate}}</td>
                        </ng-container>
                      </tr>
                    </ng-container>
                  </div>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
  </div>
  <div class="col-6" style="max-width: 45%;">
    <!-- Policy details -->
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body" style="padding: 1rem;">
          <h4 class="heading-text mb-4" *ngIf="isQuoteSummary">{{ 'Submitted Quote' | translate }}</h4>
          <h4 class="heading-text mb-4" *ngIf="!isQuoteSummary">{{ 'Submit Quote' | translate }}</h4>
          <div class="d-flex justify-content-between">
            <div>
              <p class="data-text" style="font-weight: 500;font-size: 20px;">{{'Supporting Documents' |
                translate}}</p>
            </div>
            <div>
              <span *ngIf="(getLanguage()=='en')">
                <button class="btn data-text uploadedImages" (click)="showViewImages = !showViewImages"> {{
                  uploadedimages }} / 10 <span class="material-icons dropdownbtn"
                    [ngClass]="showViewImages ? 'down-arrow' : 'right-arrow' ">arrow_forward_ios</span></button>
              </span>
              <span *ngIf="(getLanguage()=='ar')">
                <button class="btn data-text uploadedImages" (click)="showViewImages = !showViewImages"> {{
                  uploadedimages }} / 10 <span class="material-icons dropdownbtn"
                    [ngClass]="showViewImages ? 'up-arrow' : 'right-arrow' ">arrow_back_ios</span></button>
              </span>
            </div>
          </div>
          <div class="row quoteSummary" *ngIf="showViewImages">
            <div class="choose-file-section col-12 py-3 ml-3" style="max-width: 100% !important;">
              <ol class="images-list" style="border-bottom: 1px solid #98bcd5;">
                <div class="d-flex justify-content-between mb-2"
                  *ngFor="let imageIndex of imageList; let index = index">
                  <div>
                    <li class="registraton-card images-fields">
                      <label class="font-size-16">{{imageIndex |translate}}</label>
                    </li>
                  </div>
                  <div class="d-flex">
                    <div class="">
                      <label for="file-upload" class="custom-file-upload">
                        <button class="btn" style="text-decoration:none; padding:0.47rem 1.75rem;"
                          (click)="openRcCivilImage(index)">{{'View' | translate}}</button>
                      </label>
                    </div>
                    <div>
                      <div class="" *ngIf="index==0">
                        <label for="register-card" class="custom-file-upload">
                          <input type="file" name="register-card" id="register-card" (change)="onSelect($event)"
                            accept="image/*" [ngClass]="uploadedRc == 1 ? 'custom-input-upload' : 'custom-input' ">
                        </label>
                      </div>
                      <div class="" *ngIf="index==1">
                        <label for="civil-ID-front" class="custom-file-upload">
                          <input type="file" name="civil-ID-front" id="civil-ID-front" accept="image/*"
                            [ngClass]="uploadedCivilFront == 1 ? 'custom-input-upload' : 'custom-input' "
                            (change)="onSelect($event)">
                        </label>
                      </div>
                      <div class="" *ngIf="index==2">
                        <label for="civil-ID-back" class="custom-file-upload">
                          <input type="file" name="civil-ID-back" id="civil-ID-back" accept="image/*"
                            [ngClass]="uploadedCivilBack == 1 ? 'custom-input-upload' : 'custom-input' "
                            (change)="onSelect($event)">
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <li class="car-images images-fields"
                    [ngStyle]="{'text-align': ((getLanguage() == 'en') ? 'left' : 'right')}">
                    <label class="font-size-16">{{'Car Images (with Date, Time & Location)' |
                      translate}}</label>
                    <ol type="a">
                      <div class="d-flex justify-content-between mb-2"
                        *ngFor="let document of carImagesfields;  let paramIndex = index">
                        <div>
                          <li class="front-image images-fields">
                            <label class="font-size-16">{{ document |translate}}</label>
                          </li>
                        </div>
                        <div class="d-flex">
                          <div class="">
                            <label for="file-upload" class="custom-file-upload">
                              <button class="btn px-3" style="text-decoration:none; padding:0.47rem 1.75rem !important;"
                                (click)="open(paramIndex)">{{'View' | translate}}</button>
                            </label>
                          </div>
                          <div>
                            <div class="" *ngIf="paramIndex==0">
                              <label for="front-img" class="custom-file-upload">
                                <input type="file" name="front-img" id="front-img" accept="image/*"
                                  [ngClass]="uploadedCarImage_1 == 1 ? 'custom-input-upload' : 'custom-input' "
                                  (change)="onSelect($event)">
                              </label>
                            </div>
                            <div class="" *ngIf="paramIndex==1">
                              <label for="back-img" class="custom-file-upload">
                                <input type="file" name="back-img" id="back-img" accept="image/*"
                                  [ngClass]="uploadedCarImage_2 == 1 ? 'custom-input-upload' : 'custom-input' "
                                  (change)="onSelect($event)">
                              </label>
                            </div>
                            <div class="" *ngIf="paramIndex==2">
                              <label for="ls-img" class="custom-file-upload">
                                <input type="file" name="ls-img" id="ls-img" accept="image/*"
                                  [ngClass]="uploadedCarImage_3 == 1 ? 'custom-input-upload' : 'custom-input' "
                                  (change)="onSelect($event)">
                              </label>
                            </div>
                            <div class="" *ngIf="paramIndex==3">
                              <label for="rs-img" class="custom-file-upload">
                                <input type="file" name="rs-img" id="rs-img" accept="image/*"
                                  [ngClass]="uploadedCarImage_4 == 1 ? 'custom-input-upload' : 'custom-input' "
                                  (change)="onSelect($event)">
                              </label>
                            </div>
                            <div class="" *ngIf="paramIndex==4">
                              <label for="fw-img" class="custom-file-upload">
                                <input type="file" name="fw-img" id="fw-img" accept="image/*"
                                  [ngClass]="uploadedCarImage_5 == 1 ? 'custom-input-upload' : 'custom-input' "
                                  (change)="onSelect($event)">
                              </label>
                            </div>
                            <div class="" *ngIf="paramIndex==5">
                              <label for="chassis-num" class="custom-file-upload">
                                <input type="file" name="chassis-num" id="chassis-num" accept="image/*"
                                  [ngClass]="uploadedCarImage_6 == 1 ? 'custom-input-upload' : 'custom-input' "
                                  (change)="onSelect($event)">
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ol>
                  </li>
                </div>
                <div class="d-flex justify-content-between mb-1" *ngFor="let imageIndex of videoImg; let index = index">
                  <div>
                    <li class="registraton-card images-fields">
                      <label class="font-size-16">{{imageIndex |translate}}</label>
                    </li>
                  </div>
                  <div class="d-flex">
                  <div class="">
                    <label for="file-upload" class="custom-file-upload">
                      <button class="btn" style="text-decoration:none; padding:0.47rem 1.75rem;"
                        (click)="openVideo(index)">{{'View' | translate}}</button>
                    </label>
                  </div>
                  <div class="" *ngIf="index==0">
                    <label for="video-car" class="custom-file-upload">
                      <input type="file" name="video-car" id="video-car" accept="video/*"
                        [ngClass]="uploadedCarVideo == 1 ? 'custom-input-upload' : 'custom-input' "
                        (change)="onSelect($event)">
                    </label>
                  </div>
                  </div>
                </div>
              </ol>
            </div>
          </div>
          <div class="table-responsive mb-3">
            <table class="table table-centered table-nowrap mb-0">
              <tbody>
                <tr>
                  <th>
                    <h2 class="data-text">{{'Total Amount' | translate}}</h2>
                  </th>
                </tr>
                <ng-container *ngFor="let fee of selectedQuote?.fees">
                  <tr *ngIf="fee.amount > 0 && (comprehensiveAmount > 0 || limitedComprehensiveAmount > 0)">
                    <td>
                      <h5 class="light-data-text ml-3">{{ ((getLanguage() == 'en') ? fee.name :
                        fee.arabicName) | translate}}
                      </h5>
                    </td>
                    <td><small class="light-data-text"> {{fee.amount |
                        number:
                        '1.3-3'}} {{'KWD'|translate}}</small> </td>
                  </tr>
                </ng-container>

                <!-- <tr *ngIf="selectedQuote?.saiyartiAssistAmount > 0">
                                    <td>
                                        <h5 class="light-data-text ml-3">{{ 'Saiyarti Assist' |
                                            translate }}
                                        </h5>
                                    </td>
                                    <td><small class="light-data-text"> {{selectedQuote?.saiyartiAssistAmount | number:
                                            '1.3-3'}} KWD</small> </td>
                                </tr> -->
                <tr *ngIf="comprehensiveAmount > 0 || limitedComprehensiveAmount > 0">

                  <td>
                    <h5 class="light-data-text ml-3">{{ 'Total Comprehensive Premium' | translate }}
                    </h5>
                  </td>
                  <td *ngIf="comprehensiveAmount > 0"><small class="light-data-text">
                      {{comprehensiveAmount | number:
                      '1.3-3'}} {{'KWD'|translate}}</small> </td>
                  <td *ngIf="limitedComprehensiveAmount > 0"><small class="light-data-text">
                      {{limitedComprehensiveAmount | number:
                      '1.3-3'}} {{'KWD'|translate}}</small> </td>
                </tr>
                <tr *ngIf="selectedQuote.tplAmount != 0">
                  <td>
                    <h5 class="light-data-text ml-3">{{ 'Total TPL Premium' | translate }} </h5>
                  </td>
                  <td><small class="light-data-text"> {{selectedQuote.tplAmount | number: '1.3-3'}}
                      {{'KWD'|translate}}</small> </td>
                </tr>
                <tr *ngIf="selectedQuote.addOnAmount != 0">
                  <td>
                    <h5 class="light-data-text ml-3">{{ 'Total Add Ons Premium' | translate }} </h5>
                  </td>
                  <td><small class="light-data-text"> {{selectedQuote.addOnAmount | number: '1.3-3'}}
                      {{'KWD'|translate}}</small> </td>
                </tr>
                <tr>
                  <td>
                    <h5 class="heading-orange-text m-0" style="color: #DA512D;">{{ 'Total Payable' |
                      translate }}</h5>
                  </td>
                  <td><small class="dark-data-text"> {{selectedQuote.amount | number: '1.3-3'}}
                      {{'KWD'|translate}}</small> </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="mb-3 row" *ngIf="!isQuoteSummary && !isSubmitted">
            <div class="col-12 d-flex">
              <mat-checkbox
                *ngIf="rateCardSelections.tpl || rateCardSelections.limitedCoverage || rateCardSelections.comprehensive"
                class="mr-2 mt-1" [checked]="showPayNow" (change)="showPayNow = !showPayNow">
              </mat-checkbox>
              <p class="light-data-text"
                *ngIf="!rateCardSelections.tpl && !(rateCardSelections.limitedCoverage || rateCardSelections.comprehensive)">
                {{'I
                understand and accept the terms and conditions of my purchase.' | translate}} </p>
              <p class="light-data-text"
                *ngIf="rateCardSelections.tpl && !(rateCardSelections.limitedCoverage || rateCardSelections.comprehensive)">
                {{'I
                understand and accept the terms and conditions of my purchase.' | translate}} </p>
              <p class="light-data-text"
                *ngIf="!rateCardSelections.tpl && (rateCardSelections.limitedCoverage || rateCardSelections.comprehensive)">
                {{'I
                understand and accept the terms and conditions of my purchase. I agree to make the
                payment later once the Insurance Company approval is received.' | translate}} </p>
              <p class="light-data-text"
                *ngIf="rateCardSelections.tpl && (rateCardSelections.limitedCoverage || rateCardSelections.comprehensive)">
                {{'I
                understand and accept the terms and conditions of my purchase. I agree to make the
                payment for the Third Party policy now, and will pay the amount for Comprehensive
                Insurance later once the necessary approval is received.' | translate}} </p>
            </div>
          </div>
          <!-- <div class="text-center d-flex justify-content-between" *ngIf="!isQuoteSummary"> -->
          <div class="text-center d-flex justify-content-between">
            <!-- <button type="button" class="btn back-btn px-4 dark-data-text" (click)="makeBackEvent()"> Back
                        </button> -->
            <button type="button" class="btn back-btn px-4 dark-data-text" (click)="makeBackEvent()">{{
              'Back' | translate }}</button>
            <button *ngIf="comprehensiveAmount === 0 && limitedComprehensiveAmount === 0" type="button"
              class="btn  px-4" style="background-color: #DA512D;color: white;font: normal normal bold 16px/5px Roboto;"
              data-toggle="modal" data-target="#deliveryAddress" [disabled]="!showPayNow">
              <i *ngIf="showSubmitQuoteLoader"
                class="bx bx-loader bx-spin submit-quote-loader font-size-16 align-middle mr-2"></i> {{
              'Make Payment' | translate }} </button>

            <button *ngIf="comprehensiveAmount > 0 || limitedComprehensiveAmount > 0" type="button" class="btn  px-4"
              style="background-color: #DA512D;color: white;font: normal normal bold 16px/5px Roboto;"
              data-toggle="modal" data-target="#emailAddress" [disabled]="!showPayNow">
              <i *ngIf="showSubmitQuoteLoader"
                class="bx bx-loader bx-spin submit-quote-loader font-size-16 align-middle mr-2"></i> {{
              ( isPaymentDone ? 'Payment Done' : 'Submit Quote' ) |
              translate }} </button>
            <div class="modal fade" tabindex="-1" role="dialog" id="deliveryAddress" aria-labelledby="Delivery Address"
              aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title mt-0">{{ 'Enter contact details' | translate }}</h5>
                    <button #closeModelDelivery type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <form #submitQuoteForm="ngForm" (ngSubmit)="makePayment();closeModelDelivery.click();">
                      <div class="form-group text-left">
                        <label class="pl-1 mb-1" for="deliveryAddress"
                          [ngStyle]="{'text-align': ((getLanguage() == 'en') ? 'left' : 'right')}">{{'Delivery
                          Address' | translate }}</label>
                        <textarea class="form-control resize-none" id="deliveryAddress" rows="3"
                          placeholder="{{'Enter full address'|translate}}" [(ngModel)]="deliveryAddress"
                          name="deliveryAddress" #tDeliveryAddress="ngModel" required></textarea>
                        <div class="position-absolute pl-1 lh-1"
                          *ngIf="tDeliveryAddress.invalid && tDeliveryAddress.touched">
                          <small class="error" *ngIf="tDeliveryAddress.hasError('required')">{{ 'Delivery
                            Address is required' | translate }}</small>
                        </div>
                      </div>
                      <div class="form-group">
                        <button type="submit" [disabled]="submitQuoteForm.invalid"
                          class="btn btn-primary waves-effect waves-light"
                          [class.d-none]="carDetails.insuranceType == PlanType.TPLANDCOMPREHENSIVE" #tSubmitQuote> {{
                          'Pay Now' | translate }} </button>
                      </div>
                    </form>
                  </div>
                  <!-- <div class="modal-footer">
                                        <form #payNowForm="ngForm" (ngSubmit)="makePayment(payNowForm)">
                                            <div class="text-center">
                                                <button type="submit" [disabled]="payNowForm.invalid" class="btn btn-primary waves-effect waves-light w-md">
                                                    <i *ngIf="showPayNowLoader" class="bx bx-loader bx-spin pay-now-loader font-size-16 align-middle mr-2"></i>{{'customer.quotePlan.payNow' | translate}}
                                                </button>
                                            </div>
                                        </form>
                                    </div> -->
                </div>
              </div>
            </div>

            <div class="modal fade" tabindex="-1" role="dialog" id="emailAddress" aria-labelledby="Email Address"
              aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title mt-0">{{ 'Enter contact details' | translate }}</h5>
                    <button #closeModelEmail type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <form #submitQuoteComprehensiveForm="ngForm" (ngSubmit)="makePayment(); closeModelEmail.click();">
                      <div class="form-group text-left">
                        <label class="pl-1 mb-1" for="emailAddress"
                          [ngStyle]="{'text-align': ((getLanguage() == 'en') ? 'left' : 'right')}">{{'Email
                          Address' | translate }}</label>
                        <input class="form-control resize-none" id="deliveryAddress" rows="3"
                          placeholder="Enter email address" [(ngModel)]="emailAddress"
                          [disabled]="(emailAddress?.length ?? 0) > 0" name="emailAddress" #tEmailAddress="ngModel"
                          required>
                        <div class="position-absolute pl-1 lh-1" *ngIf="tEmailAddress.invalid && tEmailAddress.touched">
                          <small class="error" *ngIf="tEmailAddress.hasError('required')">{{
                            'Email
                            Address is required' | translate }}</small>
                        </div>
                        <div class="mt-3" *ngIf="this.selectedQuote?.tplAmount > 0">
                          <label class="pl-1 mb-1" for="deliveryAddress"
                            [ngStyle]="{'text-align': ((getLanguage() == 'en') ? 'left' : 'right')}">{{'Delivery
                            Address' | translate }}</label>
                          <textarea class="form-control resize-none" id="deliveryAddress" rows="3"
                            placeholder="{{'Enter full address'|translate}}" [(ngModel)]="deliveryAddress"
                            name="deliveryAddress" #tDeliveryAddress="ngModel" required></textarea>
                          <div class="position-absolute pl-1 lh-1"
                            *ngIf="tDeliveryAddress.invalid && tDeliveryAddress.touched">
                            <small class="error" *ngIf="tDeliveryAddress.hasError('required')">{{ 'Delivery
                              Address is required' | translate }}</small>
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <button [disabled]="submitQuoteComprehensiveForm.invalid" type="submit"
                          class="btn btn-primary waves-effect waves-light" #tSubmitQuote> {{
                          'Submit Quote' |
                          translate }} </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end col -->
  </div>
</div>
<!-- end row -->
<!-- View Car Images modal -->
<!-- <div class="modal fade modal-view-rc-civilId-images" tabindex="-1" role="dialog" aria-labelledby="RC/Civil ID Model" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title mt-0">{{ 'partner.requestDetails.RCAndCivilCard' | translate }}</h5>
                <button #viewRCCivilIdImagesModal type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" >
                <div class="row my-auto w-100 product-detai-imgs align-items-center">
                    <div class="col-md-2 col-sm-3 col-4">
                        <ul ngbNav #nav="ngbNav" [(activeId)]="activeImage" class="nav-pills" orientation="vertical">
                            <li ngbNavItem="{{ index }}" *ngFor="let imageIndex of [].constructor(3); let index = index">
                                <a ngbNavLink class="nav-link">
                                    <img src="{{ getImageURL( index ) }}" alt="" class="img-fluid mx-auto d-block rounded image-icons">
                                </a>
                                <ng-template ngbNavContent>
                                    <div class="tab-pane image-container d-flex">
                                        <img src="{{ getImageURL( index ) }}" alt="" class="img-fluid m-auto d-block">
                                    </div>
                                </ng-template>
                            </li>
                        </ul>
                    </div>
                    <div class="col-md-7 offset-md-1 col-sm-9 col-8">
                        <div [ngbNavOutlet]="nav" class="ml-4"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- /.modal -->
<!-- confirmation modal -->
<div class="modal fade modal-confirmation" tabindex="-1" role="dialog" aria-labelledby="Confirmation Modal"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <div class="col-12 mb-2">
          <h5 class="modal-title mt-0">{{ 'The comprehensive is subject to insurer approval. The current
            payment is for TPL. Please proceed to make payment.' | translate }}</h5>
        </div>
        <div class="form-group text-right">
          <button #closeConfirmationModel type="button" class="close d-none" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <button class="btn btn-primary waves-effect waves-light"
            (click)="tSubmitQuote?.nativeElement?.click();closeConfirmationModel.click();">{{ 'Ok' |
            translate }}</button>
        </div>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
<div class="row" *ngIf="!showReviewSubmit">
  <div class="col-6">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title mb-1">{{'Quote Submitted Successfully!' | translate}}</h5>
            <p *ngIf="rateCardSelections.tpl && !rateCardSelections.comprehensive"> {{'Thank you for
              choosing to buy a Third Party Liability policy. Your payment will be processed now and the
              policy document will be delivered to your preferred address within 2 working days. You may
              call us at +965 67734711 or email us at info@saiyarti.com for any further queries that you
              may have.' | translate}} </p>
            <p *ngIf="!rateCardSelections.tpl && rateCardSelections.comprehensive"> {{'Thank you for
              submitting your requirements for a comprehensive insurance. The quote generated here is
              valid till \'quote expire date\' and is subject to Insurance Company\'s approval. Based on
              the vehicle data and photographs shared by you the Insurance company will confirm the final
              pricing and terms and conditions of the quote. We will revert to you at earliest and send
              you the payment link to proceed once finalized. You may call us at +965 67734711 or email us
              at info@saiyarti.com for any further queries that you may have.' | translate}} </p>
            <p *ngIf="rateCardSelections.tpl && rateCardSelections.comprehensive"> {{'Thank you for
              choosing to buy a Third Party Liability insurance along with the Comprehensive Insurance for
              your vehicle. The Third Party policy payment will be completed now, and the policy document
              will be sent to your preferred address within 2 working days. The quote generated for
              comprehensive insurance is valid till \'quote expire date\', and is subject to Insurance
              Company\'s approval. Based on the vehicle data and photographs shared by you, the Insurance
              company will confirm the final pricing and terms and conditions of the comprehensive quote.
              We will revert to you with confirmation of the comprehensive insurance at earliest and send
              you the payment link to proceed for that payment. You may call us at +965 67734711 or email
              us at info@saiyarti.com for any further queries that you may have.' | translate}} </p>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title mb-1">{{'Payment Options' | translate}}</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-6">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title mb-1">{{'Payement Details' | translate}}</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
