<div class="page-content">
    <div class="container-fluid">
        <!-- start page title -->
        <div class="row">
            <div class="col-12">
                <div class="page-title-box d-flex align-items-center justify-content-between">
                    <h4 class="mb-0 font-size-18">{{ 'Vehicle Details' | translate }}</h4>
                    <!-- <div class="page-title-right">
                        <ol class="breadcrumb m-0">
                            <li class="breadcrumb-item"><a [routerLink]="['/vehicles']">{{ 'Vehicles' | translate }}</a></li>
                            <li class="breadcrumb-item active">{{ 'Vehicle Details' | translate }}</li>
                        </ol>
                    </div> -->
                </div>
            </div>
        </div>
        <!-- end page title -->
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xl-5 d-flex" *ngIf="carImages && carImages.length > 0">
                                <div class="row my-auto w-100 product-detai-imgs align-items-center">
                                    <div class="col-md-2 col-sm-3 col-4">
                                        <ul ngbNav #nav="ngbNav" [(activeId)]="activeCarImage" class="nav-pills"
                                            orientation="vertical">
                                            <li ngbNavItem="{{ image.name }}" *ngFor="let image of carImages">
                                                <a ngbNavLink class="nav-link">
                                                    <img src="{{ getCarImageURL( image ) }}" alt=""
                                                        class="img-fluid mx-auto d-block rounded image-icons">
                                                </a>
                                                <ng-template ngbNavContent>
                                                    <div class="tab-pane image-container d-flex">
                                                        <img src="{{ getCarImageURL( image ) }}" alt=""
                                                            class="img-fluid m-auto d-block">
                                                    </div>
                                                </ng-template>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="col-md-7 offset-md-1 col-sm-9 col-8">
                                        <div [ngbNavOutlet]="nav" class="ml-4"></div>
                                        <div class="text-center" *ngIf="vehicleDetails && !currentQuote">
                                            <a [routerLink]="['/vehicle', 'add', vehicleDetails.id]"
                                                class="btn btn-primary waves-effect waves-light mt-2 mr-1">
                                                <i class="bx bxs-car mr-2"></i> {{'Edit Car' | translate}} </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-7" [class.col-xl-12]="carImages && carImages.length == 0">
                                <div class="mt-4 mt-xl-3">
                                    <h4 class="mt-1 mb-3">{{ 'Vehicle Details' | translate }}</h4>
                                    <div class="row mb-3 pl-2" *ngIf="vehicleDetails">
                                        <div class="col-md-6">
                                            <p class="">{{ 'Model Year' | translate }}</p>
                                        </div>
                                        <div class="col-md-6"> {{ vehicleDetails.modelYear }} </div>
                                        <div class="col-md-6">
                                            <p class="">{{ 'Car Make' | translate }}</p>
                                        </div>
                                        <div class="col-md-6"> {{ vehicleDetails.carMake }} </div>
                                        <div class="col-md-6">
                                            <p class="">{{ 'Car Model' | translate }}</p>
                                        </div>
                                        <div class="col-md-6"> {{ vehicleDetails.carModel }} </div>
                                        <div class="col-md-6">
                                            <p class="">{{ 'Variant' | translate }}</p>
                                        </div>
                                        <div class="col-md-6"> {{ vehicleDetails.variant }} </div>
                                        <div class="col-md-6">
                                            <p class="">{{ 'Market Value' | translate }}</p>
                                        </div>
                                        <div class="col-md-6">
                                            {{'KWD'|translate}} {{ vehicleDetails.marketValue | number : '1.3-3'}}
                                        </div>
                                        <div class="col-md-6">
                                            <p class="">{{ 'Plate Number' | translate }}</p>
                                        </div>
                                        <div class="col-md-6"> {{ vehicleDetails.plateNumber }} </div>
                                    </div>
                                    <div class="row pl-2" *ngIf="vehicleDetails"
                                        [class.justify-content-around]="carImages && carImages.length == 0">
                                        <div class="img-thumbnail" [class.col-md-4]="carImages && carImages.length > 0"
                                            [class.col-md-3]="carImages && carImages.length == 0">
                                            <p class=" text-center">{{ 'Vehicle RC Image' | translate }}</p>
                                            <a href="{{ SASTokenForRCImageRead ? getRCImageURL() : '' }}"
                                                target="_blank"><img
                                                    src="{{ SASTokenForRCImageRead ? getRCImageURL() : 'assets/images/companies/img-6.png' }}"
                                                    onerror="this.src='assets/images/companies/img-6.png';" alt=""
                                                    class="img-fluid mx-auto d-block"></a>
                                        </div>
                                        <div class="img-thumbnail" [class.col-md-4]="carImages && carImages.length > 0"
                                            [class.col-md-3]="carImages && carImages.length == 0">
                                            <p class=" text-center">{{ 'Civil ID Front Image' | translate }}</p>
                                            <a href="{{ SASTokenForCivilIdFrontImageRead ? getCivilIdFrontImageURL() : '' }}"
                                                target="_blank"><img
                                                    src="{{ SASTokenForCivilIdFrontImageRead ? getCivilIdFrontImageURL() : 'assets/images/companies/img-6.png' }}"
                                                    onerror="this.src='assets/images/companies/img-6.png';" alt=""
                                                    class="img-fluid mx-auto d-block"></a>
                                        </div>
                                        <div class="img-thumbnail" [class.col-md-4]="carImages && carImages.length > 0"
                                            [class.col-md-3]="carImages && carImages.length == 0">
                                            <p class=" text-center">{{ 'Civil ID Back Image' | translate }}</p>
                                            <a href="{{ SASTokenForCivilIdBackImageRead ? getCivilIdBackImageURL() : '' }}"
                                                target="_blank"><img
                                                    src="{{ SASTokenForCivilIdBackImageRead ? getCivilIdBackImageURL() : 'assets/images/companies/img-6.png' }}"
                                                    onerror="this.src='assets/images/companies/img-6.png';" alt=""
                                                    class="img-fluid mx-auto d-block"></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- end row -->
                        <div class="mt-5" *ngIf="currentQuote">
                            <h5 class="mb-3">{{ 'Submitted Quote' | translate }}</h5>
                            <div class="table-responsive">
                                <table class="table mb-0 table-bordered">
                                    <tbody>
                                        <tr>
                                            <th scope="row" style="width: 400px;">{{ 'Insurance Company' | translate }}
                                            </th>
                                            <td>{{ currentQuote.insurer.name }}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">{{ 'Amount' | translate }}</th>
                                            <td>{{'KWD'|translate}} {{ currentQuote.addOnAmount + currentQuote.comprehensiveAmount |
                                                number:'1.3-3' }}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">{{ 'Quote Submission Date' | translate }}</th>
                                            <td>{{ currentQuote.policyStartDate | date:'dd MMM yyyy':'locale' }}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">{{ 'Quotation Expiry Date' | translate }}</th>
                                            <td>{{ currentQuote.policyExpiryDate | date:'dd MMM yyyy':'locale' }}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">{{ 'Quote Status' | translate }}</th>
                                            <td>
                                                <span class="status-badge badge badge-soft-primary"
                                                    [class.badge-soft-success]="currentQuote.status == QuoteStatus.ADMIN_APPROVED"
                                                    [class.badge-soft-danger]="currentQuote.status == QuoteStatus.ADMIN_REJECTED"
                                                    [class.badge-soft-success]="currentQuote.status == QuoteStatus.CUSTOMER_ACCEPTED"
                                                    [class.badge-soft-danger]="currentQuote.status == QuoteStatus.CUSTOMER_REJECTED"
                                                    [class.badge-soft-info]="currentQuote.status == QuoteStatus.DELIVERED || currentQuote.status == QuoteStatus.POLICY_PRINTED || currentQuote.status == QuoteStatus.PAYMENT_DONE || currentQuote.status == QuoteStatus.DISPATCHED">
                                                    {{ currentQuote.status ? (currentQuote.status | translate ): '-'}}
                                                </span>
                                            </td>
                                        </tr>
                                        <!-- <tr>
                                            <th scope="row"> {{ 'Declaration PDF' | translate }} </th>
                                            <td>
                                                <a (click)="downloadQuotePDF()"
                                                    class="btn btn-outline-info waves-effect waves-light">
                                                    <i class="bx bx-download font-size-16 align-middle mr-2"></i> Download</a>
                                            </td>
                                        </tr> -->
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <!-- end Specifications -->
                    </div>
                </div>
                <!-- end card -->
            </div>
        </div>
        <!-- end row -->
    </div> <!-- container-fluid -->
</div>
<!-- End Page-content -->

<div class="position-absolute" style="top:-10000000px; left:-10000000px;">
    <div id="quotePDF" style="padding:20px;" *ngIf="currentQuote">
        <p style="margin-bottom: 10px;font-weight: 700;">Car Details</p>
        <table style="border-collapse: collapse;border: 1px solid #000;">
            <tr>
                <th style="border-bottom:1px solid #000;border-right:1px solid #000; padding:10px;font-weight: 600;">Car
                    Make</th>
                <td style="border-bottom:1px solid #000;padding:10px;font-weight: 500;">{{vehicleDetails.carMake}}</td>
            </tr>
            <tr>
                <th style="border-bottom:1px solid #000;border-right:1px solid #000; padding:10px;font-weight: 600;">Car
                    Model</th>
                <td style="border-bottom:1px solid #000;padding:10px;font-weight: 500;">{{vehicleDetails.carModel}}</td>
            </tr>
            <tr>
                <th style="border-bottom:1px solid #000;border-right:1px solid #000; padding:10px;font-weight: 600;">
                    Model Year</th>
                <td style="border-bottom:1px solid #000;padding:10px;font-weight: 500;">{{vehicleDetails.modelYear}}
                </td>
            </tr>
            <tr>
                <th style="border-bottom:1px solid #000;border-right:1px solid #000; padding:10px;font-weight: 600;">Car
                    Variant</th>
                <td style="border-bottom:1px solid #000;padding:10px;font-weight: 500;">{{vehicleDetails.variant}}</td>
            </tr>
            <tr>
                <th style="border-right:1px solid #000; padding:10px;font-weight: 600;">Market Value</th>
                <td style="padding:10px;font-weight: 500;">{{'KWD'|translate}} {{vehicleDetails.marketValue | number: '1.3-3'}}</td>
            </tr>
        </table>
        <p style="margin-bottom: 10px;margin-top:20px;font-weight: 700;">Quote Parameter</p>
        <table style="border-collapse: collapse;border: 1px solid #000;">
            <tr>
                <th colspan="2" style="border-bottom:1px solid #000; padding:10px;">Rate Card Parameter</th>
            </tr>
            <ng-template ngFor let-rateCard [ngForOf]="currentQuote.parametersMap | keyvalue">
                <ng-template [ngIf]="rateCard.value.isSelected">
                    <tr>
                        <th
                            style="border-bottom:1px solid #000;border-right:1px solid #000; padding:10px;font-weight: 600;">
                            {{rateCard.value.name}}</th>
                        <td style="border-bottom:1px solid #000;padding:10px;font-weight: 500;"
                            innerHTML="{{getRateCardValue(currentQuote)}}"></td>
                    </tr>
                </ng-template>
            </ng-template>
            <tr *ngIf="currentQuote.addOnsMap">
                <th colspan="2" style="border-bottom:1px solid #000; padding:10px;">Add Ons</th>
            </tr>
            <ng-template ngFor let-addOns [ngForOf]="currentQuote.addOnsMap | keyvalue">
                <ng-template [ngIf]="addOns.value.isSelected">
                    <tr>
                        <th
                            style="border-bottom:1px solid #000;border-right:1px solid #000; padding:10px;font-weight: 600;">
                            {{addOns.value.name}}</th>
                        <td style="border-bottom:1px solid #000;padding:10px;font-weight: 500;">{{'KWD'|translate}}
                            {{addOns.value.valueForInsurer | number: '1.3-3'}}</td>
                    </tr>
                </ng-template>
            </ng-template>
            <tr>
                <th colspan="2" style="border-bottom:1px solid #000; padding:10px;">Fees Structure</th>
            </tr>
            <tr *ngFor="let fee of currentQuote.fees">
                <th style="border-bottom:1px solid #000;border-right:1px solid #000; padding:10px;font-weight: 600;">
                    {{fee.name}}</th>
                <td style="border-bottom:1px solid #000;padding:10px;font-weight: 500;">{{'KWD'|translate}} {{fee.amount | number:
                    '1.3-3'}}</td>
            </tr>
            <tr>
                <th style="border-right:1px solid #000; padding:10px;font-weight: 600;">Comprehensive Total Amount</th>
                <td style="padding:10px;font-weight: 500;">{{'KWD'|translate}} {{currentQuote.addOnAmount +
                    currentQuote.comprehensiveAmount | number: '1.3-3'}}</td>
            </tr>
        </table>
    </div>
</div>
