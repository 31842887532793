import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as HesabeCrypt from 'hesabe-crypt';
import * as aesjs from 'aes-js';
import { MatSnackBar as MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from 'src/app/services/translate.service';
import { environment } from 'src/environments/environment';
import { AccountService } from 'src/app/services/account.service';
import { PaymentService } from 'src/app/services/payment.service';
import { CompleteTransactionRequestBody } from 'src/app/models/paymentModel';
import { Lightbox } from 'ngx-lightbox';
import { StorageService } from 'src/app/services/storage.service';
declare let $: any;

@Component({
  selector: 'app-tpl-details',
  templateUrl: './tpl-details.component.html',
  styleUrls: ['./tpl-details.component.scss']
})
export class TplDetailsComponent implements OnInit, OnDestroy {

  paymentDataBaseId: string;
  tplPurchaseId: number;
  rcBookId: number;
  civilCardId: number;
  amountPaid: number;
  token: string;
  payment: HesabeCrypt;
  hasPaymentSuccess: boolean;
  paymentToken: string;
  paymentId: string;
  deliveryAddress: string;
  deliveryInstructions: string;
  email: string;
  civilCardFrontFileName: string;
  civilCardBackFileName: string;
  rcBookkFileName: string;
  vehicleId: string;

  // Comprehensive Text variable
  showComprehensiveText: string;

  // Documents upload token.
  ccFrontSRC: string;
  ccBackSRC: string;
  rcBookSRC: string;
  SASTokenForUploadCivil: string;
  SASTokenForReadCivilFront: string;
  SASTokenForReadCivilBack: string;
  SASTokenForUploadRCBook: string;
  SASTokenForReadRCBook: string;
  // TODO: Check if any upload is needed
  // AzureConfig: UploadParams = {
  //   sas: '',
  //   storageAccount: environment.storageAccount,
  //   containerName: 'civil-card'
  // };

  // uploadConfig: UploadConfig;

  //#region Storage API

  SASTokenForRCImageRead: string;
  SASTokenForCivilIdFrontImageRead: string;
  SASTokenForCivilIdBackImageRead: string;
  quoteExpiryDate: any = '';

  //#endregion

  constructor(private snackBar: MatSnackBar,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private accountService: AccountService,
    private paymentService: PaymentService,
    private translateService: TranslateService,
    private lightbox: Lightbox,
    private storageService: StorageService) {
    document.body.classList.add("ngx-select");

    const secret = environment.paymetGateway.secret;
    const ivKey = environment.paymetGateway.ivKey;

    const key = aesjs.utils.utf8.toBytes(secret);
    const iv = aesjs.utils.utf8.toBytes(ivKey);
    this.payment = new HesabeCrypt(key, iv);

    this.activatedRoute.queryParams.subscribe(
      params => {
        let isTPLPayment: boolean
        if (params.quoteExpiryDate) {
          this.quoteExpiryDate = params.quoteExpiryDate;
        }
        if (params.data) {
          const decrypted = this.payment.decryptAes(params.data);
          const paymentResponse = JSON.parse(decrypted);
          this.hasPaymentSuccess = paymentResponse.code === 1;

          this.setLang(paymentResponse.response.variable4);

          this.tplPurchaseId = +paymentResponse.response.variable1;
          this.paymentToken = paymentResponse.response.paymentToken;
          this.paymentId = paymentResponse.response.paymentId;
          isTPLPayment = paymentResponse.response.variable5 == "1";
          if (isTPLPayment) {
            let paymentData = paymentResponse.response.variable3.split(",");

            this.paymentDataBaseId = paymentResponse.response.variable2;
            this.civilCardId = +paymentData[0];
            this.rcBookId = +paymentData[1];
            this.vehicleId = paymentData[2];
            this.amountPaid = paymentResponse.response.amount;
          }

          // For comprehensive text
          var url = new URLSearchParams(window.location.search);
          this.showComprehensiveText = url.get('isComprehensive');

        }

        if (params.token) {
          this.token = params.token;
        }

        if (this.paymentDataBaseId) {
          this.paymentService.completeTransactionForTPL(this.paymentDataBaseId, new CompleteTransactionRequestBody(
            this.paymentId,
            this.paymentToken,
            undefined,
            undefined,
            this.amountPaid,
            this.hasPaymentSuccess ? 'success' : 'failure',
            this.civilCardId,
            this.vehicleId), this.token).subscribe(res => {
              accountService.getUploadOnlyTokenForContainer('civil-card', this.token).subscribe(token => {
                this.SASTokenForUploadCivil = token.sasToken;

                // this.getCivilCardImages();
              });

              accountService.getUploadOnlyTokenForContainer('rc-book', this.token).subscribe(token => {
                this.SASTokenForUploadRCBook = token.sasToken;

                // this.getRCImages();
              });
            }, error => {
              this.snackBar.open("Error occur while Completing Transaction. Please contact admin.", 'CANCEL');
            });
        } else {
          this.snackBar.open("Error occur while Completing Transaction. Please contact admin.", 'CANCEL');
        }
      }
    );
  }

  ngOnDestroy(): void {
    document.body.classList.remove("ngx-select");
  }

  ngOnInit() { }

  setLang(lang: string) {
    this.translateService.use(lang);
    document.body.classList.remove('en')
    document.body.classList.remove('ar')
    document.body.classList.add(lang);
  }

  updateDetails(form) {
    const data = {
      tplPurchaseId: this.paymentDataBaseId,
      deliveryAddress: this.deliveryAddress,
      deliveryInstructions: this.deliveryInstructions,
      email: this.email
    };
    this.accountService.updateDetails(data, this.token).subscribe(res => {
      this.scrollToTop();
      this.router.navigate(['/tpl-success']);
    }, err => {
      this.snackBar.open(err, 'CANCEL', {
        duration: 2000,
      });
    });
  }

  scrollToTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  // getRCImages(): void {
  //   if ( this.vehicleId && this.vehicleId == "" ) {
  //     return;
  //   }
  //   this.storageService.getReadTokenForRCImage( this.vehicleId ).subscribe( readTokenResposne => {
  //     this.SASTokenForRCImageRead = readTokenResposne.sasToken;

  //     this.rcBookSRC = environment.cdnUrl + "/" + environment.rcImageContainer + "/" + this.vehicleId + this.SASTokenForRCImageRead;

  //     this.fetchFile( this.rcBookSRC, 'rc_book.jpg', 'rcBook' );
  //   }, error => {});
  // }

  // getCivilCardImages(): void {
  //   if ( this.vehicleId && this.vehicleId == "" ) {
  //     return;
  //   }
  //   // Front Image
  //   this.storageService.getReadTokenForCivilImage( this.vehicleId + this.storageService.civilCardFront ).subscribe( readTokenResposne => {
  //     this.SASTokenForCivilIdFrontImageRead = readTokenResposne.sasToken;

  //     this.ccFrontSRC = environment.cdnUrl + "/" + environment.civilCardImageContainer + "/" + this.vehicleId + this.storageService.civilCardFront + this.SASTokenForCivilIdFrontImageRead;

  //     this.fetchFile( this.ccFrontSRC, 'civil_card_front.jpg', 'civilCardFront' );
  //   }, error => {});

  //   // Back Image
  //   this.storageService.getReadTokenForCivilImage( this.vehicleId + this.storageService.civilCardBack ).subscribe( readTokenResposne => {
  //     this.SASTokenForCivilIdBackImageRead = readTokenResposne.sasToken;

  //     this.ccBackSRC = environment.cdnUrl + "/" + environment.civilCardImageContainer + "/" + this.vehicleId + this.storageService.civilCardBack + this.SASTokenForCivilIdBackImageRead;

  //     this.fetchFile( this.ccBackSRC, 'civil_card_back.jpg', 'civilCardBack' );
  //   }, error => {});
  // }


}
