<div class="home-btn d-none d-sm-block position-absolute" style="direction: ltr !important;">
    <span style="background-color: #da512d;border-radius: 5px;" class="mx-3 px-3 py-2">
        <span [class.text-muted]="curLang == 'en'" (click)="setLang('ar')"
            style="cursor: pointer;color: white;">AR</span>
        <span class="ml-2" style="color: white;"> | </span>
        <span [class.text-muted]="curLang == 'ar'" (click)="setLang('en')" class="ml-2"
            style="cursor: pointer;color: white;">EN</span>
    </span>
    <a class="text-dark" style="vertical-align: -webkit-baseline-middle; cursor: pointer;" href="https://saiyarti-website-dev.azurewebsites.net/comprehensive"><i
            class="fas fa-home h2"></i></a>
</div>
<div class="account-pages" [dir]="isRtl ? 'rtl' : 'ltr'">
    <div class="container d-flex vh-100">
        <div class="row m-auto justify-content-center">
            <div class="col-8 my-5" style="width: 700px;">
                <div class="card overflow-hidden">
                    <div class="bg-new-primary">
                        <div class="row">
                            <div class="col-7">
                                <div class="p-3">
                                    <h5 class="mid-white-text">{{'Welcome!' | translate}}</h5>
                                    <p class="light-white-text">{{'Log in to continue to Saiyarti!' | translate }}</p>
                                </div>
                            </div>
                            <div class="col-5 align-self-end p-2">
                                <img src="../../../assets/images/saiyarti_login_image.png" alt="" class="img-fluid">
                            </div>
                        </div>
                    </div>
                    <div class="card-body pt-0">
                        <!-- <div>
                            <a href="javascript::void()">
                                <div class="avatar-md profile-user-wid mb-4">
                                    <span class="avatar-title rounded-circle bg-light">
                                        <img src="assets/images/logo.svg" alt="" class="rounded-circle" height="34">
                                    </span>
                                </div>
                            </a>
                        </div> -->
                        <div class="p-2 mt-3">
                            <form class="form-horizontal" #loginFormCustomer="ngForm"
                                (ngSubmit)="logInCustomer(loginFormCustomer)">
                                <div class="row">
                                    <div class="form-group col-5 mb-0" *ngIf="enableCountryCodeView">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label> {{ 'Country Code' | translate }} </mat-label>
                                            <mat-select [(value)]="customerUserModel.countryCode">
                                                <mat-option value="965" selected>(+965) {{ curLang == 'en' ? "KUWAIT" : "الكويت" }}  </mat-option>
                                                <mat-option value="91">(+91) INDIA</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="form-group col-7 mb-0" *ngIf="enablePhoneView">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label> {{ 'Phone Number' | translate }} </mat-label>
                                            <input type="text" matInput numeric numericType="number"
                                                minlength="{{ customerUserModel.countryCode == '91' ? 10 :  8 }}"
                                                maxlength="{{ customerUserModel.countryCode == '91' ? 10 :  8 }}"
                                                name="phoneNumber" [(ngModel)]="customerUserModel.phoneNumber"
                                                placeholder="{{'Phone Number' | translate }}"
                                                #cusPhoneNumber="ngModel" required
                                                (change)="checkUser(customerUserModel.phoneNumber)">
                                            <mat-error
                                                *ngIf="cusPhoneNumber.invalid && cusPhoneNumber.touched &&cusPhoneNumber.hasError('required')">
                                                {{ 'phoneNumber.errorMsg.required' | translate }} </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="form-group col-12 mb-0" *ngIf="enableEmailView">
                                        <p class="light-data-text">{{'We do not have you in our records. Please enter
                                            remaining details to continue' | translate}}</p>
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label> {{ 'login.user.email' | translate}} </mat-label>
                                            <input type="email" matInput name="email"
                                                [(ngModel)]="customerUserModel.email"
                                                placeholder="{{'login.user.email' | translate }}" #cusEmail="ngModel"
                                                required>
                                            <mat-error
                                                *ngIf="cusEmail.invalid && cusEmail.touched && cusEmail.hasError('required')">
                                                {{ 'email.errorMsg.required' | translate }} </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="form-group col-12 mb-0" *ngIf="enableNameView">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>{{ 'Name' | translate }}</mat-label>
                                            <input type="text" matInput name="name" [(ngModel)]="customerUserModel.name"
                                                placeholder="{{ 'Name' | translate }}" #cusName="ngModel" required>
                                            <mat-error
                                                *ngIf="cusName.invalid && cusName.touched && cusName.hasError('required')">
                                                {{ 'Name Error Required' | translate }} </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="form-group col-12 mb-0"
                                        *ngIf="!enableVerifyOtpView && customerUserModel.referrerId && customerUserModel.referrerId != ''">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Referrer</mat-label>
                                            <input type="text" matInput name="referrer"
                                                [(ngModel)]="customerUserModel.referrer" placeholder="Referrer Name"
                                                disabled>
                                        </mat-form-field>
                                    </div>
                                    <div class="form-group col-12" *ngIf="enableVerifyOtpView">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <!-- <mat-label>OTP</mat-label> -->
                                            <input type="text" matInput numeric numericType="number" maxlength="6"
                                                name="otp" [(ngModel)]="customerUserModel.otp" placeholder="······"
                                                #cusOTP="ngModel" [required]="enableVerifyOtpView"
                                                class="text-center font-size-18"
                                                style="letter-spacing: 25px; font-weight: bolder;"
                                                (keyup)="removeFocus(cusOTP.value)" id="otpField">
                                            <div class="position-absolute pl-1 resendOTP mt-2">
                                                <a (click)="sendOTP(false)" class="card-link light-data-text">{{
                                                    'login.user.resendOTP' | translate }}</a>
                                            </div>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-12 text-center loginBtn">
                                        <button class="btn btn-primary btn-block waves-effect waves-light btn-lg"
                                            [disabled]="loginFormCustomer.invalid" type="submit">
                                            <i *ngIf="showLoginLoader"
                                                class="bx bx-loader bx-spin login-loader font-size-16 align-middle mr-2"></i>{{(enableVerifyOtpView
                                            ? 'login.user.verifyOTP' : 'login.user.loginWithOTP') | translate}}
                                        </button>
                                        <span class="text-danger" *ngIf="isIncorrectOTP"> {{
                                            'login.user.invalidOTPErrorMsg' | translate }} </span>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal d-none" id="popUpModalLogin" role="dialog" aria-labelledby="popUpModalLogin">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-body">
            {{ 'The entered email Id is already existing in our system. Please type a different email ID. If you are an existing user, please type your registered phone number and email ID to login' | translate }}
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" (click) = "showModal(false)"> {{ 'ok' | translate }} </button>
        </div>
      </div>
    </div>
</div>