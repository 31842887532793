<div class="page-content">
    <div class="container-fluid">
        <!-- start page title -->
        <!-- <div class="row">
            <div class="col-12">
                <div class="page-title-box d-flex align-items-center justify-content-between">
                    <h4 class="mb-0 font-size-18">Dashboard</h4>

                </div>
            </div>
        </div> -->
        <!-- end page title -->
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div _ngcontent-nkv-c192="" class="d-flex align-items-center justify-content-between mb-4">
                            <h4 class="card-title mb-0" style="padding-top: 7px; padding-bottom: 7px;"> {{ 'All
                                Policies' | translate }}</h4>
                        </div>
                        <div class="table-responsive all-request">
                            <table class="table table-centered table-nowrap mb-0">
                                <thead class="thead-light">
                                    <tr>
                                        <th *ngFor="let column of displayedColumn" class="text-center">{{ column |
                                            translate }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="cursor-pointer" *ngFor="let order of allOrders; let index = index"
                                        (click)="orderDetails(order)">
                                        <!-- <td class="text-center">{{ index+1 }}</td> -->
                                        <td class="text-center">{{ order.rateCardId }}</td>
                                        <td class="text-center">{{ order.createdAt ? ( order.createdAt | date:'dd MMM
                                            yyyy':'locale' ) : '-'
                                            }}</td>
                                        <td class="text-center">{{ order.user.firstName ? order.user.firstName + ' ' +
                                            order.user.lastName : '-' }}</td>
                                        <td class="text-center">{{ order.vehicle.plateNumber ? order.vehicle.plateNumber
                                            : '-'
                                            }}</td>
                                        <td class="text-center">{{ order.insurer ? order.insurer.name : '-' }}</td>
                                        <td class="text-center">{{ order.type ? order.type : 'Comprehensive'
                                            }}</td>
                                        <td class="text-center">{{ order.policyNumber ? order.policyNumber : '-' }}</td>
                                        <td class="text-center"><span *ngIf="order.amount">{{'KWD'|translate}}</span> {{ order.amount
                                            ? ( order.amount | number:'1.3-3' ) : '-' }}</td>
                                        <td class="text-center">
                                            <span class="status-badge badge badge-soft-primary"
                                                [class.badge-soft-success]="order.status == QuoteStatus.APPROVED"
                                                [class.badge-soft-danger]="order.status == QuoteStatus.REJECTED"
                                                [class.badge-soft-info]="order.status == QuoteStatus.DELIVERED || order.status == QuoteStatus.POLICY_PRINTED || order.status == QuoteStatus.PAYMENT_DONE || order.status == QuoteStatus.DISPATCHED">
                                                {{ order.status ? (order.status | translate) : '-' }} </span>
                                        </td>
                                    </tr>
                                    <tr *ngIf="!showGetOrdersLoader && ( allOrders == null || allOrders.length == 0 )">
                                        <td colspan="8" class="text-center">{{'No order found' | translate}}</td>
                                    </tr>
                                    <tr *ngIf="showGetOrdersLoader">
                                        <td colspan="8" class="text-center">
                                            <div class="spinner-border text-primary m-1" role="status">
                                                <span class="sr-only">{{'Loading...' | translate}}</span>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- end table-responsive -->
                    </div>
                </div>
            </div>
        </div>
        <!-- end row -->
        <!-- end row -->
    </div>
    <!-- container-fluid -->
</div>
<!-- End Page-content -->
