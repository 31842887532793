<!-- <section *ngIf="isVerticalHeader">
    <header id="page-topbar">
        <div class="navbar-header">
            <div class="d-flex"> -->
<!-- LOGO -->
<!-- <div class="navbar-brand-box">
                    <a routerLink="/dashboard" class="logo logo-dark">
                        <span class="logo-sm">
                            <img src="assets/images/logo.svg" alt="" height="22">
                        </span>
                        <span class="logo-lg">
                            <img src="assets/images/logo-dark.png" alt="" height="17">
                        </span>
                    </a>
                    <a routerLink="/dashboard" class="logo logo-light">
                        <span class="logo-sm">
                            <img src="assets/images/logo-light.svg" alt="" height="22">
                        </span>
                        <span class="logo-lg">
                            <img src="assets/images/logo-light.png" alt="" height="19">
                        </span>
                    </a>
                </div>
                <button type="button"
                    class="btn btn-sm px-3 font-size-16 d-lg-none header-item waves-effect waves-light"
                    data-toggle="collapse" data-target="#topnav-menu-content">
                    <i class="fa fa-fw fa-bars"></i>
                </button>
            </div>
            <div class="d-flex">
                <div class="dropdown d-inline-block">
                    <ul>
                        <li>
                            <a href="javascript:void(0);" [class.text-muted]="curLang == 'en'"
                                (click)="setLang('ar')">AR</a>
                        </li>
                        <li>
                            <a href="javascript:void(0);" [class.text-muted]="curLang == 'ar'"
                                (click)="setLang('en')">EN</a>
                        </li>
                    </ul>
                    <button type="button" class="btn  px-4" style="background-color: #222B4A;color: white;"> {{'Contact
                        us' | translate}} <span class="material-icons">call</span>
                    </button>
                    <button type="button" class="btn header-item waves-effect" id="page-header-user-dropdown"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <img class="rounded-circle header-profile-user" src="assets/images/users/avatar-1.jpg"
                            alt="Header Avatar">
                        <span class="d-none d-xl-inline-block ml-1">{{ 'My Account' | translate }}</span>
                        <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                    </button>
                    <div class="dropdown-menu dropdown-menu-right">  -->
<!-- item  -->
<!-- <a class="dropdown-item" href="#"><i class="bx bx-user font-size-16 align-middle mr-1"></i> Profile</a> -->
<!-- <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logoutUser()"><i
                                class="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>{{ 'Logout' |
                            translate }}</a>
                    </div>
                </div>
            </div>
        </div>
    </header> -->
<!-- ========== Left Sidebar Start ========== -->
<!-- <div class="vertical-menu">
        <div data-simplebar class="h-100"> -->
<!--- Sidemenu -->
<!-- <div id="sidebar-menu"> -->
<!-- Left Menu Start -->
<!--   <ul class="metismenu list-unstyled" id="side-menu">
                    <li> -->
<!-- <a routerLink="/dashboard" class="waves-effect">
                            <i class="bx bx-home-circle"></i>
                            <span>Dashboards</span>
                        </a> -->
<!--   <ul class="sub-menu" aria-expanded="false">
                            <li><a routerLink="/request">{{ 'All Requests' | translate }}</a></li>
                            <li><a routerLink="/policies" *ngIf="userType == UserType.INSURER">{{ 'Policies' | translate
                                    }}</a></li>
                            <li><a routerLink="/users" *ngIf="userType == UserType.ADMIN">{{ 'All Users' | translate
                                    }}</a></li>
                            <li><a routerLink="/customers" *ngIf="userType == UserType.ADMIN">{{ 'All Customers' |
                                    translate }}</a></li>
                            <li><a routerLink="/orders" *ngIf="userType == UserType.ADMIN">{{ 'All Orders' | translate
                                    }}</a></li>
                            <li><a routerLink="/insurers" *ngIf="userType == UserType.ADMIN">{{ 'All Insurer' |
                                    translate }}</a></li>
                        </ul>
                    </li>
                    <li *ngIf="userType == UserType.ADMIN">
                        <a href="javascript: void(0);" class="waves-effect">
                            <i class="bx bx-layout"></i>
                            <span>{{'Setup' | translate}}</span>
                        </a>
                        <ul class="sub-menu" aria-expanded="false">
                            <li><a [routerLink]="['/masterRateCard']" *ngIf="userType == UserType.ADMIN">{{ 'Master Rate
                                    Card' | translate }}</a></li>
                            <li><a [routerLink]="['/insurers', 'add']" *ngIf="userType == UserType.ADMIN">{{ 'Add
                                    Insurer' | translate }}</a></li>
                        </ul>
                    </li>
                </ul>
            </div> -->
<!-- Sidebar -->
<!--     </div>
    </div>   -->
<!-- Left Sidebar End -->
<!--   <div class="topnav">
        <div class="container-fluid">
            <nav class="navbar navbar-light topnav-menu">
                <div class="collapse navbar-collapse" id="topnav-menu-content">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a class="nav-link arrow-none" data-toggle="collapse" data-target=".navbar-collapse.show"
                                routerLink="/request">{{ 'All Requests' | translate }}</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link arrow-none" data-toggle="collapse" data-target=".navbar-collapse.show"
                                routerLink="/policies" *ngIf="userType == UserType.INSURER">{{ 'Policies' | translate
                                }}</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link arrow-none" data-toggle="collapse" data-target=".navbar-collapse.show"
                                routerLink="/users" *ngIf="userType == UserType.ADMIN">{{ 'All Users' | translate }}</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link arrow-none" data-toggle="collapse" data-target=".navbar-collapse.show"
                                routerLink="/customers" *ngIf="userType == UserType.ADMIN">{{ 'All Customers' |
                                translate }}</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link arrow-none" data-toggle="collapse" data-target=".navbar-collapse.show"
                                routerLink="/orders" *ngIf="userType == UserType.ADMIN">{{ 'All Orders' | translate
                                }}</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link arrow-none" data-toggle="collapse" data-target=".navbar-collapse.show"
                                routerLink="/insurers" *ngIf="userType == UserType.ADMIN">{{ 'All Insurer' | translate
                                }}</a>
                        </li>
                        <li class="nav-item" *ngIf="userType == UserType.ADMIN">
                            <a class="nav-link arrow-none" data-toggle="collapse" data-target=".navbar-collapse.show"
                                [routerLink]="['/masterRateCard']" *ngIf="userType == UserType.ADMIN">{{ 'Master Rate
                                Card' | translate }}</a>
                        </li>
                        <li class="nav-item" *ngIf="userType == UserType.ADMIN">
                            <a class="nav-link arrow-none" data-toggle="collapse" data-target=".navbar-collapse.show"
                                [routerLink]="['/insurers', 'add']" *ngIf="userType == UserType.ADMIN">{{ 'Add Insurer'
                                | translate }}</a>
                        </li>
                    </ul>
                </div>  -->
<!-- <div class="text-right">
                    <button type="button" class="btn btn-secondary" data-toggle="modal" data-target="#exampleModal">
                        {{'Help' | translate}}
                    </button>
                </div> -->
<!-- </nav>
        </div>
    </div>
</section> -->
<!--







 -->
<section *ngIf="isVerticalHeader">
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <a routerLink="/dashboard" class="logo logo-dark">
            <span class="logo-sm">
              <img src="assets/images/logo.svg" alt="" height="22">
            </span>
            <span class="logo-lg">
              <img src="assets/images/logo-dark.png" alt="" height="17">
            </span>
          </a>
          <a routerLink="/dashboard" class="logo logo-light">
            <span class="logo-sm">
              <img src="assets/images/logo-light.svg" alt="" height="22">
            </span>
            <span class="logo-lg">
              <img src="assets/images/logo-light.png" alt="" height="19">
            </span>
          </a>
        </div>
        <button type="button" class="btn btn-sm px-3 font-size-16 d-lg-none header-item waves-effect waves-light"
          data-toggle="collapse" data-target="#topnav-menu-content">
          <i class="fa fa-fw fa-bars"></i>
        </button>
      </div>
      <div class="d-flex">
        <div class="dropdown d-inline-block">
          <ul>
            <li>
              <a href="javascript:void(0);" [class.text-muted]="curLang == 'en'" (click)="setLang('ar')">AR</a>
            </li>
            <li>
              <a href="javascript:void(0);" [class.text-muted]="curLang == 'ar'" (click)="setLang('en')">EN</a>
            </li>
          </ul>
          <button type="button" class="btn  px-4" style="background-color: #222B4A;color: white;"> {{'Contact
            us' | translate}} <span class="material-icons">call</span>
          </button>
          <button type="button" class="btn header-item waves-effect" id="page-header-user-dropdown"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <img class="rounded-circle header-profile-user" src="assets/images/users/user-icon.png" alt="Header Avatar">
            <span class="d-none d-xl-inline-block ml-1">{{ 'My Account' | translate }}</span>
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
          </button>
          <div class="dropdown-menu dropdown-menu-right">
            <!-- item-->
            <!-- <a class="dropdown-item" href="#"><i class="bx bx-user font-size-16 align-middle mr-1"></i> Profile</a> -->
            <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logoutUser()"><i
                class="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>{{ 'Logout' |
              translate }}</a>
          </div>
        </div>
      </div>
    </div>
  </header>
  <!-- ========== Left Sidebar Start ========== -->
  <div class="vertical-menu">
    <div data-simplebar class="h-100">
      <!--- Sidemenu -->
      <div id="sidebar-menu">
        <!-- Left Menu Start -->
        <ul class="metismenu list-unstyled" id="side-menu">
          <li>
            <!-- <a routerLink="/dashboard" class="waves-effect">
                            <i class="bx bx-home-circle"></i>
                            <span>Dashboards</span>
                        </a> -->
            <ul class="sub-menu" aria-expanded="false">
              <li><a routerLink="/request">{{ 'All Requests' | translate }}</a></li>
              <li><a routerLink="/policies" *ngIf="userType == UserType.INSURER">{{ 'Policies' | translate
                  }}</a></li>
              <li><a routerLink="/users" *ngIf="userType == UserType.ADMIN">{{ 'All Users' | translate
                  }}</a></li>
              <li><a routerLink="/customers" *ngIf="userType == UserType.ADMIN">{{ 'All Customers' |
                  translate }}</a></li>
              <li><a routerLink="/orders" *ngIf="userType == UserType.ADMIN">{{ 'All Orders' | translate
                  }}</a></li>
              <li><a routerLink="/insurers" *ngIf="userType == UserType.ADMIN">{{ 'All Insurer' |
                  translate }}</a></li>
            </ul>
          </li>
          <li *ngIf="userType == UserType.ADMIN">
            <a href="javascript: void(0);" class="waves-effect">
              <i class="bx bx-layout"></i>
              <span>{{'Setup' | translate}}</span>
            </a>
            <ul class="sub-menu" aria-expanded="false">
              <li><a [routerLink]="['/masterRateCard']" *ngIf="userType == UserType.ADMIN">{{ 'Master Rate
                  Card' | translate }}</a></li>
              <li><a [routerLink]="['/insurers', 'add']" *ngIf="userType == UserType.ADMIN">{{ 'Add
                  Insurer' | translate }}</a></li>
            </ul>
          </li>
        </ul>
      </div>
      <!-- Sidebar -->
    </div>
  </div>
  <!-- Left Sidebar End -->
  <div class="topnav">
    <div class="container-fluid">
      <nav class="navbar navbar-light topnav-menu">
        <div class="collapse navbar-collapse" id="topnav-menu-content">
          <ul class="navbar-nav">
            <li class="nav-item">
              <a class="nav-link arrow-none" data-toggle="collapse" data-target=".navbar-collapse.show"
                routerLink="/request">{{ 'All Requests' | translate }}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link arrow-none" data-toggle="collapse" data-target=".navbar-collapse.show"
                routerLink="/policies" *ngIf="userType == UserType.INSURER">{{ 'Policies' | translate
                }}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link arrow-none" data-toggle="collapse" data-target=".navbar-collapse.show"
                routerLink="/users" *ngIf="userType == UserType.ADMIN">{{ 'All Users' | translate }}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link arrow-none" data-toggle="collapse" data-target=".navbar-collapse.show"
                routerLink="/customers" *ngIf="userType == UserType.ADMIN">{{ 'All Customers' |
                translate }}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link arrow-none" data-toggle="collapse" data-target=".navbar-collapse.show"
                routerLink="/orders" *ngIf="userType == UserType.ADMIN">{{ 'All Orders' | translate
                }}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link arrow-none" data-toggle="collapse" data-target=".navbar-collapse.show"
                routerLink="/insurers" *ngIf="userType == UserType.ADMIN">{{ 'All Insurer' | translate
                }}</a>
            </li>
            <li class="nav-item" *ngIf="userType == UserType.ADMIN">
              <a class="nav-link arrow-none" data-toggle="collapse" data-target=".navbar-collapse.show"
                [routerLink]="['/masterRateCard']" *ngIf="userType == UserType.ADMIN">{{ 'Master Rate
                Card' | translate }}</a>
            </li>
            <li class="nav-item" *ngIf="userType == UserType.ADMIN">
              <a class="nav-link arrow-none" data-toggle="collapse" data-target=".navbar-collapse.show"
                [routerLink]="['/insurers', 'add']" *ngIf="userType == UserType.ADMIN">{{ 'Add Insurer'
                | translate }}</a>
            </li>
          </ul>
        </div>
        <!-- <div class="text-right">
                    <button type="button" class="btn btn-secondary" data-toggle="modal" data-target="#exampleModal" (click)="getHelp()">
                        {{'Help' | translate}}
                    </button>
                </div> -->
      </nav>
    </div>
  </div>
</section>
<!--







 -->
<section *ngIf="!isVerticalHeader">
  <div class="position-fixed tutorial container" *ngIf="newUser != 'false'">
    <img src="../../../assets/images/Tutorial/Comprehensive_Tut1.jpeg" alt="" class="tutorialImage"
      (click)="changeImage($event.target)" *ngIf="curLang == 'en'">
    <img src="../../../assets/images/Tutorial/Comprehensive_Tut1_AR.jpg" alt="" class="tutorialImage"
      (click)="changeImage($event.target)" *ngIf="curLang == 'ar'">
  </div>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <a class="logo logo-dark">
            <div class="col-lg-3 col-md-6 col-6" style="left: -12px;">
              <a style="display: inline-block;" class="logo" data-toggle="modal" data-target="#websiteModal"><img
                  class="logo-header" src="../../../assets/images/logo_header.png" style="width: 150px;"></a>
            </div>
          </a>
          <!-- <a routerLink="/dashboard" class="logo logo-light">
                        <span class="logo-sm">
                            <img src="../../../assets/images/logo-light.svg" alt="" height="22">
                        </span>
                        <span class="logo-lg">
                            <img src="../../../assets/images/logo-light.png" alt="" height="19">
                        </span>
                    </a> -->
        </div>
        <button type="button" class="btn btn-sm px-3 font-size-16 d-lg-none header-item waves-effect waves-light"
          data-toggle="collapse" data-target="#topnav-menu-content">
          <i class="fa fa-fw fa-bars"></i>
        </button>
      </div>
      <div class="d-flex">
        <div class="dropdown d-inline-block">
          <span style="background-color: #da512d;border-radius: 5px;" class="mx-3 px-3 py-2">
            <span [class.text-muted]="curLang == 'en'" (click)="setLang('ar')"
              style="cursor: pointer;color: white;">AR</span>
            <span class="ml-2" style="color: white;"> | </span>
            <span [class.text-muted]="curLang == 'ar'" (click)="setLang('en')" class="ml-2"
              style="cursor: pointer;color: white;">EN</span>
          </span>
          <button type="button" class="btn  px-4" style="background-color: #222B4A;color: white;"
            data-target="#contactModal" data-toggle="modal">
            <span><span class="material-icons font-size-16 align-middle mr-2">call</span>{{ 'Contact us' |
              translate }}</span>
          </button>
          <button type="button" class="btn header-item waves-effect" id="page-header-user-dropdown"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <img class="rounded-circle header-profile-user" src="../../../assets/images/users/user-icon.png"
              alt="Header Avatar">
            <span class="d-none d-xl-inline-block ml-1">{{ 'My Account' | translate }}</span>
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
          </button>
          <div class="dropdown-menu dropdown-menu-right">
            <!-- item-->
            <!-- <a class="dropdown-item" href="#"><i class="bx bx-user font-size-16 align-middle mr-1"></i> Profile</a> -->
            <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logoutUser()"><i
                class="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>{{ 'Logout' |
              translate }}</a>
          </div>
        </div>
      </div>
    </div>
  </header>
  <div class="topnav">
    <div class="container-fluid">
      <nav class="navbar navbar-light navbar-expand-lg topnav-menu">
        <div class="collapse navbar-collapse" id="topnav-menu-content">
          <ul class="navbar-nav">
            <!-- <li class="nav-item dropdown">
                            <a routerLink="/dashboard-customer" data-toggle="collapse" data-target=".navbar-collapse.show" class="nav-link arrow-none"><i class="bx bx-home-circle mr-2"></i>Dashboards</a>
                        </li> -->
            <li class="nav-item">
              <a routerLink="/vehicles" (click)="showVehicles()" data-toggle="collapse"
                data-target=".navbar-collapse.show" class="nav-link arrow-none"><i class="bx bx-car mr-2"></i>{{ 'My
                Vehicles' | translate
                }}</a>
            </li>
            <li class="nav-item">
              <a routerLink="/orders" data-toggle="collapse" data-target=".navbar-collapse.show"
                class="nav-link arrow-none"><i class="bx bx-spreadsheet mr-2"></i>{{ 'All Policies' |
                translate }}</a>
            </li>
          </ul>
        </div>
        <div class="text-right">
          <button type="button" class="btn" data-toggle="modal" data-target="#exampleModal"
            style="color: rgba(255,255,255,.6);"> {{'Help' | translate}} </button>
        </div>
      </nav>
    </div>
  </div>
</section>
<div class="modal fade" id="contactModal" tabindex="-1" role="dialog" aria-labelledby="contactModal" aria-hidden="true" >
  <div class="modal-dialog modal-dialog-scrollable" role="document" style="display: contents;">
    <div class="contact-modal modal-content offset-lg-7 col-lg-3 p-0 contactPopup"
      style="width: 22.5rem;margin: 30px 0 0 0;position: absolute;right: calc((100vw - 1120px)/2);top: 59px;box-shadow: 0px 0px 10px #ff8d7e88;">
      <div class="modal-header" style="padding: 15px 4px 3px 14px;border: none;" [ngStyle]="{'text-align': ((getLanguage() == 'en') ? 'left' : 'right')}">
        <div class="col-lg-10 col-md-10 col-10 p-0 contactTitle">
          <h3 class="BoldAr" style="font-size: 14px;font-weight: 700;color: #222c4a;"> {{'Contact us' |
            translate }} </h3>
        </div>
        <div class="col-lg-2 col-md-2 col-2 cancelIcon">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <div class="model-content" style="padding: 0 15px;"
      [ngStyle]="{'direction': ((getLanguage() == 'en') ? 'ltr' : 'rtl')}">
        <div class="col-lg-12 col-md-12 col-12 p-0 contactPopupTitle">
          <p style="font-weight: 400;font-size: 13px;color: #ff8c7e;"> {{ 'Just drop your name & phone number,
            we will get in touch with you at your convenience.' | translate }} </p>
        </div>
        <form #contactForm="ngForm" (ngSubmit)="submitContact(contactForm)">
          <div class="col-12 col-md-12 pading-right-025 p-0">
            <div class="form-group">
              <input type="text" class="form-control text16" id="name" maxlength="30" [ngStyle]="{'direction': ((getLanguage() == 'en') ? 'ltr' : 'rtl')}"
                placeholder="{{'contact.name' | translate}} " [(ngModel)]='name' name="name" #cName="ngModel" required
                autocomplete="off" style="border-radius: 5px; background: #dfe7ed; color: #222C4A;">
            </div>
            <div class="error-sec" *ngIf="cName.invalid && (cName.dirty || cName.touched)">
              <small class="error text16" *ngIf="(cName.hasError('required'))"> {{'Required' | translate}}
              </small>
            </div>
          </div>
          <div class="col-12 col-md-12 pading-right-025 p-0">
            <div class="form-group input-box">
              <input type="tel" matInput numeric numericType="number" class="form-control phone-number text16"
                id="phoneNumber" placeholder="{{'contact.phoneNumber' | translate}}" [(ngModel)]='phoneNumber'
                name="phoneNumber" #pName="ngModel" required maxlength="8" minlength="8" autocomplete="off"
                style="border-radius: 5px; background: #dfe7ed; color: #222C4A;" [ngStyle]="{'direction': ((getLanguage() == 'en') ? 'ltr' : 'rtl')}">
              <span class="country-code contactPhonenur text16 contactPopupPhoneNumber" style="color: #9095a3"> {{'arcode'|translate}}
              </span>
              <div class="error-sec" *ngIf="pName.invalid && (pName.dirty || pName.touched)">
                <small class="error text16" *ngIf="(cName.hasError('required'))"> {{'Required' |
                  translate}} </small>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-7 col-12 my-auto pb-3 ar" style="color: #39B54A; font: 400 14px Roboto;">
              <span *ngIf="contactSubmitted">{{'Submitted Successfully!' | translate}}</span>
            </div>
            <div class="col-lg-5 col-md-6 pb-3 float-right text-alien-right">
              <button type="submit" class="btn btn-default submit-button text16" [disabled]="contactForm.invalid">
                {{'SUBMIT' | translate}} </button>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-body d-flex justify-content-between flex-column mx-auto" [ngStyle]="{'direction': ((getLanguage() == 'en') ? 'ltr' : 'rtl')}">
        <div>
          <p class="font-size-14" style="color: #ff8d7e;"> {{'Please feel free to contact us we would love to
            hear from you.' | translate }} </p>
        </div>
        <!-- <p><b class="font-size-16">WhatsApp</b> - +965 67734711</p>
              <p><b class="font-size-16">Phone</b> - +965 22207335</p>
              <p><b class="font-size-16">Email</b> - info@saiyarti.com</p> -->
        <div class="my-3">

          <a href="https://web.whatsapp.com/send?phone=96567734711&text=&source=&data=&app_absent=" target="_blank"><img
              src="../../../assets/images/WhatsApp.svg" alt="" width="18" height="18"><span class="ml-2 font-size-16"
              style="color: #222B4A;vertical-align: middle;" >
              {{'wp-number'|translate}}</span></a>
        </div>
        <div class="my-3">
          <a href="tel:+965 22207335"><img src="../../../assets/images/Phone.svg" alt="" width="18" height="18"><span
              class="ml-2 font-size-16" style="color: #222B4A;vertical-align: middle;">
              {{'call-number'|translate}}</span></a>
        </div>
        <div class="my-3">
          <a href="mailto:info@saiyarti.com"><img src="../../../assets/images/Email.svg" alt="" width="18"
              height="18"><span class="ml-2 font-size-16" style="color: #222B4A;vertical-align: middle;">
              info@saiyarti.com</span></a>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="websiteModal" tabindex="-1" role="dialog" aria-labelledby="websiteModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body" [ngStyle]="{'direction': ((getLanguage() == 'en') ? 'ltr' : 'rtl')}">
        <p style="color:#222B4A;" class="font-size-16"> {{'Clicking here will redirect to the website. Are you
          sure you want to proceed?' | translate}} </p>
      </div>
      <div class="modal-footer">
        <a href="https://saiyarti.com/" target="_blank"><button type="button" class="btn"
            style="background-color: #222B4A;color: white;">{{'Yes' | translate}}</button></a>
        <button type="button" class="btn" data-dismiss="modal" style="border: 1px solid #222B4A;color: #222B4A;">{{'No'
          | translate}}</button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body" [ngStyle]="{'direction': ((getLanguage() == 'en') ? 'ltr' : 'rtl')}"> {{ 'Yes, I am willing to accept a phone call.' | translate }} </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ 'Close' | translate}}</button>
        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="getHelp()">{{ 'Ok' | translate
          }}</button>
      </div>
    </div>
  </div>
</div>
