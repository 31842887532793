import {Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
    selector: '[numeric]'
})

export class NumericDirective {

    @Input('numericType') numericType: string; // number | decimal

    private regex = {
        number: new RegExp(/^\d+$/),
        decimal: new RegExp(/^[0-9]+(\.[0-9]*){0,1}$/g)
    };

    private specialKeys = {
        number: [ 'Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Enter' ],
        decimal: [ 'Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Enter' ],
    };

    constructor(private el: ElementRef) {
    }

    @HostListener('keydown', [ '$event' ])
    onKeyDown(event: KeyboardEvent) {

        if (this.specialKeys[this.numericType].indexOf(event.key) !== -1 || this.isControlActionKey( event )) {
            return;
        }
        // Do not use event.keycode this is deprecated.
        // See: https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode
        let current: string = this.el.nativeElement.value;
        let next: string = current.concat(event.key);
        if (next && !String(next).match(this.regex[this.numericType])) {
            event.preventDefault();
        }
    }

    @HostListener( 'paste', [ '$event' ])
    onPaste( event: ClipboardEvent ) {
        let current: string = this.el.nativeElement.value;
        let pasteText: string = event.clipboardData.getData('text');
        if ( pasteText ) {
            pasteText = pasteText.replace( " ", "" )
        }
        let next: string = current.concat(pasteText);
        if (next && !String(next).match(this.regex[this.numericType])) {
            return false;
        }
    }

    isControlActionKey( event: KeyboardEvent ): boolean {
        if ( event.ctrlKey && !event.altKey && !event.shiftKey && event.key == "v" ) {
            return true;
        }
        return false;
    }
}