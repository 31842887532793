<div class="page-content">
    <div class="container-fluid">
        <app-success [isTPLSuccess]=true></app-success>
        <div class="row justify-content-center">
            <div class="col-md-8 text-center" style="padding-top: 180px; font-size: 30px;">
                {{ '"Your details are successfully uploaded, your policy will be delivered in 24-48 working hours."' | translate}}
            </div>
            <div class="col-md-8 text-center mt-2" style="padding-top: 20px; font-size: 20px;">{{ 'Please call' | translate}} <a href="tel:+965 67734711" class="assistance-number">+965 67734711</a>
                {{ 'or email' | translate}} <a href="mailto:info@saiyarti.com" target="_top">info@saiyarti.com</a> {{ 'for any assistance.' | translate}}
            </div>
            <div class="col-md-8 text-center" style="font-size: 16px;" *ngIf="downloadLink">
                {{ 'Click' | translate}} <a href="{{ downloadLink }}" target="_blank">{{ 'Here' | translate}}</a> {{ 'to download Saiyarti app.' | translate}}
            </div>
        </div>
    </div>
    <!-- container-fluid -->
</div>