<div class="row">
    <div class="col-12">
        <ul class="comprehensive-stepper">
            <li [ngClass] = "currentPage == 'addVehiclePage' ? 'active-tab' : '' ">
                <span class="bar-circle">1</span>
                <span class="text">{{'Add Vehicle' | translate}}</span>
                <span class="disable-bar"></span>
                <span class="active"></span>
            </li>

            <li [ngClass] = "currentPage == 'quotePage' ? 'active-tab' : '' ">
                <span class="bar-circle">2</span>
                <span class="text">{{'Quote Comparison' | translate}}</span>
                <span class="disable-bar"></span>
                <span class="active"></span>
            </li>

            <li [ngClass] = "currentPage == 'imagePage' ? 'active-tab' : '' ">
                <span class="bar-circle">3</span>
                <span class="text">{{'Upload Images' | translate}}</span>
                <span class="disable-bar"></span>
                <span class="active"></span>
            </li>

            <li [ngClass] = "currentPage == 'summaryPage' ? 'active-tab' : '' ">
                <span class="bar-circle">4</span>
                <span class="text">{{'Review & Submit' | translate}}</span>
                <span class="disable-bar"></span>
                <span class="active"></span>
            </li>

            <li [ngClass] = "currentPage == 'paymentPage' ? 'active-tab' : '' ">
                <span class="bar-circle">5</span>
                <span class="text">{{'Payment' | translate}}</span>
                <span class="disable-bar" style="border: 0px;"></span>
                <span class="active"></span>
            </li>
        </ul>
    </div>
</div>