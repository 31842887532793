import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { IUpdateCustomerStatusRequest } from '../contracts/requests/iupdate-customer-status';
import { Helpers } from '../helper/helper';
import { QuoteSummary } from '../models/carModel';
import { QuoteService } from '../services/quote.service';

@Component({
  selector: 'app-customer-status',
  templateUrl: './customer-status.component.html',
  styleUrls: ['./customer-status.component.scss']
})
export class CustomerStatusComponent implements OnInit {

  isAccepted = false;
  isRejected = false;
  insuranceCompanyName = ''
  dontShowOptions = false
  vehicleId: string;

  constructor(private _activatedRoute: ActivatedRoute,
    private _helpers: Helpers,
    private _router: Router,
    private _quoteService: QuoteService) {
    this._activatedRoute.queryParamMap.subscribe(
      paramMap => {

        let token = paramMap.get("token");
        let customerStatus = paramMap.get("customerstatus");
        let ratecardId = paramMap.get("ratecardid");
        this.vehicleId = paramMap.get("vehicleid");

        token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJiaGF1dGlrLm1Acm95YWxlY2hlZXNlLmNvbSIsImp0aSI6IjZkMDRhNjdiLTNiMGQtNGRjNC04NTMxLTI0ZmZmMzA1ZGJkYiIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL25hbWVpZGVudGlmaWVyIjoiNjYyZjEyYzEtMmUyZS00NzVlLTljNzYtNmRiOTQwNmI1MWIwIiwiaHR0cDovL3NjaGVtYXMueG1sc29hcC5vcmcvd3MvMjAwNS8wNS9pZGVudGl0eS9jbGFpbXMvbmFtZSI6ImJoYXV0aWsubUByb3lhbGVjaGVlc2UuY29tIiwidHlwZSI6IlVzZXIiLCJleHAiOjE5ODY1Mjc3ODIsImlzcyI6Imh0dHBzOi8vc2FpeWFydGkuY29tIiwiYXVkIjoiaHR0cHM6Ly9zYWl5YXJ0aS5jb20ifQ.hig4HHZh264q66PxCb8OrlJDwULi92Gp6CCKdtWP3jg';
        this._helpers.setToken({ token });

        if (customerStatus) {
          // SHOW ACCEPTED
          this.isAccepted = true

          // this._router.navigate(['/vehicle', this.vehicleId, 'quote'], { state: { noOfPassenger: 0, type: 'this.carDetails.insuranceType' } });
        } else {
          // SHOW REJECTED
          this.isRejected = true
        }

        const request: IUpdateCustomerStatusRequest = {
          ratecardId,
          customerStatus
        }
        this._quoteService.updateCustomerStatus(request).pipe(take(1)).subscribe(_ => {
          if (customerStatus) {

            this._quoteService.getQuoteSummaryForVehicle(this.vehicleId).subscribe((summaryResponse: QuoteSummary) => {
              // Redirect to the portal summary page
              this._router.navigate(['/vehicle', this.vehicleId, 'quote'], { state: { summary: summaryResponse } });
            });

          }
        })
      })
  }

  showOptions() {
    this._router.navigate(['/vehicle', this.vehicleId, 'quote'], { state: { noOfPassenger: 0, type: 'this.carDetails.insuranceType' } });
  }

  ngOnInit(): void {
  }

}
