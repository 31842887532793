import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StaticDataProviderService {

  constructor(private http: HttpClient) { }

  getCarStaticDetails(): Observable<any> {
    return this.http.get('assets/metadata/car_static_details.json')
  }
}
