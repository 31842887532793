import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Helpers } from 'src/app/helper/helper';
import { CarDetails, VehicleSummary } from 'src/app/models/carModel';
import { RateCard, RateCardSelections } from 'src/app/models/rate-card';
import { VehicleService } from 'src/app/services/vehicle.service';

@Component({
  selector: 'app-make-payment',
  templateUrl: './make-payment.component.html',
  styleUrls: ['./make-payment.component.scss']
})
export class MakePaymentComponent implements OnInit {

  carDetails: CarDetails;
  submittedQuote: RateCard;
  selectedQuoteDetails?: RateCardSelections;
  passengerCount: number;
  compreAmount: number;
  uploadedImageCounter: number;

  constructor(private activatedRoute: ActivatedRoute,
    private vehicleService: VehicleService,
    private helper: Helpers) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((paramMap: any) => {
      let vehicleId = paramMap.get('vehicleId');
      let token = paramMap.get('token');

      if (token) {
        this.helper.setToken({ token: token });
      }

      if (vehicleId) {
        this.vehicleService.getSummaryByVehicleId(vehicleId).subscribe((summaryResponse: VehicleSummary) => {
          this.submittedQuote = new RateCard(summaryResponse.rateCard.id, summaryResponse.rateCard);
          this.submittedQuote.insurer.name = summaryResponse.insuranceCompanyName;
          this.carDetails = new CarDetails(summaryResponse);
        }, error => { });
      }
    }, error => { });
  }

}
