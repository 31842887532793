import { Component, OnInit } from '@angular/core';
import * as HesabeCrypt from 'hesabe-crypt';
import * as aesjs from 'aes-js';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from 'src/app/services/translate.service';
import { PaymentService } from 'src/app/services/payment.service';
import { CompleteTransactionRequestBody } from 'src/app/models/paymentModel';
import { MatSnackBar as MatSnackBar } from '@angular/material/snack-bar';
declare let $: any;

@Component({
  selector: 'app-payment-failure',
  templateUrl: './payment-failure.component.html',
  styleUrls: ['./payment-failure.component.scss']
})
export class PaymentFailureComponent implements OnInit {

  payment: HesabeCrypt;
  Link = '/make-payment';
  token: string;
  vehicleId: number;
  paymentDataBaseId: string;
  paymentId: string;
  paymentToken: string;
  userId: string;
  amountPaid: number;
  civilCardId: number;
  rcBookId: number;
  isTPLPayment: boolean;


  // For comprehensive text
  showComText: string;

  constructor(private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private router: Router,
    private paymentService: PaymentService,
    private snackBar: MatSnackBar) {
    const secret = environment.paymetGateway.secret;
    const ivKey = environment.paymetGateway.ivKey;

    const key = aesjs.utils.utf8.toBytes(secret);
    const iv = aesjs.utils.utf8.toBytes(ivKey);
    this.payment = new HesabeCrypt(key, iv);
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params: any) => {
      if (params.data) {
        const decrypted = this.payment.decryptAes(params.data);
        const paymentResponse = JSON.parse(decrypted);
        this.setLang(paymentResponse.response.variable4);
        this.vehicleId = paymentResponse.response.variable1;
        this.paymentToken = paymentResponse.response.paymentToken;
        this.paymentId = paymentResponse.response.paymentId;
        this.isTPLPayment = paymentResponse.response.variable5 == '1';

        if (this.isTPLPayment) {
          let paymentData = paymentResponse.response.variable3.split(",");
          this.paymentDataBaseId = paymentResponse.response.variable2;
          this.civilCardId = +paymentData[0];
          this.rcBookId = +paymentData[1];
          this.vehicleId = paymentData[2];
          this.amountPaid = paymentResponse.response.amount;
        } else {
          this.paymentDataBaseId = paymentResponse.response.variable3;

          this.paymentService.completeTransaction(this.paymentDataBaseId, new CompleteTransactionRequestBody(
            this.paymentId,
            this.paymentToken,
            null,
            null,
            this.amountPaid,
            "failure",
            this.civilCardId), this.token).subscribe(res => {
            }, error => {
              this.snackBar.open("Error occur while Completing Transaction. Please contact admin.", 'CANCEL');
            });
        }

        //  For comprehensive text
        var url = new URLSearchParams(window.location.search);
        this.showComText = url.get('isComprehensive');
      }


      if (params.token) {
        this.token = params.token;
        if (this.isTPLPayment) {
          this.paymentService.completeTransactionForTPL(this.paymentDataBaseId, new CompleteTransactionRequestBody(
            this.paymentId,
            this.paymentToken,
            null,
            null,
            this.amountPaid,
            "failure",
            this.civilCardId), this.token).subscribe(res => {
            }, error => {
              this.snackBar.open("Error occur while Completing Transaction. Please contact admin.", 'CANCEL');
            });
        }
      }
    }, error => { });
  }

  setLang(lang: string) {
    this.translateService.use(lang);
    $(document).ready(() => {
      $('body').removeClass('en');
      $('body').removeClass('ar');
      $('body').addClass(lang);
    });
  }

  retry() {
    this.router.navigate(['/vehicles']);
  }
}
