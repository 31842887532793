import { EventEmitter, Injectable } from '@angular/core';
import { CarDetails } from './models/carModel';
import { PurposeOfLicense } from './models/tpl.model';

@Injectable({
  providedIn: 'root'
})
export class AddQuoteService {

  carDetails?: CarDetails;
  purposeOfLicense = new PurposeOfLicense(0, '');
  purposeOfLicenseAr = new PurposeOfLicense(0, '');
  noOfPassenger = 0
  quoteExpiryDate = ""
  onShowMyVehicles: EventEmitter<void> = new EventEmitter()

  constructor() { }


}
