<section dashboard="false">
    <!-- Start Banner Area -->
    <div class="main-banner-area">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="banner-text">
                        <span class="wow fadeInUp" data-wow-delay=".2s">Investment Retirement Insurance</span>

                        <h1 class="wow fadeInUp" data-wow-delay=".4s">The Right Protection to Keep You Moving Forward</h1>

                        <p class="wow fadeInUp" data-wow-delay=".6s">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum.</p>

                        <div class="banner-btn wow fadeInUp" data-wow-delay=".9s">
                            <a href="#" class="default-btn">
                                Get Started
                            </a>

                            <a href="#" class="default-btn active">
                                Find An Agent
                            </a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 pr-0">
                    <div class="banner-img wow fadeInUp" data-wow-delay=".2s">
                        <img src="assets/img/banner/banner-img.png" alt="Image">
                    </div>
                </div>
            </div>
        </div>

        <div class="shape">
            <img src="assets/img/banner/banner-shape.png" alt="Image">
        </div>
        <div class="banner-shape-right">
            <img src="assets/img/banner/banner-shape-right.png" alt="Image">
        </div>
    </div>
    <!-- End Banner Area -->
</section>