<div class="page-content">
    <div class="container-fluid">
        <div class="row" *ngIf="!isPaymentSuccessfully">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex align-items-center justify-content-between mb-4">
                            <h4 class="card-title m-0">{{ 'My Vehicles' | translate }}</h4>
                            <div class="page-title-right">
                                <a routerLink="/vehicle/add"
                                    class="btn btn-primary waves-effect waves-light add-car-btn">
                                    <i class="bx bxs-car mr-2"></i> {{ 'Add Car' | translate }} </a>
                            </div>
                        </div>
                        <div class="table-responsive all-request">
                            <table class="table table-centered table-nowrap mb-0">
                                <thead class="thead-light">
                                    <tr>
                                        <th *ngFor="let column of displayedColumn" class="table-headings">{{ column |
                                            translate }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="cursor-pointer" *ngFor="let vehicle of allVehicle">
                                        <td *ngIf="vehicle.quoteStatus" (click)="showQuoteSummary(vehicle, false)">{{
                                            vehicle.carMake }}</td>
                                        <td *ngIf="!vehicle.quoteStatus" (click)="showVehicleSummary(vehicle)">{{
                                            vehicle.carMake }}</td>
                                        <td *ngIf="vehicle.quoteStatus" (click)="showQuoteSummary(vehicle, false)">{{
                                            vehicle.carModel }}</td>
                                        <td *ngIf="!vehicle.quoteStatus" (click)="showVehicleSummary(vehicle)">{{
                                            vehicle.carModel }}</td>
                                        <span *ngIf="vehicle.quoteStatus">
                                            <td (click)="showQuoteSummary(vehicle, false)" *ngIf="vehicle.variant">
                                                {{ vehicle.variant }}</td>
                                            <td (click)="showQuoteSummary(vehicle, false)" *ngIf="!vehicle.variant"
                                                style="text-align: left;">
                                                <b>---</b>
                                            </td>
                                        </span>
                                        <td *ngIf="!vehicle.quoteStatus" (click)="showVehicleSummary(vehicle)">{{
                                            vehicle.variant }}</td>
                                        <td *ngIf="vehicle.quoteStatus" (click)="showQuoteSummary(vehicle, false)">{{
                                            vehicle.plateNumber }}</td>
                                        <td *ngIf="!vehicle.quoteStatus" (click)="showVehicleSummary(vehicle)">{{
                                            vehicle.plateNumber }}</td>
                                        <td *ngIf="vehicle.quoteStatus" (click)="showQuoteSummary(vehicle, false)">{{
                                            vehicle.modelYear }}</td>
                                        <td *ngIf="!vehicle.quoteStatus" (click)="showVehicleSummary(vehicle)">{{
                                            vehicle.modelYear }}</td>
                                        <td style="min-width: 90px;width: 90px;" class="quoteStatus">
                                            <a *ngIf="!vehicle.quoteStatus"
                                                [routerLink]="['/vehicle', 'add', vehicle.id]"
                                                class="waves-effect waves-light"> {{ 'Buy Full Coverage' | translate }}
                                            </a>
                                            <a *ngIf="vehicle.quoteStatus?.toLowerCase() == QuoteStatus.CUSTOMER_REJECTED.toLowerCase()"
                                                [routerLink]="['/vehicle', vehicle.id, 'quote']"
                                                class="waves-effect waves-light"> {{ 'View Alternate Quotes' | translate
                                                }} </a>
                                            <a *ngIf="vehicle.quoteStatus?.toLowerCase() == QuoteStatus.ADMIN_REJECTED.toLowerCase()"
                                                [routerLink]="['/vehicle', vehicle.id, 'quote']"
                                                class="waves-effect waves-light"> {{ 'View Alternate Quotes' | translate
                                                }} </a>
                                            <a *ngIf="vehicle.quoteStatus?.toLowerCase() == QuoteStatus.ADMIN_UPDATED.toLowerCase()"
                                                (click)="showQuoteSummary(vehicle, false)"
                                                class="waves-effect waves-light">
                                                <span>{{ 'View Counter Offer' | translate }}</span> </a>
                                            <a *ngIf="vehicle.quoteStatus?.toLowerCase() === QuoteStatus.CUSTOMER_ACCEPTED.toLowerCase()
                                               || vehicle.quoteStatus?.toLowerCase() == QuoteStatus.ADMIN_APPROVED.toLowerCase() || vehicle.status.toLowerCase()==QuoteStatus.PENDING.toLowerCase()"
                                                (click)="showQuoteSummary(vehicle, false)"
                                                class="waves-effect waves-light"> {{ 'View Submitted Quote' | translate
                                                }} </a>
                                            <a *ngIf="vehicle.quoteStatus?.toLowerCase() === 'DISPATCHED'.toLowerCase()
                                                || vehicle.quoteStatus?.toLowerCase()=== 'POLICY_PRINTED'.toLowerCase()
                                            || vehicle.quoteStatus?.toLowerCase() == 'DELIVERED'.toLowerCase()"
                                                (click)="showPolicyDetails(vehicle)" class="waves-effect waves-light">
                                                {{ 'View Policy Details' | translate
                                                }} </a>
                                        </td>
                                        <td style="min-width: 70px;width: 70px;">
                                            <button
                                                *ngIf="vehicle.status.toLowerCase() == QuoteStatus.ADMIN_APPROVED.toLowerCase()"
                                                (click)="showQuoteSummary(vehicle, true)"
                                                class="btn btn-primary waves-effect waves-light"> {{ 'Make Payment' |
                                                translate }}
                                            </button>
                                            <!-- {{ vehicle.status }} -->
                                            <span class="status-badge badge badge-soft-primary badge-soft-info w-100"
                                                *ngIf="vehicle.status.toLowerCase() == 'PENDING'.toLowerCase() ||
                                            vehicle.status.toLowerCase() == 'POLICY_PRINTED'.toLowerCase()">
                                                {{ 'Paid' | translate }}
                                            </span>
                                            <span class="status-badge badge badge-soft-primary badge-soft-info w-100"
                                                *ngIf="vehicle.status.toLowerCase() == 'DISPATCHED'.toLowerCase()">{{
                                                'Dispatched' |translate }}</span>
                                            <span class="status-badge badge badge-soft-primary badge-soft-info w-100"
                                                *ngIf="vehicle.status.toLowerCase() == 'DELIVERED'.toLowerCase()">{{
                                                'Delivered'| translate }}</span>
                                        </td>
                                    </tr>
                                    <tr
                                        *ngIf="!showGetVehiclesLoader && ( allVehicle == null || allVehicle.length == 0 )">
                                        <td colspan="6" class="text-center">{{'No vehicle found' | translate}}</td>
                                    </tr>
                                    <tr *ngIf="showGetVehiclesLoader">
                                        <td colspan="6" class="text-center">
                                            <div class="spinner-border text-primary m-1" role="status">
                                                <span class="sr-only">{{'Loading...' | translate}}</span>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <!-- end table-responsive -->
                    </div>
                </div>
            </div>
        </div>
        <!-- end row -->
        <div class="row justify-content-center" *ngIf="isPaymentSuccessfully">
            <div class="col-6">
                <app-success (redirected)="redirected($event)" [redirectLink]="'self'"></app-success>
            </div>
        </div>
    </div>
    <!-- container-fluid -->
</div>
<!-- End Page-content -->
