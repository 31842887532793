import { PlanType, QuoteStatus } from './quoteModel';
import { RateCard } from './rate-card';

export class CarDetails {
    id?: number;
    carMake: string;
    carMakeAr: string;
    carModel: string;
    carModelAr: string;
    trim: string;
    trimAr: string;
    modelYear: string;
    variant: string;
    marketValue: number = 0;
    insuranceType?: PlanType = PlanType.COMPREHENSIVE;
    firstName?: string;
    lastName?: string;
    plateNumber?: string;
    purposeOfLicense?: string;
    noOfPassenger?: number
    ownerName?: string;
    rcBookId: number;
    tplStatus: string;
    status: string;
    action?: boolean;
    phoneNumber: string;
    insuranceCompanyName: string;
    rateCard: RateCard;
    quoteStatus?: string;
    rateCardId: number;



    constructor(obj: any) {
        if (obj) {
            Object.assign(this, obj);
        }
    }

    public getJson() {
        return {
            carMake: this.carMake,
            carModel: this.carModel,
            modelYear: this.modelYear,
            variant: this.variant,
            marketValue: this.marketValue
        }
    }
}

export enum VehicleQuoteStatus {
    SUBMITTED = "submitted",
    REJECTED = "rejected",
    NOQUOTE = "noQuote"
}

export class CarEstimateData {
    minimum: number;
    maximum: number;

    constructor(min: number, max: number) {
        this.minimum = min,
            this.maximum = max;
    }
}

export class CarVariantData {
    index: number;
    name: string;
    estimate: CarEstimateData;

    constructor(index: number, name: string, estimate: CarEstimateData) {
        this.index = index,
            this.name = name,
            this.estimate = estimate;
    }
}

export class CarModelData {
    index: number;
    name: string;

    constructor(index: number, name: string) {
        this.index = index,
            this.name = name;
    }
}

export class CarMakeData {
    index: number;
    make: string;

    constructor(index: number, make: string) {
        this.index = index,
            this.make = make;
    }
}

export class ModelYearData {
    index: number;
    year: string;

    constructor(index: number, year: string) {
        this.year = year;
        this.index = index;
    }
}

export class VehicleSummary extends CarDetails {
    rateCard: RateCard;
}

export class QuoteSummary extends CarDetails {
    rateCardSummary: RateCard[];
}

export class CarImages {
    id: number;
    createdat: Date;
    name: string;
    vehicleId: number;
}