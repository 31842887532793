<!-- ORIGINAL -->
<div class="page-content">
    <div class="container-fluid">
        <app-comprehensive-stepper [currentPage]="currentPage"></app-comprehensive-stepper>
        <div class="row">
            <div class="col-lg-12 mx-auto">
                <div class="card">
                    <div class="card-body add-vehicle-card-body">
                        <h4 class="card-title mb-4" *ngIf="carId == null">{{ 'Add Vehicle' | translate }}</h4>
                        <h4 class="card-title mb-4" *ngIf="carId != null">{{ 'Edit Vehicle' | translate }}</h4>
                        <form #carDetailsForm="ngForm" (ngSubmit)="addCarDetails(carDetailsForm)">
                            <div class="d-flex">
                                <!-- Owner Name -->
                                <div class="col-4 pl-0">
                                    <mat-form-field class="w-100" appearance="outline">
                                        <mat-label>{{ 'Owner Name' | translate }}</mat-label>
                                        <input style="font-size: 16px;" type="text" matInput name="ownerName"
                                            [(ngModel)]="carDetails.ownerName"
                                            placeholder="{{ 'Owner Name' | translate }}" #tFirstName="ngModel" required>
                                        <mat-error
                                            *ngIf="tFirstName.invalid && tFirstName.touched &&tFirstName.hasError('required')">
                                            {{ 'First Name Value is required' | translate }} </mat-error>
                                    </mat-form-field>
                                </div>
                                <!-- Plate Number -->
                                <div class="col-4 pl-0">
                                    <mat-form-field class="w-100" appearance="outline">
                                        <mat-label>{{ 'Plate Number' | translate }}</mat-label>
                                        <input type="text" style="font-size: 16px;" matInput name="plateNumber"
                                            [(ngModel)]="carDetails.plateNumber"
                                            placeholder="{{'Plate Number' | translate }}" #tplateNumber="ngModel"
                                            required>
                                        <mat-error
                                            *ngIf="tplateNumber.invalid && tplateNumber.touched &&tplateNumber.hasError('required')">
                                            {{ 'Plate Number is required' | translate }} </mat-error>
                                    </mat-form-field>
                                </div>
                                <!-- Purpose of Licence -->
                                <div class="col-4 pl-0">
                                    <!-- <mat-form-field class="w-100" appearance="outline">
                                        <mat-label>{{ 'customer.userDetails.purposeOfLicense' | translate }}</mat-label>
                                        <input type="text" style="font-size: 16px;" matInput name="purposeOfLicense"
                                            [(ngModel)]="carDetails.purposeOfLicense"
                                            placeholder="{{'customer.userDetails.purposeOfLicense' | translate }}"
                                            #tpurposeOfLicense="ngModel" required>
                                        <mat-error
                                            *ngIf="tpurposeOfLicense.invalid && tpurposeOfLicense.touched &&tpurposeOfLicense.hasError('required')">
                                            {{ 'purposeOfLicense.errorMsg.required' | translate }}
                                        </mat-error>
                                    </mat-form-field> -->
                                    <mat-form-field appearance="outline" class="ar w-100">
                                        <mat-label>{{'Purpose of License' | translate}}</mat-label>
                                        <mat-select style="font-size: 16px;" name="purposeOfLicense"
                                            [(ngModel)]="selectedLicense">
                                            <mat-option style="font-size: 16px;"
                                                *ngFor="let purposeOfLicense of purposeOfLicenses; let i = index "
                                                [value]="purposeOfLicense"> {{purposeOfLicense.name | translate}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="d-flex">
                                <!-- Car Make -->
                                <div class="col-4 pl-0">
                                    <mat-form-field appearance="outline" class="ar w-100" *ngIf="getLanguage() == 'en'">
                                        <mat-label>{{ 'Car Make' | translate }}</mat-label>

                                        <input type="text" matInput [formControl]="carMakeCtrl"
                                            [matAutocomplete]="carMake" style="font-size: 16px;"
                                            placeholder="{{ 'Car Make' | translate }}" name="carMake" required>
                                        <mat-error
                                            *ngIf="carMakeCtrl.invalid && carMakeCtrl.touched && carMakeCtrl.hasError('required')">
                                            {{ 'Car Make Value is required' | translate }} </mat-error>
                                        <mat-autocomplete #carMake="matAutocomplete">
                                            <mat-option *ngFor="let make of filteredCarMakeList | async; let i = index "
                                                [value]="make" style="font-size: 16px;">
                                                {{make}} </mat-option>

                                        </mat-autocomplete>

                                    </mat-form-field>
                                    <mat-form-field appearance="outline" class="en w-100" *ngIf="getLanguage() == 'ar'">
                                        <mat-label>{{ 'Car Make' | translate }}</mat-label>

                                        <input type="text" matInput [formControl]="carMakeCtrlAr"
                                            [matAutocomplete]="carMakeAr" style="font-size: 16px;"
                                            placeholder="{{ 'Car Make' | translate }}" name="carMakeAr" required>
                                        <mat-error
                                            *ngIf="carMakeCtrlAr.invalid && carMakeCtrlAr.touched && carMakeCtrlAr.hasError('required')">
                                            {{ 'Car Make Value is required' | translate }} </mat-error>
                                        <mat-autocomplete #carMakeAr="matAutocomplete">
                                            <mat-option
                                                *ngFor="let make of filteredCarMakeListAr | async; let i = index "
                                                [value]="make" style="font-size: 16px;">
                                                {{make}} </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                </div>
                                <!-- Car Model -->
                                <div class="col-4 pl-0">
                                    <mat-form-field appearance="outline" class="ar w-100" *ngIf="getLanguage() == 'en'">
                                        <mat-label>{{'Car Model' | translate}}</mat-label>
                                        <input type="text" matInput [formControl]="carModelCtrl"
                                            [matAutocomplete]="carModel" style="font-size: 16px;"
                                            placeholder="{{ 'Car Model' | translate }}" name="carModel" required>
                                        <mat-error
                                            *ngIf="carModelCtrl.invalid && carModelCtrl.touched && carModelCtrl.hasError('required')">
                                            {{ 'Car Model Value is required' | translate }} </mat-error>
                                        <mat-autocomplete #carModel="matAutocomplete">
                                            <mat-option style="font-size: 16px;"
                                                *ngFor="let model of filteredCarModelList | async; let i = index "
                                                [value]="model" style="font-size: 16px;"> {{model}} </mat-option>
                                        </mat-autocomplete>

                                    </mat-form-field>
                                    <mat-form-field appearance="outline" class="en w-100" *ngIf="getLanguage() == 'ar'">
                                        <mat-label>{{'Car Model' | translate}}</mat-label>

                                        <!-- <mat-select style="font-size: 16px;" placeholder="{{'Car Model' | translate}}"
                                            name="carModelAr" [required]="getLanguage() == 'ar'"
                                            [formControl]="carModelCtrlAr">
                                            <mat-option>
                                                <ngx-mat-select-search [formControl]="carModelFilterCtrlAr"
                                                    style="font-size: 16px;"
                                                    placeholderLabel="{{'Search Car Model' | translate}}"
                                                    noEntriesFoundLabel="{{ 'No result found' | translate }}"
                                                    [hideClearSearchButton]=true></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option style="font-size: 16px;"
                                                *ngFor="let modelAr of filteredCarModelListAr | async; let i = index "
                                                [value]="modelAr"> {{modelAr}} </mat-option>
                                        </mat-select> -->

                                        <input type="text" matInput [formControl]="carModelCtrlAr"
                                            [matAutocomplete]="carModelAr" style="font-size: 16px;"
                                            placeholder="{{ 'Car Model' | translate }}" name="carModel" required>
                                        <mat-error
                                            *ngIf="carModelCtrlAr.invalid && carModelCtrlAr.touched && carModelCtrlAr.hasError('required')">
                                            {{ 'Car Model Value is required' | translate }} </mat-error>
                                        <mat-autocomplete #carModelAr="matAutocomplete">
                                            <mat-option style="font-size: 16px;"
                                                *ngFor="let model of filteredCarModelListAr | async; let i = index "
                                                [value]="model" style="font-size: 16px;"> {{model}} </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                </div>
                                <!-- Car Trim -->
                                <div class="col-4 pl-0">

                                    <mat-form-field appearance="outline" class="ar w-100" *ngIf="getLanguage() == 'en'">

                                        <mat-label>{{'Variant' | translate}}</mat-label>
                                        <!-- <mat-select style="font-size: 16px;" placeholder="{{'Variant' | translate}}"
                                            name="trim" [required]="getLanguage() == 'en'" [(ngModel)]="CarTrim"
                                            (selectionChange)="showModal(true)">
                                            <mat-option style="font-size: 16px;"
                                                *ngFor="let trim of carTrimList; let i = index " [value]="trim"
                                                (click)="editRestriction()">
                                                {{trim}} </mat-option>

                                        </mat-select> -->
                                        <input type="text" matInput [formControl]="carTrimCtrl"
                                            [matAutocomplete]="carTrim" style="font-size: 16px;"
                                            placeholder="{{ 'Car Trim' | translate }}" name="carTrim">
                                        <mat-autocomplete #carTrim="matAutocomplete">
                                            <mat-option *ngFor="let trim of filteredCarTrimList | async; let i = index "
                                                [value]="trim" style="font-size: 16px;" (click)="editRestriction()">
                                                {{trim}} </mat-option>

                                        </mat-autocomplete>

                                    </mat-form-field>
                                    <mat-form-field appearance="outline" class="en w-100" *ngIf="getLanguage() == 'ar'">
                                        <mat-label>{{'Variant' | translate}}</mat-label>
                                        <!-- <mat-select style="font-size: 16px;" placeholder="{{'Variant' | translate}}"
                                            name="trimAr" [required]="getLanguage() == 'ar'" [(ngModel)]="CarTrimAr">
                                            <mat-option style="font-size: 16px;"
                                                *ngFor="let trimAr of carTrimListAr; let i = index " [value]="trimAr">
                                                {{trimAr}} </mat-option>
                                        </mat-select> -->
                                        <input type="text" matInput [formControl]="carTrimCtrlAr"
                                            [matAutocomplete]="carTrimAr" style="font-size: 16px;"
                                            placeholder="{{ 'Car Trim' | translate }}" name="carTrimAr">
                                        <mat-autocomplete #carTrimAr="matAutocomplete">
                                            <mat-option *ngFor="let trim of filteredCarTrimListAr | async; let i = index "
                                                [value]="trim" style="font-size: 16px;" (click)="editRestriction()">
                                                {{trim}} </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                </div>

                            </div>
                            <div class="d-flex">
                                <!-- Car Model year -->
                                <div class="col-4 pl-0">
                                    <mat-form-field class="w-100" appearance="outline">
                                        <mat-label>{{'Model Year' | translate}}</mat-label>
                                        <input type="text" style="font-size: 16px;" matInput numeric
                                            numericType="number" maxlength="4" name="modelYear" [(ngModel)]="ModelYear"
                                            placeholder="{{'Model Year' | translate }}" #tModelYear="ngModel" required>
                                        <mat-error
                                            *ngIf="tModelYear.invalid && tModelYear.touched &&tModelYear.hasError('required')">
                                            {{ 'Car Model Year is required' | translate }} </mat-error>
                                    </mat-form-field>
                                </div>
                                <!-- Number of Passengers -->
                                <div class="col-4 pl-0">
                                    <mat-form-field class="w-100" appearance="outline">
                                        <mat-label>{{'Number of Passengers' | translate}}</mat-label>
                                        <input type="number" min="0" style="font-size: 16px;" matInput maxlength="4"
                                            name="numberOfPassenger" [(ngModel)]="passengers" #tPassanger="ngModel"
                                            [disabled]="editData" required>
                                        <mat-error
                                            *ngIf="tPassanger.invalid && tPassanger.touched &&tPassanger.hasError('required')">
                                            {{ 'Number of Passanger is required' | translate }} </mat-error>
                                    </mat-form-field>
                                </div>
                                <!-- Market Value -->
                                <div *ngIf="carDetails.insuranceType != PlanType.TPL" class="col-4 pl-0">
                                    <mat-form-field class="w-100" appearance="outline"
                                        matTooltip="{{'The market value of the car is required for the purpose of computing the insurance premium amount. If you need any assistance, please call us on +965 67734711' |translate}}"
                                        matTooltipPosition="above">
                                        <mat-label> {{ 'Market Value of Car' | translate }} <i
                                                class="material-icons font-size-15">info</i>
                                        </mat-label>
                                        <div class="d-flex">

                                            <span style="font-size: 16px;" class="mr-1">{{'KWD'|translate}} </span>
                                            <input type="number" matInput numeric numericType="number"
                                                name="marketValue" [(ngModel)]="carDetails.marketValue"
                                                style="font-size: 16px;"
                                                placeholder="{{'Market Value of Car' | translate }}"
                                                #tMarketValue="ngModel" required>

                                        </div>
                                        <mat-error
                                            *ngIf="tMarketValue.invalid && tMarketValue.touched &&tMarketValue.hasError('required')">
                                            {{ 'Car Market Value is required' | translate }} </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="text-right pr-3">
                                <!-- Add Quote button -->
                                <button type="submit" class="btn btn-primary w-md position-relative ml-5 get-quote-btn"
                                    [disabled]="carDetailsForm.invalid || carDetails.marketValue <= 0 || carMakeCtrl.invalid || carMakeCtrlAr.invalid || carModelCtrl.invalid ||carModelCtrlAr.invalid || passengers <=0">
                                    <i *ngIf="showAddCarLoader"
                                        class="bx bx-loader bx-spin add-car-loader font-size-16 align-middle mr-2"></i>
                                    {{ (carId != null ? 'Save' : 'Get Quote' ) | translate }} </button>
                                <p class="text-danger pl-3 mb-0 mt-2" *ngIf="showErrorMsgForAddCar"> {{ ( errorMsg != ''
                                    ? 'Looks like you\'ve already added this car before! Please recheck your list of
                                    Cars under Add Vehicle section' : 'Unable to add Car please try again!' ) |
                                    translate }} </p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <!-- end row -->
        <div class="modal d-none" id="popUpModal" role="dialog" aria-labelledby="popuUpModel">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        We are sorry to know that your car make/ car model/ car variant (the field name changes based on
                        the other option selected) is not listed. Please wait for the servicing team to contact you.
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal"
                            (click)="showModal(false)">Close</button>
                        <button type="button" class="btn btn-primary" (click)="sendNotificationToAdmin()">Yes</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- container-fluid -->
    <!-- Pop Up Modal -->
</div>
<!-- End Page-content -->
