import { Directionality } from "@angular/cdk/bidi";
import { Component, DoCheck, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { Helpers } from "src/app/helper/helper";
import { UserType } from "src/app/models/userModel";

@Component({
  selector: "app-navigator",
  templateUrl: "./navigator.component.html",
  styleUrls: ["./navigator.component.scss"],
})
export class NavigatorComponent implements OnInit {
  isVerticalHeader = false;

  // constructor(private helper: Helpers) {
  // }

  ngOnInit(): void {
    let userToken = this.helper.getDecodedAccessToken(this.helper.getToken());
    if (userToken) {
      if (userToken.type == UserType.CUSTOMER) {
        this.isVerticalHeader = false;
        document.body.setAttribute("data-sidebar", "light");
        document.body.setAttribute("data-layout", "horizontal");
        document.body.setAttribute("data-topbar", "light");
      } else {
        this.isVerticalHeader = true;
        document.body.setAttribute("data-sidebar", "dark");
        document.body.setAttribute("data-topbar", "dark");
        document.body.removeAttribute("data-layout");
      }
    }
  }

  /** Whether the widget is in RTL mode or not. */
  isRtl: boolean;

  /** Subscription to the Directionality change EventEmitter. */
  private _dirChangeSubscription = Subscription.EMPTY;

  constructor(dir: Directionality, private helper: Helpers) {
    const lang = window.localStorage["language"];
    this.isRtl = lang == "ar" ? true : false;

    this._dirChangeSubscription = dir.change.subscribe((dir) => {
      console.log(dir);
    });
  }

  ngOnDestroy() {
    this._dirChangeSubscription.unsubscribe();
  }

  changeLanguage(language) {
    if (language == "en") {
      this.isRtl = false;
    } else {
      this.isRtl = true;
    }
  }
}
