import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  userEmail: string;
  showResetLoader: boolean;
  isInvalid: boolean;
  isSuccess: boolean;

  constructor(private loginservice: LoginService) { }

  ngOnInit(): void {
    this.showResetLoader = false;
  }

  resetPassword(resetFormData): void {
    if ( resetFormData.value.email && resetFormData.value.email != "" )
    this.showResetLoader = true;
    this.isInvalid = false;
    this.isSuccess = false;
    this.loginservice.resetPassword({email: resetFormData.value.email}).subscribe( resetResponse => {
      this.showResetLoader = false;
      this.isSuccess = true;
    }, error => {
      // handle error
      this.showResetLoader = false;
      this.isInvalid = true;
    })
  }
}
