export class PurposeOfLicense {
    constructor(public id: number, public name: string) { }
}

export class Fee {
    id: number;
    price: number;
    period: number;
    currency: string;
    supervisionFee: number;
    deliveryFee: number;
    convenienceFee: number;
    issueFee: number;
    processingFee: number;
    ministryFee: number
    supervisionFee2?: number;
    supervisionFee3?: number;
}