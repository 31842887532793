<div class="account-pages">
    <div class="container d-flex vh-100">
        <div class="row my-auto justify-content-center">
            <div class="col-md-8 col-lg-6 col-xl-5 my-5">
                <div class="card overflow-hidden">
                    <div class="bg-soft-primary">
                        <div class="row">
                            <div class="col-7">
                                <div class="text-primary p-4">
                                    <h5 class="text-primary">{{'Welcome Back!' | translate}}</h5>
                                    <p>{{'Log in to continue to Saiyarti!' | translate }}</p>
                                </div>
                            </div>
                            <div class="col-5 align-self-end">
                                <img src="assets/images/profile-img.png" alt="" class="img-fluid">
                            </div>
                        </div>
                    </div>
                    <div class="card-body pt-0"> 
                        <div>
                            <a href="javascript::void()">
                                <div class="avatar-md profile-user-wid mb-4">
                                    <span class="avatar-title rounded-circle bg-light">
                                        <img src="assets/images/logo.svg" alt="" class="rounded-circle" height="34">
                                    </span>
                                </div>
                            </a>
                        </div>
                        <div class="p-2">
                            <form class="form-horizontal" #loginFormAdmin="ngForm" (ngSubmit)="logInUser(loginFormAdmin)">
                                
                                <div class="row">
                                    <div class="form-group col-12">
                                        <input class="form-control" type="email" id="email" name="email" [(ngModel)]="userModel.email" placeholder="{{'Email Address' | translate }}" #tEmail="ngModel" required pattern="^(?=[^@]{2,}@)([\w\.-]*[a-zA-Z0-9_]@(?=.{2,}\.[^.]*$)[\w\.-]*[a-zA-Z0-9]\.[a-zA-Z][a-zA-Z\.]*[a-zA-Z])$">
                                        <div class="pl-1" *ngIf="tEmail.invalid && tEmail.touched">
                                            <small class="error" *ngIf="tEmail.hasError('required')">{{ 'Email is required' | translate }}</small>
                                            <small class="error" *ngIf="tEmail.hasError('pattern')">{{ 'Email is not valid' | translate }}</small>
                                        </div>
                                    </div>
                                    <div class="form-group col-12">
                                        <input class="form-control" type="password" id="password" name="password" [(ngModel)]="userModel.password" placeholder="{{'Password' | translate }}" #tPassword="ngModel" required>
                                        <div class="pl-1" *ngIf="tPassword.invalid && tPassword.touched">
                                            <small class="error" *ngIf="tPassword.hasError('required')">{{ '"Password is required": "Password is required"' | translate }}</small>
                                        </div>
                                    </div>
                                    
                                    <div class="col-12 mt-3 text-center">
                                        <button class="btn btn-primary btn-block waves-effect waves-light" [disabled]="loginFormAdmin.invalid" type="submit">
                                            <i *ngIf="showLoginLoader" class="bx bx-loader bx-spin login-loader font-size-16 align-middle mr-2"></i>{{'Log In Now' | translate}}
                                        </button>
                                        <span class="text-danger" *ngIf="isInvalidCredentials"> {{ 'User with the given Email and password was not found' | translate }} </span>
                                    </div>
                                </div>

                                <div class="mt-4 text-center">
                                    <a [routerLink]="['/reset-password']" class="text-muted"><i class="mdi mdi-lock mr-1"></i> {{'Forgot your password?' | translate}}</a>
                                </div>
                            </form>
                        </div>
    
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>