export class UserModel {
    email: string;
    password: string;
}

export class CustomerUserModel {
    name?: string;
    countryCode?: string = "965";
    phoneNumber?: string;
    otp?: string;
    referrer?: string;
    referrerId?: string;
    email?: string;
}

export enum UserType {
    CUSTOMER = 'User',
    ADMIN = 'Admin',
    INSURER = 'Insurer'
}

export enum RegistrationPurpose {
    PRIVATE = 0,
    COMMON = 1
}