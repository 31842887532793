import { Component, OnInit } from '@angular/core';
import * as HesabeCrypt from 'hesabe-crypt';
import * as aesjs from 'aes-js';
import { environment } from 'src/environments/environment';
import { TranslateService } from 'src/app/services/translate.service';
import { PaymentService } from 'src/app/services/payment.service';
import { CompleteTransactionRequestBody } from 'src/app/models/paymentModel';
import { MatSnackBar as MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import * as amplitude from '@amplitude/analytics-browser';
declare let $: any;

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss']
})
export class PaymentSuccessComponent implements OnInit {

  //#region Payment Variable

  payment: HesabeCrypt;
  hasPaymentSuccess: boolean;
  vehicleId: number;
  paymentDataBaseId: string;
  paymentId: string;
  paymentToken: string;
  token: string;
  userId: string;
  amountPaid: number;
  civilCardId: number;

  // For comprehensive text
  showComText: string;

  //#endregion

  isPaymentSuccessfully: boolean;
  orderId: any;

  constructor(private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private paymentService: PaymentService,
    private snackBar: MatSnackBar,
    private router: Router) {
    const secret = environment.paymetGateway.secret;
    const ivKey = environment.paymetGateway.ivKey;

    const key = aesjs.utils.utf8.toBytes(secret);
    const iv = aesjs.utils.utf8.toBytes(ivKey);
    this.payment = new HesabeCrypt(key, iv);

    this.isPaymentSuccessfully = false;

    amplitude.init(environment.amplitudekey, undefined, { defaultTracking: { sessions: true, pageViews: true, formInteractions: true, fileDownloads: true } });
    this.activatedRoute.queryParams.subscribe(params => {
      let isTPLPayment: boolean
      if (params.data) {
        const decrypted = this.payment.decryptAes(params.data);
        const paymentResponse = JSON.parse(decrypted);
        this.hasPaymentSuccess = paymentResponse.code === 1;

        this.setLang(paymentResponse.response.variable4);

        this.orderId = paymentResponse.response.variable2;
        this.vehicleId = +paymentResponse.response.variable1;
        this.paymentToken = paymentResponse.response.paymentToken;
        this.paymentId = paymentResponse.response.paymentId;
        this.amountPaid = paymentResponse.response.amount;
        isTPLPayment = paymentResponse.response.variable5;

        if (isTPLPayment) {
          let paymentData = JSON.parse(paymentResponse.response.variable3);

          this.paymentDataBaseId = paymentData.policyId;
          this.userId = paymentData.userId;
          this.civilCardId = paymentData.civilId;
          this.amountPaid = paymentResponse.response.amount;
        } else {
          this.paymentDataBaseId = paymentResponse.response.variable3;
        }

        // For comprehensive text
        var url = new URLSearchParams(window.location.search);
        this.showComText = url.get('isComprehensive');
      }

      if (params.token) {
        this.token = params.token;
      }

      if (this.vehicleId && this.paymentDataBaseId) {
        if (isTPLPayment) {
          this.paymentService.completeTransactionForTPL(this.paymentDataBaseId, new CompleteTransactionRequestBody(
            this.paymentId,
            this.paymentToken,
            this.userId,
            null,
            this.amountPaid,
            "success",
            this.civilCardId), this.token).subscribe(res => {
              this.isPaymentSuccessfully = true;
              amplitude.track('Payment Success for TPL !',{ payload: JSON.stringify(this.paymentDataBaseId) })
            }, error => {
              this.snackBar.open("Error occur while Completing Transaction. Please contact admin.", 'CANCEL');
              this.router.navigate(['/vehicles']);
            });
        } else {
          this.paymentService.completeTransaction(this.orderId, new CompleteTransactionRequestBody(this.paymentId, this.paymentToken, undefined, undefined, this.amountPaid, "success"), this.token).subscribe(res => {
            this.isPaymentSuccessfully = true;
            amplitude.track('Payment Success for Comprehensive !',{ payload: JSON.stringify(this.paymentId) })
          }, error => {
            this.snackBar.open(error, 'CANCEL');
            this.router.navigate(['/vehicles']);
          });
        }
      } else {
        this.snackBar.open("Error occur while Completing Transaction. Please contact admin.", 'CANCEL');
        this.router.navigate(['/vehicles']);
      }
    }, error => { });
  }

  ngOnInit(): void { }

  setLang(lang: string) {
    this.translateService.use(lang);
    $(document).ready(() => {
      $('body').removeClass('en');
      $('body').removeClass('ar');
      $('body').addClass(lang);
    });
  }

}
