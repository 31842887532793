import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TranslateService {


  data: any = {};
  lang!: string;
  constructor(private http: HttpClient) { }
  use(lang: string): Promise<{}> {
    if (lang !== 'en' && lang !== 'ar') {
      lang = 'en';
    }
    window.localStorage['language'] = lang;
    this.lang = lang;
    document.body.classList.add(this.lang)
    return new Promise<{}>((resolve, reject) => {
      const langPath = `assets/i18n/${lang || 'en'}.json`;
      this.http.get<{}>(langPath).subscribe(
        translation => {
          if ( lang == 'en' ) {
            document.body.classList.remove("ar");
            document.body.classList.add("en");
          } else {
            document.body.classList.remove("en");
            document.body.classList.add("ar");
          }
          this.data = Object.assign({}, translation || {});
          resolve(this.data);
        },
        error => {
          this.data = {};
          reject(error);
        }
      );
    });
  }
}
