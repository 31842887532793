import { RegistrationPurpose } from "./userModel";

export class InitiateTransactionRequestBody {
    amountPaid?: number;
    deliveryAddress?: string

    constructor(amountPaid?: number, deliveryAddress?: string) {
        this.amountPaid = amountPaid;
        this.deliveryAddress = deliveryAddress;
    }
}

export class InitiateTransactionForTPLRequestBody {
    noOfPassenger: number;
    premiumFee: number;
    issueFee: number;
    supervisionFee: number;
    totalFee: number;
    registrationPurpose: RegistrationPurpose | string;
    plateNumber?: string;
    deliveryAddress?: string;
    deliveryFee: number;
    carMake: string;
    convenienceFee: number;
    amountPaid: number;
    carModelYear: number;
    carModel: string;
    tplId: number;
    rCbookId?: number;
    civilId?: number;
    carTrim?: string;
    ownerName?: string;
    comprehensiveVehicleId: number;

    constructor(noOfPassenger?: number, premiumFee?: number, issueFee?: number, supervisionFee?: number, totalFee?: number, registrationPurpose?: RegistrationPurpose | string, plateNumber?: string, deliveryAddress?: string, deliveryFee?: number, carMake?: string, convenienceFee?: number, amountPaid?: number, carModelYear?: number, carModel?: string, tplId?: number, rCbookId?: number, civilId?: number, carTrim?: string, comprehensiveVehicleId?: number) {
        this.noOfPassenger = noOfPassenger;
        this.premiumFee = premiumFee;
        this.issueFee = issueFee;
        this.supervisionFee = supervisionFee;
        this.totalFee = totalFee;
        this.registrationPurpose = registrationPurpose;
        this.plateNumber = plateNumber;
        this.deliveryAddress = deliveryAddress;
        this.deliveryFee = deliveryFee;
        this.carMake = carMake;
        this.convenienceFee = convenienceFee;
        this.amountPaid = amountPaid;
        this.carModelYear = carModelYear;
        this.carModel = carModel;
        this.tplId = tplId;
        if (rCbookId) {
            this.rCbookId = rCbookId;
        }
        if (civilId) {
            this.civilId = civilId;
        }
        if (carTrim) {
            this.carTrim = carTrim;
        }
        this.comprehensiveVehicleId = comprehensiveVehicleId;
    }
}

export class CompleteTransactionRequestBody {
    paymentId?: string;
    paymentToken?: string;
    userId: string;
    redeemPoints: number;
    amountPaid: number;
    status: string;
    civilCardId: number;
    comprhensiveVehicleId?: string;

    constructor(paymentId?: string, paymentToken?: string, userId?: string, redeemPoints?: number, amountPaid?: number, status?: string, civilCardId?: number, vehicleId?: string) {
        this.paymentId = paymentId,
            this.paymentToken = paymentToken;
        this.userId = userId;
        this.redeemPoints = redeemPoints;
        this.amountPaid = amountPaid;
        this.status = status;
        this.civilCardId = civilCardId;
        this.comprhensiveVehicleId = vehicleId;
    }
}