<div class="page-content">
    <div class="container-fluid">

        <div class="row justify-content-center">
            <div class="card col-12 col-md-8">
                <div class="row py-3">
                    <h3 class="col-12 mb-0" style="font-size: 22px;"><b>{{ 'Third Party Insurance' | translate }}</b>
                    </h3>
                    <form class="col-12 mt-3" #paymentForm="ngForm" (ngSubmit)="makePayment(paymentForm)">
                        <div class="row">
                            <!-- Civil Card -->
                            <div class="c-xxxs-12 c-xxs-6 c-xs-4 col-sm-4 col-md-4" *ngIf="userCivilCardList">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>{{'Civil Card' | translate}}</mat-label>
                                    <mat-select placeholder="{{'Civil Card' | translate}}" name="civilId" required
                                        [(ngModel)]="selectCivilCard">
                                        <mat-option *ngFor="let civilCard of userCivilCardList; let i = index "
                                            [value]=civilCard.id>
                                            {{civilCard.ownerName}}
                                        </mat-option>
                                        <mat-option [value]='' (click)="addCivilCard()">
                                            {{'Add Civil Card' | translate}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <!-- First Name -->
                            <div class="c-xxxs-12 c-xxs-6 c-xs-4 col-sm-4 col-md-4">
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label>{{'First Name' | translate}}</mat-label>
                                    <input type="text" matInput name="firstName" [(ngModel)]="carDetails.firstName"
                                        placeholder="{{'First Name' | translate }}" required>
                                </mat-form-field>
                            </div>

                            <!-- Last Name -->
                            <div class="c-xxxs-12 c-xxs-6 c-xs-4 col-sm-4 col-md-4">
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label>{{'Last Name' | translate}}</mat-label>
                                    <input type="text" matInput name="lastName" [(ngModel)]="carDetails.lastName"
                                        placeholder="{{'Last Name' | translate }}" required>
                                </mat-form-field>
                            </div>

                            <!-- Car Make -->
                            <div class="c-xxxs-12 c-xxs-6 c-xs-4 col-sm-4 col-md-4">
                                <mat-form-field appearance="outline" class="ar w-100">
                                    <mat-label>{{ 'Car Make' | translate }}</mat-label>
                                    <mat-select placeholder="{{ 'Car Make' | translate }}" name="carMake"
                                        [required]="getLanguage() == 'en'" [formControl]="carMakeCtrl"
                                        [disabled]="isCarDetailsAvail">
                                        <mat-option>
                                            <ngx-mat-select-search [formControl]="carMakeFilterCtrl"
                                                placeholderLabel="{{'Search Car Make' | translate}}"
                                                noEntriesFoundLabel="{{ 'No result found' | translate }}"
                                                [hideClearSearchButton]=true></ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let make of filteredCarMakeList | async; let i = index "
                                            [value]="make">
                                            {{make}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field appearance="outline" class="en w-100">
                                    <mat-label>{{ 'Car Make' | translate }}</mat-label>
                                    <mat-select placeholder="{{'Car Make' | translate}}" name="carMakeAr"
                                        [required]="getLanguage() == 'ar'" [formControl]="carMakeCtrlAr"
                                        [disabled]="isCarDetailsAvail">
                                        <mat-option>
                                            <ngx-mat-select-search [formControl]="carMakeFilterCtrlAr"
                                                placeholderLabel="{{'Search Car Make' | translate}}"
                                                noEntriesFoundLabel="{{ 'No result found' | translate }}"
                                                [hideClearSearchButton]=true></ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let makeAr of filteredCarMakeListAr | async; let i = index "
                                            [value]="makeAr">
                                            {{makeAr}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <!-- Car Model -->
                            <div class="c-xxxs-12 c-xxs-6 c-xs-4 col-sm-4 col-md-4">
                                <mat-form-field appearance="outline" class="ar w-100">
                                    <mat-label>{{'Car Model' | translate}}</mat-label>
                                    <mat-select placeholder="{{'Car Model' | translate}}" name="carModel"
                                        [required]="getLanguage() == 'en'" [formControl]="carModelCtrl"
                                        [disabled]="isCarDetailsAvail">
                                        <mat-option>
                                            <ngx-mat-select-search [formControl]="carModelFilterCtrl"
                                                placeholderLabel="{{'Search Car Model' | translate}}"
                                                noEntriesFoundLabel="{{ 'No result found' | translate }}"
                                                [hideClearSearchButton]=true></ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let model of filteredCarModelList | async; let i = index "
                                            [value]="model">
                                            {{model}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field appearance="outline" class="en w-100">
                                    <mat-label>{{'Car Model' | translate}}</mat-label>
                                    <mat-select placeholder="{{'Car Model' | translate}}" name="carModelAr"
                                        [required]="getLanguage() == 'ar'" [formControl]="carModelCtrlAr"
                                        [disabled]="isCarDetailsAvail">
                                        <mat-option>
                                            <ngx-mat-select-search [formControl]="carModelFilterCtrlAr"
                                                placeholderLabel="{{'Search Car Model' | translate}}"
                                                noEntriesFoundLabel="{{ 'No result found' | translate }}"
                                                [hideClearSearchButton]=true></ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option
                                            *ngFor="let modelAr of filteredCarModelListAr | async; let i = index "
                                            [value]="modelAr">
                                            {{modelAr}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <!-- Car Trim -->
                            <div class="c-xxxs-12 c-xxs-6 c-xs-4 col-sm-4 col-md-4">
                                <mat-form-field appearance="outline" class="ar w-100">
                                    <mat-label>{{'Trim' | translate}}</mat-label>
                                    <mat-select placeholder="{{'Trim' | translate}}" name="trim"
                                        [required]="getLanguage() == 'en'" [(ngModel)]="CarTrim"
                                        [disabled]="isCarDetailsAvail">
                                        <mat-option *ngFor="let trim of carTrimList; let i = index " [value]="trim">
                                            {{trim}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field appearance="outline" class="en w-100">
                                    <mat-label>{{'Trim' | translate}}</mat-label>
                                    <mat-select placeholder="{{'Trim' | translate}}" name="trimAr"
                                        [required]="getLanguage() == 'ar'" [(ngModel)]="CarTrimAr"
                                        [disabled]="isCarDetailsAvail">
                                        <mat-option *ngFor="let trimAr of carTrimListAr; let i = index "
                                            [value]="trimAr">
                                            {{trimAr}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <!-- Car Model year -->
                            <div class="c-xxxs-12 c-xxs-6 c-xs-4 col-sm-4 col-md-4">
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label>{{'Model Year' | translate}}</mat-label>
                                    <input type="text" matInput numeric numericType="number" maxlength="4"
                                        name="modelYear" [(ngModel)]="ModelYear"
                                        placeholder="{{'Model Year' | translate }}" required
                                        [disabled]="isCarDetailsAvail">
                                </mat-form-field>
                            </div>

                            <div class="col-lg-12 mt-3"></div>

                            <div class="col-md-4">
                                <div class="row">
                                    <div class="col-12">
                                        <h5 style="font-size: 22px;font-weight: 700;">{{ 'Policy Details' | translate}}
                                        </h5>
                                    </div>

                                    <!-- Purpose of License -->
                                    <div class="c-xxs-12 c-xs-4 col-sm-4 col-md-12">
                                        <mat-form-field appearance="outline" class="ar w-100">
                                            <mat-label>{{'Purpose of License' | translate}}</mat-label>
                                            <mat-select name="purposeOfLicense" [(ngModel)]="SelectedLicense"
                                                [required]="getLanguage() == 'en'">
                                                <mat-option
                                                    *ngFor="let purposeOfLicense of purposeOfLicenses; let i = index "
                                                    [value]="purposeOfLicense">
                                                    {{purposeOfLicense.name | translate}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>

                                        <mat-form-field appearance="outline" class="en w-100">
                                            <mat-label>{{'Purpose of License' | translate}}</mat-label>
                                            <mat-select name="purposeOfLicenseAr" [(ngModel)]="SelectedLicenseAr"
                                                [required]="getLanguage() == 'ar'">
                                                <mat-option
                                                    *ngFor="let purposeOfLicenseAr of purposeOfLicensesAr; let i = index "
                                                    [value]="purposeOfLicenseAr">
                                                    {{purposeOfLicenseAr.name | translate}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <!-- Number of Passengers -->
                                    <div class="c-xxs-6 c-xs-4 col-sm-4 col-md-12">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>{{'Number of Passengers' | translate}}</mat-label>
                                            <input type="text" matInput maxlength="4" name="passengers"
                                                [(ngModel)]="passengers" required disabled>
                                        </mat-form-field>
                                    </div>

                                    <!-- Duration of Policy -->
                                    <div class="c-xxs-6 c-xs-4 col-sm-4 col-md-12" *ngIf="hasQuote">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>{{'Duration' | translate}}</mat-label>
                                            <mat-select placeholder="{{'Duration' | translate}}" name="duration"
                                                [(ngModel)]="selectedFee" required>
                                                <mat-option *ngFor="let fee of fees" [value]="fee">
                                                    {{fee.period}} Year</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 mt-3"></div>
                            <div class="col-md-5" *ngIf="hasQuote">
                                <div class="row">
                                    <div class="col-12">
                                        <h5 style="font-size: 22px;font-weight: 700;">{{ 'Payment Details' | translate}}
                                        </h5>
                                    </div>

                                    <div class="col-12">
                                        <div class="row pb-1 justify-content-between">
                                            <div class="col-6 payment-label">{{'Premium' | translate}}:
                                            </div>
                                            <div class="col-5">
                                                <div class="d-flex justify-content-between">
                                                    <div class="d-inline-flex">{{selectedFee.currency}}</div>
                                                    <div class="d-inline-flex">{{ selectedFee.price | number:'.3-3' }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row pb-1 justify-content-between">
                                            <div class="col-6 payment-label">{{'Supervision Fee' | translate}}:
                                            </div>
                                            <div class="col-5">
                                                <div class="d-flex justify-content-between">
                                                    <div class="d-inline-flex">{{selectedFee.currency}}</div>
                                                    <div class="d-inline-flex">{{
                                                        getSupervisionFeeForPeriod(selectedFee.period) | number:'.3-3'
                                                        }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row pb-1 justify-content-between">
                                            <div class="col-6 payment-label">{{'Delivery Fee' | translate}}:
                                            </div>
                                            <div class="col-5">
                                                <div class="d-flex justify-content-between">
                                                    <div class="d-inline-flex">{{selectedFee.currency}}</div>
                                                    <div class="d-inline-flex">{{ fee.deliveryFee | number:'.3-3' }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row pb-1 justify-content-between">
                                            <div class="col-6 payment-label">{{'Issue Fee' | translate}}:
                                            </div>
                                            <div class="col-5">
                                                <div class="d-flex justify-content-between">
                                                    <div class="d-inline-flex">{{selectedFee.currency}}</div>
                                                    <div class="d-inline-flex">{{ fee.issueFee | number:'.3-3' }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row pb-1 justify-content-between">
                                            <div class="col-6 payment-label">{{'Convenience Fee' | translate}}:
                                            </div>
                                            <div class="col-5">
                                                <div class="d-flex justify-content-between">
                                                    <div class="d-inline-flex">{{selectedFee.currency}}</div>
                                                    <div class="d-inline-flex">{{ fee.convenienceFee | number:'.3-3' }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row mt-3 mb-2 justify-content-between">
                                            <div class="col-6 payment-label" style="font-weight: 500;">
                                                {{'Total Amount' | translate}}:
                                            </div>
                                            <div class="col-5" style="font-size: 20px;font-weight: 500;">
                                                <div class="d-flex justify-content-between">
                                                    <div class="d-inline-flex">{{selectedFee.currency}}</div>
                                                    <div class="d-inline-flex">{{ ( selectedFee.price +
                                                        getSupervisionFeeForPeriod(selectedFee.period) + fee.issueFee +
                                                        fee.convenienceFee + fee.deliveryFee ) | number:'.3-3' }}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12" style="float: right;">
                                        <button type="submit"
                                            class="btn btn-primary btn-block waves-effect waves-light policyBtn"
                                            [disabled]="paymentForm.invalid">
                                            {{'Make Payment' | translate}}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    </div> <!-- container-fluid -->
</div>
<!-- End Page-content -->