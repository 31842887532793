import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatInputModule as MatInputModule } from '@angular/material/input'
import { MatSelectModule as MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule as MatCheckboxModule } from '@angular/material/checkbox';
import { MatSnackBarModule as MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS as MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatFormFieldModule as MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule as MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule } from '@angular/material-moment-adapter';
import { LightboxModule } from 'ngx-lightbox';

import { NumericDirective } from './directive/NumericDirective';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { LoginComponent } from './components/login/login.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { NavigatorComponent } from './components/navigator/navigator.component';
import { RedirectUserComponent } from './components/redirect-user/redirect-user.component';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './helper/canActivateAuthGuard';
import { DashboardCustomerComponent } from './components/dashboard-customer/dashboard-customer.component';
import { TranslatePipe } from './pipes/translate.pipe';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { PageFooterComponent } from './components/page-footer/page-footer.component';
import { AddVehicleComponent } from './components/add-vehicle/add-vehicle.component';
import { VehiclesComponent } from './components/vehicles/vehicles.component';
import { OrdersComponent } from './components/orders/orders.component';
import { LoginCustomerComponent } from './components/login-customer/login-customer.component';
import { VehicleDetailsComponent } from './components/vehicle-details/vehicle-details.component';
import { OrderDetailsComponent } from './components/order-details/order-details.component';
import { AddQuoteComponent } from './components/add-quote/add-quote.component';
import { QuotesComponent } from './components/quotes/quotes.component';
import { QuoteSummaryComponent } from './components/quote-summary/quote-summary.component';
import { QuoteDetailsComponent } from './components/quote-details/quote-details.component';
import { SuccessComponent } from './components/success/success.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { MakePaymentComponent } from './components/make-payment/make-payment.component';
import { PaymentSuccessComponent } from './components/payment-success/payment-success.component';
import { PaymentFailureComponent } from './components/payment-failure/payment-failure.component';
import { SelectInsuranceComponent } from './components/select-insurance/select-insurance.component';
import { QrCodeModule } from 'ng-qrcode';
import { MatTooltipModule as MatTooltipModule } from '@angular/material/tooltip';
import { TplComponent } from './components/tpl/tpl.component';
import { LoaderDialogComponent } from './dialogs/loader-dialog/loader-dialog.component';
import { TplDetailsComponent } from './components/tpl-details/tpl-details.component';
import { TplSuccessComponent } from './components/tpl-success/tpl-success.component';
import { AddCivilCardDialogComponent } from './dialogs/add-civil-card-dialog/add-civil-card-dialog.component';
import { ComprehensiveStepperComponent } from './components/comprehensive-stepper/comprehensive-stepper.component';
import { ComprehensiveSuccessComponent } from './components/comprehensive-success/comprehensive-success.component';
import { CustomerStatusComponent } from './customer-status/customer-status.component';
import { QuoteComparisionComponent } from './components/quote-comparision/quote-comparision.component';
import { RatecardSummaryComponent } from './components/ratecard-summary/ratecard-summary.component';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { MatAutocompleteModule as MatAutocompleteModule } from '@angular/material/autocomplete';
import { VehicleDetailPageComponent } from './components/vehicle-detail-page/vehicle-detail-page.component';
import { TranslateService } from './services/translate.service';
// Note we need a separate function as it's required
// by the AOT compiler.

export function setupTranslateFactory(
  service: TranslateService): Function {
  return () => service.use(window.localStorage['language']);
}
export function playerFactory() {
  return player;
}
const appRoutes: Routes = [
  { path: '', redirectTo: "/customer-login", pathMatch: "full" },
  { path: 'home', component: HomeComponent },
  { path: 'partner-login', component: LoginComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'customer-login', component: LoginCustomerComponent },
  { path: 'customer-login/:id/:userName', component: LoginCustomerComponent },
  {
    path: '', component: NavigatorComponent,
    children: [
      { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
      { path: 'dashboard-customer', component: DashboardCustomerComponent, canActivate: [AuthGuard] },
      { path: 'quotes', component: QuotesComponent, canActivate: [AuthGuard] },
      { path: 'tpl', component: TplComponent, canActivate: [AuthGuard] },
      { path: 'tpl-details', component: TplDetailsComponent, canActivate: [AuthGuard] },
      { path: 'tpl-success', component: TplSuccessComponent, canActivate: [AuthGuard] },
      { path: 'quotes/:id', component: QuoteDetailsComponent, canActivate: [AuthGuard] },
      { path: 'make-payment/:vehicleId', component: MakePaymentComponent },
      { path: 'payment-success', component: PaymentSuccessComponent, canActivate: [AuthGuard] },
      { path: 'payment-failure', component: PaymentFailureComponent, canActivate: [AuthGuard] },
      { path: 'vehicle/add', component: AddVehicleComponent, canActivate: [AuthGuard] },
      { path: 'vehicle/add/:id', component: AddVehicleComponent, canActivate: [AuthGuard] },
      { path: 'vehicles', component: VehiclesComponent, canActivate: [AuthGuard] },
      { path: 'vehicle/:id', component: VehicleDetailsComponent, canActivate: [AuthGuard] },
      { path: 'vehicle/:id/quote', component: AddQuoteComponent, canActivate: [AuthGuard] },
      { path: 'vehicle/:id/tpl', component: TplComponent, canActivate: [AuthGuard] },
      { path: 'select-insurance', component: SelectInsuranceComponent, canActivate: [AuthGuard] },
      { path: 'orders', component: OrdersComponent, canActivate: [AuthGuard] },
      { path: 'request', component: DashboardCustomerComponent, canActivate: [AuthGuard] },
      { path: 'orders/:id', component: OrderDetailsComponent, canActivate: [AuthGuard] },
      { path: 'comprehensive-success', component: ComprehensiveSuccessComponent, canActivate: [AuthGuard] },
      { path: 'customer-status', component: CustomerStatusComponent },
      { path: 'ratecard-summary/:id', component: RatecardSummaryComponent },
      { path: 'vehicle-detail-page/:id', component: VehicleDetailPageComponent }
    ]
  },
  { path: '**', component: RedirectUserComponent }
];

@NgModule({
  declarations: [
    AppComponent,
    NumericDirective,
    HomeComponent,
    FooterComponent,
    HeaderComponent,
    LoginComponent,
    DashboardComponent,
    NavigatorComponent,
    RedirectUserComponent,
    DashboardCustomerComponent,
    TranslatePipe,
    PageHeaderComponent,
    PageFooterComponent,
    AddVehicleComponent,
    VehiclesComponent,
    OrdersComponent,
    LoginCustomerComponent,
    VehicleDetailsComponent,
    OrderDetailsComponent,
    AddQuoteComponent,
    QuotesComponent,
    QuoteSummaryComponent,
    QuoteDetailsComponent,
    SuccessComponent,
    ResetPasswordComponent,
    MakePaymentComponent,
    PaymentSuccessComponent,
    PaymentFailureComponent,
    SelectInsuranceComponent,
    TplComponent,
    LoaderDialogComponent,
    TplDetailsComponent,
    TplSuccessComponent,
    AddCivilCardDialogComponent,
    ComprehensiveStepperComponent,
    ComprehensiveSuccessComponent,
    CustomerStatusComponent,
    QuoteComparisionComponent,
    RatecardSummaryComponent,
    VehicleDetailPageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule.forRoot(appRoutes, { scrollPositionRestoration: 'top' }),
    RouterModule.forChild(appRoutes),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxDropzoneModule,
    NgbModule,
    MatSnackBarModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    NgxMatSelectSearchModule,
    MatFormFieldModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMomentDateModule,
    LightboxModule,
    QrCodeModule,
    LottieModule.forRoot({ player: playerFactory }),
    MatAutocompleteModule
  ],
  providers: [TranslateService, {
    provide: APP_INITIALIZER,
    useFactory: setupTranslateFactory,
    deps: [TranslateService],
    multi: true,
  },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 3000 } },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
