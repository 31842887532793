<div class="card">
    <div class="card-body p-0">
        <form>
            <div class="table-responsive">
                <table class="table table-nowrap table-centered mb-0 quote-table">
                    <tbody>
                        <!-- SAIYARTI RECOMMENDED -->
                        <tr>
                            <th style="max-width: 300px;"></th>
                            <td *ngFor="let quote of rateCards" class="text-center"
                                [ngClass]="quote.saiyartiRecommended ? 'recommended' : ''">
                                <div *ngIf="quote.saiyartiRecommended">
                                    <div class="recommended-box p-3 text-center bold-text"> {{ 'Saiyarti
                                        Recommended' | translate }} </div>
                                </div>
                            </td>
                        </tr>



                        <!-- INSURANCE COMPANY IMAGES -->
                        <tr>
                            <th [ngStyle]="{'text-align' : ((getLanguage() == 'en') ? 'left' : 'right')}"
                                [ngClass]="(getLanguage() == 'en') ? 'pr-5' : 'pl-5'"
                                style="max-width: 300px; flex-wrap: wrap; border-bottom: 2px solid;">
                                <!-- Display the Contact Us infor only if we are showing TPL or Limited Coverage -->
                                <div>
                                    <div style="background-color: rgb(218, 81, 45, .3); border-radius: 10px; flex-wrap: wrap;"
                                        class="d-none flex-row p-3">
                                        <div class="d-flex flex-row" style="white-space: break-spaces;">
                                            <img src="assets/images/ic_info@2x.png" style="width:20px; height:20px;"
                                                alt="">

                                            <div class="d-inline" [ngClass]="(getLanguage() == 'en') ? 'ml-2' : 'mr-2'">
                                                <span class="data-text" style="white-space: wrap !important;">{{
                                                    'Click on dropdown to change the Insurance Company.' | translate
                                                    }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </th>

                            <td *ngFor="let rateCard of rateCards" class="text-center" style="border-bottom: 2px solid;"
                                [ngClass]="rateCard.saiyartiRecommended ? 'recommended' : ''">
                                <img [src]="getCompanyImageURL(rateCard.insurerId)" class="card-img-top mx-auto"
                                    alt="comapany image" style="width: 120px;height: auto;">
                            </td>
                        </tr>

                        <!-- DROP DOWN for company selection -->
                        <tr>

                            <th [ngStyle]="{'text-align' : ((getLanguage() == 'en') ? 'left' : 'right')}"
                                [ngClass]="(getLanguage() == 'en') ? 'pr-5' : 'pl-5'"
                                style="max-width: 300px; flex-wrap: wrap;">
                                <!-- Display the Contact Us infor only if we are showing TPL or Limited Coverage -->
                                <div>
                                    <div style="background-color: rgb(218, 81, 45, .3); border-radius: 10px; flex-wrap: wrap;"
                                        class="flex-row p-3">
                                        <div class="d-flex flex-row" style="white-space: break-spaces;">
                                            <img src="assets/images/ic_info@2x.png"
                                                style="margin-top:4px; width:20px; height:20px;" alt="">
                                            <div class="d-flex" [ngClass]="(getLanguage() == 'en') ? 'ml-2' : 'mr-2'">
                                                <span class="data-text" style="white-space: wrap !important;">{{
                                                    'Click on dropdown to change the Insurance Company.' | translate
                                                    }}</span>
                                                <ng-lottie [options]="options" width="31px"
                                                    *ngIf="getLanguage()=='en'"></ng-lottie>
                                                <ng-lottie [options]="options" width="31px" *ngIf="getLanguage()=='ar'"
                                                    style="transform:scaleX(-1);">

                                                </ng-lottie>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </th>

                            <td [ngClass]="quote.saiyartiRecommended ? 'recommended' : ''"
                                *ngFor="let quote of rateCards; let i = index" class="text-center">
                                <div style="margin-top: 5px;"
                                    *ngIf="quote.saiyartiRecommended === false; else recommended">
                                    <mat-select class="w-75" [value]="quote"
                                        style="font-size: 16px; padding: 8px; border : 1px solid #aaa; border-radius: 10px;"
                                        placeholder="{{'Select Insurance Company' | translate}}" name="dropDown">
                                        <mat-option style="font-size: 16px;" [value]="quote"> {{quote.insurer.name |
                                            translate}}
                                        </mat-option>
                                        <mat-option (onSelectionChange)="changeInsurer(rateCard, i)"
                                            style="font-size: 16px;" *ngFor="let rateCard of restOfRateCards;"
                                            [value]="rateCard"> {{rateCard.insurer.name | translate}}
                                        </mat-option>
                                    </mat-select>
                                </div>
                                <ng-template #recommended>
                                    <button class="w-75"
                                        style="margin-top:22px; cursor: auto; font-size: 16px; padding: 8px; background:rgb(218, 81, 45, 0.3); color: rgb(218, 81, 45); border : 1px solid rgb(218, 81, 45); border-radius: 10px;"
                                        name="dropDown">
                                        {{quote.insurer.name |
                                        translate}}
                                    </button>
                                </ng-template>
                            </td>
                        </tr>

                        <!-- RATE CARD VALUES -->
                        <tr>
                            <th [ngStyle]="{'text-align' : ((getLanguage() == 'en') ? 'left' : 'right')}"
                                style="min-width: 300px;"></th>
                            <td *ngFor="let rateCard of rateCards;" class="amount text-center"
                                [ngClass]="rateCard.saiyartiRecommended ? 'recommended' : ''">
                                <h5 class="card-title" style="color: #DA512D;">
                                    <span class="position-relative" style="font-size: 24px;"
                                        *ngIf="(getLanguage()=='en')">
                                        {{'KWD'|translate}} {{rateCard.amount | number: '1.3-3'}}</span>
                                    <span *ngIf="(getLanguage()=='ar')">د.ك {{rateCard.amount | number: '1.3-3'}}
                                    </span>
                                </h5>
                            </td>
                        </tr>

                        <!-- PROCEED BUTTON -->
                        <tr>
                            <th style="min-width: 300px;"
                                [ngStyle]="{'text-align' : ((getLanguage() == 'en') ? 'left' : 'right')}">
                            </th>
                            <td *ngFor="let rateCard of rateCards; let i = index" class="text-center"
                                [ngClass]="rateCard.saiyartiRecommended ? 'recommended' : ''">
                                <button class="btn w-75 proceed-btn" (click)="proceed(rateCard)"
                                    [disabled]="(showTPL || showLimitedCoverage || showComprehensive) ? false : true">{{
                                    'Proceed' | translate }}</button>
                            </td>
                        </tr>

                        <!-- SAIYARTI ASSIST -->
                        <!-- <tr class="trBackground2">
                            <th [ngStyle]="{'text-align' : ((getLanguage() == 'en') ? 'left' : 'right')}"
                                class="quote-summary-left-part">
                                <p [ngStyle]="{'margin-right': ((getLanguage() == 'en') ? '0rem' : '1rem')}">
                                    {{'Saiyarti Assist' | translate }}<i
                                        class="bx bx-info-circle font-size-16 align-text-top cursor-pointer"
                                        matTooltip="Assist Description" matTooltipPosition="right"
                                        matTooltipClass="description-tooltip"
                                        [ngStyle]="{'margin-left' : ((getLanguage() == 'en') ? '11px' : '-29px')}"></i>
                                </p>
                            </th>
                            <td *ngFor="let n of rateCards; let i = index " class="text-center"
                                [ngClass]="rateCards[i].saiyartiRecommended ? 'recommended' : ''">
                                <p><span class='material-icons green-color'>done</span></p>
                            </td>
                        </tr> -->

                        <!-- THIRD PARTY LIABILITY -->
                        <tr class="trBackground1">
                            <th [ngStyle]="{'text-align' : ((getLanguage() == 'en') ? 'left' : 'right')}"
                                class="quote-summary-left-part">
                                <p>
                                    <mat-checkbox [checked]="showTPL" (change)="toggleTPL()"
                                        class="primary quote-summary-checkbox" [disabled]="!tplPaymentPending">{{ 'Third
                                        Party
                                        Liability' | translate }} </mat-checkbox>
                                </p>
                            </th>
                            <td *ngFor="let rateCard of rateCards; let i = index;"
                                class="text-center quote-summary-icon"
                                [ngClass]="rateCards[i].saiyartiRecommended ? 'recommended' : ''">
                                <div class="list-group list-inline flex-row justify-content-center">
                                    <button type="button" class="list-group-item  list-group-item-action btn TPL {{i}}"
                                        *ngFor="let tplFee of tplFees; let yearIndex = index" data-bs-toggle="button"
                                        [class.active]="tplFee == selectedTPLFee" id="TPL_{{yearIndex}}{{i}}"
                                        (click)="changeTPLFee(tplFee)" [disabled]="!tplPaymentPending"> {{
                                        tplFee.period }} {{'yr'|translate}}
                                    </button>
                                </div>
                            </td>
                        </tr>

                        <!-- LIMITED COMPREHENSIVE COVERAGE -->
                        <tr class="trBackground2">
                            <th [ngStyle]="{'text-align' : ((getLanguage() == 'en') ? 'left' : 'right')}"
                                class="quote-summary-left-part">
                                <p>

                                    <mat-checkbox class="primary quote-summary-checkbox" [checked]="showLimitedCoverage"
                                        #checkvalue (change)="toggleLimitedCoverage()"
                                        [ngStyle]="{'margin-left' : ((getLanguage() == 'en') ? '0px' : '29px')}">
                                        {{ 'Limited Comprehensive Cover' | translate }} </mat-checkbox>
                                    <i class="bx bx-info-circle font-size-16 align-text-top cursor-pointer"
                                        matTooltip="Mandatory Description" matTooltipPosition="right"
                                        matTooltipClass="description-tooltip"
                                        [ngStyle]="{'margin-left' : ((getLanguage() == 'en') ? '-7px' : '-29px')}"></i>
                                </p>
                            </th>
                            <td *ngFor="let rateCard of rateCards; let i = index;" class="text-center"
                                [ngClass]="rateCard.saiyartiRecommended ? 'recommended' : ''">
                                <p *ngIf="showLimitedCoverage;else notSelectedLimitedCoverage">
                                    <span class='material-icons green-color'>done</span></p>
                                <ng-template #notSelectedLimitedCoverage>
                                    <p><span class="material-icons red-color">close</span></p>
                                </ng-template>
                            </td>
                        </tr>

                        <tr *ngFor="let parameter of limitedCoverageParameters;"
                            class="comprehensive-params trBackground2">
                            <th class="quote-summary-left-part">
                                <p class="parameter-name"
                                    style="max-width: 310px;word-break: break-word;white-space: normal;"> {{ parameter |
                                    translate}}
                                    <!-- <i class="bx bx-info-circle font-size-16 align-text-top cursor-pointer"
                                        [matTooltip]="parameterKeyValue.value.translatedDescription(getLanguage())"
                                        matTooltipPosition="right" matTooltipClass="description-tooltip"
                                        style="margin-left: 11px"></i> -->
                                </p>
                            </th>

                            <td *ngFor="let rateCard of rateCards" class="text-center"
                                [ngClass]="rateCard.saiyartiRecommended ? 'recommended' : ''">


                                <p *ngIf="showLimitedCoverage;else notSelectedLimitedCoverage">
                                    <span class='material-icons green-color'>done</span></p>
                                <ng-template #notSelectedLimitedCoverage>
                                    <p><span class="material-icons red-color">close</span></p>
                                </ng-template>
                            </td>
                        </tr>

                        <!-- Comprehensive Add-Ons -->
                        <tr class="trBackground1">
                            <th [ngStyle]="{'text-align' : ((getLanguage() == 'en') ? 'left' : 'right')}"
                                class="quote-summary-left-part">
                                <p>
                                    <mat-checkbox #comprehensiveCheckbox class="primary quote-summary-checkbox"
                                        [checked]="showComprehensive" (change)="toggleComprehensive()">
                                        {{'Full comprehensive cover' | translate }}</mat-checkbox>
                                </p>
                            </th>
                            <td *ngFor="let rateCard of rateCards" class="text-center"
                                [ngClass]="rateCard.saiyartiRecommended ? 'recommended' : ''">
                                <p *ngIf="showComprehensive;else notSelectedFullCoverage">
                                    <span class='material-icons green-color'>done</span></p>
                                <ng-template #notSelectedFullCoverage>
                                    <p><span class="material-icons red-color">close</span></p>
                                </ng-template>
                            </td>
                        </tr>

                        <!-- Comprehensive Add-On Parameters -->
                        <tr *ngFor="let parameterKeyValue of comprehensiveParametersAggregate | keyvalue : sort; let parameterIndex = index;"
                            class="comprehensive-params trBackground2">
                            <th class="quote-summary-left-part">
                                <p class="parameter-name"
                                    style="max-width: 310px;word-break: break-word;white-space: normal;"> {{
                                    parameterKeyValue.value.translatedName(getLanguage()) }}
                                    <i class="bx bx-info-circle font-size-16 align-text-top cursor-pointer"
                                        [matTooltip]="parameterKeyValue.value.translatedDescription(getLanguage())"
                                        matTooltipPosition="right" matTooltipClass="description-tooltip"
                                        style="margin-left: 12px" *ngIf="getLanguage()=='ar'"></i>
                                    <i class="bx bx-info-circle font-size-16 align-text-top cursor-pointer"
                                        [matTooltip]="parameterKeyValue.value.translatedDescription(getLanguage())"
                                        matTooltipPosition="right" matTooltipClass="description-tooltip"
                                        style="margin-right: 15px" *ngIf="getLanguage()=='en'"></i>
                                </p>
                            </th>

                            <td *ngFor="let rateCard of rateCards; let rateCardIndex = index" class="text-center"
                                [ngClass]="rateCard.saiyartiRecommended ? 'recommended' : ''">

                                <ng-template
                                    [ngIf]="parameterKeyValue.key !== 'Waiver of Subrogation' && rateCard.parametersMap[parameterKeyValue.key].subParametersMap && rateCard.parametersMap[parameterKeyValue.key].isSelected && Object.keys(rateCard.parametersMap[parameterKeyValue.key].subParametersMap).length == 2">
                                    <div *ngFor="let subParameterKeyValue of rateCard.parametersMap[parameterKeyValue.key].subParametersMap | keyvalue : sort; let i = index;"
                                        class="mx-auto list-group divWidth">
                                        <button type="button"
                                            *ngIf="rateCard.parametersMap[parameterKeyValue.key].isMandatory"
                                            class="btn mb-2 w-100 px-2 list-group-item list-group-item-action comprehensive"
                                            data-bs-toggle="button"
                                            [class.active]="((rateCard.parametersMap[parameterKeyValue.key].subParametersMap[subParameterKeyValue.key].higherRange >= diffOfYear) && (diffOfYear >= (rateCard.parametersMap[parameterKeyValue.key].subParametersMap[subParameterKeyValue.key].lowerRange)))"
                                            [disabled]="!showComprehensive || !((rateCard.parametersMap[parameterKeyValue.key].subParametersMap[subParameterKeyValue.key].higherRange >= diffOfYear) && (diffOfYear >= (rateCard.parametersMap[parameterKeyValue.key].subParametersMap[subParameterKeyValue.key].lowerRange)))"
                                            (click)="selectComprehensiveSubParameter(rateCard.parametersMap[parameterKeyValue.key], subParameterKeyValue.value)">
                                            {{subParameterKeyValue.value.translatedName(getLanguage())}} </button>
                                    </div>
                                </ng-template>
                                <ng-template
                                    [ngIf]="(parameterKeyValue.key =='Waiver of Subrogation' || (rateCard.parametersMap[parameterKeyValue.key].subParametersMap && Object.keys(rateCard.parametersMap[parameterKeyValue.key].subParametersMap).length === 3)) && rateCard.parametersMap[parameterKeyValue.key].isSelected">
                                    <ng-container
                                        *ngFor="let subParameterKeyValue of rateCard.parametersMap[parameterKeyValue.key].subParametersMap | keyvalue : sort; let i = index;">
                                        <span class="mr-1 mx-1 d-inline comprehensive {{i}}" style="width: 100%;"
                                            *ngIf="i !== 2">
                                            <button type="button" class="btn sublimit"
                                                (click)="selectComprehensiveSubParameter(rateCard.parametersMap[parameterKeyValue.key], subParameterKeyValue.value)"
                                                style="border: 1px solid #222B4A; border-radius: 12px !important; padding: 0px 10px; color: #222B4A"
                                                [class.active]="((rateCard.parametersMap[parameterKeyValue.key].subParametersMap[subParameterKeyValue.key].higherRange >= diffOfYear) && (diffOfYear >= (rateCard.parametersMap[parameterKeyValue.key].subParametersMap[subParameterKeyValue.key].lowerRange)))"
                                                data-bs-toggle="button" [disabled]="!showComprehensive || !((rateCard.parametersMap[parameterKeyValue.key].subParametersMap[subParameterKeyValue.key].higherRange >= diffOfYear) && (diffOfYear >= (rateCard.parametersMap[parameterKeyValue.key].subParametersMap[subParameterKeyValue.key].lowerRange)))">
                                                {{ subParameterKeyValue.value.translatedName(getLanguage()) }} </button>
                                        </span>
                                        <span class="mx-auto mt-1 d-block comprehensive {{i}}" style="width: 20%;"
                                            *ngIf="i == 2">
                                            <button type="button" class="btn sublimit" data-bs-toggle="button"
                                                style="line-height:0.7;"
                                                [class.active]="subParameterKeyValue.value.isSelected"
                                                (click)="selectComprehensiveSubParameter(rateCard.parametersMap[parameterKeyValue.key], subParameterKeyValue.value)"
                                                [disabled]="!showComprehensive || (rateCard.parametersMap[parameterKeyValue.key].higherRange < diffOfYear)">
                                                {{ subParameterKeyValue.value.translatedName(getLanguage()) }} </button>
                                        </span>
                                    </ng-container>
                                </ng-template>
                                <ng-template
                                    [ngIf]="Object.keys(rateCard.parametersMap[parameterKeyValue.key].subParametersMap).length === 0">
                                    <p
                                        *ngIf="rateCard.parametersMap[parameterKeyValue.key].isMandatory && rateCard.parametersMap[parameterKeyValue.key].isSelected && showComprehensive">
                                        <span class='material-icons green-color'>done</span>
                                    </p>
                                    <p *ngIf="!rateCard.parametersMap[parameterKeyValue.key].isSelected || !showComprehensive"><span
                                            class='material-icons  red-color'>close</span>
                                    </p>
                                </ng-template>
                                <ng-template
                                    [ngIf]="Object.keys(rateCard.parametersMap[parameterKeyValue.key].subParametersMap).length > 0">
                                    <p *ngIf="!rateCard.parametersMap[parameterKeyValue.key].isSelected"><span
                                            class='material-icons  red-color'>close</span>
                                    </p>
                                </ng-template>

                            </td>

                        </tr>


                        <!-- Saiyarti Add-Ons  -->
                        <tr class="trBackground2">
                            <th [ngStyle]="{'text-align' : ((getLanguage() == 'en') ? 'left' : 'right')}"
                                class="quote-summary-left-part">
                                <p>
                                    <mat-checkbox class="primary quote-summary-checkbox" #addOnCheckBox
                                        id="addOnCheckboxMain" [indeterminate]="addOnIntermediate"
                                        (change)="toggleAddOns()" [checked]="showAddOns">{{ 'SAIYARTI
                                        ADD-ONS' | translate }} </mat-checkbox>
                                </p>
                            </th>
                            <td *ngFor="let rateCard of rateCards; let rateCardIndex = index;" class="text-center"
                                [ngClass]="rateCard.saiyartiRecommended ? 'recommended' : ''">
                                <p *ngIf="showAddOns;else notSelectedAddOns">
                                    <span class='material-icons green-color'>done</span></p>
                                <ng-template #notSelectedAddOns>
                                    <p><span class="material-icons red-color">close</span></p>
                                </ng-template>
                            </td>
                        </tr>


                        <!-- Saiyarti Add-On Parameters -->

                        <tr *ngFor="let addOnKeyValue of saiyartiAddOnsAggregate | keyvalue : sort; let addOnIndex = index;"
                            class="addOns trBackground2">
                            <th class="quote-summary-left-part"
                                [ngStyle]="{'text-align' : ((getLanguage() == 'en') ? 'left' : 'right')}">
                                <p style=" margin-left: 4rem;">
                                    <mat-checkbox class="primary quote-summary-checkbox addOnCheckBoxes"
                                        (change)="toggleAddOn(addOnKeyValue)" [checked]="addOnKeyValue.value.isSelected"
                                        name="addOnCheckBoxes">{{addOnKeyValue.value.translatedName(getLanguage())
                                        }}</mat-checkbox>
                                    <i class="bx bx-info-circle font-size-16  align-text-top cursor-pointer"
                                        [matTooltip]="addOnKeyValue.value.translatedDescription(getLanguage())"
                                        matTooltipPosition="right" matTooltipClass="description-tooltip"
                                        *ngIf="getLanguage()=='en'"></i>
                                    <i class="bx bx-info-circle font-size-16  align-text-top cursor-pointer mr-4"
                                        [matTooltip]="addOnKeyValue.value.translatedDescription(getLanguage())"
                                        matTooltipPosition="right" matTooltipClass="description-tooltip"
                                        *ngIf="getLanguage()=='ar'"></i>
                                </p>

                                <ng-container *ngIf="addOnKeyValue.key === 'Replacement Car'; ">
                                    <div [ngStyle]="{'margin-right': ((getLanguage() == 'en') ? '0rem' : '5rem')}"
                                        style="margin-left: 5rem;padding-bottom: 1rem;"
                                        *ngFor="let subParameter of (Object.values(this.saiyartiAddOnsAggregate['Replacement Car'].subParametersMap))[0].subParametersMap| keyvalue : sort">
                                        <input type="radio" name="sublimit" [disabled]="!addOnKeyValue.value.isSelected"
                                            (click)="selectAddOnSubParameter(subParameter.value, addOnKeyValue.value)"
                                            class="ADDONSUBLIMIT" [checked]="subParameter.value.isSelected">
                                        <span
                                            class="subBlue mx-2">{{subParameter.value.translatedName(getLanguage())}}</span>
                                    </div>
                                </ng-container>
                            </th>
                            <td class="text-center" *ngFor="let rateCard of rateCards;"
                                [ngClass]="rateCard.saiyartiRecommended ? 'recommended' : ''"
                                [ngStyle]="{'text-align' : ((getLanguage() == 'en') ? 'left' : 'right')}">


                                <ng-container *ngIf="addOnKeyValue.key === 'Replacement Car'; ">
                                    <div class="position-relative" style="top: -10px;">
                                        <p class="mb-0" *ngIf="(getLanguage() =='ar')">اختر حجم السيارة</p>
                                        <p class="mb-0" *ngIf="(getLanguage() =='en')">Choose Car Size</p>
                                        <div class="quote-summary-icon">
                                            <div class="list-group list-inline flex-row justify-content-center">
                                                <button type="button"
                                                    class="list-group-item list-group-item-action btn ADDONS"
                                                    data-bs-toggle="button"
                                                    *ngFor="let carSize of rateCard.addOnsMap[addOnKeyValue.key].subParametersMap | keyvalue : sort"
                                                    [class.active]="carSize.value.isSelected"
                                                    (click)="selectSubParameterForAddOn(carSize.value, rateCard.addOnsMap[addOnKeyValue.key])"
                                                    [disabled]="!rateCard.addOnsMap[addOnKeyValue.key].isSelected">
                                                    {{carSize.value.translatedName(getLanguage()).charAt(0).toLocaleUpperCase()}}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        *ngFor="let carValueLimit of rateCard.addOnsMap[addOnKeyValue.key].subParametersMap | keyvalue : sort">
                                        <div *ngIf="carValueLimit.value.isSelected">
                                            <div *ngFor="let value of carValueLimit.value.subParametersMap | keyvalue : sort"
                                                class="subBlue">
                                                <div >{{'KWD'|translate}}
                                                    {{value.value.valueForInsurer | number: '1.3-3'}}</div>
                                                <!-- <div *ngIf="(getLanguage()=='ar')">د.ك {{value.value.valueForInsurer |
                                                    number: '1.3-3'}}</div> -->
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>

                                <div
                                    *ngIf="rateCard.addOnsMap[addOnKeyValue.key] && rateCard.addOnsMap[addOnKeyValue.key].valueForInsurer">
                                    <p class="subBlue" *ngIf="(getLanguage()=='en')">KWD
                                        {{rateCard.addOnsMap[addOnKeyValue.key].valueForInsurer | number:'1.3-3'}} </p>
                                    <p class="subBlue" *ngIf="(getLanguage()=='ar')">د.ك
                                        {{rateCard.addOnsMap[addOnKeyValue.key].valueForInsurer | number:'1.3-3'}} </p>
                                </div>
                            </td>
                        </tr>


                        <!-- QUOTE AMOUNT INFORMATION -->
                        <tr>
                            <th [ngStyle]="{'text-align' : ((getLanguage() == 'en') ? 'left' : 'right')}"
                                style="padding-left: 4rem;">
                                <h5 [ngStyle]="{'margin-right': ((getLanguage() == 'en') ? '0rem' : '3rem')}"
                                    style="color: #222B4A;font: normal normal bold 20px/24px Roboto;letter-spacing: 0.19px;">
                                    {{ 'TOTAL AMOUNT' | translate }}</h5>
                            </th>
                            <td *ngFor="let rateCard of rateCards" class="text-center"
                                [ngClass]="rateCard.saiyartiRecommended ? 'recommended' : ''">
                            </td>
                        </tr>
                        <tr>
                            <th [ngStyle]="{'text-align' : ((getLanguage() == 'en') ? 'left' : 'right')}"
                                style="padding-left: 5rem;">
                                <p class="subBlue"
                                    [ngStyle]="{'margin-right': ((getLanguage() == 'en') ? '0rem' : '5rem')}">
                                    {{ 'Total Comprehensive Premium' | translate }}</p>
                            </th>
                            <td *ngFor="let rateCard of rateCards" class="text-center"
                                [ngClass]="rateCard.saiyartiRecommended ? 'recommended' : ''">
                                <span *ngIf="showComprehensive">
                                   <p class="subBlue" *ngIf="(getLanguage()=='en')">KWD {{rateCard.comprehensiveAmount |
                                    number: '1.3-3'}}</p>
                                   <p class="subBlue" *ngIf="(getLanguage()=='ar')">د.ك {{rateCard.comprehensiveAmount |
                                    number: '1.3-3'}}</p>
                                </span>
                                <span *ngIf="showLimitedCoverage">
                                        <p class="subBlue" *ngIf="(getLanguage()=='en')">KWD {{rateCard.limitedComprehensiveAmount |
                                            number: '1.3-3'}}</p>
                                        <p class="subBlue" *ngIf="(getLanguage()=='ar')">د.ك {{rateCard.limitedComprehensiveAmount |
                                            number: '1.3-3'}}</p>
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <th [ngStyle]="{'text-align' : ((getLanguage() == 'en') ? 'left' : 'right')}"
                                style="padding-left: 5rem;">
                                <p class="subBlue"
                                    [ngStyle]="{'margin-right': ((getLanguage() == 'en') ? '0rem' : '5rem')}">
                                    {{ 'Total TPL Premium' | translate }} </p>
                            </th>
                            <td *ngFor="let rateCard of rateCards;" class="text-center"
                                [ngClass]="rateCard.saiyartiRecommended ? 'recommended' : ''">
                                <p class="subBlue" *ngIf="(getLanguage()=='en')"> KWD {{rateCard.tplAmount | number:
                                    '1.3-3'}}</p>
                                <p class="subBlue" *ngIf="(getLanguage()=='ar')">د.ك {{rateCard.tplAmount | number:
                                    '1.3-3'}}</p>
                            </td>
                        </tr>
                        <tr>
                            <th [ngStyle]="{'text-align' : ((getLanguage() == 'en') ? 'left' : 'right')}"
                                style="padding-left: 5rem;">
                                <p class="subBlue"
                                    [ngStyle]="{'margin-right': ((getLanguage() == 'en') ? '0rem' : '5rem')}">
                                    {{ 'Total Add-Ons Premium' | translate }} </p>
                            </th>
                            <td *ngFor="let rateCard of rateCards;" class="text-center"
                                [ngClass]="rateCard.saiyartiRecommended ? 'recommended' : ''">
                                <p class="subBlue" *ngIf="(getLanguage()=='en')"> KWD {{rateCard.addOnAmount | number:
                                    '1.3-3'}}</p>
                                <p class="subBlue" *ngIf="(getLanguage()=='ar')">د.ك {{rateCard.addOnAmount | number:
                                    '1.3-3'}}</p>
                            </td>
                        </tr>
                        <div style="position: fixed; z-index: 5;">
                            <tr class="total-payable">
                                <th [ngStyle]="{'text-align' : ((getLanguage() == 'en') ? 'left' : 'right')}"
                                    style="padding-left: 4rem; width: 40%">
                                    <h5 [ngStyle]="{'margin-right': ((getLanguage() == 'en') ? '0rem' : '3rem')}"
                                        style="color: #DA512D;font: normal normal bold 20px/24px Roboto;letter-spacing: 0.19px;">
                                        {{'TOTAL PAYABLE' | translate }} </h5>
                                </th>
                                <td *ngFor="let rateCard of rateCards" class="amount text-center" style="width: 20%">
                                    <h5 class="card-title" style="color: #DA512D;">
                                        <span class="position-relative" style="font-size: 24px;color: #DA512D;"
                                            *ngIf="(getLanguage()=='en')"> KWD {{rateCard.amount | number:
                                            '1.3-3'}}</span>
                                        <span *ngIf="(getLanguage()=='ar')">د.ك {{rateCard.amount | number:
                                            '1.3-3'}}</span>
                                    </h5>
                                </td>
                            </tr>
                        </div>
                        <tr class="pb-3">
                            <th [ngStyle]="{'text-align' : ((getLanguage() == 'en') ? 'left' : 'right')}"
                                style="padding-left: 4rem;">
                                <button class="btn w-25 back-btn dark-data-text"
                                    [ngStyle]="{'margin-right': ((getLanguage() == 'en') ? '0rem' : '3rem')}"
                                    (click)="back()">{{'Back' | translate }}</button>
                            </th>
                            <td *ngFor="let rateCard of rateCards" class="text-center"
                                [ngClass]="rateCard.saiyartiRecommended ? 'recommended' : ''"
                                [ngStyle]="{'box-shadow' : ((rateCard.saiyartiRecommended === true ) ? '0px 10px 10px -10px rbga(0,0,0,0.4) ' : 'none')}">
                                <button class="btn w-75 proceed-btn" (click)="proceed(rateCard)"
                                    [disabled]="(showTPL || showLimitedCoverage || showComprehensive) ? false : true">{{
                                    'Proceed' | translate }}</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </form>
    </div>
</div>
