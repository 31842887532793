import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar as MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Helpers } from '../helper/helper';
import { BaseService } from './base.service';
import { TranslateService } from 'src/app/services/translate.service';
import { environment } from 'src/environments/environment';
import { decode } from 'base64-arraybuffer';

@Injectable({
  providedIn: 'root'
})
export class StorageService extends BaseService {

  civilCardFront: string = "_front";
  civilCardBack: string = "_back";
  carImageFront: string = "_carFront";
  carImageBack: string = "_carBack";
  carImageLeftSide: string = "_carLeftSide";
  carImageRightSide: string = "_carRightSide";
  carImageFrontWSImg: string = "_carFrontWSImg";
  carImageChassisNum: string = "_carFrontChassisNum";
  carVideo: string = "_carVideo";
  constructor(helper: Helpers,
    private http: HttpClient,
    private snackBar: MatSnackBar, translate: TranslateService) {
    super(helper, translate);
  }

  getUploadTokenForCarImage(): Observable<any> {
    return this.http.get(this.pathAPI + "Storage/GetUploadTokenForCar", super.header()).pipe(
      catchError(super.handleError)
    );
  }

  getReadTokenForCarImage(): Observable<any> {
    return this.http.get(this.pathAPI + "Storage/GetReadTokenForCar", super.header()).pipe(
      catchError(super.handleError)
    );
  }

  getUploadOnlyTokenForContainer(containerName: string): any {

    return this.http.post<any>(this.pathAPI + 'Storage/GetUploadOnlyTokenForContainer', { containerName }, super.header()).pipe(
      catchError(super.handleError)
    );
  }

  getUploadTokenForRCImage(fileName: string): Observable<any> {
    return this.http.get(this.pathAPI + "Storage/GetUploadTokenForRCImage/" + fileName, super.header()).pipe(
      catchError(super.handleError)
    );
  }

  getReadTokenForRCImage(fileName: string): Observable<any> {
    return this.http.get(this.pathAPI + "Storage/GetReadTokenForRCImage/" + fileName, super.header()).pipe(
      catchError(super.handleError)
    );
  }

  getUploadTokenForCivilImage(fileName: string): Observable<any> {
    return this.http.get(this.pathAPI + "Storage/GetUploadTokenForCivilImage/" + fileName, super.header()).pipe(
      catchError(super.handleError)
    );
  }

  getReadTokenForCivilImage(fileName: string): Observable<any> {
    return this.http.get(this.pathAPI + "Storage/GetReadTokenForCivilImage/" + fileName, super.header()).pipe(
      catchError(super.handleError)
    );
  }

  getUploadTokenForPolicyImage(fileName: string): Observable<any> {
    return this.http.get(this.pathAPI + "Storage/GetUploadTokenForPolicyImage/" + fileName, super.header()).pipe(
      catchError(super.handleError)
    );
  }

  getReadTokenForPolicyImage(fileName: string): Observable<any> {
    return this.http.get(this.pathAPI + "Storage/GetReadTokenForPolicyImage/" + fileName, super.header()).pipe(
      catchError(super.handleError)
    );
  }

  getUploadTokenForTradelicenseImage(fileName: string): Observable<any> {
    return this.http.get(this.pathAPI + "Storage/GetUploadTokenForTradelicenseNumber/" + fileName, super.header()).pipe(
      catchError(super.handleError)
    );
  }

  getReadTokenForTradelicenseNumber(fileName: string): Observable<any> {
    return this.http.get(this.pathAPI + "Storage/GetReadTokenForTradelicenseNumber/" + fileName, super.header()).pipe(
      catchError(super.handleError)
    );
  }

  getUploadTokenForCompanyLogo(fileName: string): Observable<any> {
    return this.http.get(this.pathAPI + "Storage/GetUploadTokenForCompanyLogo/" + fileName, super.header()).pipe(
      catchError(super.handleError)
    );
  }

  getReadTokenForCompanyLogo(fileName: string): Observable<any> {
    return this.http.get(this.pathAPI + "Storage/GetReadTokenForCompanyLogo/" + fileName, super.header()).pipe(
      catchError(super.handleError)
    );
  }

  getSASToken(blobName: string, containerName: string): any {
    return this.http.post<any>(this.pathAPI + 'Storage/GetReadOnlyToken', { blobName, containerName }, super.header()).pipe(
      catchError(super.handleError)
    );
  }

  getSASTokenForContainer(containerName: string): any {
    return this.http.post<any>(this.pathAPI + 'Storage/GetReadOnlyTokenForContainer', { containerName }, super.header()).pipe(
      catchError(super.handleError)
    );
  }

  getCarImagesByVehicleId(vehicleId: string): Observable<any> {
    return this.http.get(this.pathAPI + "Quote/CarImages/" + vehicleId, super.header()).pipe(
      catchError(super.handleError)
    );
  }

  downloadImageByURL(imageURL: string, fileName: string): void {
    this.http.get(imageURL, { responseType: 'blob' as 'json' }).subscribe(response => {
      this.downLoadFile(response, fileName);
    }, err => {
      this.snackBar.open("Unable to download file: " + fileName, 'CANCEL');
    });
  }

  uploadCarImages(vehicleId: string, body: any): Observable<any> {
    return this.http.post(environment.apiUrlForRateCard + "Quote/CarImages/" + vehicleId, body, super.header()).pipe(
      catchError(super.handleError)
    );
  }

  uploadCarImagesToAzure(baseUrl: string, sasToken: string, body: Blob): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'text/plain', 'x-ms-blob-type': 'BlockBlob' });
    return this.http.put<any>(baseUrl + sasToken, body, { headers: headers })
      .pipe(catchError(this.handleError));
  }

  userDocumentPresent(vehicleId: number): Observable<any> {
    return this.http.get(this.pathAPI + "Storage/UserDocumentPresent/" + vehicleId, super.header()).pipe(
      catchError(super.handleError)
    );
  }

  downLoadFile(response: any, fileName: string) {

    let urlCreator = window.URL || window.webkitURL;
    let imageUrl = urlCreator.createObjectURL(response);

    const anchor = document.createElement('a');
    anchor.download = fileName;
    anchor.href = imageUrl;
    anchor.click();
  }

  uploadFileToAzure(fileName: string, file: File, format: string) {
    let headers = new HttpHeaders({ 'Content-Type': format, 'x-ms-blob-type': 'BlockBlob' })
    const fileUrl = `${environment.cdnUrl}${fileName}`
    return this.http.put<void>(fileUrl, file, { headers })
  }
}
