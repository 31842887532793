import { Component, OnInit } from '@angular/core';
import { TranslateService } from 'src/app/services/translate.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  curLang: string;
  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
    this.curLang = this.translate.lang;
  }

  setLang(lang) {
    this.translate.use(lang);
    this.curLang = this.translate.lang;
  }

}
