
<div class="page-content">
    <div class="container-fluid">

        <div class="row" *ngIf="false">
            <div class="col-lg-3">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-6 card-title m-0">{{ 'customer.dashboard.totalRequests' | translate }}</div>
                            <div class="col-6 text-right">requestsCount</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-6 card-title m-0">{{ 'customer.dashboard.totalVehicles' | translate }}</div>
                            <div class="col-6 text-right">vehiclesCount</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-6 card-title m-0">{{ 'customer.dashboard.totalOrders' | translate }}</div>
                            <div class="col-6 text-right">ordersCount</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-6 card-title m-0">{{ 'customer.dashboard.totalPremium' | translate }}</div>
                            <div class="col-6 text-right">totalPremium</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end row -->

        <div class="row" *ngIf="false">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title mb-4">{{ 'dashboard.cus.allRequest' | translate }}</h4>
                        <div class="table-responsive all-request">
                            <table class="table table-centered table-nowrap mb-0">
                                <thead class="thead-light">
                                    <tr>
                                        <th *ngFor="let column of displayedColumn">{{column}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="cursor-pointer" *ngFor="let request of allRequestedQuote" (click)="requestDetails(request)">
                                        <td>{{request.requestNumber}}</td>
                                        <td>{{request.ownerName}}</td>
                                        <td>{{request.plateNumber}}</td>
                                        <td>{{request.carMake}}</td>
                                        <td>{{request.quotationAmount}}</td>
                                        <td>
                                            <span class="badge badge-pill badge-soft-success font-size-12">{{request.requestStatus}}</span>
                                        </td>
                                    </tr>
                                    <tr *ngIf="allRequestedQuote == null || allRequestedQuote.length == 0">
                                        <td colspan="6" class="text-center">No request found</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- end table-responsive -->
                    </div>
                </div>
            </div>
        </div>
        <!-- end row -->
        <!-- end row -->

    </div>
    <!-- container-fluid -->
</div>
<!-- End Page-content -->