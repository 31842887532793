import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TranslateService } from 'src/app/services/translate.service';
import { Helpers } from '../helper/helper';
import { BaseService } from './base.service';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginService extends BaseService {

  constructor(helper: Helpers, private http: HttpClient, translate: TranslateService) {
    super(helper, translate);
  }

  verifyUser(body: any): Observable<any> {
    return this.http.post(this.pathAPI + 'Account/Login', body, super.header()).pipe(
      catchError(super.handleError)
    );
  }

  resetPassword(body: any): Observable<any> {
    return this.http.post(this.pathAPI + 'Account/ResetPassword', body, super.header()).pipe(
      catchError(super.handleError)
    );
  }

  getOtp(body: any): Observable<any> {
    return this.http.post(this.pathAPI + 'Account/LoginAsCustomer', body, super.header()).pipe(
      catchError(super.handleError)
    );
  }

  verifyOtp(body: any): Observable<any> {
    return this.http.post(this.pathAPI + 'Account/VerifyOtp', body, super.header()).pipe(
      catchError(super.handleError)
    );
  }

  verifyOtpForQuote(body: any): Observable<any> {
    return this.http.post(this.pathAPI + 'Account/VerifyOtpForQuote', body, super.header()).pipe(
      catchError(super.handleError)
    );
  }

  registerCustomer(body: any): Observable<any> {
    return this.http.post(this.pathAPI + 'Account/RegisterAsCustomer', body, super.header()).pipe(
      catchError(super.handleError)
    );
  }
}
