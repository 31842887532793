<div class="page-content">
    <div class="container-fluid" style="margin-top: 30px;align-items: center;">
        <div class="col-lg-9">
            <div class="card">
                <div class="card-body" style="padding: 1rem;">
                    <div class="d-flex justify-content-between">
                        <div>
                            <h4 class="heading-text mb-4"> {{ 'Vehicle Details' | translate }}</h4>
                        </div>
                    </div>
                    <div class="d-flex">
                        <div class="table-responsive" style="overflow-x:unset">
                            <table class="table table-centered table-nowrap mb-0 col-6">
                                <tbody>
                                    <tr>
                                        <td>
                                            <h5 class="data-text m-0">{{ 'Plate Number' | translate }} </h5>
                                        </td>
                                        <td class="data-text">{{ carDetails.plateNumber }}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <h5 class="data-text m-0">{{ 'Purpose of License' | translate }}
                                            </h5>
                                        </td>
                                        <td class="data-text">{{ carDetails.purposeOfLicense }}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <h5 class="data-text m-0">{{ 'Car Make' | translate }}</h5>
                                        </td>
                                        <td class="data-text">{{ carDetails.carMake }}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <h5 class="data-text m-0">{{ 'Car Model' | translate }} </h5>
                                        </td>
                                        <td class="data-text">{{ carDetails.carModel }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="table-responsive" style="overflow-x:unset">
                            <table class="table table-centered table-nowrap mb-0 col-6">
                                <tbody>
                                    <tr>
                                        <td>
                                            <h5 class="data-text m-0">{{ 'Variant' | translate }}</h5>
                                        </td>
                                        <td class="data-text">{{ carDetails.variant }}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <h5 class="data-text m-0">{{ 'Model Year' | translate }} </h5>
                                        </td>
                                        <td class="data-text">{{ carDetails.modelYear }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <h5 class="data-text m-0">{{ 'Number of Passengers' | translate
                                                }}</h5>
                                        </td>
                                        <td class="data-text">{{carDetails.noOfPassenger}}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <h5 class="data-text m-0">{{ 'Market Value of Car' | translate
                                                }}</h5>
                                        </td>
                                        <td class="data-text">{{'KWD'|translate}} {{ carDetails.marketValue |
                                            number : '1.3-3'
                                            }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
