export class PolicyDetailsModel {
    insuranceCompanyName: string;
    policyNumber: number;
    startDate: Date;
    expiryDate: Date;
    premiumAmount: number;
    orderId: number;
    status: string;
    requestStatus?: string;

    constructor(insuranceCompanyName?: string, policyNumber?: number, premiumAmount?: number, startDate?: Date, expiryDate?: Date, orderId?: number, status?: string, requestStatus?: string) {
        this.insuranceCompanyName = insuranceCompanyName,
            this.policyNumber = policyNumber,
            this.startDate = startDate,
            this.expiryDate = expiryDate,
            this.premiumAmount = premiumAmount,
            this.orderId = orderId;
        this.status = status;
        this.requestStatus = requestStatus;
    }
}

export enum RateCardType {
    RATE_CARD = "rate_card",
    ADD_ONS = "add_ons"
}

export enum RCValueType {
    CALCULATIVE = "Calculative",
    TEXT = "Text",
}

export enum PlanType {
    TPL = "tpl",
    COMPREHENSIVE = "comprehensive",
    TPLANDCOMPREHENSIVE = "tplAndComprehensive"
}

export enum PlanStatus {
    NEW_QUOTE = "New Quote",
    DELIVERED = "Delivered",
    PAY_NOW = "Pay Now",
    UNSUBMITTED_QUOTE = "Unsubmitted Quote",
    WAITING_FOR_APPROVAL = "Waiting For Approval",
    REJECTED = "Rejected",
    QUOTE_EXPIRED = "Quote Expired",
    POLICY_PRINTED = "Policy Printed"
}

export enum QuoteStatus {
    CUSTOMER_ACCEPTED = 'CUSTOMER_ACCEPTED',
    CUSTOMER_REJECTED = 'CUSTOMER_REJECTED',
    ADMIN_APPROVED = 'ADMIN_APPROVED',
    ADMIN_REJECTED = 'ADMIN_REJECTED',
    ADMIN_UPDATED = 'ADMIN_UPDATED',

    NEW_QUOTE = "New Quote",
    APPROVED = "Approved Request",
    REJECT = "Reject",
    CONDITIONALLY_APPROVE = "Conditionally Approve",
    REJECTED = "Rejected",
    PENDING = "PENDING",
    WAITING_FOR_APPROVAL = "Waiting For Approval",
    NOT_SUBMITTED = "Not Submitted",
    POLICY_PRINTED = "POLICY_PRINTED",
    PAYMENT_DONE = "PAYMENT_DONE",
    DELIVERED = "DELIVERED",
    DISPATCHED = "DISPATCHED",
}

export enum Steps {
    QUOTES = 1,
    USER_INFO = 2,
    CONFIRMATION = 3,
    SUCCESS = 6
}