import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Helpers } from '../helper/helper';
import { RegistrationPurpose } from '../models/userModel';
import { BaseService } from './base.service';
import { TranslateService } from 'src/app/services/translate.service';
import { Fee } from '../models/tpl.model';

@Injectable({
  providedIn: 'root'
})
export class TplService extends BaseService {

  pathAPIForTPL = environment.apiUrlForTPL;

  constructor(helper: Helpers,
    private http: HttpClient, translate: TranslateService) {
    super(helper, translate);
  }

  getFee(): Observable<any> {
    return this.http.get(this.pathAPIForTPL + "Fee", super.header()).pipe(
      catchError(super.handleError)
    );
  }

  getTPL(modelYear: number, vehicleType: RegistrationPurpose, noOfPassengers: number): Observable<Fee[]> {
    return this.http.get<Fee[]>(this.pathAPIForTPL + "TPL?ModelYear=" + modelYear + "&VehicleType=" + vehicleType + "&Passengers=" + noOfPassengers, super.header()).pipe(
      catchError(super.handleError)
    );
  }

  completeTransaction(data: any, token: string): Observable<any> {
    return this.http.post<any>(environment.apiUrl + 'TPLPurchase', data, super.header()).pipe(catchError(super.handleError));
  }

  initiateTransaction(renewCustomerId: number, data: any, token: string): Observable<any> {
    return this.http.post<any>(environment.apiUrl + 'TPLPurchase/InitiateTransaction/' + renewCustomerId, data, super.header()).pipe(catchError(super.handleError));
  }
}
