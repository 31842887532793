import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Helpers } from '../helper/helper';
import { BaseService } from './base.service';
import { TranslateService } from 'src/app/services/translate.service';
import { IUpdateCustomerStatusRequest } from '../contracts/requests/iupdate-customer-status';
import { RateCard } from '../models/rate-card';

@Injectable({
  providedIn: 'root'
})
export class QuoteService extends BaseService {

  constructor(helper: Helpers, private http: HttpClient, translate: TranslateService) {
    super(helper, translate);
  }

  getQuoteCount(): Observable<any> {
    return this.http.get(this.pathAPI + "Quote/Count", super.header()).pipe(
      catchError(super.handleError)
    )
  }

  getAllQuoteRequest(): Observable<any> {
    return this.http.get(this.pathAPI + "Quote/AllRequest", super.header()).pipe(
      catchError(super.handleError)
    )
  }

  getQuoteRequestByVehicleId(requestId: string): Observable<any> {
    return this.http.get(this.pathAPI + "Quote/Request/" + requestId, super.header()).pipe(
      catchError(super.handleError)
    )
  }

  updateCustomerStatus(request: IUpdateCustomerStatusRequest): Observable<any> {
    return this.http.post(this.pathAPI + "Quote/CustomerStatusUpdate/", request, super.header()).pipe(
      catchError(super.handleError)
    )
  }

  sendNotificationToApp(requestId: number): Observable<any> {
    return this.http.post(this.pathAPI + "Quote/SendNotificationToApp/" + requestId, null, super.header()).pipe(
      catchError(super.handleError)
    )
  }

  getQuotes(body: any): Observable<RateCard[]> {
    return this.http.post<RateCard[]>(environment.apiUrlForRateCard + "Quote/GetInsurerRateCardsForCustomer", body, super.header())
      .pipe(
        map(res => res.map((rateCard: any) => new RateCard(rateCard.id, rateCard)))
      ).pipe(
        catchError(super.handleError)
      )
  }

  getQuoteByCarId(carId: string): Observable<any> {
    return this.http.get(this.pathAPI + "Quote/" + carId, super.header()).pipe(
      catchError(super.handleError)
    )
  }

  addQuote(body: any): Observable<any> {
    return this.http.post(environment.apiUrlForRateCard + 'Quote', body, super.header()).pipe(
      catchError(super.handleError)
    );
  }

  updateQuote(body: any, quoteId: string): Observable<any> {
    return this.http.put(this.pathAPI + 'Quote/UpdateQuote/' + quoteId, body, super.header()).pipe(
      catchError(super.handleError)
    );
  }

  addConfirmedQuote(vehicleId: number, rateCard: RateCard): Observable<any> {
    const filteredRC = new RateCard(rateCard.rateCardId, rateCard, true);
    return this.http.put(environment.apiUrlForRateCard + 'Quote/SubmitQuote?vehicleId=' + vehicleId, filteredRC, super.header()).pipe(
      catchError(super.handleError)
    );
  }

  getQuoteSummaryForVehicle(vehicleId: string): Observable<any> {
    return this.http.get(this.pathAPI + "Quote/GetQuoteSummary/" + vehicleId, super.header()).pipe(
      catchError(super.handleError)
    );
  }

  addRateCard(body: any): Observable<any> {
    return this.http.post(this.pathAPI + 'Quote/AddRateCard', body, super.header()).pipe(
      catchError(super.handleError)
    );
  }

  getRateCardByInsurerId(insurerId: string): Observable<any> {
    return this.http.get(this.pathAPI + "Quote/GetRateCard/" + insurerId, super.header()).pipe(
      catchError(super.handleError)
    )
  }

  updateRateCardByInsurerId(body: any, insurerId: string): Observable<any> {
    return this.http.put(this.pathAPI + 'Quote/UpdateRateCard/' + insurerId, body, super.header()).pipe(
      catchError(super.handleError)
    );
  }

  getAllRateCard(): Observable<any> {
    return this.http.get(this.pathAPI + "Quote/GetAllRateCard", super.header()).pipe(
      catchError(super.handleError)
    );
  }

  updateRequestOfCustomer(userId: string, vehicleId: string, body: any): Observable<any> {
    return this.http.put(this.pathAPI + "Quote/UpdateRequestOfCustomer/" + userId + "/" + vehicleId, body, super.header()).pipe(
      catchError(super.handleError)
    );
  }

  getAllOrders(): Observable<RateCard[]> {
    return this.http.post(environment.apiUrlForRateCard + "Quote/GetAllOrders", {}, super.header()).pipe(map((x: any) => {
      return x.map(y => new RateCard(y.rateCardId, y));
    })).pipe(
      catchError(super.handleError)
    );
  }

  getOrderByVehicleId(vehicleId: string): Observable<any> {
    return this.http.get(this.pathAPI + "Quote/Order/" + vehicleId, super.header()).pipe(
      catchError(super.handleError)
    );
  }

  uploadCarImages(vehicleId: string, body: any): Observable<any> {
    return this.http.post(this.pathAPI + "Quote/CarImages/" + vehicleId, body, super.header()).pipe(
      catchError(super.handleError)
    );
  }

  getCarImagesByVehicleId(vehicleId: string): Observable<any> {
    return this.http.get(this.pathAPI + "Quote/CarImages/" + vehicleId, super.header()).pipe(
      catchError(super.handleError)
    );
  }

  setPolicyNumberAndPremium(userId: string, vehicleId: number, body: any): Observable<any> {
    return this.http.put(this.pathAPI + "Quote/SetPolicyNumberAndPremium/" + userId + '/' + vehicleId, body, super.header()).pipe(
      catchError(super.handleError)
    );
  }

  getQuoteById(id: number): Observable<any> {
    return this.http.get(environment.apiUrlForRateCard + "Quote/GetCustomerRateCard/" + id, super.header()).pipe(
      catchError(super.handleError)
    );
  }

  acceptRateCard(rateCardId): Observable<any> {
    return this.http.put(environment.apiUrlForRateCard + "Quote/CustomerAcceptedRequest?rateCardId=" + rateCardId, {}, super.header()).pipe(
      catchError(super.handleError)
    );
  }

  rejectRateCard(rateCardId): Observable<any> {
    return this.http.put(environment.apiUrlForRateCard + "Quote/CustomerRejectedRequest?rateCardId=" + rateCardId, {}, super.header()).pipe(
      catchError(super.handleError)
    );
  }
}
