<!-- Start Footer Top Area -->
<footer class="footer-top-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-widget">
                    <a href="index.html">
                        <img src="../../../assets/img/footer-logo.png" alt="Image">
                    </a>

                    <p>Lorem ipsum dolor sitamet,cons adipiscing elit, sed do eiusmod te incididunt ut labore et dolore Lorem ipsum dolor sitamet,cons adipiscing dolore Lorem ipsum dolor.</p>

                    <div class="social-area">
                        <ul>
                            <li>
                                <a href="#"><i class="bx bxl-facebook"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="bx bxl-twitter"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="bx bxl-linkedin"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="bx bxl-youtube"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="bx bxl-instagram"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-widget contact">
                    <h3>Contact Us</h3>

                    <ul>
                        <li class="pl-0">
                            <a href="tel:Phone:+822456974">
                                <i class="flaticon-call"></i>
                                <span>Hotline:</span> 
                                Phone: +822456974
                            </a>
                        </li>
                        
                        <li class="pl-0">
                            <a href="mailto:hello@surety.com">
                                <i class="flaticon-email"></i>
                                <span>Email:</span> 
                                hello@surety.com
                            </a>
                        </li>
                        
                        <li>
                            <i class="flaticon-maps-and-flags"></i>
                            <span>Address:</span> 
                            123, Western Road, Melbourne Australia
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="single-widget">
                    <h3>Service Links</h3>

                    <ul>
                        <li>
                            <a href="#">
                                Insurance
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                Car Insurance
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                Home Insurance
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                Investment
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                Health Insurance
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                Business 
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="single-widget">
                    <h3>Newsletter</h3>

                    <p class="newsletter-p">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.</p>

                    <div class="subscribe-wrap">
                        <form class="newsletter-form" data-toggle="validator">
                            <input type="email" class="form-control" placeholder="Enter Your Email" name="EMAIL" required autocomplete="off">

                            <button class="default-btn" type="submit">
                                Subscribe
                            </button>

                            <div id="validator-newsletter" class="form-result"></div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- End Footer Top Area -->

<!-- Start Footer Bottom Area -->
<footer class="footer-bottom-area footer-bottom-electronics-area">
    <div class="container">
        <div class="copy-right">
            <p>Copyright @2020 Surety Designed By <a href="https://EnvyTheme.com/" target="blank">EnvyTheme</a></p>
        </div>
    </div>
</footer>
<!-- End Footer Bottom Area -->

<!-- Start Go Top Area -->
<div class="go-top">
    <i class='bx bx-chevrons-up'></i>
    <i class='bx bx-chevrons-up'></i>
</div>
<!-- End Go Top Area --> 
