import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatSnackBar as MatSnackBar, MatSnackBarRef as MatSnackBarRef, SimpleSnackBar as SimpleSnackBar } from '@angular/material/snack-bar';
import { ReplaySubject, Subject } from 'rxjs';
import { CarDetails } from 'src/app/models/carModel';
import { Fee, PurposeOfLicense } from 'src/app/models/tpl.model';
import { TranslateService } from 'src/app/services/translate.service';
import { MatDialog as MatDialog, MatDialogRef as MatDialogRef } from '@angular/material/dialog';
import { take, takeUntil } from 'rxjs/operators';
import { LoaderDialogComponent } from 'src/app/dialogs/loader-dialog/loader-dialog.component';
import { CatalogService } from 'src/app/services/catalog.service';
import { TplService } from 'src/app/services/tpl.service';
import { PaymentService } from 'src/app/services/payment.service';
import * as HesabeCrypt from 'hesabe-crypt';
import * as aesjs from 'aes-js';
import { environment } from 'src/environments/environment';
import { InitiateTransactionForTPLRequestBody } from 'src/app/models/paymentModel';
import { Helpers } from 'src/app/helper/helper';
import { UserService } from 'src/app/services/user.service';
import { AddCivilCardDialogComponent } from 'src/app/dialogs/add-civil-card-dialog/add-civil-card-dialog.component';
import { ActivatedRoute } from '@angular/router';
import { VehicleService } from 'src/app/services/vehicle.service';

@Component({
  selector: 'app-tpl',
  templateUrl: './tpl.component.html',
  styleUrls: ['./tpl.component.scss']
})
export class TplComponent implements OnInit, OnDestroy {

  carDetails: CarDetails;
  isCarDetailsAvail: boolean;
  fetchedCarDetails: CarDetails;

  showText: boolean;

  fees: Fee[];
  selectedFee: Fee;
  fee: Fee;
  selectedLicense: PurposeOfLicense;
  userId: string;
  userCivilCardList: any;
  selectCivilCard: number;

  // Payment details
  payment: HesabeCrypt;

  // Car Make
  get CarMake() {
    return this.carDetails.carMake;
  }
  set CarMake(value) {
    this.carDetails.carMake = value;
    this.getCarModels();
    this.carDetails.carMakeAr = '';
    this.carDetails.carModel = '';
    this.carDetails.trim = '';
  }

  get CarMakeAr() {
    return this.carDetails.carMakeAr;
  }
  set CarMakeAr(value) {
    this.carDetails.carMakeAr = value;
    this.getCarModels();
    this.carDetails.carMake = '';
    this.carDetails.carModelAr = '';
    this.carDetails.trimAr = '';
  }

  // Car Model
  get CarModel() {
    return this.carDetails.carModel;
  }
  set CarModel(value) {
    this.carDetails.carModel = value;
    this.getCarTrim();
    this.carDetails.carModelAr = '';
    this.carDetails.trim = '';
  }

  get CarModelAr() {
    return this.carDetails.carModelAr;
  }
  set CarModelAr(value) {
    this.carDetails.carModelAr = value;
    this.getCarTrim();
    this.carDetails.carModel = '';
    this.carDetails.trimAr = '';
  }

  // Model Year
  get ModelYear() {
    return this.carDetails.modelYear;
  }
  set ModelYear(value) {
    this.carDetails.modelYear = value;
    this.getQuote();
  }

  // Car Trim
  get CarTrim() {
    return this.carDetails.trim;
  }
  set CarTrim(value) {
    this.carDetails.trim = value;
    this.getPassengerCount();
    this.carDetails.trimAr = '';
  }

  get CarTrimAr() {
    return this.carDetails.trimAr;
  }
  set CarTrimAr(value) {
    this.carDetails.trimAr = value;
    this.getPassengerCount();
    this.carDetails.trim = '';
  }

  get SelectedLicense() {
    return this.selectedLicense;
  }
  set SelectedLicense(value) {
    this.selectedLicense = value;
    if (value) {
      this.getQuote();
    }
  }

  selectedLicenseAr: PurposeOfLicense;
  get SelectedLicenseAr() {
    return this.selectedLicenseAr;
  }
  set SelectedLicenseAr(value) {
    this.selectedLicenseAr = value;
    if (value) {
      this.getQuote();
    }
  }

  carMakeList: any;
  carModelList: any;
  carTrimList: any;

  carMakeListAr: any;
  carModelListAr: any;
  carTrimListAr: any;

  passengers: number = 0;

  load = 0;
  hasQuote = false;
  token: string;
  getQuoteRef: MatSnackBarRef<SimpleSnackBar>;
  purposeOfLicenses =
    [
      new PurposeOfLicense(0, 'Private'),
      new PurposeOfLicense(1, 'Taxi'),
      new PurposeOfLicense(2, 'Japanese Pick Up'),
      new PurposeOfLicense(3, 'American Pick Up'),
      new PurposeOfLicense(4, 'Water Tank'),
      new PurposeOfLicense(5, 'Ambulance'),
      new PurposeOfLicense(6, 'Motorcycle'),
      new PurposeOfLicense(7, 'Crane'),
      new PurposeOfLicense(8, 'Bus'),
      new PurposeOfLicense(12, 'Mixer'),
      new PurposeOfLicense(13, 'Fork Cliff'),
      new PurposeOfLicense(15, 'Tipper'),
      new PurposeOfLicense(18, 'Half Lorry'),
    ];

  purposeOfLicensesAr =
    [
      new PurposeOfLicense(0, 'خاصة'),
      new PurposeOfLicense(0, 'تاكسي'),
      new PurposeOfLicense(0, 'ونيت ياباني'),
      new PurposeOfLicense(0, 'ونيت أمريكي'),
      new PurposeOfLicense(0, 'خزان مياه'),
      new PurposeOfLicense(0, 'إسعاف'),
      new PurposeOfLicense(0, ' دراجة بخارية'),
      new PurposeOfLicense(0, 'رافعة (كرين)'),
      new PurposeOfLicense(0, 'باص'),
    ];


  /** control for the selected carMake */
  public carMakeCtrl: UntypedFormControl = new UntypedFormControl();
  public carMakeCtrlAr: UntypedFormControl = new UntypedFormControl();
  /** control for the MatSelect filter keyword */
  public carMakeFilterCtrl: UntypedFormControl = new UntypedFormControl();
  public carMakeFilterCtrlAr: UntypedFormControl = new UntypedFormControl();
  /** list of car make filtered by search keyword */
  public filteredCarMakeList: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);
  public filteredCarMakeListAr: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);

  /** control for the selected carModel */
  public carModelCtrl: UntypedFormControl = new UntypedFormControl();
  public carModelCtrlAr: UntypedFormControl = new UntypedFormControl();
  /** control for the MatSelect filter keyword */
  public carModelFilterCtrl: UntypedFormControl = new UntypedFormControl();
  public carModelFilterCtrlAr: UntypedFormControl = new UntypedFormControl();
  /** list of car make filtered by search keyword */
  public filteredCarModelList: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);
  public filteredCarModelListAr: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);
  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  loaderDialogRef: MatDialogRef<LoaderDialogComponent>;

  constructor(private translateService: TranslateService,
    private snackBar: MatSnackBar,
    private catalogService: CatalogService,
    private tplService: TplService,
    private paymentService: PaymentService,
    private helper: Helpers,
    private userService: UserService,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private vehicleService: VehicleService) {

    // Add class to body to manipulated style of select dropdown. 
    document.body.classList.add("ngx-select");

    // payment related variable initialization.
    const secret = environment.paymetGateway.secret;
    const ivKey = environment.paymetGateway.ivKey;

    const key = aesjs.utils.utf8.toBytes(secret);
    const iv = aesjs.utils.utf8.toBytes(ivKey);
    this.payment = new HesabeCrypt(key, iv);
    this.token = this.helper.getToken();

    this.loaderDialogRef = this.dialog.open(LoaderDialogComponent, {
      disableClose: true,
      id: 'loader-dialog',
      backdropClass: 'loader-backdrop'
    });

    // Get User Civil Card details.
    this.userService.getCustomerCivilCardDetail().subscribe(result => {
      if (result && result.length > 0) {
        this.userCivilCardList = Object.assign([], result);
        this.selectCivilCard = this.userCivilCardList[0].id;
      }
      this.hideLoaderDialog();
    }, error => {
      this.hideLoaderDialog();
    });

    // Get Car Make list from server, and initialize base variable
    this.carDetails = new CarDetails({});
    this.selectedLicense = this.purposeOfLicenses[0];
    this.selectedLicenseAr = this.purposeOfLicensesAr[0];

    // Get TPL service Fees
    this.tplService.getFee().subscribe(fee => {
      this.fee = fee;
    });

    // Get Car Makes
    this.catalogService.getCarMakes().subscribe(carMakesResponse => {
      this.carMakeList = carMakesResponse;
      this.createCarMakeFilter();
      if (this.isCarDetailsAvail) {
        this.carMakeCtrl.patchValue(this.fetchedCarDetails.carMake);
      }
    }, error => {
      this.snackBar.open("Error occur while fetching Car Makes", 'Cancel', {
        duration: 2000,
        horizontalPosition: 'right'
      });
    });

    this.catalogService.getCarMakes('ar').subscribe(carMakesArResponse => {
      if (carMakesArResponse) {
        this.carMakeListAr = carMakesArResponse.filter(carMake => { if (carMake) return true });
        this.createCarMakeFilterAr();
      }
    }, error => { });
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(param => {
      var vehicleId = +param.get("id");
      if (vehicleId && !Number.isNaN(vehicleId)) {
        this.vehicleService.getCarById(vehicleId.toString()).subscribe((vehicle: CarDetails) => {
          this.isCarDetailsAvail = true;
          this.fetchedCarDetails = vehicle;
          this.fetchedCarDetails.trim = vehicle.variant;
          this.carDetails.modelYear = this.fetchedCarDetails.modelYear;
          this.carDetails.firstName = this.fetchedCarDetails.firstName;
          this.carDetails.lastName = this.fetchedCarDetails.lastName;
          this.carDetails.id = this.fetchedCarDetails.id;
          this.carMakeCtrl.patchValue(this.fetchedCarDetails.carMake);
        }, error => {

        });
      }
    });
  }

  ngOnDestroy() {
    document.body.classList.remove("ngx-select");

    this._onDestroy.next();
    this._onDestroy.complete();

    if (this.loaderDialogRef) {
      this.loaderDialogRef.close();
    }
  }

  hideLoaderDialog(): void {
    if (this.loaderDialogRef) {
      this.loaderDialogRef.close();
    }
  }

  //#region Car Make filter

  createCarMakeFilter(): void {
    // load the initial car make list
    this.filteredCarMakeList.next(this.carMakeList.slice());

    // listen for search field value changes
    this.carMakeFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterCarMake();
      });

    this.carMakeCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(
      () => {
        this.CarMake = this.carMakeCtrl.value;
      }, error => { }
    )
  }

  createCarMakeFilterAr(): void {
    // load the initial car make list
    this.filteredCarMakeListAr.next(this.carMakeListAr.slice());

    // listen for search field value changes
    this.carMakeFilterCtrlAr.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterCarMakeAr();
      });

    this.carMakeCtrlAr.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(
      () => {
        this.CarMakeAr = this.carMakeCtrlAr.value;
      }, error => { }
    )
  }

  protected filterCarMake() {
    if (!this.carMakeList) {
      return;
    }
    // get the search keyword
    let search = this.carMakeFilterCtrl.value;
    if (!search) {
      this.filteredCarMakeList.next(this.carMakeList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the CarMake
    this.filteredCarMakeList.next(
      this.carMakeList.filter(carMake => carMake.toLowerCase().indexOf(search) > -1)
    );
  }

  protected filterCarMakeAr() {
    if (!this.carMakeListAr) {
      return;
    }
    // get the search keyword
    let search = this.carMakeFilterCtrlAr.value;
    if (!search) {
      this.filteredCarMakeListAr.next(this.carMakeListAr.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the CarMake
    this.filteredCarMakeListAr.next(
      this.carMakeListAr.filter(carMake => carMake.toLowerCase().indexOf(search) > -1)
    );
  }

  //#endregion

  //#region  Car model filter

  createCarModelFilter(): void {
    // load the initial car model list
    this.filteredCarModelList.next(this.carModelList.slice());

    // listen for search field value changes
    this.carModelFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterCarModel();
      });

    this.carModelCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(
      () => {
        this.CarModel = this.carModelCtrl.value;
      }, error => { }
    )
  }

  createCarModelFilterAr(): void {
    // load the initial car model list
    this.filteredCarModelListAr.next(this.carModelListAr.slice());

    // listen for search field value changes
    this.carModelFilterCtrlAr.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterCarModelAr();
      });

    this.carModelCtrlAr.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(
      () => {
        this.CarModelAr = this.carModelCtrlAr.value;
      }, error => { }
    )
  }

  protected filterCarModel() {
    if (!this.carModelList) {
      return;
    }
    // get the search keyword
    let search = this.carModelFilterCtrl.value;
    if (!search) {
      this.filteredCarModelList.next(this.carModelList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the CarModel
    this.filteredCarModelList.next(
      this.carModelList.filter(carModel => carModel.toLowerCase().indexOf(search) > -1)
    );
  }

  protected filterCarModelAr() {
    if (!this.carModelListAr) {
      return;
    }
    // get the search keyword
    let search = this.carModelFilterCtrlAr.value;
    if (!search) {
      this.filteredCarModelListAr.next(this.carModelListAr.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the CarModel
    this.filteredCarModelListAr.next(
      this.carModelListAr.filter(carModel => carModel.toLowerCase().indexOf(search) > -1)
    );
  }

  //#endregion car model filter

  getLanguage(): string {
    return this.translateService.lang;
  }

  getDeviceLink(): string {
    if (!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)) {
      return "https://bit.ly/3d4E2Wm";
    } else {
      return "https://bit.ly/2YkSjuf";
    }
  }

  // Get Car Models
  getCarModels() {
    let carModelSnackBarRef;
    if (this.carDetails.carMake && this.translateService.lang == 'en') {
      this.catalogService.getCarModels(this.carDetails.carMake).subscribe(carModelsResponse => {
        this.carModelList = carModelsResponse;
        this.createCarModelFilter();
        if (this.isCarDetailsAvail) {
          this.carModelCtrl.patchValue(this.fetchedCarDetails.carModel);
        }
      }, error => {
        carModelSnackBarRef = this.snackBar.open("Error occur while fetching Car Models", 'Try Again', {
          duration: 2000,
          horizontalPosition: 'right'
        });
      });
    }

    if (this.carDetails.carMakeAr && this.translateService.lang == 'ar') {
      this.catalogService.getCarModels(this.carDetails.carMakeAr, 'ar').subscribe(carModelsArResponse => {
        this.carModelListAr = carModelsArResponse;
        this.createCarModelFilterAr();
      }, error => {
        carModelSnackBarRef = this.snackBar.open("Error occur while fetching Car Models", 'Try Again', {
          duration: 2000,
          horizontalPosition: 'right'
        });
      });
    }

    if (carModelSnackBarRef) {
      carModelSnackBarRef.onAction().pipe(take(1)).subscribe(
        action => {
          carModelSnackBarRef.dismiss();
          this.getCarModels();
        }, error => { });
    }
  }

  // Get Car trim
  getCarTrim() {
    let carTrimSnackBarRef;
    if (this.carDetails.carMake && this.carDetails.carModel && this.translateService.lang == 'en') {
      this.catalogService.getCarTrim(this.carDetails.carMake, this.carDetails.carModel).subscribe(carTrimResponse => {
        this.carTrimList = carTrimResponse;
        if (this.isCarDetailsAvail) {
          this.CarTrim = this.fetchedCarDetails.variant;
        }
      }, error => {
        carTrimSnackBarRef = this.snackBar.open("Error occur while fetching Car Trim", 'Try Again', {
          duration: 2000,
          horizontalPosition: 'right'
        });
      });
    }

    if (this.carDetails.carMakeAr && this.carDetails.carModelAr && this.translateService.lang == 'ar') {
      this.catalogService.getCarTrim(this.carDetails.carMakeAr, this.carDetails.carModelAr, 'ar').subscribe(carTrimArResponse => {
        this.carTrimListAr = carTrimArResponse;
      }, error => {
        carTrimSnackBarRef = this.snackBar.open("Error occur while fetching Car Trim", 'Try Again', {
          duration: 2000,
          horizontalPosition: 'right'
        });
      });
    }

    if (carTrimSnackBarRef) {
      carTrimSnackBarRef.onAction().pipe(take(1)).subscribe(
        action => {
          carTrimSnackBarRef.dismiss();
          this.getCarTrim();
        }, error => { });
    }
  }

  // Get PassengerCount
  getPassengerCount() {
    let passengerSnackBarRef;
    if (this.carDetails.carMake && this.carDetails.carModel && this.carDetails.trim && this.translateService.lang == 'en') {
      this.catalogService.getPassengerCount(this.carDetails.carMake, this.carDetails.carModel, this.carDetails.trim).subscribe(passengerCountResponse => {
        this.passengers = passengerCountResponse.passengers;
        this.getQuote();
      }, error => {
        passengerSnackBarRef = this.snackBar.open("Error occur while fetching passenger count", 'Try Again', {
          duration: 2000,
          horizontalPosition: 'right'
        });
      });
    }

    if (this.carDetails.carMakeAr && this.carDetails.carModelAr && this.carDetails.trimAr && this.translateService.lang == 'ar') {
      this.catalogService.getPassengerCount(this.carDetails.carMakeAr, this.carDetails.carModelAr, this.carDetails.trimAr).subscribe(passengerCountResponse => {
        this.passengers = passengerCountResponse.passengers;
        this.getQuote();
      }, error => {
        passengerSnackBarRef = this.snackBar.open("Error occur while fetching passenger count", 'Try Again', {
          duration: 2000,
          horizontalPosition: 'right'
        });
      });
    }

    if (passengerSnackBarRef) {
      passengerSnackBarRef.onAction().pipe(take(1)).subscribe(
        action => {
          passengerSnackBarRef.dismiss();
          this.getPassengerCount();
        }, error => { });
    }
  }

  // Get Quote
  getQuote() {
    if (this.carDetails.modelYear != null
      && this.selectedLicense.id != null
      && this.passengers != null
      && this.load != null && this.carDetails.modelYear.length > 3) {
      this.hasQuote = false;
      this.tplService.getTPL(+this.carDetails.modelYear, (this.translateService.lang == 'en' ? this.selectedLicense.id : this.selectedLicenseAr.id), this.passengers).subscribe(fees => {
        this.fees = fees;
        this.selectedFee = fees[0];
        this.hasQuote = true;
      }, error => {
        this.getQuoteRef = this.snackBar.open("Unable to retrieve payment information.", "Try again", { duration: 2000, horizontalPosition: 'right' });

        this.getQuoteRef.onAction().pipe(take(1)).subscribe(action => {
          this.getQuoteRef.dismiss();
          this.getQuote();
        }, error => { });
      });
    }
  }

  makePayment(form) {
    const tplRequestBody = new InitiateTransactionForTPLRequestBody(
      this.passengers,
      this.selectedFee.price,
      this.fee.issueFee,
      this.getSupervisionFeeForPeriod(this.selectedFee.period),
      this.selectedFee.price,
      this.translateService.lang == 'en' ? this.selectedLicense.name : this.selectedLicenseAr.name,
      this.carDetails.plateNumber,
      "",
      this.fee.deliveryFee,
      this.translateService.lang == 'en' ? this.carDetails.carMake : this.carDetails.carMakeAr,
      this.fee.convenienceFee,
      this.selectedFee.price,
      +this.carDetails.modelYear,
      this.translateService.lang == 'en' ? this.carDetails.carModel : this.carDetails.carModelAr,
      this.selectedFee ? this.selectedFee.id : 0,
      this.carDetails.rcBookId ? this.carDetails.rcBookId : null,
      this.selectCivilCard,
      this.translateService.lang == 'en' ? this.carDetails.trim : this.carDetails.trimAr);

    tplRequestBody.ownerName = this.carDetails.ownerName;

    this.paymentService.initiateTransactionForTPL(tplRequestBody).subscribe((initiateTransactionResponse: any) => {
      if (initiateTransactionResponse.policyId) {
        let paymentObj = {
          policyId: initiateTransactionResponse.policyId,
          civilId: initiateTransactionResponse.civilId,
          rcBookId: initiateTransactionResponse.rcBookId
        }
        const requestBody = {
          merchantCode: environment.paymetGateway.merchantCode,
          amount: this.selectedFee.price + this.getSupervisionFeeForPeriod(this.selectedFee.period) + this.fee.issueFee + this.fee.deliveryFee + this.fee.convenienceFee,
          paymentType: 1,
          responseUrl: window.location.origin + '/tpl-details?' + (this.token ? ('?token=' + this.token) : ''),
          failureUrl: window.location.origin + '/payment-failure' + (this.token ? ('?token=' + this.token) : ''),
          version: '2.0',
          variable1: this.selectedFee ? this.selectedFee.id : 0,
          variable2: paymentObj.policyId,
          variable3: paymentObj.civilId + "," + paymentObj.rcBookId + "," + (this.fetchedCarDetails ? this.fetchedCarDetails.id : ""),
          variable4: this.translateService.lang,
          variable5: true,
        };

        const encryptedRequestBody = this.payment.encryptAes(JSON.stringify(requestBody)); // Ecnryption
        this.paymentService.getPaymentGatewayLink(encryptedRequestBody).subscribe(paymentRes => {
          const decrypted = JSON.parse(this.payment.decryptAes(paymentRes));
          window.location.href = environment.paymetGateway.url + '/payment?data=' + decrypted.response.data;
        }, err => {
          this.snackBar.open('Error occur while Initiating Transaction. Please try again.', 'CANCEL');
        });
      }
    }, error => {
      this.snackBar.open('Error occur while Initiating Transaction. Please try again.', 'CANCEL');
    });
  }

  // Add Civil Card.
  addCivilCard(): void {
    const addCCDialog = this.dialog.open(AddCivilCardDialogComponent, { disableClose: true, maxWidth: '380px' });

    addCCDialog.afterClosed().subscribe(result => {
      if (result && result != 'false') {
        this.userService.addCustomerCivilCard(result).subscribe(addResponse => {
          if (addResponse) {
            this.userCivilCardList.push(addResponse);
            this.selectCivilCard = this.userCivilCardList[this.userCivilCardList.length - 1].id;
          }
        }, error => {
          this.selectCivilCard = this.userCivilCardList[this.userCivilCardList.length - 1].id;
          this.snackBar.open(error, "CANCEL", {
            horizontalPosition: 'right',
            duration: 2000
          });
        });
      }
    });
  }

  // Supervision fee for selected period
  getSupervisionFeeForPeriod(period: number): number {
    return (period == 1 ? this.fee.supervisionFee : (period == 2 ? (this.fee.supervisionFee2 ? this.fee.supervisionFee2 : this.fee.supervisionFee) : (period == 3 ? (this.fee.supervisionFee3 ? this.fee.supervisionFee3 : this.fee.supervisionFee) : this.fee.supervisionFee)));
  }

}
