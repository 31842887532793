<div class="page-content">
    <div class="container-fluid">
        <!-- start page title -->
        <div class="row">
            <div class="col-12">
                <div class="page-title-box d-flex align-items-center justify-content-between">
                    <h4 class="mb-0 font-size-18">{{ 'Order Details' | translate }}</h4>
                </div>
            </div>
        </div>
        <!-- end page title -->
        <div class="row">
            <div class="col-lg-8" *ngIf="orderDetails">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-xl-5">
                                        <div>
                                            <h4 class="card-title mt-1 mb-3">{{ 'Owner Details' | translate }}</h4>
                                            <div class="table-responsive">
                                                <table class="table table-centered table-nowrap mb-0">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <h5 class="font-size-14 text-dark m-0">{{ 'Owner Name' |
                                                                    translate }}</h5>
                                                            </td>
                                                            <td>{{ orderDetails.user.firstName + ' ' +
                                                                orderDetails.user.lastName}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <h5 class="font-size-14 text-dark m-0">{{ 'Owner Civil
                                                                    ID' | translate }}</h5>
                                                            </td>
                                                            <td>{{ orderDetails.civilCard.civilCardNumber}}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <h5 class="font-size-14 text-dark m-0">{{ 'Phone Number'
                                                                    | translate }}</h5>
                                                            </td>
                                                            <td>{{ orderDetails.user.phoneNumber ?
                                                                orderDetails.user.phoneNumber :
                                                                '-' }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <h5 class="font-size-14 text-dark m-0">{{ 'Email ID' |
                                                                    translate }}</h5>
                                                            </td>
                                                            <td>{{ orderDetails.user.email ? orderDetails.user.email :
                                                                '-' }}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-7 text-center">
                                        <div class="row justify-content-around">
                                            <div class="col-md-5 img-thumbnail">
                                                <p class="text-muted text-center">{{ 'Civil ID Front Image' | translate
                                                    }}</p>
                                                <a href="{{ SASTokenForCivilIdFrontImageRead ? getCivilIdFrontImageURL() : '' }}"
                                                    target="_blank"><img
                                                        src="{{ SASTokenForCivilIdFrontImageRead ? getCivilIdFrontImageURL() : 'assets/images/companies/img-6.png' }}"
                                                        onerror="this.src='assets/images/companies/img-6.png';" alt=""
                                                        class="img-fluid mx-auto d-block"></a>
                                            </div>
                                            <div class="col-md-5 img-thumbnail">
                                                <p class="text-muted text-center">{{ 'Civil ID Back Image' | translate
                                                    }}</p>
                                                <a href="{{ SASTokenForCivilIdBackImageRead ? getCivilIdBackImageURL() : '' }}"
                                                    target="_blank"><img
                                                        src="{{ SASTokenForCivilIdBackImageRead ? getCivilIdBackImageURL() : 'assets/images/companies/img-6.png' }}"
                                                        onerror="this.src='assets/images/companies/img-6.png';" alt=""
                                                        class="img-fluid mx-auto d-block"></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end col -->
                    <div class="col-lg-6">
                        <div class="card">
                            <div class="card-body">
                                <h4 class="card-title mb-4">{{ 'Order Details' | translate }}</h4>
                                <div class="table-responsive">
                                    <table class="table table-centered table-nowrap mb-0">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <h5 class="font-size-14 text-dark m-0">{{ 'Order Number' | translate
                                                        }}</h5>
                                                </td>
                                                <td>{{ orderDetails.rateCardId ? orderDetails.rateCardId : '-' }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <h5 class="font-size-14 text-dark m-0">{{ 'Order Status' | translate
                                                        }}</h5>
                                                </td>
                                                <td>
                                                    <span class="status-badge badge badge-soft-primary"
                                                        [class.badge-soft-success]="orderDetails.status == QuoteStatus.APPROVED"
                                                        [class.badge-soft-danger]="orderDetails.status == QuoteStatus.REJECTED"
                                                        [class.badge-soft-info]="orderDetails.status == QuoteStatus.DELIVERED || orderDetails.status == QuoteStatus.POLICY_PRINTED || orderDetails.status == QuoteStatus.PAYMENT_DONE || orderDetails.status == QuoteStatus.DISPATCHED">
                                                        {{ orderDetails.status ? (orderDetails.status|translate) : '-' | translate }} </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <h5 class="font-size-14 text-dark m-0">{{ 'Order Date' | translate
                                                        }}</h5>
                                                </td>
                                                <td>{{ orderDetails.policyOrderDate ? ( orderDetails.policyOrderDate |
                                                    date:'dd MMM
                                                    yyyy':'locale' ) : '-' }}</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <h5 class="font-size-14 text-dark m-0">{{
                                                        'orderDetails.deliveryDate' | translate }}</h5>
                                                </td>
                                                <td>{{ orderDetails.policyDeliveryDate ? (
                                                    orderDetails.policyDeliveryDate | date:'dd
                                                    MMM
                                                    yyyy':'locale' ) : '-' }}</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <h5 class="font-size-14 text-dark m-0">{{ 'Amount' | translate }}
                                                    </h5>
                                                </td>
                                                <td><span *ngIf="orderDetails.amount">{{'KWD'|translate}}</span> {{
                                                    orderDetails.amount | number:'1.3-3' }}</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <h5 class="font-size-14 text-dark m-0">{{ 'Payment Reference Number'
                                                        | translate }}</h5>
                                                </td>
                                                <td>{{ orderDetails.transaction.paymentToken ?
                                                    orderDetails.transaction.paymentToken : '-' }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <h5 class="font-size-14 text-dark m-0">{{ 'Delivery Address' |
                                                        translate }}</h5>
                                                </td>
                                                <td>{{ orderDetails.address ? orderDetails.address : '-' }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end col -->
                    <div class="col-lg-6">
                        <div class="card">
                            <div class="card-body">
                                <h4 class="card-title mb-4">{{ 'Policy Details' | translate }}</h4>
                                <div class="table-responsive">
                                    <table class="table table-centered table-nowrap mb-0">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <h5 class="font-size-14 text-dark m-0">{{ 'Policy Number' |
                                                        translate }}</h5>
                                                </td>
                                                <td>{{ orderDetails.policyNumber ? orderDetails.policyNumber : '-' }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <h5 class="font-size-14 text-dark m-0">{{ 'Insurance Company' |
                                                        translate }}</h5>
                                                </td>
                                                <td>{{ orderDetails.insurer.name ? orderDetails.insurer.name : '-' }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <h5 class="font-size-14 text-dark m-0">{{ 'Start Date' | translate
                                                        }}</h5>
                                                </td>
                                                <td>{{ orderDetails.policyStartDate ? ( orderDetails.policyStartDate |
                                                    date:'dd MMM
                                                    yyyy':'locale' ) : '-' }}</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <h5 class="font-size-14 text-dark m-0">{{ 'Expiry Date' | translate
                                                        }}</h5>
                                                </td>
                                                <td>{{ orderDetails.policyExpiryDate ? ( orderDetails.policyExpiryDate |
                                                    date:'dd MMM yyyy':'locale' ) : '-' }}</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <h5 class="font-size-14 text-dark m-0">{{ 'Issue Date' | translate
                                                        }}</h5>
                                                </td>
                                                <td>{{ orderDetails.policyIssueDate ? ( orderDetails.policyIssueDate |
                                                    date:'dd MMM
                                                    yyyy':'locale' ) : '-' }}</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <h5 class="font-size-14 text-dark m-0">{{ 'Delivery Date' |
                                                        translate }}</h5>
                                                </td>
                                                <td>{{ orderDetails.policyDeliveryDate ? (
                                                    orderDetails.policyDeliveryDate | date:'dd MMM
                                                    yyyy':'locale' ) : '-' }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end col -->
                </div>
            </div>
            <div class="col-lg-4">
                <div class="row">
                    <div class="col-lg-12" *ngIf="orderDetails">
                        <div class="card">
                            <div class="card-body">
                                <a class="cursor-pointer" [routerLink]="['/vehicle', orderDetails.vehicleId]">
                                    <h4 class="card-title mb-4">{{ 'Vehicle Details' | translate }}</h4>
                                </a>
                                <div class="table-responsive">
                                    <table class="table table-centered table-nowrap mb-0">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <h5 class="font-size-14 text-dark m-0">{{ 'Model Year' | translate
                                                        }}</h5>
                                                </td>
                                                <td>{{ orderDetails.vehicle.modelYear ? orderDetails.vehicle.modelYear :
                                                    '-' }}</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <h5 class="font-size-14 text-dark m-0">{{ 'Plate Number' | translate
                                                        }}</h5>
                                                </td>
                                                <td>{{ orderDetails.vehicle.plateNumber ?
                                                    orderDetails.vehicle.plateNumber : '-' }}</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <h5 class="font-size-14 text-dark m-0">{{ 'Car Make' | translate }}
                                                    </h5>
                                                </td>
                                                <td>{{ orderDetails.vehicle.carMake ? orderDetails.vehicle.carMake : '-'
                                                    }}</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <h5 class="font-size-14 text-dark m-0">{{ 'Car Model' | translate }}
                                                    </h5>
                                                </td>
                                                <td>{{ orderDetails.vehicle.carModel ? orderDetails.vehicle.carModel :
                                                    '-' }}</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <h5 class="font-size-14 text-dark m-0">{{ 'Trim' | translate }}</h5>
                                                </td>
                                                <td>{{ orderDetails.vehicle.carTrim ? orderDetails.vehicle.carTrim : '-'
                                                    }}</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <h5 class="font-size-14 text-dark m-0">{{ 'Market Value of Car' |
                                                        translate }}</h5>
                                                </td>
                                                <td><span *ngIf="orderDetails.vehicle.marketValue">{{'KWD'|translate}}</span> {{
                                                    orderDetails.vehicle.marketValue ? (
                                                    orderDetails.vehicle.marketValue | number:'1.3-3' ) : '-' }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end col -->
                    <div class="col-lg-12">
                        <div class="card">
                            <div class="card-body">
                                <h4 class="card-title mb-4">{{ 'Documents' | translate }}</h4>
                                <div class="table-responsive">
                                    <table class="table table-nowrap table-centered table-hover mb-0">
                                        <tbody>
                                            <tr>
                                                <td style="width: 45px;">
                                                    <div class="avatar-sm">
                                                        <span
                                                            class="avatar-title rounded-circle bg-soft-primary text-primary font-size-24">
                                                            <i class="bx bxs-file-doc"></i>
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <h5 class="font-size-14 mb-1"><a
                                                            (click)="downloadImage( ImageType.POLICY_DOC )"
                                                            class="text-dark cursor-pointer">{{ 'Policy Document' |
                                                            translate }}</a>
                                                    </h5>
                                                </td>
                                                <td>
                                                    <div class="text-center">
                                                        <a (click)="downloadImage( ImageType.POLICY_DOC )"
                                                            class="text-dark cursor-pointer"><i
                                                                class="bx bx-download h3 m-0"></i></a>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="width: 45px;">
                                                    <div class="avatar-sm">
                                                        <span
                                                            class="avatar-title rounded-circle bg-soft-primary text-primary font-size-24">
                                                            <i class="bx bxs-file-doc"></i>
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <h5 class="font-size-14 mb-1"><a
                                                            (click)="downloadImage( ImageType.MOROOR_DOC )"
                                                            class="text-dark cursor-pointer">{{ 'Moroor Document' |
                                                            translate }}</a>
                                                    </h5>
                                                </td>
                                                <td>
                                                    <div class="text-center">
                                                        <a (click)="downloadImage( ImageType.MOROOR_DOC )"
                                                            class="text-dark cursor-pointer"><i
                                                                class="bx bx-download h3 m-0"></i></a>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="width: 45px;">
                                                    <div class="avatar-sm">
                                                        <span
                                                            class="avatar-title rounded-circle bg-soft-primary text-primary font-size-24">
                                                            <i class="bx bxs-file-doc"></i>
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <h5 class="font-size-14 mb-1"><a
                                                            (click)="downloadImage( ImageType.INVOICE_DOC )"
                                                            class="text-dark cursor-pointer">{{ 'Invoice Document' |
                                                            translate }}</a>
                                                    </h5>
                                                </td>
                                                <td>
                                                    <div class="text-center">
                                                        <a (click)="downloadImage( ImageType.INVOICE_DOC )"
                                                            class="text-dark cursor-pointer"><i
                                                                class="bx bx-download h3 m-0"></i></a>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="avatar-sm">
                                                        <span
                                                            class="avatar-title rounded-circle bg-soft-primary text-primary font-size-24">
                                                            <i class="bx bxs-file-doc"></i>
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <h5 class="font-size-14 mb-1"><a
                                                            (click)="downloadImage( ImageType.CIVILID )"
                                                            class="text-dark cursor-pointer">{{ 'Owner Civil ID' |
                                                            translate }}</a>
                                                    </h5>
                                                </td>
                                                <td>
                                                    <div class="text-center">
                                                        <a (click)="downloadImage( ImageType.CIVILID )"
                                                            class="text-dark cursor-pointer"><i
                                                                class="bx bx-download h3 m-0"></i></a>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="avatar-sm">
                                                        <span
                                                            class="avatar-title rounded-circle bg-soft-primary text-primary font-size-24">
                                                            <i class="bx bxs-file-doc"></i>
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <h5 class="font-size-14 mb-1"><a
                                                            (click)="downloadImage( ImageType.RC_IMAGE )"
                                                            class="text-dark cursor-pointer">{{ 'Car RC' | translate
                                                            }}</a></h5>
                                                </td>
                                                <td>
                                                    <div class="text-center">
                                                        <a (click)="downloadImage( ImageType.RC_IMAGE )"
                                                            class="text-dark cursor-pointer"><i
                                                                class="bx bx-download h3 m-0"></i></a>
                                                    </div>
                                                </td>
                                            </tr>
                                            <!-- <tr>
                                                <td>
                                                    <div class="avatar-sm">
                                                        <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-24">
                                                            <i class="bx bxs-file-doc"></i>
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <h5 class="font-size-14"><a href="javascript::void()" class="text-dark">{{ 'orderDetails.documents.declarationPDF' | translate }}</a></h5>
                                                </td>
                                                <td>
                                                    <div class="text-center">
                                                        <a href="javascript::void()" class="text-dark"><i class="bx bx-download h3 m-0"></i></a>
                                                    </div>
                                                </td>
                                            </tr> -->
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end col -->
                </div>
            </div>
        </div>
        <!-- end row -->
    </div> <!-- container-fluid -->
</div>
<!-- End Page-content -->
